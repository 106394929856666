import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { MetricMachineContext } from '../../../../tailwind_clearstep/MetricForm/Context/MetricFormContext';
import CloseIcon from '../../../../tailwind_clearstep/MetricForm/Components/MetricCard/CloseIcon';
import styles from './AppFeedback.css';
import Textarea from '../../../../tailwind_common/Textarea';
import Button from '../../../../tailwind_common/Button';
// TODO: tailwind migration, re-sync with other MetricCard styles
export var AppFeedback = function (props) {
    var saveEvent = props.saveEvent, submitEvent = props.submitEvent, collapseEvent = props.collapseEvent;
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var _a = useState(!!current.context.message), valid = _a[0], setValid = _a[1];
    var t = useTranslation().t;
    // Handles validating input
    // Only saves char entered if valid state is changing to avoid too many save calls (handled by onBlur in general case)
    var handleValid = function (event) {
        var text = event.target.value;
        var currValid = !!text;
        var doSave = (!valid && currValid) || (valid && !currValid);
        if (doSave) {
            saveEvent(text);
        }
        setValid(!!text);
    };
    return (React.createElement("div", { className: styles.appFeedbackCard, tabIndex: -1 },
        React.createElement("div", { className: styles.appFeedbackCard__container },
            React.createElement("div", { className: styles.appFeedbackCard__header },
                React.createElement("h5", null, t('common.giveFeedbackLowerF', 'Give feedback')),
                React.createElement("button", { onClick: collapseEvent },
                    React.createElement(CloseIcon, null))),
            React.createElement("div", { className: styles.appFeedbackCard__container__body },
                React.createElement("p", { className: styles.appFeedbackCard__container__body__description }, t('feedbackModal.general.questionLabel', 'What would you like to share about your experience? We value your opinion.')),
                React.createElement(Textarea, { onChange: handleValid, value: current.context.message, placeholder: t('feedbackModal.general.questionPlaceholder', 'Enter your response, but do not include any personal health information.') || '', onBlur: function (event) { return saveEvent(event.target.value); }, id: "appFeedbackTextarea" })),
            React.createElement("div", { className: styles.appFeedbackCard__container__footer },
                React.createElement("div", { className: styles.appFeedbackCard__container__footer__buttons },
                    React.createElement(Button, { isText: true, onClick: collapseEvent }, t('feedbackModal.general.closeCta', 'Continue later')),
                    React.createElement(Button, { onClick: submitEvent, ariaLabel: "Submit score", disabled: !valid }, t('common.submitCta', 'Submit')))))));
};
