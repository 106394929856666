import React, { useContext } from 'react';
import 'i18next';
import '../../../clearstep/proto/care_locations_pb';
import CareLocation from '../../../clearstep/CareLocation/CareLocation';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import '../../../clearstep/proto/results_pb';
import '../../../../api/ConversationResults';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
export var CareLocationOrPlaceholder = function (props) {
    var careLocation = props.careLocation, hideMap = props.hideMap, hideFindOtherLocations = props.hideFindOtherLocations, findOtherLocationsText = props.findOtherLocationsText, findOtherLocationsUrl = props.findOtherLocationsUrl, sourceOfCare = props.sourceOfCare, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isLoading = props.isLoading, setZipFocus = props.setZipFocus;
    var careType = sourceOfCare.careType;
    var insuranceStatus = props.insuranceStatus, zipCode = props.zipCode;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    return careLocation ? (React.createElement(CareLocation, { location: careLocation, key: careLocation.name, hideMap: hideMap, insuranceStatus: insuranceStatus, zipCode: zipCode, hideFindOtherLocations: hideFindOtherLocations, findOtherLocationsText: findOtherLocationsText, findOtherLocationsUrl: findOtherLocationsUrl, costEstimate: costEstimate, showCostEstimate: showCostEstimate })) : (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus, wrapperClassName: "mb-0" }));
};
