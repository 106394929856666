import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import uniqueId from 'lodash-es/uniqueId';
import '../../clearstep/proto/conversation_pb';
var SingleSelectOption = function (props) {
    var option = props.option, questionId = props.questionId, isSelected = props.isSelected, onSelect = props.onSelect;
    var _a = React.useState(false), isFocused = _a[0], setFocus = _a[1];
    var optionId = uniqueId('single-select-value-');
    return (React.createElement("li", { className: "border-solid border-common-bot-medium-gray", "data-questionId": questionId, "data-optionId": option.conceptId },
        React.createElement("label", { className: "relative flex cursor-pointer flex-row border-0 bg-white text-left text-black transition-all duration-100 hover:bg-option-hover hover:text-primary", "aria-describedby": optionId },
            React.createElement("input", { className: "z-index-neg-1 absolute h-full w-full opacity-0", type: "button", value: option.value, onClick: function () { return onSelect(option); }, onFocus: function () { return setFocus(true); }, onBlur: function () { return setFocus(false); } }),
            React.createElement("span", { id: optionId, className: twMerge([
                    'w-full px-6 py-4 text-left leading-5',
                    (isSelected || isFocused) && 'bg-primary text-white',
                ]), "data-cy": "single-select-option-text" }, option.value))));
};
export default SingleSelectOption;
