import * as React from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import AppHeader from '../AppHeader/AppHeader';
import { i18nInit } from '../../../util/i18n/config';
import { THEME } from '../util/Theme';
import styles from './ErrorPage.css';
import Button from '../../tailwind_common/Button';
var ErrorPage = function (props) {
    var statusCode = props.statusCode;
    var _a = React.useState(false), translationsInit = _a[0], setTranslationsInit = _a[1];
    var t = useTranslation('translation', { useSuspense: false }).t;
    React.useEffect(function () {
        // render Conversation once translations are loaded
        i18nInit(function () { return setTranslationsInit(true); });
    }, []);
    var getErrorHeader = function () {
        switch (statusCode) {
            case 400:
                return t('errorPage.header.400', "We're sorry, an error occurred");
            case 403:
                return t('errorPage.header.403', 'Access forbidden');
            case 404:
                return t('errorPage.header.404', "Oops! We can't find that page.");
            case 500:
                return t('errorPage.header.500', "We're sorry, an error occurred");
            default:
                return '';
        }
    };
    var getErrorText = function () {
        switch (statusCode) {
            case 400:
                return t('errorPage.text.400', "Let's get you back home to try again.");
            case 403:
                return t('errorPage.text.403', "We're sorry, but you are not authorized to view this page. Let's get you back home to find what you're looking for.");
            case 404:
                return t('errorPage.text.404', "Let's get you back home to find what you're looking for.");
            case 500:
                return t('errorPage.text.500', 'Please try again later.');
            default:
                return '';
        }
    };
    if (!translationsInit) {
        return null;
    }
    return (React.createElement(Suspense, { fallback: "" },
        React.createElement("div", { className: styles.errorPage },
            React.createElement(AppHeader, { applyBottomBorder: true }),
            React.createElement("div", { className: styles.errorPage__content },
                React.createElement("div", { className: styles.errorPage__icon },
                    React.createElement("img", { src: "/static/images/illustrations/sick_cozy_at_home.png", alt: t('common.errorImageAlt', 'Sick at home') || '' })),
                React.createElement("div", { className: styles.errorPage__header }, getErrorHeader()),
                React.createElement("div", { className: styles.errorPage__text }, getErrorText()),
                React.createElement("div", { className: styles.errorPage__buttonContainer },
                    React.createElement(Button, { onClick: function () {
                            window.location.href = THEME.partnerUrl;
                        } }, t('errorPage.button', 'Return home')))))));
};
export default ErrorPage;
