var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'i18next';
import { twMerge } from 'tailwind-merge';
import { isEmpty } from 'lodash-es';
import { SourceOfCare } from '../../clearstep/proto/results_pb';
import { ResultStatus, useYesNoOptions, } from '../../clearstep/Types';
import { getURLParamValue, isDemo } from '../../clearstep/util/AppHelpers';
import { getTreatmentWithConfig } from '../../clearstep/util/Split';
import CareOption from '../CareOption/CareOption';
import ResultsCard from '../ResultsCard/ResultsCard';
import Modal from '../../tailwind_common/Modal/Modal.Lazy.Wrapped';
import { THEME } from '../../clearstep/util/Theme';
import { PartnerCustomizationContext } from '../Partners/symptom_check/PartnerCustomizations';
import ZipCodeSubmit from '../../tailwind_common/ZipCodeSubmit';
import { SuccessIcon } from '../../svg/SuccessIcon';
import { logEvent, MessageType } from '../../../util/EventLog';
import { VaccineCareDetailDescriptor } from '../../../util/care_details/VaccineCareDetail';
import { CoronavirusFAQ } from '../../clearstep/CoronavirusFAQ/CoronavirusFAQ';
import { StethoscopeCircleIcon } from '../../svg/StethoscopeCircleIcon';
import FindCareLocation from '../../clearstep/FindCareLocation/FindCareLocation';
import CareCard from '../CareCard/CareCard';
import '../../clearstep/proto/conversation_pb';
import { getSentenceCase, removeDashes } from '../../clearstep/util/RenderHelpers';
import { CovidInformationCareDetailDescriptor } from '../../../util/care_details/CovidInformationCareDetail';
import '../../clearstep/ConversationResults/ConversationResults';
import SearchCareLocationsForm from '../../tailwind_common/SearchCareLocationsForm';
import { getCostEstimate, } from '../../../api/ConversationResults';
import { getCareDetailsForCareType, getCareOptionsForRendering, partnerSupportsCareDetail, } from '../../clearstep/util/CareOptions.util';
import { TestingCareDetail } from '../../../util/care_details/TestingCareDetails';
import '../../clearstep/proto/theme_pb';
import { SpecialistCareDetail } from '../../../util/care_details/SpecialistCareDetail';
import { SuicideLifelineStandalone } from './SuicideLifeline/SuicideLifeline';
import { isEligibilityResult } from '../Partners/symptom_check/CareDetails';
import SuccessMessage from '../../tailwind_common/SuccessMessage';
import Button from '../../tailwind_common/Button';
var CareOptions = function (props) {
    var contentRef = useRef(null);
    var userEnterredZipCode = props.userEnterredZipCode, recommendedCare = props.recommendedCare, conversationId = props.conversationId, searchCareLocationsRequestData = props.searchCareLocationsRequestData;
    var t = useTranslation().t;
    var yesNoOptions = useYesNoOptions();
    var _a = useState(yesNoOptions.No.value), insuranceStatus = _a[0], setInsuranceStatus = _a[1];
    var _b = useState(false), showBookAppointmentModal = _b[0], setShowBookAppointmentModal = _b[1];
    var _c = useState(false), showBookedAppointmentModal = _c[0], setShowBookedAppointmentModal = _c[1];
    var _d = useState(false), zipCodeForSharingSubmitted = _d[0], setZipCodeForSharingSubmitted = _d[1];
    var _e = useState(false), showCostEstimate = _e[0], setShowCostEstimate = _e[1];
    var _f = useState({}), showCostEstimateForCareOption = _f[0], setShowCostEstimateForCareOption = _f[1];
    var _g = useState({
        error: false,
        loading: false,
    }), costEstimate = _g[0], setCostEstimate = _g[1];
    var _h = useState(false), isTherapistSupported = _h[0], setIsTherapistSupported = _h[1];
    var _j = useState(false), zipFocus = _j[0], setZipFocus = _j[1];
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var setRequestData = props.setRequestData;
    useEffect(function () {
        var getCostEstimateSplit = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, getTreatmentWithConfig('show_cost_estimate', {
                        partner: THEME.partnerHostName,
                    })];
            });
        }); };
        var setupShowCostEstimate = function () { return __awaiter(void 0, void 0, void 0, function () {
            var existDiagnosticResources, show, treatment;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        existDiagnosticResources = props.existDiagnosticResources;
                        show = getURLParamValue('estimate') === '1' && existDiagnosticResources;
                        if (!show) return [3 /*break*/, 2];
                        return [4 /*yield*/, getCostEstimateSplit()];
                    case 1:
                        treatment = _d.sent();
                        show =
                            show && ((_a = treatment.config) === null || _a === void 0 ? void 0 : _a.on) && showCostEstimateForOutcome((_b = treatment.config) === null || _b === void 0 ? void 0 : _b.careTypes);
                        setShowCostEstimateForCareOption(((_c = treatment.config) === null || _c === void 0 ? void 0 : _c.careTypes) || {});
                        _d.label = 2;
                    case 2:
                        setShowCostEstimate(show);
                        return [2 /*return*/];
                }
            });
        }); };
        setupShowCostEstimate();
    }, []);
    var showCostEstimateForOutcome = function (showCostEstimateForCareOptionMap) {
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions;
        return careOptions.some(function (careOption) {
            var careType = careOption.careType;
            return showCostEstimateForCareOptionMap[careType];
        });
    };
    var outcomeHasCareType = function (careType, options) {
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions;
        var careDetail = options.careDetail;
        var careOptionInfoForCareType = careOptions.find(function (careOption) { return careOption.careType === careType; });
        return careOptionInfoForCareType
            ? careDetail
                ? careOptionInfoForCareType.careDetails.includes(careDetail)
                : true
            : false;
    };
    var handleChangeZipCode = function (value) {
        setRequestData(__assign(__assign({}, searchCareLocationsRequestData), { zipCode: value }));
    };
    var handleShareZipCode = function () {
        var zipCode = searchCareLocationsRequestData.zipCode;
        setZipCodeForSharingSubmitted(true);
        logEvent(MessageType.USER_POS, {
            zipCode: zipCode,
        }, { partner: THEME.partner });
    };
    var expandBestCareType = function (careType) {
        var _a;
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var bestCareType = triageResponse.bestCareType;
        return ((_a = THEME.symptomCheckCustomizations) === null || _a === void 0 ? void 0 : _a.expandBestCareType) && bestCareType === careType;
    };
    var getFindCareOptionsSubheader = isDemo()
        ? t('findCareOptions.subheader.demo', 'Find in-network care near you')
        : t('common.findCareNearYou', 'Find care near you');
    var handleSearchCareLocations = function (addERFallback) {
        var onSearchCareLocations = props.onSearchCareLocations;
        if (contentRef.current) {
            contentRef.current.focus();
        }
        onSearchCareLocations(searchCareLocationsRequestData, { addERFallback: addERFallback });
        logEvent(null, {}, {
            partner: THEME.partner,
            customData: {
                'external-window': {
                    title: MessageType.LOCATION_SEARCH,
                    address: searchCareLocationsRequestData.zipCode,
                },
            },
        });
    };
    var handleUpdateSearchCareLocationsRequestData = function (type, value) {
        if (type === 'zip') {
            setRequestData(__assign(__assign({}, searchCareLocationsRequestData), { zipCode: value }));
        }
    };
    var renderZipcodeSubmit = function (addERFallback) {
        var userEnterredZipCode = props.userEnterredZipCode, careLocationsError = props.careLocationsError, recommendedCare = props.recommendedCare, conversationId = props.conversationId;
        var zipCode = searchCareLocationsRequestData.zipCode;
        var careOptions = recommendedCare.triageResponse.careOptions;
        return (React.createElement(SearchCareLocationsForm, { onSearchCareLocations: function () { return handleSearchCareLocations(addERFallback); }, careLocationsError: careLocationsError, handleUpdateZipcode: function (value) {
                return handleUpdateSearchCareLocationsRequestData('zip', value);
            }, handleUpdateInsuranceStatus: setInsuranceStatus, initialZipcode: userEnterredZipCode, careOptions: careOptions, zipCode: zipCode, showCostEstimate: showCostEstimate, getCostEstimate: function (params) {
                return retrieveCostEstimate(params, showCostEstimate);
            }, setIsTherapistSupported: function (isSupported) {
                setIsTherapistSupported(isSupported);
            }, conversationId: conversationId, zipFocus: zipFocus, setZipFocus: setZipFocus, setRequestData: setRequestData, requestData: searchCareLocationsRequestData }));
    };
    var retrieveCostEstimate = function (params, showCostEstimate) { return __awaiter(void 0, void 0, void 0, function () {
        var costEstimate_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!showCostEstimate) return [3 /*break*/, 2];
                    setCostEstimate({ loading: true, error: false });
                    return [4 /*yield*/, getCostEstimate(params)];
                case 1:
                    costEstimate_1 = _a.sent();
                    setCostEstimate(costEstimate_1);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var showFindPharmacy = function () {
        return !THEME.symptomCheckCustomizations.hideFindPharmacy &&
            !THEME.covidCustomizations.hideFindPharmacy;
    };
    var renderFindPharmacy = function (hideZipCodeInput) {
        var zipCode = searchCareLocationsRequestData.zipCode;
        var careLocations = props.careLocations, careLocationsError = props.careLocationsError;
        var pharmacies = careLocations[SourceOfCare.Type.HOME];
        var pharmacyLocation = Array.isArray(pharmacies) ? pharmacies[0] : null;
        var loadingPharmacyLocation = careLocations[SourceOfCare.Type.HOME] === ResultStatus.Loading;
        var pharmacyLocationError = careLocations[SourceOfCare.Type.HOME] === ResultStatus.Error;
        var showPlaceholder = (loadingPharmacyLocation || !pharmacyLocation) && !pharmacyLocationError;
        var pharmacyCustomizations = partnerCustomizations.getPharmacyCardCustomizations();
        var _a = __assign({}, pharmacyCustomizations), title = _a.title, icon = _a.icon, description = _a.description, hideFindOtherLocations = _a.hideFindOtherLocations;
        var fallbackTitle = t('careCards.findPharmacy.title', 'Find a pharmacy');
        return (showFindPharmacy() && (React.createElement(FindCareLocation, { zipCode: zipCode, onChange: function (value) { return handleChangeZipCode(value); }, onSubmit: function () { return handleSearchCareLocations(); }, error: careLocationsError, insuranceStatus: insuranceStatus, careLocations: pharmacies, placeholder: showPlaceholder, loading: loadingPharmacyLocation, hideZipCodeInput: hideZipCodeInput, setZipFocus: setZipFocus, componentData: {
                title: title !== null && title !== void 0 ? title : fallbackTitle,
                description: description,
                icon: icon,
                hideFindOtherLocations: hideFindOtherLocations,
            } })));
    };
    var renderShareCovidResultsResource = function () {
        var zipCode = searchCareLocationsRequestData.zipCode;
        var careLocationsError = props.careLocationsError;
        var showShareCovidResults = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {
            careDetail: CovidInformationCareDetailDescriptor.COVID_19_SHARE_RESULTS,
        });
        return showShareCovidResults ? (React.createElement(ResultsCard, null,
            React.createElement("section", null,
                React.createElement("header", null,
                    React.createElement("h3", { className: "text-2xl font-bold leading-7.5 mobile:text-1.5xl mobile:leading-6.5" }, t('stopSpreadZipCodeSubmit.header', "Let's fight COVID-19 together"))),
                React.createElement("div", null,
                    React.createElement("p", null,
                        React.createElement("span", null, t('stopSpreadZipCodeSubmit.subheader', '(optional)')),
                        t('stopSpreadZipCodeSubmit.description', 'Share your current zip code to help us track the spread of infection, facilitate community resources, and build more location-specific features for you in the future.')),
                    React.createElement(ZipCodeSubmit, { cta: t('common.search', 'Search'), placeholder: t('common.enterZipCode', 'Enter zip code') || '', inputAriaLabel: t('common.zipCode', 'Zip code'), label: t('common.zipCode', 'Zip code') || '', initialValue: zipCode, onChange: function (value) { return handleChangeZipCode(value); }, onSubmit: handleShareZipCode, error: careLocationsError })),
                zipCodeForSharingSubmitted && (React.createElement(SuccessMessage, { text: t('stopSpreadZipCodeSubmit.submitConfirmedMessage', 'Thanks for contributing to our efforts!') }))))) : null;
    };
    var renderCovidCareAdvice = function () {
        var recommendedCare = props.recommendedCare;
        var careDetails = getCareDetailsForCareType(recommendedCare, SourceOfCare.Type.INFORMATION);
        var showCovidCareAdvice = careDetails.includes(CovidInformationCareDetailDescriptor.COVID_19_CARE_ADVICE);
        var showVaccineInfo = careDetails.includes(CovidInformationCareDetailDescriptor.COVID_19_VACCINE_INFORMATION);
        var showTestingInfo = careDetails.includes(CovidInformationCareDetailDescriptor.COVID_19_TESTING_INFORMATION);
        if (showVaccineInfo || showTestingInfo) {
            return (React.createElement(ResultsCard, null, partnerCustomizations.partnerOptionWithCareDetail({ careDetails: careDetails })));
        }
        return showCovidCareAdvice ? (React.createElement(ResultsCard, null,
            React.createElement(CoronavirusFAQ, null))) : null;
    };
    var renderCovidTestingResource = function (options) {
        var _a;
        var isAsymptomatic = options.isAsymptomatic, isSymptomatic = options.isSymptomatic, isTestToTreat = options.isTestToTreat;
        var showCovidTesting = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {
            careDetail: CovidInformationCareDetailDescriptor.COVID_19_TESTING_INFORMATION,
        });
        var testingResource = partnerCustomizations.testingResource({
            url: (_a = THEME.covidCustomizations) === null || _a === void 0 ? void 0 : _a.testingSiteLink,
            isAsymptomatic: isAsymptomatic,
            isSymptomatic: isSymptomatic,
            isTestToTreat: isTestToTreat,
        }) || null;
        return showCovidTesting ? testingResource : null;
    };
    var renderCovidVaccineResource = function () {
        var _a;
        var showCovidVaccine = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {
            careDetail: CovidInformationCareDetailDescriptor.COVID_19_VACCINE_INFORMATION,
        });
        var vaccineResource = partnerCustomizations.vaccineResource({
            url: (_a = THEME.covidCustomizations) === null || _a === void 0 ? void 0 : _a.vaccineSiteLink,
        }) || null;
        return showCovidVaccine ? vaccineResource : null;
    };
    var renderInfluenzaVaccineInfo = function () {
        var recommendedCare = props.recommendedCare, careLocations = props.careLocations, isNewPatient = props.isNewPatient, complaint = props.complaint;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions, defaultZip = triageResponse.defaultZip;
        var showInfluenzaVaccineInfo = outcomeHasCareType(SourceOfCare.Type.VACCINE, {
            careDetail: VaccineCareDetailDescriptor.INFLUENZA,
        });
        if (!showInfluenzaVaccineInfo) {
            return null;
        }
        var fluVaccineCareOption = careOptions.find(function (careOption) {
            return careOption.careType === SourceOfCare.Type.VACCINE &&
                careOption.careDetails.includes('influenza');
        });
        if (!fluVaccineCareOption) {
            return null;
        }
        var vaccineCareLocations = careLocations[SourceOfCare.Type.VACCINE];
        var isLoading = function () { return vaccineCareLocations === ResultStatus.Loading; };
        var mixSchedulingAndNonLocations = function () {
            return partnerCustomizations.mixSchedulingAndNonLocations({
                complaint: complaint,
                sourceOfCare: fluVaccineCareOption,
                careLocations: vaccineCareLocations || [],
                zipCode: userEnterredZipCode || defaultZip,
                isNewPatient: isNewPatient,
                costEstimate: costEstimate,
                showCostEstimate: showCostEstimate,
                loading: isLoading(),
                insuranceStatus: insuranceStatus,
                setZipFocus: setZipFocus,
            });
        };
        return partnerCustomizations.renderInfluenzaVaccineResultsCard(fluVaccineCareOption, {
            complaint: props.complaint,
            careLocations: vaccineCareLocations || [],
            zipCode: userEnterredZipCode || defaultZip,
            insuranceStatus: insuranceStatus,
            isNewPatient: props.isNewPatient,
            costEstimate: costEstimate,
            showCostEstimate: showCostEstimate && showCostEstimateForCareOption[SourceOfCare.Type.RETAIL_CLINIC],
            setZipFocus: setZipFocus,
            isLoading: isLoading,
            mixSchedulingAndNonLocations: mixSchedulingAndNonLocations,
        });
    };
    var renderImagingCenterInfo = function () {
        var showImagingCenterInfo = outcomeHasCareType(SourceOfCare.Type.SPECIALIST, {
            careDetail: SpecialistCareDetail.IMAGING_TEST,
        });
        if (showImagingCenterInfo) {
            var careDetails = [SpecialistCareDetail.IMAGING_TEST];
            var imagingCenterCareOption = partnerCustomizations.partnerOptionWithCareDetail({
                careDetails: careDetails,
            });
            if (!imagingCenterCareOption) {
                return null;
            }
            return React.createElement(ResultsCard, { useDropdownRenderStyling: true }, imagingCenterCareOption);
        }
        return null;
    };
    var getCovidConcernedAboutOtherSymptoms = function () { return (React.createElement(CareCard, { title: t('covidCards.concernedAboutOtherSymptoms.title', 'Concerned about other symptoms?') || '', description: t('covidCards.concernedAboutOtherSymptoms.description', 'Answer a few more questions to learn more about your best next steps for care.') || '', buttons: [
            {
                url: '/checksymptoms/',
                cta: t('covidCards.concernedAboutOtherSymptoms.cta', 'Check non-COVID-19 related symptoms'),
            },
        ] })); };
    var renderCovidTestingOutcome = function (options) {
        var isSymptomatic = options.isSymptomatic, isAsymptomatic = options.isAsymptomatic, isTestToTreat = options.isTestToTreat;
        var partnerSupportsTestResults = partnerSupportsCareDetail(SourceOfCare.Type.INFORMATION, CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS, THEME.supportedCareDetails // Protobuf mistypes this map as an array...
        );
        var outcomeHasTestResultDetail = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {
            careDetail: CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS,
        });
        return (React.createElement(React.Fragment, null,
            React.createElement(ResultsCard, null,
                React.createElement("h2", null, t('covidCards.testingResources.title', 'COVID-19 Testing Resources')),
                renderCovidTestingResource({ isSymptomatic: isSymptomatic, isAsymptomatic: isAsymptomatic, isTestToTreat: isTestToTreat })),
            outcomeHasTestResultDetail && partnerSupportsTestResults && (React.createElement(ResultsCard, null, partnerCustomizations.viewCovidTestResultsResource())),
            React.createElement(ResultsCard, null, renderFindPharmacy()),
            React.createElement(ResultsCard, null, getCovidConcernedAboutOtherSymptoms()),
            React.createElement(ResultsCard, null,
                React.createElement(CareCard, { title: t('covidCards.vaccineLearnMore.title', 'Want to learn more about COVID-19 vaccines?') || '', buttons: [
                        {
                            cta: t('covidCards.vaccineLearnMore.cta', 'Get COVID-19 vaccine information'),
                            url: 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/index.html',
                        },
                    ] }))));
    };
    var renderCovidVaccineOutcome = function () { return (React.createElement(React.Fragment, null,
        React.createElement(ResultsCard, null,
            React.createElement("h2", null, t('covidCards.vaccineResources.title', 'COVID-19 Vaccine Resources')),
            renderCovidVaccineResource()),
        React.createElement(ResultsCard, null, renderFindPharmacy()),
        React.createElement(ResultsCard, null, getCovidConcernedAboutOtherSymptoms()),
        React.createElement(ResultsCard, null,
            React.createElement(CareCard, { title: t('covidCards.testingLearnMore.title', 'Want to learn more about COVID-19 testing?') ||
                    '', buttons: [
                    {
                        cta: t('covidCards.testingLearnMore.description', 'Get COVID-19 testing information'),
                        url: 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html',
                    },
                ] })))); };
    var renderCareOption = function (sourceOfCare, expandInitially) {
        if (expandInitially === void 0) { expandInitially = false; }
        var careLocations = props.careLocations, recommendedCare = props.recommendedCare, userEnterredZipCode = props.userEnterredZipCode, age = props.age, complaint = props.complaint, isNewPatient = props.isNewPatient;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions, defaultZip = triageResponse.defaultZip;
        // ER type is rendered by renderEmergencyCareOption
        // INFORMATION and TESTING types are rendered in nonDropdownCareOptions
        // HOME type is rendered in renderNativeCareOptions
        if (!sourceOfCare ||
            sourceOfCare.careType === SourceOfCare.Type.ER ||
            sourceOfCare.careType === SourceOfCare.Type.INFORMATION ||
            sourceOfCare.careType === SourceOfCare.Type.TESTING ||
            sourceOfCare.careType === SourceOfCare.Type.HOME ||
            sourceOfCare.careType === SourceOfCare.Type.UNKNOWN) {
            return null;
        }
        var careType = sourceOfCare.careType, careDetails = sourceOfCare.careDetails;
        var result = careLocations[careType];
        var expandedDefault = isExpanded(sourceOfCare, careOptions, expandInitially);
        var keyTitle = sourceOfCare.title;
        if (careDetails.length > 0) {
            keyTitle = getSentenceCase(removeDashes(careDetails[0]));
        }
        return (React.createElement(CareOption, { zocdocBaseLink: recommendedCare === null || recommendedCare === void 0 ? void 0 : recommendedCare.zocdocBaseLink, complaint: complaint, key: keyTitle, id: sourceOfCare.title.replace(/\s/g, '-'), age: age, sourceOfCare: sourceOfCare, careLocations: result || [], onOpenBookAppointmentModal: function () { return setShowBookAppointmentModal(true); }, zipCode: userEnterredZipCode || defaultZip, insuranceStatus: insuranceStatus, expandedDefault: expandedDefault, isNewPatient: isNewPatient, costEstimate: costEstimate, showCostEstimate: showCostEstimate && showCostEstimateForCareOption[careType], isTherapistSupported: isTherapistSupported, setZipFocus: setZipFocus, conversationId: conversationId }));
    };
    var isExpanded = function (sourceOfCare, careOptions, expandInitially) {
        if (expandInitially === void 0) { expandInitially = false; }
        var isAlwaysCollapsed = partnerCustomizations.isAlwaysCollapsed(sourceOfCare);
        if (isAlwaysCollapsed) {
            return false;
        }
        var isAlwaysExpanded = partnerCustomizations.isAlwaysExpanded(sourceOfCare);
        if (isAlwaysExpanded) {
            return true;
        }
        var careType = sourceOfCare.careType;
        var preferredCareOption = partnerCustomizations.preferredCareOption(careOptions);
        var preferred = careType === (preferredCareOption === null || preferredCareOption === void 0 ? void 0 : preferredCareOption.careType);
        return expandInitially || preferred || expandBestCareType(careType);
    };
    var renderHomeCareOption = function () {
        var careLocations = props.careLocations, complaint = props.complaint, isNewPatient = props.isNewPatient, recommendedCare = props.recommendedCare, userEnterredZipCode = props.userEnterredZipCode, age = props.age;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions, defaultZip = triageResponse.defaultZip;
        var telemedicineCareOption = careOptions.find(function (careOption) { return careOption.careType === SourceOfCare.Type.TELEMEDICINE; });
        var homeCareOption = careOptions.find(function (opt) { return opt.careType === SourceOfCare.Type.HOME; });
        var showZipInputAtTop = !!(partnerCustomizations.homeCareProviderOptionNeedsZipcodeInput() && telemedicineCareOption); // only show for partners with zipcode-searchable telemed option
        var homeCareParams = {
            careLocations: careLocations[SourceOfCare.Type.TELEMEDICINE] || [],
            complaint: complaint,
            zipCode: userEnterredZipCode || defaultZip || '',
            isNewPatient: isNewPatient,
            sourceOfCare: telemedicineCareOption || {},
            insuranceStatus: insuranceStatus,
            age: age,
            careType: SourceOfCare.Type.TELEMEDICINE,
            loading: false,
            isCovid: false,
            showCostEstimate: showCostEstimate,
            homeCareOption: homeCareOption,
            setZipFocus: setZipFocus,
        };
        var renderTelemedicine = !!telemedicineCareOption && !!partnerCustomizations.homeCareProviderOption(homeCareParams);
        return (React.createElement(React.Fragment, null,
            showZipInputAtTop && (React.createElement("div", { className: "mt-6" },
                React.createElement("h3", { className: twMerge('mb-4 text-[26.569px] font-semibold leading-6.25 tracking-[0.85px]') }, getFindCareOptionsSubheader),
                renderZipcodeSubmit())),
            renderTelemedicine && (React.createElement(ResultsCard, null, partnerCustomizations.homeCareProviderOption(homeCareParams))),
            renderFindPharmacy(showZipInputAtTop) && (React.createElement(ResultsCard, null, renderFindPharmacy(showZipInputAtTop)))));
    };
    var getEmergencyCareOptionOverride = function (emergentCareType, careOptions) {
        var emergencyCareOption = careOptions.find(function (careOption) { return careOption.careType === emergentCareType; });
        if (!emergencyCareOption) {
            return null;
        }
        var emergencyCareCard = partnerCustomizations.emergencyCareOptionOverride(emergencyCareOption);
        if (emergencyCareCard) {
            return emergencyCareCard;
        }
        return null;
    };
    var renderLaborAndDeliveryOption = function () {
        return partnerCustomizations.laborAndDeliveryOption() ||
            renderEmergencyCareOption(SourceOfCare.Type.ER);
    };
    var renderCovidRelatedEmergencyOption = function (showHospital) {
        var partnerCovidER = partnerCustomizations.covidRelatedEmergencyResponse();
        var partnerER = renderEmergencyCareOption(SourceOfCare.Type.ER);
        var partnerAdditionalGuidance = partnerCustomizations.emergencyAdditionalGuidanceOption();
        return partnerCovidER ? (React.createElement(React.Fragment, null,
            React.createElement(ResultsCard, null,
                React.createElement("h2", null, t('common.findCareNearYou', 'Find care near you')),
                partnerCovidER),
            React.createElement(ResultsCard, null, partnerAdditionalGuidance))) : showHospital ? (partnerER) : null;
    };
    var renderEmergencyCareOption = function (emergentCareType) {
        var recommendedCare = props.recommendedCare, careLocations = props.careLocations, careLocationsError = props.careLocationsError;
        var _a = recommendedCare.triageResponse, bestCareType = _a.bestCareType, careOptions = _a.careOptions;
        var zipCode = searchCareLocationsRequestData.zipCode;
        var hospitals = careLocations[bestCareType];
        var hospitalLocation = Array.isArray(hospitals) ? hospitals[0] : null;
        var loadingHospitalLocation = hospitals === ResultStatus.Loading;
        var hospitalLocationError = hospitals === ResultStatus.Error;
        var showPlaceholder = (loadingHospitalLocation || !hospitalLocation) && !hospitalLocationError;
        var additionalGuidance = partnerCustomizations.emergencyAdditionalGuidanceOption();
        var renderSuicideLifelineCard = outcomeHasCareType(SourceOfCare.Type.SPECIALIST, {
            careDetail: SpecialistCareDetail.SUICIDE_LIFELINE,
        });
        var emergentOverrideCard = getEmergencyCareOptionOverride(emergentCareType, careOptions);
        if (emergentOverrideCard) {
            return emergentOverrideCard;
        }
        var erCustomizations = partnerCustomizations.getERCardCustomizations(recommendedCare.triageResponse.primaryCareDescription);
        var _b = __assign({}, erCustomizations), primaryCareDescription = _b.description, hideFindOtherLocations = _b.hideFindOtherLocations, findOtherLocationsUrl = _b.findOtherLocationsUrl, icon = _b.icon, title = _b.title;
        var emergencyCareCardTitle = title || t('careCards.emergency.title', 'Find a hospital');
        return (React.createElement(React.Fragment, null,
            React.createElement(ResultsCard, null,
                React.createElement(FindCareLocation, { zipCode: zipCode, onChange: function (value) { return handleChangeZipCode(value); }, onSubmit: function () { return handleSearchCareLocations(); }, insuranceStatus: insuranceStatus, careLocations: hospitals, placeholder: showPlaceholder, error: careLocationsError, loading: loadingHospitalLocation, setZipFocus: setZipFocus, componentData: {
                        title: emergencyCareCardTitle,
                        heading: 'h2',
                        description: primaryCareDescription,
                        icon: icon || React.createElement(StethoscopeCircleIcon, null),
                        hideFindOtherLocations: hideFindOtherLocations,
                        findOtherLocationsUrl: findOtherLocationsUrl,
                    } })),
            !!additionalGuidance && React.createElement(ResultsCard, null, additionalGuidance),
            renderSuicideLifelineCard && React.createElement(SuicideLifelineStandalone, null)));
    };
    var renderUrgentCareEmergencyOption = function (hideZipCodeInput) {
        var careLocations = props.careLocations, careLocationsError = props.careLocationsError;
        var zipCode = searchCareLocationsRequestData.zipCode;
        var emergencyRooms = careLocations[SourceOfCare.Type.ER];
        var emergencyRoomLocation = Array.isArray(emergencyRooms) ? emergencyRooms[0] : null;
        var loadingEmergencyRoomLocation = emergencyRooms === ResultStatus.Loading;
        var emergencyRoomLocationError = emergencyRooms === ResultStatus.Error;
        var showPlaceholder = (loadingEmergencyRoomLocation || !emergencyRoomLocation) && !emergencyRoomLocationError;
        var erFallbackOverride = partnerCustomizations.erFallbackOverride();
        if (erFallbackOverride) {
            return erFallbackOverride;
        }
        var erFallbackCustomizations = partnerCustomizations.getERFallbackCardCustomizations();
        var _a = __assign({}, erFallbackCustomizations), title = _a.title, primaryCareDescription = _a.description, hideFindOtherLocations = _a.hideFindOtherLocations, findOtherLocationsUrl = _a.findOtherLocationsUrl;
        var fallbackTitle = t('careCards.urgentEmergency.title', 'Unable to see or chat with a doctor? Go to the ER now');
        return (React.createElement(ResultsCard, null,
            React.createElement(FindCareLocation, { zipCode: zipCode, onChange: function (value) { return handleChangeZipCode(value); }, onSubmit: function () { return handleSearchCareLocations(true); }, insuranceStatus: insuranceStatus, careLocations: emergencyRooms, error: careLocationsError, placeholder: showPlaceholder, loading: loadingEmergencyRoomLocation, setZipFocus: setZipFocus, componentData: {
                    title: title !== null && title !== void 0 ? title : fallbackTitle,
                    description: primaryCareDescription,
                    hideFindOtherLocations: hideFindOtherLocations,
                    findOtherLocationsUrl: findOtherLocationsUrl,
                }, hideZipCodeInput: hideZipCodeInput })));
    };
    var renderBookedAppointmentModalContent = function () { return (React.createElement("div", { className: "flex flex-col items-center text-center" },
        React.createElement(SuccessIcon, null),
        React.createElement("div", { className: "max-w-150 pb-6 pt-4 text-xl leading-6.5 text-dark-gray" }, t('appointmentBookedModal.confirmation', "We've confirmed your appointment. Check your email for appointment reminders and additional information.")),
        React.createElement(Button, { onClick: function () { return setShowBookAppointmentModal(false); } }, t('appointmentBookedModal.closeCta', 'Back to results')))); };
    var renderCovidDoctorTriageResponse = function () { return (React.createElement(React.Fragment, null,
        renderDropdownCareOptions(),
        partnerCustomizations.renderLatestCovidInformationCard() && (React.createElement(ResultsCard, null, partnerCustomizations.renderLatestCovidInformationCard())),
        showFindPharmacy() && React.createElement(ResultsCard, null, renderFindPharmacy()))); };
    var renderPoisonControlAdditionalGuidance = function (careOptions) {
        var careTypes = careOptions.map(function (careOption) { return careOption.careType; });
        var additionalGuidance = partnerCustomizations.emergencyAdditionalGuidanceOption();
        return (careTypes.includes(SourceOfCare.Type.POISON_CONTROL) &&
            additionalGuidance && React.createElement(ResultsCard, null, additionalGuidance));
    };
    var renderCovidCareOptions = function () {
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var bestCareType = triageResponse.bestCareType;
        var isInformationOutcome = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {});
        var isTestingOutcome = outcomeHasCareType(SourceOfCare.Type.TESTING, {});
        var isAsymptomaticCovidTesting = false;
        var isSymptomaticCovidTesting = false;
        var isTestToTreatCovid = false;
        if (isTestingOutcome) {
            isAsymptomaticCovidTesting = outcomeHasCareType(SourceOfCare.Type.TESTING, {
                careDetail: TestingCareDetail.ASYMPTOMATIC_COVID_TESTING,
            });
            isSymptomaticCovidTesting = outcomeHasCareType(SourceOfCare.Type.TESTING, {
                careDetail: TestingCareDetail.SYMPTOMATIC_COVID_TESTING,
            });
            isTestToTreatCovid = outcomeHasCareType(SourceOfCare.Type.TESTING, {
                careDetail: TestingCareDetail.TEST_TO_TREAT_COVID,
            });
        }
        var showHospital = !THEME.covidCustomizations.hideFindHospital;
        switch (bestCareType) {
            case SourceOfCare.Type.TELEMEDICINE:
            case SourceOfCare.Type.PRIMARY_CARE:
            case SourceOfCare.Type.URGENT_CARE:
            case SourceOfCare.Type.HOME: {
                return renderCovidDoctorTriageResponse();
            }
            case SourceOfCare.Type.ER:
            case SourceOfCare.Type.AMBULANCE:
                return renderCovidRelatedEmergencyOption(showHospital);
            case SourceOfCare.Type.VACCINE:
                return isInformationOutcome && renderCovidVaccineOutcome();
            case SourceOfCare.Type.TESTING:
                return (isInformationOutcome &&
                    renderCovidTestingOutcome({
                        isSymptomatic: isSymptomaticCovidTesting,
                        isAsymptomatic: isAsymptomaticCovidTesting,
                        isTestToTreat: isTestToTreatCovid,
                    }));
            default:
                return null;
        }
    };
    // Special case for fixed flow where only the test results card is shown
    var renderCovidTestResults = function () {
        var partnerSupportsTestResults = partnerSupportsCareDetail(SourceOfCare.Type.INFORMATION, CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS_ONLY, THEME.supportedCareDetails // Protobuf mistypes this map as an array...
        );
        var outcomeHasTestResultDetail = outcomeHasCareType(SourceOfCare.Type.INFORMATION, {
            careDetail: CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS_ONLY,
        });
        if (outcomeHasTestResultDetail && partnerSupportsTestResults) {
            return React.createElement(ResultsCard, null, partnerCustomizations.viewCovidTestResultsResource());
        }
        return null;
    };
    var renderEligibilityResult = function () {
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions;
        if (careOptions.length !== 1) {
            return null;
        }
        var _a = careOptions[0], careType = _a.careType, careDetails = _a.careDetails;
        if (careOptions.length === 1 &&
            isEligibilityResult(careType, careDetails) &&
            !!partnerCustomizations.partnerOptionWithCareDetail({ careDetails: careDetails })) {
            return (React.createElement(ResultsCard, { useDropdownRenderStyling: true }, partnerCustomizations.partnerOptionWithCareDetail({ careDetails: careDetails })));
        }
        return null;
    };
    var renderNativeCareOptions = function () {
        var recommendedCare = props.recommendedCare;
        var triageResponse = recommendedCare.triageResponse;
        var bestCareType = triageResponse.bestCareType;
        switch (bestCareType) {
            case SourceOfCare.Type.HOME:
                return renderHomeCareOption();
            case SourceOfCare.Type.ER:
                return renderEmergencyCareOption(SourceOfCare.Type.ER);
            case SourceOfCare.Type.AMBULANCE:
                return renderEmergencyCareOption(SourceOfCare.Type.AMBULANCE);
            case SourceOfCare.Type.LABOR_AND_DELIVERY:
                return renderLaborAndDeliveryOption();
            default:
                return null;
        }
    };
    var renderShinglesVaccineCareOption = function () {
        if (partnerCustomizations.shinglesVaccineCareOption()) {
            return partnerCustomizations.shinglesVaccineCareOption();
        }
        return null;
    };
    var renderVaccineInfo = function () {
        var triageResponse = recommendedCare.triageResponse;
        var careOptions = triageResponse.careOptions;
        var showShinglesVaccineInfo = outcomeHasCareType(SourceOfCare.Type.VACCINE, {
            careDetail: VaccineCareDetailDescriptor.SHINGLES,
        });
        if (showShinglesVaccineInfo) {
            var shinglesVaccineCareOption = findVaccineCareOption(careOptions, VaccineCareDetailDescriptor.SHINGLES);
            if (!shinglesVaccineCareOption) {
                return null;
            }
            return React.createElement(ResultsCard, null, renderShinglesVaccineCareOption());
        }
        return null;
    };
    var findVaccineCareOption = function (careOptions, targetDetail) {
        return careOptions.find(function (careOption) {
            return careOption.careType === SourceOfCare.Type.VACCINE &&
                careOption.careDetails.includes(targetDetail);
        });
    };
    var renderNonDropdownCareOptions = function () {
        var complaint = props.complaint, conversationType = props.conversationType;
        if (renderCovidTestResults()) {
            return renderCovidTestResults();
        }
        if (complaint === 'covid-19' ||
            (conversationType === 'NATIVE_CORONA' && renderCovidCareOptions())) {
            return (React.createElement(React.Fragment, null,
                renderCovidCareOptions(),
                renderCovidCareAdvice(),
                renderShareCovidResultsResource()));
        }
        if (renderNativeCareOptions()) {
            return renderNativeCareOptions();
        }
        if (renderEligibilityResult()) {
            return renderEligibilityResult();
        }
        if (renderVaccineInfo()) {
            return renderVaccineInfo();
        }
        if (renderImagingCenterInfo()) {
            return renderImagingCenterInfo();
        }
        return null;
    };
    // If bestCareType is URGENT_CARE or a careType of ER is present, show "Unable to see or chat with a doctor? Go to ER now." card.
    var showERFallbackCareOption = function (bestCareType, careOptions) {
        if (careOptions.some(function (careOption) { return careOption.careType === SourceOfCare.Type.ER; })) {
            return true;
        }
        return bestCareType === SourceOfCare.Type.URGENT_CARE;
    };
    var renderDropdownCareOptions = function () {
        var _a;
        var recommendedCare = props.recommendedCare, careLocations = props.careLocations, complaint = props.complaint, isNewPatient = props.isNewPatient;
        var triageResponse = recommendedCare.triageResponse;
        var bestCareType = triageResponse.bestCareType, careOptions = triageResponse.careOptions;
        var careOptionsForRendering = getCareOptionsForRendering(careOptions);
        var zipCode = searchCareLocationsRequestData.zipCode;
        var showZipCodeInput = partnerCustomizations.showZipcodeInput(careOptions);
        var showUrgentCareEmergencyOption = showERFallbackCareOption(bestCareType, careOptions);
        var pharmacyOption = partnerCustomizations.pharmacyOptionForHomeTriageRec({
            insuranceStatus: insuranceStatus,
        });
        var preferredCareOption = partnerCustomizations.preferredCareOption(careOptions);
        var primaryCareOption = careOptions.find(function (careOption) { return careOption.careType === SourceOfCare.Type.PRIMARY_CARE; });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex flex-col" },
                isEmpty((_a = triageResponse.newTriageDesign) === null || _a === void 0 ? void 0 : _a.triageSummary) && (React.createElement("h2", { className: "text-3.5xl font-bold leading-11.5 mobile:hidden" }, t('common.findCareNearYou', 'Find care near you'))),
                preferredCareOption && (React.createElement("div", { className: "mt-6" }, renderCareOption(preferredCareOption))),
                !THEME.symptomCheckCustomizations.hideZipCodeInput && (React.createElement("div", { className: "mt-6" }, showZipCodeInput && renderZipcodeSubmit(showUrgentCareEmergencyOption))),
                React.createElement("div", { className: "mt-8 flex flex-col", ref: contentRef, tabIndex: -1 },
                    careOptionsForRendering.map(function (careOption, index) {
                        if (index === 0) {
                            return renderCareOption(careOption, true);
                        }
                        return renderCareOption(careOption);
                    }),
                    showUrgentCareEmergencyOption && renderUrgentCareEmergencyOption(showZipCodeInput),
                    pharmacyOption && pharmacyOption),
                React.createElement(Modal, { isOpen: showBookAppointmentModal, onClose: function () { return setShowBookAppointmentModal(false); }, title: t('common.bookAppointment', 'Book appointment') }, primaryCareOption &&
                    partnerCustomizations.appointmentScheduling({
                        careLocations: careLocations[SourceOfCare.Type.PRIMARY_CARE] || [],
                        complaint: complaint,
                        zipCode: zipCode,
                        sourceOfCare: primaryCareOption,
                        isNewPatient: isNewPatient,
                        loading: careLocations[SourceOfCare.Type.PRIMARY_CARE] === ResultStatus.Loading,
                        costEstimate: costEstimate,
                        showCostEstimate: showCostEstimate,
                        setZipFocus: setZipFocus,
                    })),
                React.createElement(Modal, { isOpen: showBookedAppointmentModal, onClose: function () { return setShowBookedAppointmentModal(false); }, title: t('appointmentBookedModal.title', 'Your appointment confirmation') }, renderBookedAppointmentModalContent())),
            partnerCustomizations.renderAllCareOptionsCTA(),
            renderInfluenzaVaccineInfo(),
            renderPoisonControlAdditionalGuidance(careOptions)));
    };
    return renderNonDropdownCareOptions() ? (React.createElement(React.Fragment, null,
        renderNonDropdownCareOptions(),
        partnerCustomizations.renderAllCareOptionsCTA(),
        renderInfluenzaVaccineInfo())) : (renderDropdownCareOptions());
};
export default CareOptions;
