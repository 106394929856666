import * as React from 'react';
import { twMerge } from 'tailwind-merge';
var Loading = function (props) {
    var innerDivStyleTW = 'w-full h-full absolute border-4 border-primary border-solid bg-transparent opacity-90 rounded-full transform-origin-center animate-ripple';
    return (React.createElement("div", { className: "flex flex-col items-center justify-center" },
        React.createElement("div", { className: "relative inline-block h-16 w-16 mobile:h-8 mobile:w-8" },
            React.createElement("div", { className: innerDivStyleTW }),
            React.createElement("div", { className: twMerge(innerDivStyleTW, 'animation-delay-700') })),
        props.text && React.createElement("p", { className: "pt-5 text-center text-[18px] font-semibold" }, props.text)));
};
export default Loading;
