import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DoctorCareOptionIcon } from '../../../svg/DoctorCareOptionIcon';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import ResultsCard from '../../ResultsCard/ResultsCard';
import { isSmallScreen } from '../../../clearstep/util/AppHelpers';
export var SuicideLifelineStandalone = function () {
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var t = useTranslation().t;
    var heightAndWidthSize = isSmallScreen() ? '28' : '56';
    return (React.createElement(ResultsCard, null,
        React.createElement("header", null,
            React.createElement(DoctorCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize }),
            React.createElement("h1", null, t('careCards.suicideLifeline.title', 'Call the suicide lifeline'))),
        partnerCustomizations.renderSuicideLifelineCard()));
};
