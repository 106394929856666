import { useTranslation } from 'react-i18next';
export var useCareRecDescriptionGroups = function () {
    var t = useTranslation().t;
    return [
        {
            color: 'bg-green-700',
            id: 1,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.home.title', 'Self Care at Home'),
                    description: t('careRecSlideover.careOptions.home.description', 'Not likely to need medical evaluation at this point, and can likely be managed at home with over-the-counter treatments.'),
                },
            ],
        },
        {
            color: 'bg-yellow-500',
            id: 2,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.primaryCareOfficeHours.title', 'Seek In-Person or Virtual Care during normal office hours'),
                    description: t('careRecSlideover.careOptions.primaryCareOfficeHours.description', 'Not likely to need urgent evaluation, and symptoms best assessed by a general medical provider who is qualified to diagnose and evaluate a wide variety of medical conditions.'),
                },
                {
                    title: t('careRecSlideover.careOptions.primaryCare24Hours.title', 'Seek In-Person or Virtual Care during - Within 24 Hours'),
                    description: t('careRecSlideover.careOptions.primaryCare24Hours.description', 'Symptoms best assessed urgently by a general medical provider who is qualified to diagnose and evaluate a wide variety of medical conditions.'),
                },
                {
                    title: t('careRecSlideover.careOptions.primaryCareToday.title', 'Seek In-Person or Virtual Care - Today'),
                    description: t('careRecSlideover.careOptions.primaryCareToday.description', 'Symptoms best assessed right away by a general medical provider who is qualified to diagnose and evaluate a wide variety of medical conditions.'),
                },
            ],
        },
        {
            color: 'bg-yellow-500',
            id: 3,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.specialist.title', 'See a Specialist'),
                    description: t('careRecSlideover.careOptions.specialist.description', 'Symptoms best assessed by a medical provider who is an expert in their field. This could include mental health specialists, dentists, orthopedic surgeons, poison control, and other specialists.'),
                },
            ],
        },
        {
            color: 'bg-orange-400',
            id: 4,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.urgentCare.title', 'Go to Urgent Care'),
                    description: t('careRecSlideover.careOptions.urgentCare.description', 'Symptoms are not likely to be life-threatening, but may require a medical provider who can evaluate quickly.'),
                },
            ],
        },
        {
            color: 'bg-red-500',
            id: 5,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.er.title', 'Go to the Emergency Room'),
                    description: t('careRecSlideover.careOptions.er.description', 'Symptoms may be a very urgent medical emergency. The ER is the best place to get an immediate assessment by a qualified medical provider.'),
                },
            ],
        },
        {
            color: 'bg-red-800',
            id: 6,
            careRecDescriptions: [
                {
                    title: t('careRecSlideover.careOptions.911.title', 'Call 911 Now'),
                    description: t('careRecSlideover.careOptions.911.description', 'Symptoms may be a serious life-threatening medical emergency, and 911 is the best way to get immediate medical care.'),
                },
            ],
        },
    ];
};
