import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CareLocationError.css';
import { ErrorIcon } from '../../svg/ErrorIcon';
var CareLocationError = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles.careLocationError },
        React.createElement(ErrorIcon, { fill: "#ffffff" }),
        React.createElement("h5", null, t('careLocation.error.noneFound', "We can't find any care locations for that zip code right now. Please try another zip code, or try again later."))));
};
export default CareLocationError;
