import React from 'react';
import { twMerge } from 'tailwind-merge';
import './careRecDescriptions';
import CareRecDescription from './CareRecDescription';
export var CareRecDescriptionGroup = function (props) {
    var careRecDescriptionGroup = props.careRecDescriptionGroup;
    return (React.createElement("div", { className: "flex border-x-0 border-b border-t-0 border-solid border-gray-200 px-4 py-3 last:border-0 md:px-6 md:py-4" },
        React.createElement("div", { className: twMerge(careRecDescriptionGroup.color, 'mr-2 w-2 shrink-0 grow-0 basis-2 rounded-md') }),
        React.createElement("div", { className: "shrink grow basis-0" }, careRecDescriptionGroup.careRecDescriptions.map(function (careRecDescription) { return (React.createElement(CareRecDescription, { careRecDescription: careRecDescription, key: careRecDescription.title })); }))));
};
