import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../clearstep/proto/care_locations_pb';
import '../../clearstep/proto/partners_pb';
import Button from '../../tailwind_common/Button';
import { MessageType, linkToAndLogConvo } from '../../../util/EventLog';
import { THEME } from '../../clearstep/util/Theme';
export var NoAvailableAppointments = function (props) {
    var t = useTranslation().t;
    var schedule = props.schedule;
    return (React.createElement("div", { className: "mt-4 flex flex-col items-center" },
        React.createElement("p", { className: "pl-2 text-base font-normal text-gray-700" }, (schedule && schedule.error) ||
            t('careLocation.bookingAppointment.noAvailableAppointments.alertText', 'This provider does not have any open appointments available in the next 4 weeks.')),
        React.createElement("div", { className: "mt-4 flex w-full flex-col" },
            React.createElement(Button, { onClick: function () {
                    return linkToAndLogConvo(props.availabilityLink || '', MessageType.CLICKED_PARTNER_CARE_OPTION, {
                        partner: THEME.partner,
                    });
                }, useMarginTop: false, className: "h-fit w-full", innerClassName: "font-normal" }, t('careLocation.bookingAppointment.nonSelectedAppointmentText', 'See more availability')))));
};
