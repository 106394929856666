import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { restartConversation } from '../../clearstep/util/AppHelpers';
import Button from '../../tailwind_common/Button';
var UserAgeHelper = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: "mt-2" },
        React.createElement("div", { role: "alert" },
            React.createElement("p", { className: "mb-1 text-base font-semibold tracking-tight" }, t('ageInput.tooYoungErrorHelper.title', 'Answering on behalf of a minor?')),
            React.createElement("p", { className: "mb-4 text-base font-normal text-gray-500" }, t('ageInput.tooYoungErrorHelper.text', 'Please restart and select answering for "Someone Else"'))),
        React.createElement(Button, { onClick: restartConversation, secondary: true, className: "mobile:w-full" }, t('common.restart', 'Restart'))));
};
export default UserAgeHelper;
