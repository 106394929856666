import * as React from 'react';
export var RightArrow = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? 'var(--primary-color)' : _b;
    return (React.createElement("svg", { className: "rightArrow", width: "16", height: "12", viewBox: "0 0 16 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M15.7286 6.53033C16.0214 6.23744 16.0214 5.76256 15.7286 5.46967L10.9556 0.696699C10.6627 0.403806 10.1878 0.403806 9.89493 0.696699C9.60203 0.989592 9.60203 1.46447 9.89493 1.75736L14.1376 6L9.89493 10.2426C9.60203 10.5355 9.60203 11.0104 9.89493 11.3033C10.1878 11.5962 10.6627 11.5962 10.9556 11.3033L15.7286 6.53033ZM0 6.75H15.1982V5.25H0L0 6.75Z", style: { fill: fill } })));
};
export var LeftArrow = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? 'var(--primary-color)' : _b;
    return (React.createElement("svg", { className: "leftArrow", width: "16", height: "12", viewBox: "0 0 16 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M0.469669 6.53033C0.176776 6.23744 0.176776 5.76256 0.469669 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989592 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.469669 6.53033ZM16 6.75H0.999999V5.25H16V6.75Z", style: { fill: fill } })));
};
