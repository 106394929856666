var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import 'i18next';
import { SourceOfCare } from '../../../clearstep/proto/results_pb';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../../api/ConversationResults';
import '../../../clearstep/Types';
import PoisonControlCareOption from './PoisonControlCareOption';
import VaccineCareOption from './VaccineCareOption';
import { PrimaryCareOption } from './PrimaryCareOption';
import { RetailClinicOption } from './RetailClinicOption';
import { LabOption } from './LabOption';
import { DentistOption } from './DentistOption';
import { SpecialistOption } from './SpecialistOption';
import { UrgentCareOption } from './UrgentCareOption';
import { AsynchCareOption } from './AsynchCareOption';
import { TelemedicineCareOption } from './TelemedicineCareOption';
export var CareOptionContentByCareType = function (props) {
    var zipCode = props.zipCode, sourceOfCare = props.sourceOfCare, isNewPatient = props.isNewPatient, careLocations = props.careLocations, complaint = props.complaint, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, setZipFocus = props.setZipFocus, isLoading = props.isLoading;
    var careType = sourceOfCare.careType;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var loading = isLoading();
    var appointmentScheduling = partnerCustomizations.appointmentScheduling({
        careLocations: careLocations,
        complaint: complaint,
        zipCode: zipCode,
        sourceOfCare: sourceOfCare,
        isNewPatient: isNewPatient,
        loading: loading,
        costEstimate: costEstimate,
        showCostEstimate: showCostEstimate,
        setZipFocus: setZipFocus,
    });
    var mixSchedulingAndNonLocations = function () {
        var zipCode = props.zipCode, sourceOfCare = props.sourceOfCare, isNewPatient = props.isNewPatient, careLocations = props.careLocations, complaint = props.complaint, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, insuranceStatus = props.insuranceStatus, setZipFocus = props.setZipFocus;
        var loading = isLoading();
        return partnerCustomizations.mixSchedulingAndNonLocations({
            complaint: complaint,
            sourceOfCare: sourceOfCare,
            careLocations: careLocations,
            zipCode: zipCode,
            isNewPatient: isNewPatient,
            costEstimate: costEstimate,
            showCostEstimate: showCostEstimate,
            loading: loading,
            insuranceStatus: insuranceStatus,
            setZipFocus: setZipFocus,
        });
    };
    var renderValidCareLocation = function (careLocations, careDetails) {
        if (Array.isArray(careLocations) && careLocations.length === 1) {
            return careLocations[0];
        }
        if (Array.isArray(careLocations) && careLocations.length > 1) {
            var validLocation = careLocations.find(function (careLocation) {
                return careDetails.some(function (careDetail) { return careLocation.careDetails.includes(careDetail); }) ||
                    !careDetails ||
                    careDetails.length === 0;
            });
            if (validLocation) {
                return validLocation;
            }
        }
        return null;
    };
    var renderCareOptionByCareType = function () {
        if (appointmentScheduling) {
            return appointmentScheduling;
        }
        switch (careType) {
            case SourceOfCare.Type.TELEMEDICINE:
                return (React.createElement(TelemedicineCareOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations })));
            case SourceOfCare.Type.PRIMARY_CARE:
                return (React.createElement(PrimaryCareOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations })));
            case SourceOfCare.Type.POISON_CONTROL:
                return React.createElement(PoisonControlCareOption, __assign({}, props));
            case SourceOfCare.Type.URGENT_CARE:
                return (React.createElement(UrgentCareOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations })));
            case SourceOfCare.Type.RETAIL_CLINIC:
                return (React.createElement(RetailClinicOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations })));
            case SourceOfCare.Type.ASYNCH_CARE_ASTHMA_MEDICATION_REFILL:
            case SourceOfCare.Type.ASYNCH_CARE_COLD_SORES:
            case SourceOfCare.Type.ASYNCH_CARE_ECZEMA:
            case SourceOfCare.Type.ASYNCH_CARE:
                return (React.createElement(AsynchCareOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations })));
            case SourceOfCare.Type.DENTIST:
                return (React.createElement(DentistOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations, renderValidCareLocation: renderValidCareLocation })));
            case SourceOfCare.Type.VACCINE:
                return React.createElement(VaccineCareOption, __assign({}, props, { renderValidCareLocation: renderValidCareLocation }));
            case SourceOfCare.Type.SPECIALIST:
                return (React.createElement(SpecialistOption, __assign({}, props, { mixSchedulingAndNonLocations: mixSchedulingAndNonLocations, renderValidCareLocation: renderValidCareLocation })));
            case SourceOfCare.Type.BEHAVIORAL_HEALTH:
                return partnerCustomizations.behavioralHealthOption({
                    behavioralHealthOption: sourceOfCare,
                });
            case SourceOfCare.Type.LAB:
                return React.createElement(LabOption, __assign({}, props));
            case SourceOfCare.Type.CARDIAC_EVALUATION_CENTER:
                return partnerCustomizations.cardiacEvaluationOption();
            default:
                return null;
        }
    };
    return React.createElement(React.Fragment, null, renderCareOptionByCareType());
};
export default CareOptionContentByCareType;
