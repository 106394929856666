import React from 'react';
import { twMerge } from 'tailwind-merge';
export var MetricPrompt = function (props) {
    var promptText = props.promptText, promptSubtext = props.promptSubtext;
    return (React.createElement("p", { className: twMerge('mb-4 text-base text-gray-900', 'mobile:mb-6') },
        React.createElement("span", { className: "font-normal" }, promptText),
        promptSubtext && React.createElement("span", { className: "font-semibold" },
            " ",
            promptSubtext)));
};
