var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import 'i18next';
import { logEvent } from '../../../util/EventLog';
import { MessageType } from '../../clearstep/proto/logging_pb';
import '../../clearstep/proto/results_pb';
import { ResultStatus } from '../../clearstep/Types';
import '../../../api/ConversationResults';
import { PartnerCustomizationContext } from '../Partners/symptom_check/PartnerCustomizations';
import { THEME } from '../../clearstep/util/Theme';
import { CareOptionCard } from './CareOptionCard/LegacyCareOptionCard/CareOptionCard';
import { VisitFilterProviderWrapper } from '../../../context/VisitFilterProvider';
var CareOption = function (props) {
    var sourceOfCare = props.sourceOfCare, careLocations = props.careLocations;
    var careType = sourceOfCare.careType, title = sourceOfCare.title, careDetails = sourceOfCare.careDetails;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var handleUpdateExpanded = function (updateFn) {
        logEvent(MessageType.TOGGLED_DROPDOWN_BLOCK, {
            name: title,
        }, { partner: THEME.partner });
        return updateFn();
    };
    var isLoading = function () {
        var careLocations = props.careLocations;
        return careLocations === ResultStatus.Loading;
    };
    var showLoading = partnerCustomizations.showLoading(isLoading(), careType);
    if (!partnerCustomizations.careOptionSupported(careType, careDetails)) {
        return null;
    }
    return (React.createElement(VisitFilterProviderWrapper, { sourceOfCare: sourceOfCare, careLocations: careLocations },
        React.createElement(CareOptionCard, __assign({}, props, { title: title, isLoading: isLoading, showLoading: showLoading, handleUpdateExpanded: handleUpdateExpanded }))));
};
export default CareOption;
