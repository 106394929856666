var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import 'i18next';
import uniqueId from 'lodash-es/uniqueId';
import { twMerge } from 'tailwind-merge';
import { DropdownIcon } from '../svg/DropdownIcon';
export var SelectVariant = {
    Default: 0,
    Wide: 1,
    Line: 2,
    PrimaryColor: 3,
};
export var LabelSize = {
    Default: 0,
    Large: 1,
    Hidden: 2,
};
var Select = /** @class */ (function (_super) {
    __extends(Select, _super);
    function Select(props) {
        var _this = _super.call(this, props) || this;
        _this.closeOnClickOutside = function (event) {
            var _a, _b;
            var open = _this.state.open;
            if (open && !((_b = (_a = _this.selectRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
                _this.setState({ open: false });
            }
        };
        _this.handleSelectOption = function (selected) {
            var onSelect = _this.props.onSelect;
            _this.setState({ selected: selected, open: false });
            onSelect(selected);
        };
        _this.handleToggle = function () {
            var open = _this.state.open;
            _this.setState({ open: !open });
        };
        _this.selectRef = React.createRef();
        _this.state = {
            open: false,
            selected: props.selected || {
                value: '',
                label: '',
            },
        };
        return _this;
    }
    Select.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b, _c;
        // Set selected when the selected prop loads asynchronously
        // (i.e. from MountSinaiVisitSection)
        // If props.selected changes, update this.state.selected
        if (((_a = prevProps.selected) === null || _a === void 0 ? void 0 : _a.value) !== ((_b = this.props.selected) === null || _b === void 0 ? void 0 : _b.value) && ((_c = this.props.selected) === null || _c === void 0 ? void 0 : _c.value)) {
            this.setState({ selected: this.props.selected });
        }
    };
    Select.prototype.componentDidMount = function () {
        var _this = this;
        document.addEventListener('click', function (e) { return _this.closeOnClickOutside(e); });
    };
    Select.prototype.componentWillUnmount = function () {
        var _this = this;
        document.removeEventListener('click', function (e) { return _this.closeOnClickOutside(e); });
    };
    Select.prototype.isWideVariant = function () {
        var variant = this.props.variant;
        return variant === SelectVariant.Wide;
    };
    Select.prototype.isLineVariant = function () {
        var variant = this.props.variant;
        return variant === SelectVariant.Line;
    };
    Select.prototype.isPrimaryColorVariant = function () {
        var variant = this.props.variant;
        return variant === SelectVariant.PrimaryColor;
    };
    Select.prototype.renderOptions = function (isOpen, label) {
        var _this = this;
        var options = this.props.options;
        var selected = this.state.selected;
        return options.map(function (option) { return (React.createElement("button", { className: twMerge('w-full cursor-pointer border-none text-left hover:text-primary', option.value === selected.value && 'text-primary'), onClick: function (event) {
                event.preventDefault();
                _this.handleSelectOption(option);
            }, key: option.label + option.value, role: "option", "aria-selected": option.value === selected.value, tabIndex: isOpen ? 0 : -1 },
            React.createElement("span", { className: "box-border flex w-full px-3.75 py-2.5" }, option.subDescription ? (React.createElement("div", { className: "flex flex-col" },
                React.createElement("p", { "aria-hidden": !isOpen, className: "text-base font-semibold", "data-testid": "option-".concat(option.value, "-").concat(label) }, option.label.toUpperCase()),
                React.createElement("p", { "aria-hidden": !isOpen, className: "text-xs font-normal", "data-testid": "option-".concat(option.subDescription, "-").concat(label) }, option.subDescription))) : (React.createElement("p", { "aria-hidden": !isOpen, "data-testid": "option-".concat(option.value, "-").concat(label) }, option.label))))); });
    };
    Select.prototype.render = function () {
        var _a = this.props, label = _a.label, required = _a.required, error = _a.error, placeholder = _a.placeholder, labelSize = _a.labelSize, disabled = _a.disabled, className = _a.className;
        var _b = this.state, open = _b.open, selected = _b.selected;
        var labelId = "select-".concat(uniqueId());
        return (React.createElement("div", { className: twMerge('select flex w-37.5 max-w-37.5 flex-col', (this.isWideVariant() || this.isLineVariant()) && 'w-57.5 max-w-57.5', className), ref: this.selectRef },
            label && labelSize !== LabelSize.Hidden && (React.createElement("label", { id: labelId, className: twMerge('pb-2 text-[12px]', labelSize === LabelSize.Large && 'text-base font-semibold') },
                label,
                required && (React.createElement("span", { className: "font-bold text-common-tomato" }, "*")))),
            React.createElement("button", { className: twMerge('hover:cursor-pointer focus:shadow-focus', 'flex flex-row rounded-md border border-solid border-ui-light-gray bg-white px-3.75 py-3', this.isLineVariant() &&
                    'rounded-none border-0 border-b border-solid border-text-light-gray p-0 pb-1', this.isPrimaryColorVariant() && 'border-none bg-primary text-white', !!error && 'border border-tomato'), onClick: this.handleToggle, type: "button", "aria-haspopup": "listbox", "aria-expanded": open, "aria-labelledby": label ? labelId : undefined, "aria-label": label ? undefined : 'Select', disabled: disabled },
                React.createElement("span", { className: "flex w-full flex-row items-center justify-between" },
                    React.createElement("p", { "data-testid": "placeholder-".concat(selected.value || placeholder), className: "font-semibold" }, selected.label || React.createElement("span", { className: "text-common-dark-gray" }, placeholder)),
                    React.createElement(DropdownIcon, { className: twMerge('ml-8 rotate-0 transform transition-all duration-360 ease-in-out', open && 'rotate-180'), strokeColor: this.isPrimaryColorVariant() ? '#ffffff' : '#6B7280' }))),
            React.createElement("div", { className: twMerge(!open && '!absolute !left-[-9999px] !top-[-9999px]') },
                React.createElement("div", { className: "relative" },
                    React.createElement("div", { role: "listbox", className: "absolute z-1 mt-3 max-h-37.5 w-full overflow-y-auto rounded-md bg-white py-2.5 shadow-select-option", tabIndex: -1 }, this.renderOptions(open, label || '')))),
            error && (React.createElement("div", { role: "alert", className: "mt-1 text-sm text-common-tomato" }, error))));
    };
    Select.defaultProps = {
        variant: SelectVariant.Default,
        labelSize: LabelSize.Default,
    };
    return Select;
}(React.Component));
export default Select;
