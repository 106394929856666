import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import Textarea from '../../../../tailwind_common/Textarea';
import { MetricPrompt } from '../MetricPrompt/MetricPrompt';
import { GenericLabel } from '../GenericLabel/GenericLabel';
import { GenericFooter } from '../GenericFooter/GenericFooter';
export var GenericFeedbackCard = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var message = current.context.message;
    var t = useTranslation().t;
    return (React.createElement("div", { className: "my-6 flex flex-1 flex-col mobile:mt-4" },
        React.createElement(MetricPrompt, { promptText: t('feedbackModal.generic.question.promptText', 'Would you like to share any additional information to help us improve'), promptSubtext: t('feedbackModal.generic.question.promptSubtext', 'checking symptoms or booking options?') }),
        React.createElement(Textarea, { containerClassName: "flex-1", placeholder: t('feedbackModal.generic.question.placeholder', 'Enter your response'), Label: GenericLabel, onChange: function (e) { return metricService.send({ type: 'SAVE', message: e.target.value }); }, value: message !== null && message !== void 0 ? message : '', id: "generic feedback" }),
        React.createElement(GenericFooter, null)));
};
