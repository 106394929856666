import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { MessageType } from '../clearstep/proto/logging_pb';
import { DropdownIcon } from '../svg/DropdownIcon';
import { THEME } from '../clearstep/util/Theme';
import { logEvent } from '../../util/EventLog';
import { onlyText } from '../../util/ReactChildren';
export var DropdownBlock = function (props) {
    var title = props.title, children = props.children, classStyles = props.classStyles;
    var noDetails = isEmpty(children);
    var _a = useState(props.expandedDefault), expanded = _a[0], setExpanded = _a[1];
    var initialLoad = useRef(true);
    var _b = useState(false), isUsingKeyboard = _b[0], setIsUsingKeyboard = _b[1];
    var setUsingKeyboard = function () { return setIsUsingKeyboard(true); };
    var unsetUsingKeyboard = function () { return setIsUsingKeyboard(false); };
    useEffect(function () {
        document.addEventListener('keydown', setUsingKeyboard);
        document.addEventListener('mousedown', unsetUsingKeyboard);
        return function () {
            document.removeEventListener('keydown', setUsingKeyboard);
            document.removeEventListener('mousedown', unsetUsingKeyboard);
        };
    }, []);
    var handleClick = function () {
        var titleString = onlyText(props.title);
        logEvent(MessageType.TOGGLED_DROPDOWN_BLOCK, {
            name: titleString,
        }, { partner: THEME.partner });
        setExpanded(!expanded);
        initialLoad.current = false;
    };
    return (React.createElement("div", { className: twMerge('pb-3', expanded && 'mb-3', classStyles) },
        React.createElement("button", { type: "button", className: twMerge('my-3 flex w-full items-center justify-between px-1 py-1 focus-within:text-primary', isUsingKeyboard && 'focus:shadow-outline-blue', noDetails && 'cursor-auto'), onClick: handleClick, disabled: noDetails, "aria-expanded": !!expanded },
            React.createElement("span", { className: twMerge('relative text-left text-base font-semibold text-common-charcoal', expanded && 'text-primary') },
                title,
                React.createElement("span", { className: twMerge('absolute bottom-neg-0.5 left-0 inline-block h-0.5 bg-primary transition-all', !expanded && !initialLoad.current && 'animate-shrinking-underline', expanded && 'animate-growing-underline') })),
            !noDetails && (React.createElement(DropdownIcon, { className: twMerge('transition-all duration-360 ease-in-out', expanded && 'rotate-180') }))),
        React.createElement("div", { className: "dropdownContentContainer" }, expanded && children)));
};
export default DropdownBlock;
