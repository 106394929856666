/* eslint-disable react/jsx-no-literals */
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isInternetExplorer } from '../clearstep/util/AppHelpers';
var InternetExplorerBanner = function () {
    if (!isInternetExplorer()) {
        return null;
    }
    var t = useTranslation().t;
    return (React.createElement(Trans, { t: t, i18nKey: "internetExplorerError" },
        React.createElement("div", { className: "z-1000 absolute left-0 right-0 top-15 mx-auto flex flex-col bg-primary px-24 py-6 text-xl font-semibold leading-9 text-common-white" },
            React.createElement("div", { className: "text-center" },
                React.createElement("span", null, "This works best in Microsoft Edge, Chrome, Firefox, and Safari. Please visit "),
                ' ',
                React.createElement("a", { className: "text-common-grass no-underline", href: window.location.href },
                    ' ',
                    "this page"),
                ' ',
                React.createElement("span", null, "in one of those browsers.")))));
};
export default InternetExplorerBanner;
