import React, { useContext } from 'react';
import 'i18next';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../clearstep/proto/results_pb';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/care_locations_pb';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import '../../../../api/ConversationResults';
export var VaccineCareOption = function (props) {
    var careLocations = props.careLocations, sourceOfCare = props.sourceOfCare, renderValidCareLocation = props.renderValidCareLocation, setZipFocus = props.setZipFocus, zipCode = props.zipCode, insuranceStatus = props.insuranceStatus, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isLoading = props.isLoading;
    var careDetails = sourceOfCare.careDetails, careType = sourceOfCare.careType;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerVaccineCareOption = partnerCustomizations.vaccineCareOption();
    if (partnerVaccineCareOption) {
        return partnerVaccineCareOption;
    }
    var validCareLocation = renderValidCareLocation(careLocations, careDetails);
    if (validCareLocation) {
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: validCareLocation, zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
export default VaccineCareOption;
