export var isTomorrow = function (date) {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return (tomorrow.getDate() === date.getDate() &&
        tomorrow.getMonth() === date.getMonth() &&
        tomorrow.getFullYear() === date.getFullYear());
};
export var isToday = function (date) { return new Date().toDateString() === date.toDateString(); };
export var getTodayOrTomorrow = function (apptString) {
    var apptDate = new Date(apptString);
    if (isToday(apptDate)) {
        return 'Today, ';
    }
    if (isTomorrow(apptDate)) {
        return 'Tomorrow, ';
    }
    return "".concat(apptDate.toLocaleDateString('en-US', { weekday: 'long' }), ", ");
};
export var formatDateForDisplay = function (apptString) {
    if (apptString) {
        var date = new Date(apptString);
        return date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    return '';
};
export var formatTimeForDisplay = function (apptString) {
    var date = new Date(apptString);
    var time = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }).toLowerCase();
    return time;
};
export var getFormattedDate = function (apptString) {
    return "".concat(getTodayOrTomorrow(apptString) + formatDateForDisplay(apptString), " at ").concat(formatTimeForDisplay(apptString));
};
