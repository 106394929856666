import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { logEvent, MessageType } from '../../util/EventLog';
import { THEME } from '../clearstep/util/Theme';
import Button from './Button';
export var ButtonWithIconVariant = {
    Default: 0,
    Gray: 1,
    Red: 2,
};
var ButtonWithIcon = function (props) {
    var text = props.text, icon = props.icon, onClick = props.onClick, variant = props.variant, disabled = props.disabled;
    var buttonColor = function () {
        if (variant === ButtonWithIconVariant.Gray) {
            return 'text-common-medium-gray';
        }
        if (variant === ButtonWithIconVariant.Red) {
            return 'text-common-tomato';
        }
        return 'text-primary';
    };
    var handleClick = function () {
        onClick();
        logEvent(MessageType.BUTTON_CLICK, {
            name: text,
        }, {
            partner: THEME.partner,
            customData: {
                'external-window': {
                    title: MessageType.BUTTON_CLICK,
                    text: text,
                },
            },
        });
    };
    return (React.createElement(Button, { onClick: handleClick, innerClassName: "m-0 p-0", className: twMerge("flex cursor-pointer flex-row items-center bg-transparent hover:underline ".concat(buttonColor()), disabled && 'cursor-not-allowed'), "aria-label": text },
        React.createElement("span", { className: "flex items-center", tabIndex: -1 },
            icon,
            React.createElement("p", { className: "ml-2 font-semibold tracking-tight" }, text))));
};
ButtonWithIcon.defaultProps = {
    variant: ButtonWithIconVariant.Default,
};
export default ButtonWithIcon;
