import { cloneDeep } from 'lodash';
import { useEffect } from 'preact/hooks';
import React, { useState } from 'react';
import '../SelectScore/ScoreSelector';
export var ScoreSelectCES = function (props) {
    var scores = props.scores, scoreNumber = props.score, onSelect = props.onSelect;
    var _a = useState(Array.from(Array(7).keys()).map(function (_) { return false; })), hovered = _a[0], setHovered = _a[1];
    var _b = useState(Array.from(Array(7).keys()).map(function (index) {
        return index + 1 <= (scoreNumber !== null && scoreNumber !== void 0 ? scoreNumber : -1) ? scores[0].selectIcon : scores[0].defaultIcon;
    })), icons = _b[0], setIcons = _b[1];
    var getIconsFromState = function (scoreNumber) {
        var states = Array.from(Array(7).keys()).map(function (_) { return ''; });
        var selected = states.map(function (_, i) { return (i + 1 <= (scoreNumber !== null && scoreNumber !== void 0 ? scoreNumber : -1) ? 'selected' : ''); });
        var firstHovered = hovered.findIndex(function (val) { return !!val; });
        var hoveredIcons = selected.map(function (val, i) { return (i < firstHovered ? 'selected' : val); });
        if (firstHovered !== -1 && hoveredIcons[firstHovered] !== 'selected') {
            hoveredIcons[firstHovered] = 'hovered';
        }
        return hoveredIcons.map(function (val) {
            switch (val) {
                case 'selected':
                    return scores[0].selectIcon;
                case 'hovered':
                    return scores[0].hoverIcon;
                default:
                    return scores[0].defaultIcon;
            }
        });
    };
    useEffect(function () {
        setIcons(getIconsFromState(scoreNumber !== null && scoreNumber !== void 0 ? scoreNumber : -1));
    }, [scoreNumber, hovered]);
    var selectIcon = function (index) {
        onSelect(index + 1);
    };
    var handleMouseEnter = function (index) {
        var newHovered = cloneDeep(hovered);
        newHovered[index] = true;
        setHovered(newHovered);
    };
    var handleMouseLeave = function (index) {
        var newHovered = cloneDeep(hovered);
        newHovered[index] = false;
        setHovered(newHovered);
    };
    return (React.createElement(React.Fragment, null, icons.map(function (icon, index) { return (React.createElement("button", { 
        // eslint-disable-next-line react/no-array-index-key
        key: "".concat(icon, "-").concat(index), onClick: function () { return selectIcon(index); }, onMouseEnter: function () { return handleMouseEnter(index); }, onMouseLeave: function () { return handleMouseLeave(index); } },
        React.createElement("img", { src: icon, alt: "" }))); })));
};
