import classNames from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { linkToAndLogConvo } from '../../../util/EventLog';
import { MessageType } from '../proto/logging_pb';
import { THEME } from '../util/Theme';
import styles from './ZocdocScheduleButton.css';
var ZocdocScheduleButton = function (props) {
    var _a;
    var href = props.href, removeMargin = props.removeMargin, _b = props.variant, variant = _b === void 0 ? 'default' : _b, redirectLog = props.redirectLog;
    var t = useTranslation().t;
    var cta = t('zocDocScheduleButton.text', 'Schedule an appointment with Zocdoc');
    var logObj = redirectLog || { name: cta };
    // ensure cta is always logged when no name field exists
    logObj.name = logObj.name || cta;
    return (React.createElement("button", { className: classNames(styles.zocdocScheduleButton, (_a = {},
            _a[styles['zocdocScheduleButton--useMarginTop']] = !removeMargin,
            _a[styles['zocdocScheduleButton--branded']] = variant === 'branded',
            _a)), onClick: function () {
            return linkToAndLogConvo(href, MessageType.CLICKED_PARTNER_CARE_OPTION, logObj, {
                partner: THEME.partner,
            });
        } },
        React.createElement("div", { className: styles.zocdocScheduleButton__content },
            React.createElement("img", { className: styles.zocdocScheduleButton__icon, src: "/static/images/app/zocdoc_icon.png", alt: "Zocdoc logo" }),
            React.createElement("p", null, cta))));
};
export default ZocdocScheduleButton;
