import React, { Suspense, forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'i18next';
import { twMerge } from 'tailwind-merge';
import { onEnter as onlyOnEnter } from '../../util/KeyPressHandlers';
export var TextFieldVariant = {
    Default: 0,
    LineOnly: 1,
};
var TextField = function (props, ref) {
    var _a = React.useState(props.initialValue || ''), value = _a[0], setValue = _a[1];
    var _b = React.useState(props.error), error = _b[0], setError = _b[1];
    var _c = props.onEnter, onEnter = _c === void 0 ? function () { } : _c, _d = props.inputType, inputType = _d === void 0 ? 'text' : _d, _e = props.disabled, disabled = _e === void 0 ? false : _e, _f = props.placeholder, placeholder = _f === void 0 ? '' : _f, _g = props.variant, variant = _g === void 0 ? TextFieldVariant.Default : _g, _h = props.onChange, onChange = _h === void 0 ? undefined : _h, _j = props.boldLabel, boldLabel = _j === void 0 ? false : _j, className = props.className, innerClassName = props.innerClassName;
    var t = useTranslation('translation', { useSuspense: false }).t;
    var usePrevious = function (value) {
        var ref = useRef();
        useEffect(function () {
            ref.current = value;
        }, [value]);
        return ref.current;
    };
    var prevInitialValue = usePrevious(props.initialValue);
    var prevError = usePrevious(error);
    var prevValidate = usePrevious(props.validate);
    useEffect(function () {
        if (props.initialValue && prevInitialValue !== props.initialValue) {
            setValue(props.initialValue || '');
        }
    }, [prevInitialValue, props.initialValue]);
    useEffect(function () {
        if (prevError !== props.error) {
            setError(props.error);
        }
    }, [prevError, props.error]);
    useEffect(function () {
        if (!prevValidate !== props.validate) {
            handleValidation();
        }
    });
    var handleValidation = function () {
        var validators = props.validators;
        if (!validators) {
            return;
        }
        validators.forEach(function (validate) {
            var error = validate(value);
            if (error) {
                setError(error);
            }
        });
    };
    var handleChange = function (event) {
        var value = event.target.value;
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    };
    var isLineOnly = function () { return variant === TextFieldVariant.LineOnly; };
    return (React.createElement(Suspense, { fallback: "" },
        React.createElement("div", { className: twMerge('textFieldContainer flex flex-col items-start font-primary font-medium focus-within:text-common-dark-gray', isLineOnly() && 'block', className) },
            isLineOnly() ? (React.createElement("div", { className: twMerge('text-[12px] text-transparent', !!value && 'text-common-dark-gray') }, props.placeholder)) : null,
            React.createElement("div", { className: twMerge('textFieldInner flex flex-col', innerClassName) },
                props.label && (React.createElement("label", { className: twMerge('pb-2 text-[12px]', boldLabel && 'text-[15px] font-bold'), htmlFor: props.ariaLabel },
                    props.label,
                    ' ',
                    props.required && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "font-bold text-common-tomato" }, 
                        // eslint-disable-next-line react/jsx-no-literals
                        '*'),
                        React.createElement("span", { className: "text-[12px] text-common-tomato" },
                            ' ',
                            t('common.formRequired', 'required')))))),
                React.createElement("input", { className: twMerge('flex h-10.5 max-w-[40vw] overflow-hidden bg-white font-primary font-medium disabled:border-none disabled:bg-ui-light-gray disabled:placeholder-common-dark-gray', 'mobile:max-w-none', isLineOnly()
                        ? 'h-6.25 border-b border-solid border-none border-dark-gray text-sm text-black placeholder:text-gray-500 focus:placeholder-transparent'
                        : 'box-border rounded-md border border-solid border-ui-light-gray p-3 text-base text-black placeholder:text-[15px] placeholder:text-gray-500 focus:rounded-full focus:rounded-md focus:shadow-focus', !!error &&
                        'border border-solid border-tomato focus:border focus:border-solid focus:border-tomato focus:outline-none'), id: props.id || props.ariaLabel, "aria-label": props.ariaLabel, disabled: disabled, type: inputType, inputMode: props.inputMode, value: props.usePropsForValue ? props.value : value, placeholder: placeholder || '', onChange: handleChange, onFocus: function () { return setError(''); }, onBlur: handleValidation, onKeyDown: onlyOnEnter(function () { return onEnter(); }), autoComplete: "off", "aria-invalid": !!error, "aria-required": props.required, max: props.max, maxLength: props.maxLength, ref: ref })),
            error ? (React.createElement("div", { role: "alert", className: "mt-1 text-[12px] text-common-tomato" }, error)) : (React.createElement("div", { className: "h-4" })))));
};
export default forwardRef(TextField);
