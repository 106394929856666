import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { twMerge } from 'tailwind-merge';
import { isDemo } from '../clearstep/util/AppHelpers';
import { THEME } from '../clearstep/util/Theme';
import { renderLink } from '../clearstep/util/MarkdownHelpers';
import { MessageType } from '../clearstep/proto/logging_pb';
import Loading from './Loading';
var FindCarePlaceholder = function (props) {
    var loadingCareLocation = props.loadingCareLocation, text = props.text, setZipFocus = props.setZipFocus, wrapperClassName = props.wrapperClassName;
    var t = useTranslation().t;
    var defaultText = isDemo()
        ? t('placeholderZipPrompt.demo', '[Enter your zip code and insurance information](zip-focus) above to see options near you')
        : THEME.symptomCheckCustomizations.requireMembership
            ? t('placeholderZipPrompt.requireMembership', 'Please select [filter options above](zip-focus) to see care options')
            : t('placeholderZipPrompt.noMembership', '[Enter your zip code](zip-focus) above to see options near you');
    var focusZipInput = function (href, children) { return (React.createElement("button", { className: "m-0 cursor-pointer text-base font-medium text-common-blue-500 underline", onClick: function () {
            if (typeof setZipFocus === 'function') {
                setZipFocus(true);
            }
        } }, children)); };
    return (React.createElement("div", { className: twMerge('flex h-57.5 w-full flex-col items-center justify-center  rounded-2xl bg-bot-light-gray mobile:mb-4 mobile:box-border mobile:px-4', wrapperClassName) },
        loadingCareLocation && React.createElement(Loading, null),
        !loadingCareLocation && (React.createElement("div", { className: "flex flex-col items-center text-center text-base" },
            React.createElement(Markdown, { options: {
                    overrides: {
                        p: function (innerProps) { return (React.createElement("p", { className: "m-0 text-base font-medium" }, innerProps.children)); },
                        span: function (innerProps) { return (React.createElement("span", { className: "m-0 text-base font-medium" }, innerProps.children)); },
                        a: function (innerProps) {
                            if (innerProps.href === 'zip-focus') {
                                return focusZipInput(innerProps.href, innerProps.children);
                            }
                            return renderLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK);
                        },
                    },
                } }, text || defaultText)))));
};
export default FindCarePlaceholder;
