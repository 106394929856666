import React from 'react';
import AppHeader from '../../AppHeader/AppHeader';
import '../../proto/conversation_pb';
import styles from './ConversationHeader.css';
import ProgressBar from '../../../tailwind_common/ProgressBar';
import { hasEmbedUrlParam, useWidgetStyle } from '../../util/AppHelpers';
export var ConversationHeader = function (props) {
    var conversationType = props.conversationType, hideProgressBar = props.hideProgressBar, percentQuestionsAnswered = props.percentQuestionsAnswered, complaint = props.complaint;
    var showAppHeader = !hasEmbedUrlParam() && !useWidgetStyle();
    return (React.createElement("div", { className: styles.conversationHeader },
        showAppHeader && (React.createElement(AppHeader, { applyBottomBorder: hideProgressBar, conversationType: conversationType, complaint: complaint })),
        !hideProgressBar && React.createElement(ProgressBar, { fillPercentage: percentQuestionsAnswered })));
};
