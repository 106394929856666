export var Flows;
(function (Flows) {
    Flows["CHECK_SYMPTOMS"] = "CHECK_SYMPTOMS";
    Flows["COVID"] = "COVID";
    Flows["IMMEDIATE_CARE"] = "IMMEDIATE_CARE";
    Flows["BAYCARE_CHECK_SYMPTOMS"] = "BAYCARE_CHECK_SYMPTOMS";
    Flows["BAYCARE_GET_CARE_NOW"] = "BAYCARE_GET_CARE_NOW";
    Flows["BAYCARE_FIND_HOSPTIAL"] = "BAYCARE_FIND_HOSPTIAL";
    Flows["BAYCARE_FIND_PROVIDER"] = "BAYCARE_FIND_PROVIDER";
    Flows["DULY_GET_CARE_NOW"] = "DULY_GET_CARE_NOW";
    Flows["DULY_FIND_LAB"] = "DULY_FIND_LAB";
    Flows["DULY_SPEAK_TO_TEAM_MEMBER"] = "DULY_SPEAK_TO_TEAM_MEMBER";
    Flows["DULY_TAKE_MY_INSURANCE"] = "DULY_TAKE_MY_INSURANCE";
    Flows["DULY_FIND_PROVIDER"] = "DULY_FIND_PROVIDER";
    Flows["COMPLAINT"] = "COMPLAINT";
    Flows["SEARCH_WORKFLOWS"] = "SEARCH_WORKFLOWS";
})(Flows || (Flows = {}));
// Keeping this workflow on the frontend because it requires args from the URL
// We also don't render it as a button like the other widget fixed flows
export var getComplaintWorkflow = function (complaint, searchParams) { return ({
    title: '',
    icon: '',
    value: Flows.COMPLAINT,
    path: "/conversation/new/".concat(complaint).concat(searchParams),
    shortTitle: 'Back',
    isVisible: false,
}); };
