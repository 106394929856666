import React, { useContext } from 'react';
import 'i18next';
import { THEME } from '../../../clearstep/util/Theme';
import CareLocation from '../../../clearstep/CareLocation/CareLocation';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import { ResultStatus } from '../../../clearstep/Types';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import '../../../clearstep/proto/results_pb';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
export var PoisonControlCareOption = function (props) {
    var careLocations = props.careLocations, zipCode = props.zipCode, insuranceStatus = props.insuranceStatus, setZipFocus = props.setZipFocus, isLoading = props.isLoading, sourceOfCare = props.sourceOfCare;
    var careType = sourceOfCare.careType;
    var numberOfCareLocations = THEME.numberOfCareLocations;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var customPoisonControlCard = partnerCustomizations.poisonControlOption(sourceOfCare);
    if (customPoisonControlCard) {
        return customPoisonControlCard;
    }
    if (Array.isArray(careLocations) && !!careLocations.length) {
        return (React.createElement("div", null, careLocations.slice(0, numberOfCareLocations).map(function (careLocation) { return (React.createElement(CareLocation, { location: careLocation, key: careLocation.name, insuranceStatus: insuranceStatus, zipCode: zipCode })); })));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
export default PoisonControlCareOption;
