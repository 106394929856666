var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import FocusTrap from 'focus-trap-react';
import 'i18next';
import { noop } from 'lodash';
import { twMerge } from 'tailwind-merge';
import { CloseIcon } from '../svg/CloseIcon';
import { onEnter } from '../../util/KeyPressHandlers';
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal(props) {
        var _this = _super.call(this, props) || this;
        _this.closeModalOnClickOutside = function (event) {
            var _a;
            var _b = _this.props, onClose = _b.onClose, disableCloseOnClickOutside = _b.disableCloseOnClickOutside;
            // If the user clicks on the modal backdrop div *and* the target of the click event
            // is not within the modal box itself, close the modal. If the modal is open and the
            // user clicks on the modal box, don't close the modal.
            if (!disableCloseOnClickOutside &&
                !((_a = _this.modalBoxRef.current) === null || _a === void 0 ? void 0 : _a.contains(event === null || event === void 0 ? void 0 : event.target))) {
                onClose();
            }
        };
        _this.closeModalOnEscape = function (event) {
            var _a = _this.props, isOpen = _a.isOpen, onClose = _a.onClose;
            var key = event.which || event.keyCode;
            if (key === 27 && isOpen) {
                event.preventDefault();
                onClose();
            }
        };
        _this.modalBoxRef = React.createRef();
        return _this;
    }
    Modal.prototype.componentDidMount = function () {
        document.addEventListener('keydown', this.closeModalOnEscape);
    };
    Modal.prototype.componentWillUnmount = function () {
        document.removeEventListener('keydown', this.closeModalOnEscape);
    };
    Modal.prototype.render = function () {
        var _a = this.props, isOpen = _a.isOpen, children = _a.children, disableCloseOnClickOutside = _a.disableCloseOnClickOutside, onClose = _a.onClose, hideCloseButton = _a.hideCloseButton, title = _a.title, className = _a.className, titleStyle = _a.titleStyle, titleChildren = _a.titleChildren;
        if (!isOpen) {
            return null;
        }
        // Note(amelia): FocusTrap works inside the first child element, so the
        // backdrop will not be focusable by keyboard even though it has tabIndex.
        // Keep in mind this fact when using the Modal element, as you must provide
        // an interactable way to close the modal when using hideCloseButton=true
        return (React.createElement(FocusTrap, null,
            React.createElement("div", { onKeyPress: !disableCloseOnClickOutside ? onEnter(this.closeModalOnClickOutside) : noop, onClick: !disableCloseOnClickOutside ? this.closeModalOnClickOutside : noop },
                React.createElement("div", { className: "fixed left-0 top-0 z-10 flex h-full w-full flex-row items-center justify-center bg-modal-rgba mobile:items-end", role: "dialog", "aria-modal": "true", "aria-labelledby": "modalTitle", "aria-describedby": "modalContent" },
                    React.createElement("div", { className: twMerge('relative m-6 flex max-h-70vh w-full max-w-528 flex-col overflow-y-auto rounded-lg border border-gray-300 bg-white shadow-md', 'mobile:max-h-minMaxHeight-calc-80vh small-phone:max-h-[calc(100% - 60px - 1px)] mobile:m-0 mobile:h-full mobile:max-w-full mobile:rounded-t-lg', className), ref: this.modalBoxRef },
                        !hideCloseButton && (React.createElement("button", { className: "absolute right-0 top-0 m-4.25 focus:rounded-full focus:shadow-focus", onClick: onClose, "aria-label": "close" },
                            React.createElement("span", { className: "flex focus:rounded-full focus:shadow-focus", tabIndex: -1 },
                                React.createElement(CloseIcon, null)))),
                        title && (React.createElement("div", { className: "border-0 border-b border-solid border-ui-light-gray bg-bot-light-gray p-4 pl-6 pr-13 mobile:pl-4" },
                            React.createElement("h1", { id: "modalTitle", className: "text-xl font-bold leading-6 text-common-penguin mobile:leading-5", style: titleStyle }, title),
                            titleChildren)),
                        React.createElement("div", { id: "modalContent", className: twMerge('flex flex-grow flex-col p-6 text-common-charcoal', 'mobile:pb-2 mobile:pl-4 mobile:pr-4 mobile:pt-4') }, children))))));
    };
    return Modal;
}(React.Component));
export default Modal;
