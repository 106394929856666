import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import CloseIcon from './CloseIcon';
import Button from '../../../../tailwind_common/Button';
export var MetricCard = function (props) {
    var step = props.step, disableBack = props.disableBack, disableSubmit = props.disableSubmit, children = props.children, nextEvent = props.nextEvent, backEvent = props.backEvent, collapseEvent = props.collapseEvent;
    var lastStep = 3;
    var t = useTranslation().t;
    return (React.createElement("div", { className: twMerge('mb-6 box-border h-metric-card w-metric-card rounded-lg border border-ui-light-gray bg-neutral-50 shadow-feedback-card', 'mobile:mb-0 mobile:mt-auto mobile:h-85vh mobile:w-full'), tabIndex: -1 },
        React.createElement("div", { className: "flex h-full w-full flex-col" },
            React.createElement("div", { className: twMerge('box-border flex h-15 w-full items-center justify-between rounded-t-lg bg-gray-100 px-6', 'mobile:px-4') },
                React.createElement("h5", { className: "text-xl font-semibold text-gray-900" }, t('common.giveFeedbackLowerF', 'Give feedback')),
                React.createElement("button", { onClick: collapseEvent },
                    React.createElement(CloseIcon, null))),
            React.createElement("div", { className: "mx-6 flex flex-1 flex-col mobile:mx-4" }, children),
            React.createElement("div", { className: twMerge('border-box mx-6 mb-6 mt-auto flex items-center justify-end', 'mobile:mx-4 mobile:mb-4 mobile:justify-between') },
                React.createElement("div", { className: "mobile:ml-4" },
                    React.createElement("span", { className: "text-base font-semibold tracking-wide text-gray-900" }, t('feedbackModal.stepCounter.1', 'Step {{step}}', { step: step })),
                    ' ',
                    React.createElement("span", { className: "text-base tracking-wide text-gray-900" }, t('feedbackModal.stepCounter.2', 'of')),
                    ' ',
                    React.createElement("span", { className: "text-base font-semibold tracking-wide text-gray-900" }, lastStep)),
                React.createElement(Button, { secondary: true, disabled: disableBack, onClick: backEvent, className: twMerge(
                    // TODO: Larger refactor to set these as 'default' secondary style
                    'ml-auto border-gray-300 text-gray-700', 'hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700', 'disabled:border-gray-300 disabled:text-gray-700 disabled:opacity-40', 'mobile:order-first mobile:ml-0'), innerClassName: "font-medium" }, t('feedbackModal.previousStepCta', 'Back')),
                React.createElement(Button, { onClick: nextEvent, disabled: disableSubmit, ariaLabel: t('feedbackModal.submitAriaLabel', 'Submit score') || '', className: "ml-4", innerClassName: "font-medium" }, step === lastStep
                    ? t('common.submitCta', 'Submit')
                    : t('feedbackModal.nextStepCta', 'Next'))))));
};
