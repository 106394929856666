var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from 'react';
import 'i18next';
import { twMerge } from 'tailwind-merge';
import '../../../../clearstep/proto/results_pb';
import '../../../../clearstep/Types';
import '../../../../../api/ConversationResults';
import CareOptionCostIcon from '../../CareOptionCost/CareOptionCostIcon';
import { LegacyDropdownIcon } from '../../../../svg/LegacyDropdownIcon';
import Loading from '../../../../tailwind_common/Loading';
import LoadingAlignment from '../../../../tailwind_common/LoadingAlignment';
import { PartnerCustomizationContext } from '../../../Partners/symptom_check/PartnerCustomizations';
import { CareOptionContent } from './CareOptionContent';
export var CareOptionCard = function (props) {
    var expandedDefault = props.expandedDefault, handleUpdateExpanded = props.handleUpdateExpanded, id = props.id, showLoading = props.showLoading, sourceOfCare = props.sourceOfCare, zipCode = props.zipCode, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isTherapistSupported = props.isTherapistSupported, careLocations = props.careLocations, age = props.age, insuranceStatus = props.insuranceStatus;
    var careType = sourceOfCare.careType, title = sourceOfCare.title, careDetails = sourceOfCare.careDetails;
    var _a = useState(expandedDefault || false), expanded = _a[0], setExpanded = _a[1];
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var careOptionTitle = partnerCustomizations.careOptionTitle({
        title: title,
        careLocations: careLocations,
        careType: careType,
        careDetails: careDetails,
        zipCode: zipCode,
        age: age,
        insuranceStatus: insuranceStatus,
    });
    return (React.createElement("div", { className: twMerge('duration-180 mb-6 flex max-h-fit flex-col rounded-2xl border border-solid border-ui-light-gray bg-white shadow-results-box-shadow transition-max-height ease-linear', expanded && 'border-none') },
        React.createElement("button", { className: twMerge('flex flex-col items-center justify-between rounded-2xl focus:shadow-conversation-keyboard-focus-outline', expanded &&
                'rounded-none rounded-tl-2xl rounded-tr-2xl border border-solid border-ui-light-gray focus:rounded-tl-2xl focus:rounded-tr-2xl focus:shadow-conversation-keyboard-focus-outline'), onClick: function () { return handleUpdateExpanded(function () { return setExpanded(!expanded); }); }, "aria-label": title, "aria-describedby": "careOptionInfo-".concat(id), "aria-expanded": expanded },
            React.createElement("span", { className: "box-border flex w-full flex-row justify-between p-6 mobile:justify-start mobile:p-3.2", tabIndex: -1, id: "careOptionInfo-".concat(id) },
                React.createElement("div", { className: "flex w-87.5 max-w-17/20 flex-row items-center" },
                    React.createElement("div", { className: "flex flex-col justify-center pr-4" }, partnerCustomizations.careOptionIcon(careType, careDetails)),
                    React.createElement("h3", { className: "text-left text-1.5xl font-bold leading-7.5 text-black mobile:text-lg mobile:leading-6.5", "data-cy": "careOptionTitle" }, careOptionTitle)),
                showLoading ? React.createElement(Loading, null) : React.createElement(LoadingAlignment, null),
                React.createElement("div", { className: "flex flex-row items-center mobile:flex-grow mobile:justify-end" },
                    React.createElement(CareOptionCostIcon, { careType: sourceOfCare.careType, costEstimate: costEstimate, zipCode: zipCode, sourceOfCare: sourceOfCare, showCostEstimate: showCostEstimate, isTherapistSupported: isTherapistSupported }),
                    React.createElement("div", { className: twMerge(showLoading && 'mobile:hidden') },
                        React.createElement(LegacyDropdownIcon, { classNames: twMerge('ml-8 transform rotate-90 transition-all duration-360 ease-cubic', expanded && 'rotate-270') }))))),
        expanded && React.createElement(CareOptionContent, __assign({}, props))));
};
