import React, { useContext } from 'react';
import 'i18next';
import '../../../../api/ConversationResults';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/results_pb';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../clearstep/proto/care_locations_pb';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
export var DentistOption = function (props) {
    var careLocations = props.careLocations, sourceOfCare = props.sourceOfCare, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, setZipFocus = props.setZipFocus, zipCode = props.zipCode, insuranceStatus = props.insuranceStatus, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isLoading = props.isLoading, renderValidCareLocation = props.renderValidCareLocation;
    var careDetails = sourceOfCare.careDetails;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    if (mixSchedulingAndNonLocations()) {
        return mixSchedulingAndNonLocations();
    }
    var customDentistOption = partnerCustomizations.renderDentistOption({ sourceOfCare: sourceOfCare });
    if (customDentistOption) {
        return customDentistOption;
    }
    var validCareLocation = renderValidCareLocation(careLocations, careDetails);
    if (validCareLocation) {
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: validCareLocation, zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
