import React from 'react';
import { twMerge } from 'tailwind-merge';
import { addParams, UTMContext } from '../../../util/UTM';
import { getAppPath } from '../../../util/ProxyPath';
import { Partner } from '../../clearstep/proto/partners_pb';
import { useWidgetStyle, isMobile } from '../../clearstep/util/AppHelpers';
import { THEME } from '../../clearstep/util/Theme';
var PartnerLogo = function (props) {
    var _a, _b;
    var enableLogoLink = props.enableLogoLink, imageClassName = props.imageClassName;
    var getHeaderLogo = function () {
        var headerLogoPath = THEME.headerLogoPath, mobileHeaderLogoPath = THEME.mobileHeaderLogoPath;
        var customLogoPath = props.customLogoPath;
        if (customLogoPath) {
            return customLogoPath;
        }
        if ((isMobile() || useWidgetStyle()) && mobileHeaderLogoPath) {
            return mobileHeaderLogoPath;
        }
        return headerLogoPath;
    };
    var getHeaderLogoAlt = function () {
        var partner = THEME.partner;
        return "".concat(partner, " logo");
    };
    var logoClass = THEME.partner === Partner.CVS
        ? 'max-w-60 max-h-11.5 object-contain w-full h-full mobile:max-w-12.5'
        : 'max-w-75 max-h-11.5 object-contain w-full h-full mobile:max-w-12.5';
    var userId = window.externalUserId;
    var maybeUserId = userId ? { user_id: String(userId) } : undefined;
    var getHeaderImage = function () { return (React.createElement("img", { className: twMerge(logoClass, imageClassName), "data-cy": "logo-image", src: getAppPath(getHeaderLogo()), alt: getHeaderLogoAlt() })); };
    if ((_b = (_a = THEME.widgetCustomizations) === null || _a === void 0 ? void 0 : _a.widgetHeaderTheme) === null || _b === void 0 ? void 0 : _b.disableLogoLink) {
        return (React.createElement("button", { className: "ml-4 flex cursor-pointer no-underline focus-visible:outline-0 mobile:ml-2", style: { cursor: 'default' } }, getHeaderImage()));
    }
    return (React.createElement(UTMContext.Consumer, null, function (utmParams) { return (React.createElement(React.Fragment, null, enableLogoLink ? (React.createElement("a", { className: "ml-4 flex cursor-pointer no-underline focus-visible:outline-0 mobile:ml-2", href: addParams(THEME.partnerUrl, utmParams, {
            content: window.conversationId,
        }, maybeUserId) }, getHeaderImage())) : (React.createElement("div", null, getHeaderImage())))); }));
};
export default PartnerLogo;
