var _a;
export var CovidInformationCareDetailDescriptor;
(function (CovidInformationCareDetailDescriptor) {
    CovidInformationCareDetailDescriptor["COVID_19_TESTING_INFORMATION"] = "covid-19-testing-information";
    CovidInformationCareDetailDescriptor["COVID_19_VACCINE_INFORMATION"] = "covid-19-vaccine-information";
    CovidInformationCareDetailDescriptor["COVID_19_CARE_ADVICE"] = "covid-19-care-advice";
    CovidInformationCareDetailDescriptor["COVID_19_SHARE_RESULTS"] = "covid-19-share-results";
    CovidInformationCareDetailDescriptor["COVID_19_SHOW_TEST_RESULTS"] = "covid-test-results";
    CovidInformationCareDetailDescriptor["COVID_19_SHOW_TEST_RESULTS_ONLY"] = "covid-test-results-only";
})(CovidInformationCareDetailDescriptor || (CovidInformationCareDetailDescriptor = {}));
export var CovidInformationCareDetailLabel = (_a = {},
    _a[CovidInformationCareDetailDescriptor.COVID_19_TESTING_INFORMATION] = 'Covid 19 Testing Information',
    _a[CovidInformationCareDetailDescriptor.COVID_19_VACCINE_INFORMATION] = 'Covid 19 Vaccine Information',
    _a[CovidInformationCareDetailDescriptor.COVID_19_CARE_ADVICE] = 'Covid 19 Care Advice',
    _a[CovidInformationCareDetailDescriptor.COVID_19_SHARE_RESULTS] = 'Covid 19 Share Results',
    _a[CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS] = 'Covid Test Results',
    _a[CovidInformationCareDetailDescriptor.COVID_19_SHOW_TEST_RESULTS_ONLY] = 'Covid Test Results Only',
    _a);
