var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import '../../../../../clearstep/proto/conversation_pb';
import { THEME } from '../../../../../clearstep/util/Theme';
import { Partner } from '../../../../../clearstep/proto/partners_pb';
export var myTuftsUserParams = {
    concept_id: '1',
    question_id: 'mytufts-user',
    text: 'Yes',
    type: 'mytufts-login',
};
export var isMyTuftsUser = function (questionId, option) {
    return window.location.pathname.includes('/workflows/tufts_login') &&
        THEME.partner === Partner.TUFTS &&
        questionId === myTuftsUserParams.question_id &&
        option &&
        option.conceptId === myTuftsUserParams.concept_id;
};
export var useMyTuftsLogin = function () {
    var _a = useState(false), showMyTuftsLogin = _a[0], setShowMyTuftsLogin = _a[1];
    return {
        showMyTuftsLogin: showMyTuftsLogin,
        setShowMyTuftsLogin: setShowMyTuftsLogin,
    };
};
export var getMyTuftsAnswerQuestionParams = function (conversation_id) { return (__assign(__assign({}, myTuftsUserParams), { conversation_id: conversation_id })); };
