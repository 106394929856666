import * as React from 'react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'preact/hooks';
import SectionHeader from '../SectionHeader/SectionHeader';
import EducationMarkdown from './EducationMarkdown';
import styles from './Education.css';
import { prepareText } from '../TextUtil';
import { hasHideHeaderParam } from '../util/AppHelpers';
var Education = function (props) {
    var _a = useState(false), hideHeader = _a[0], setHideHeader = _a[1];
    var education = props.education, isProxyUser = props.isProxyUser;
    var t = useTranslation('translation', { useSuspense: false }).t;
    useEffect(function () {
        setHideHeader(hasHideHeaderParam());
    }, []);
    return (React.createElement(Suspense, { fallback: "" },
        React.createElement("div", { className: styles.education },
            !hideHeader && (React.createElement(SectionHeader, { text: t('educationalInformation.header', 'Educational Information'), className: styles.educationalInfoHeader })),
            React.createElement(EducationMarkdown, { text: prepareText(education, { isProxyUser: isProxyUser }), isProxyUser: isProxyUser }))));
};
export default Education;
