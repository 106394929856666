import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWithArrow, { ButtonWithArrowColorVariant, ButtonWithArrowDirectionVariant, } from '../../tailwind_common/ButtonWithArrow';
export var RewindButton = function (props) {
    var allowRewind = props.allowRewind, showUserAgeHelper = props.showUserAgeHelper, handleRewind = props.handleRewind;
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null, allowRewind && !showUserAgeHelper && (React.createElement("div", { className: "mt-5 flex flex-col self-end" },
        React.createElement(ButtonWithArrow, { onClick: handleRewind, colorVariant: ButtonWithArrowColorVariant.Gray, directionVariant: ButtonWithArrowDirectionVariant.Left, text: t('conversationControls.previousQuestion', 'Previous question') })))));
};
export default RewindButton;
