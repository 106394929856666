import React, { Suspense } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isCorona, isConversation } from '../util/AppHelpers';
import '../proto/conversation_pb';
import { THEME } from '../util/Theme';
import { MessageType, logEvent } from '../../../util/EventLog';
import { UTMContext, addParams } from '../../../util/UTM';
import styles from './AppHeader.css';
import { RightArrow } from '../../svg/Arrows';
import PartnerLogo from '../../tailwind_clearstep/PartnerLogo/PartnerLogo';
import Button from '../../tailwind_common/Button';
import LanguageDropdown from '../../tailwind_clearstep/LanguageDropdown/LanguageDropdown';
function showSwitchProduct() {
    return !THEME.symptomCheckOnly && !THEME.hideSwitchProduct;
}
var AppHeader = function (props) {
    var _a;
    var applyBottomBorder = props.applyBottomBorder, conversationType = props.conversationType;
    var t = useTranslation('translation', { useSuspense: false }).t;
    var getSwitchProductCta = isCorona(props.complaint)
        ? t('switchProductCta.nonCovid', 'Check non-COVID symptoms')
        : t('switchProductCta.covid', 'Have a concern related to COVID? Click here');
    var switchProduct = function (url) {
        logEvent(MessageType.CLICKED_CONVO_HEADER, {
            name: getSwitchProductCta,
        }, { partner: THEME.partner });
        window.location.href = url;
    };
    var userId = window.externalUserId;
    var maybeUserId = userId ? { user_id: String(userId) } : undefined;
    var otherProduct = new URL(isCorona(props.complaint) ? '/checksymptoms/corona_funnel' : '/conversation/new/covid-19', window.location.href).toString();
    return (React.createElement(Suspense, { fallback: "" },
        React.createElement("header", { className: styles.appHeaderWrapper },
            React.createElement("div", { className: classNames(styles.appHeader, (_a = {},
                    _a[styles['appHeader--bottom-border']] = applyBottomBorder,
                    _a)) },
                React.createElement(PartnerLogo, { enableLogoLink: true }),
                React.createElement(UTMContext.Consumer, null, function (utmParams) {
                    var doSwitch = function () {
                        return switchProduct(addParams(otherProduct, utmParams, {}, maybeUserId));
                    };
                    if (isConversation(conversationType) && showSwitchProduct()) {
                        return (React.createElement("div", { className: styles.appHeader__switchProduct },
                            React.createElement(Button, { onClick: doSwitch, styledText: true, rightArrow: true },
                                getSwitchProductCta,
                                React.createElement("div", { style: { marginLeft: '0.4rem' } },
                                    React.createElement(RightArrow, null)))));
                    }
                    return null;
                }),
                React.createElement(LanguageDropdown, { conversationType: conversationType })))));
};
export default AppHeader;
