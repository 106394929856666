import React, { useContext } from 'react';
import 'i18next';
import '../../../../api/ConversationResults';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/results_pb';
import { THEME } from '../../../clearstep/util/Theme';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
export var AsynchCareOption = function (props) {
    var careLocations = props.careLocations, zipCode = props.zipCode, sourceOfCare = props.sourceOfCare, insuranceStatus = props.insuranceStatus, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, setZipFocus = props.setZipFocus, isLoading = props.isLoading;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerAsychCareOption = partnerCustomizations.asynchCareOption({
        insuranceStatus: insuranceStatus,
        zipCode: zipCode,
        soc: sourceOfCare,
    });
    if (THEME.symptomCheckCustomizations.defaultStatic && partnerAsychCareOption) {
        return partnerAsychCareOption;
    }
    if (Array.isArray(careLocations) && !!careLocations.length) {
        if (mixSchedulingAndNonLocations()) {
            return mixSchedulingAndNonLocations();
        }
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: careLocations[0], zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
