import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { LeftArrow, RightArrow } from '../svg/Arrows';
import { ButtonWithArrowDirectionVariant } from './ButtonWithArrow';
export var LinkWithArrowVariant = {
    Default: 0,
    Left: 1,
};
var LinkWithArrow = function (props) {
    var text = props.text, href = props.href, color = props.color, className = props.className, onClick = props.onClick, openNewTab = props.openNewTab, variant = props.variant;
    return (React.createElement("a", { className: twMerge('text-decoration-none flex cursor-pointer flex-row items-center no-underline', className), onClick: onClick, href: href, target: openNewTab ? 'blank' : '', rel: "noopener noreferrer", style: { color: color } },
        React.createElement("span", { className: "flex items-center", tabIndex: -1 },
            variant === ButtonWithArrowDirectionVariant.Left && React.createElement(LeftArrow, { fill: color }),
            React.createElement("p", { className: twMerge('font-semibold tracking-tight', variant === ButtonWithArrowDirectionVariant.Left && 'ml-2', variant === ButtonWithArrowDirectionVariant.Default && 'mr-2') }, text),
            variant === ButtonWithArrowDirectionVariant.Default && React.createElement(RightArrow, { fill: color }))));
};
LinkWithArrow.defaultProps = {
    color: 'var(--primary-color)',
    variant: LinkWithArrowVariant.Default,
};
export default LinkWithArrow;
