import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import '../proto/conversation_pb';
import reactSelectStyles from './reactSelectStyles';
import { CustomDropdownIcon } from './CustomDropdownIcon';
import ScreenReaderFormGroup from '../../tailwind_common/ScreenReaderFormGroup';
export var SingleSelectSearchableQuestion = function (props) {
    var options = props.options, onSelect = props.onSelect, label = props.label, autoFocus = props.autoFocus;
    var t = useTranslation().t;
    return (React.createElement(ScreenReaderFormGroup, { alert: true, label: label },
        React.createElement(Select, { getOptionLabel: function (option) { return option.value; }, getOptionValue: function (option) { return option.value; }, placeholder: t('searchableSingleSelect.placeholder', 'Select...'), isSearchable: true, onChange: onSelect, styles: reactSelectStyles, autoFocus: autoFocus, options: options, components: {
                IndicatorSeparator: function () { return null; },
                DropdownIndicator: CustomDropdownIcon,
            } })));
};
export default SingleSelectSearchableQuestion;
