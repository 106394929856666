var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import '../../clearstep/proto/conversation_pb';
import { addQueryParamsAndReload, isConversation } from '../../clearstep/util/AppHelpers';
import { THEME } from '../../clearstep/util/Theme';
import '../../tailwind_common/DropDownMenuSection';
import { getTreatmentWithConfig } from '../../clearstep/util/Split';
import DropdownMenu from '../../tailwind_common/DropdownMenu';
export var LanguageDropdown = function (_a) {
    var conversationType = _a.conversationType;
    var _b = useState([]), supportedLocales = _b[0], setSupportedLocales = _b[1];
    var _c = useState({
        value: THEME.locale,
        displayName: THEME.locale,
    }), selectedLocale = _c[0], setSelectedLocale = _c[1];
    // Get supported languages from split
    useEffect(function () {
        var setUpSupportedLanguages = function () { return __awaiter(void 0, void 0, void 0, function () {
            var treatment, locales, sl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTreatmentWithConfig('show_language_dropdown', {
                            partner: THEME.partnerHostName,
                        })];
                    case 1:
                        treatment = _a.sent();
                        if (!treatment || !treatment.config) {
                            return [2 /*return*/];
                        }
                        locales = Object.entries(treatment.config);
                        sl = locales.map(function (locale) { return ({
                            value: locale[0],
                            displayName: String(locale[1]),
                        }); });
                        setSupportedLocales(sl);
                        return [2 /*return*/];
                }
            });
        }); };
        setUpSupportedLanguages();
    }, []);
    // get locale object in OptionProps format
    var getLocaleObj = function (locale) {
        for (var i = 0; i < supportedLocales.length; i++) {
            var sl = supportedLocales[i];
            if (sl.value === locale) {
                return sl;
            }
        }
        // return null if locale not supported
        return null;
    };
    // Set selected locale of dropdown based on i18next current language
    useEffect(function () {
        var currentLocale = i18next.language;
        var localeObj = getLocaleObj(currentLocale);
        if (supportedLocales.length > 1 && localeObj) {
            setSelectedLocale(localeObj);
        }
    }, [THEME.locale, supportedLocales]);
    var pageSupportsMultiLanguage = function () {
        return isConversation(conversationType) || /\/find-care/.test(window.location.pathname);
    };
    return (React.createElement(React.Fragment, null, supportedLocales.length > 1 && pageSupportsMultiLanguage() && (React.createElement(DropdownMenu, { className: "mr-3", options: supportedLocales, onClickOptionSelected: function (optionSelected) {
            if (optionSelected.value !== selectedLocale.value) {
                addQueryParamsAndReload('language', optionSelected.value);
            }
            setSelectedLocale(optionSelected);
        }, selectedOption: selectedLocale, label: selectedLocale.displayName, checkmarkFill: "var(--primary-color)" }))));
};
export default LanguageDropdown;
