var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ScoreIcon } from './ScoreIcon';
import './SelectScore';
export var ScoreSelector = function (props) {
    var scores = props.scores, scoreNumber = props.score, useOverflow = props.useOverflow, onSelect = props.onSelect;
    var mapScoresToIcons = function (scoreArr) {
        return scoreArr.map(function (score) { return (React.createElement(ScoreIcon, __assign({ key: score.score }, score, { selected: scoreNumber === score.score, onSelect: onSelect }))); });
    };
    if (useOverflow) {
        var pivot = scores.length / 2 + 1;
        var firstRow = scores.slice(0, pivot);
        var secondRow = scores.slice(pivot);
        return (React.createElement("div", { className: "gap-0.75 flex flex-col" },
            React.createElement("div", { className: "flex justify-center gap-3" }, mapScoresToIcons(firstRow)),
            React.createElement("div", { className: "flex justify-center gap-3" }, mapScoresToIcons(secondRow))));
    }
    return React.createElement(React.Fragment, null, mapScoresToIcons(scores));
};
