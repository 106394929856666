import React from 'react';
import '../../../../api/ConversationResults';
import styles from './InsuranceBreakdown.css';
import InsuranceCalculationGraph from '../InsuranceCalculationGraph/InsuranceCalculationGraph';
export var InsuranceBreakdown = function (props) {
    var _a, _b;
    var costEstimate = props.costEstimate;
    var outOfPocketMax = costEstimate &&
        costEstimate.data &&
        costEstimate.data.benefitInfo &&
        costEstimate.data.benefitInfo.coverage &&
        costEstimate.data.benefitInfo.coverage.outOfPocketMax;
    var deductible = costEstimate &&
        costEstimate.data &&
        costEstimate.data.benefitInfo &&
        costEstimate.data.benefitInfo.coverage &&
        costEstimate.data.benefitInfo.coverage.deductable;
    return costEstimate && costEstimate.data ? (React.createElement("div", { className: styles.insuranceBreakdown__insuranceDetails },
        React.createElement("div", { className: styles.insuranceBreakdown__insuranceDetailsHeader },
            React.createElement("div", { className: styles.insuranceBreakdown__insuranceDetailsTitle }, "Your current insurance coverage details"),
            React.createElement("div", { className: styles.insuranceBreakdown__insuranceDetailsInsurer }, (_b = (_a = costEstimate.data) === null || _a === void 0 ? void 0 : _a.insuranceInfo) === null || _b === void 0 ? void 0 : _b.plan)),
        React.createElement("div", null,
            deductible && React.createElement(InsuranceCalculationGraph, { title: "Deductible", calculation: deductible }),
            outOfPocketMax && (React.createElement(InsuranceCalculationGraph, { title: "Out-of-pocket-max", calculation: outOfPocketMax }))))) : (React.createElement(React.Fragment, null));
};
export default InsuranceBreakdown;
