import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import ThumbsUpIcon from './ThumbsUpIcon';
import CloseIcon from '../MetricCard/CloseIcon';
import { Partner } from '../../../../clearstep/proto/partners_pb';
import { THEME } from '../../../../clearstep/util/Theme';
import PartnerThumbsUpIcon from './PartnerThumbsUpIcon';
import Button from '../../../../tailwind_common/Button';
export var InitialCard = function () {
    var send = useContext(MetricMachineContext).send;
    var SelectedThumbsUpIcon = THEME.partner === Partner.CLEARSTEP ? React.createElement(ThumbsUpIcon, null) : React.createElement(PartnerThumbsUpIcon, null);
    var t = useTranslation().t;
    return (React.createElement("div", { className: twMerge('z-50 mx-auto mb-6 flex w-100 flex-col rounded-lg bg-neutral-50 shadow-feedback-card', 'mobile:z-10 mobile:mb-0 mobile:w-full'), tabIndex: -1 },
        React.createElement("div", { className: twMerge('m-6 mb-4 flex flex-row items-start justify-between', 'mobile:mx-4') },
            SelectedThumbsUpIcon,
            React.createElement("button", { className: "m-0.5 mobile:m-0", onClick: function () { return send('COLLAPSE'); } },
                React.createElement(CloseIcon, null))),
        React.createElement("p", { className: twMerge('mx-6 mb-1 text-xl font-bold text-common-penguin', 'mobile:mx-4') }, t('feedbackModal.initialCard.header', 'Help us improve your experience')),
        React.createElement("p", { className: twMerge('mx-6 text-base font-normal text-common-smoke', 'mobile:mx-4') }, t('feedbackModal.initialCard.subheader', 'May we ask you 3 quick questions about your experience? It should take less than 1 minute.')),
        React.createElement("div", { className: twMerge('m-6 mt-8 flex flex-row items-center justify-end gap-3', 'mobile:m-4 mobile:mt-6 mobile:flex-col-reverse mobile:items-stretch mobile:gap-1') },
            React.createElement(Button, { isText: true, onClick: function () { return send('COLLAPSE'); } }, t('feedbackModal.initialCard.closeCta', 'Maybe later')),
            React.createElement(Button, { onClick: function () { return send('GIVE_FEEDBACK'); } }, t('common.giveFeedbackLowerF', 'Give feedback')))));
};
