var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Markdown from 'markdown-to-jsx';
import { twMerge } from 'tailwind-merge';
import { prepareText } from '../../clearstep/TextUtil';
import { renderLink } from '../../clearstep/util/MarkdownHelpers';
import { MessageType } from '../../clearstep/proto/logging_pb';
import './TriageRecommendationeColorMapping';
var alertDiv = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({}, props, { role: "alert" }), children));
};
var renderSummaryText = function (primaryCareSummary, isProxyUser, options) {
    if (options === void 0) { options = {}; }
    var preparedText = prepareText(primaryCareSummary, { isProxyUser: isProxyUser });
    var _a = options.textColor, textColor = _a === void 0 ? '' : _a;
    return (React.createElement(Markdown, { options: {
            forceBlock: true,
            overrides: {
                p: function (props) {
                    props.className = 'font-semibold leading-6 text-lg lg:text-2xl lg:leading-8';
                    return alertDiv(props);
                },
                a: function (_a) {
                    var href = _a.href, children = _a.children;
                    return renderLink(href, children, MessageType.CLICKED_PARTNER_CARE_OPTION, twMerge(textColor, 'focus:underline'), {
                        extraData: { linkType: 'triage-summary' },
                    });
                },
            },
        } }, preparedText));
};
export var TriageRecommendation = function (props) {
    var isProxyUser = props.isProxyUser, theme = props.theme, hideIcon = props.hideIcon, primaryCareSummary = props.primaryCareSummary;
    var _a = theme || {}, backgroundColor = _a.backgroundColor, textColor = _a.textColor, Icon = _a.Icon;
    return (React.createElement("div", { className: twMerge('flex w-full flex-col rounded-md', backgroundColor, textColor) },
        React.createElement("div", { className: "flex items-center p-5 text-lg font-extrabold leading-none lg:text-2xl", "data-cy": "triageRecommendation" },
            !hideIcon && Icon && (React.createElement("div", { className: "mr-3" },
                React.createElement(Icon, null))),
            renderSummaryText(primaryCareSummary, isProxyUser, { textColor: textColor }))));
};
export default TriageRecommendation;
