import React, { useContext } from 'react';
import '../../../../api/ConversationResults';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../clearstep/proto/results_pb';
import { THEME } from '../../../clearstep/util/Theme';
var getDollarSigns = function (zipCode, sourceOfCare, isTherapistSupported) {
    var locationCost = sourceOfCare.locationCost, careDetails = sourceOfCare.careDetails, careType = sourceOfCare.careType;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var cost = partnerCustomizations.careOptionCost({
        careType: careType,
        careDetails: careDetails,
        zipCode: zipCode,
        isTherapistSupported: isTherapistSupported,
    }) || locationCost;
    return cost ? '$'.repeat(cost) : null;
};
export var CareOptionCostIcon = function (props) {
    var _a, _b;
    var costEstimate = props.costEstimate, zipCode = props.zipCode, sourceOfCare = props.sourceOfCare, showCostEstimate = props.showCostEstimate, isTherapistSupported = props.isTherapistSupported;
    var careOptionCostIconStyling = 'text-grass w-24 text-right text-2.5xl leading-9 font-bold mobile:hidden';
    // Show the exact $ amount if we have the data & in cost estimate mode
    if (showCostEstimate && ((_a = costEstimate === null || costEstimate === void 0 ? void 0 : costEstimate.data) === null || _a === void 0 ? void 0 : _a.totalCost)) {
        return (React.createElement("span", { className: careOptionCostIconStyling }, 
        // eslint-disable-next-line react/jsx-no-literals
        "$".concat((_b = costEstimate === null || costEstimate === void 0 ? void 0 : costEstimate.data) === null || _b === void 0 ? void 0 : _b.totalCost.toFixed(0))));
    }
    // Show $$ repeated unless the partner hides this
    if (!THEME.symptomCheckCustomizations.hideCareOptionsCost) {
        return (React.createElement("span", { className: careOptionCostIconStyling }, getDollarSigns(zipCode, sourceOfCare, isTherapistSupported)));
    }
    // Or else show an empty span with styles for spacing
    return React.createElement("span", { className: careOptionCostIconStyling });
};
export default CareOptionCostIcon;
