import { useState, useEffect } from 'react';
import { hexToRedGreenBlue } from '../../util/Color';
export var useRingStyle = function () {
    var ringOpacity = '.6';
    var _a = useState({
        '--tw-ring-color': window.getComputedStyle(document.body).getPropertyValue('--primary-color'),
    }), ringStyle = _a[0], setRingStyle = _a[1];
    // We are only able to specify the ring opacity if we are using an rgb() color
    // But --primary-color is a hex
    useEffect(function () {
        var primary = window.getComputedStyle(document.body).getPropertyValue('--primary-color');
        var _a = hexToRedGreenBlue(primary), red = _a.red, green = _a.green, blue = _a.blue;
        if (Number.isInteger(red) && Number.isInteger(green) && Number.isInteger(blue)) {
            setRingStyle({ '--tw-ring-color': "rgba(".concat(red, ", ").concat(green, ", ").concat(blue, ", ").concat(ringOpacity, ")") });
        }
    }, []);
    return ringStyle;
};
