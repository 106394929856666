var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../api/Axios';
import '../components/tailwind_common/RadioGroup';
import '../components/clearstep/proto/results_pb';
import { THEME, geti18nFormattedLocale } from '../components/clearstep/util/Theme';
import { getVisitTypes } from '../api/ConversationResults';
import '../components/clearstep/proto/care_locations_pb';
import { PartnerCustomizationContext } from '../components/tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import '../components/clearstep/Types';
import '../components/tailwind_common/Select';
export var defaultVisitTypeOption = {
    value: '',
    label: '',
    type: '',
    externalId: '',
};
export var createOptionFromResponseVisitType = function (respVt) { return ({
    value: respVt.id.toString(),
    label: respVt.name,
    type: respVt.type,
    externalId: respVt.externalId,
}); };
var defaultContext = {
    visitTypes: [],
    setVisitTypes: function () { },
    selectedVisitType: defaultVisitTypeOption,
    setSelectedVisitType: function () { },
    selectedVisitLocation: {
        value: '',
        label: '',
    },
    setSelectedVisitLocation: function () { },
    visibleVisitTypes: [],
    setVisibleVisitTypes: function () { },
};
export var VisitFilterContext = React.createContext(defaultContext);
var VisitFilterProvider = function (_a) {
    var children = _a.children, sourceOfCare = _a.sourceOfCare, careLocations = _a.careLocations;
    var _b = useState([]), visitTypes = _b[0], setVisitTypes = _b[1];
    var _c = useState([]), visibleVisitTypes = _c[0], setVisibleVisitTypes = _c[1];
    var _d = useState(defaultVisitTypeOption), selectedVisitType = _d[0], setSelectedVisitType = _d[1];
    var _e = useState(), visitTypesResponse = _e[0], setVisitTypesResponse = _e[1];
    var _f = useState({
        value: '',
        label: '',
    }), selectedVisitLocation = _f[0], setSelectedVisitLocation = _f[1];
    var t = useTranslation().t;
    var visitLocations = [
        {
            value: 'in_person',
            label: t('careLocation.visitOptions.inPersonVisit', 'In-Person Visit'),
        },
        {
            value: 'video_visit',
            label: t('careLocation.visitOptions.virtualVisit', 'Virtual Visit'),
        },
    ];
    var getVisitLocationOrDefault = function (vlStr) {
        return visitLocations.find(function (visitLocation) { return visitLocation.value === vlStr; }) || visitLocations[0];
    };
    var shouldSelectVisitType = function (visitType, visitTypeFromConvo) {
        var isSelectedFromConvo = visitTypeFromConvo && visitType.externalId === visitTypeFromConvo.value;
        var isSelectedByDefault = visitType.isDefault && !visitTypeFromConvo;
        return isSelectedFromConvo || isSelectedByDefault;
    };
    useEffect(function () {
        var retrieveVisitTypes = function () { return __awaiter(void 0, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getVisitTypes({
                            locale: geti18nFormattedLocale(THEME.locale),
                        })];
                    case 1:
                        resp = _a.sent();
                        setVisitTypesResponse(resp);
                        return [2 /*return*/];
                }
            });
        }); };
        retrieveVisitTypes();
    }, []);
    useEffect(function () {
        /*
            Retrieve visitTypes from /visit_types endpoint on load
            search tags to see if user selected visit type during convo, if so, set as default.
            If not, set the selected visit type when is_default is true
            (this should align with the visit type returned from the initial search_care_locations response)
        */
        var setupVisitTypeOptionsFilters = function () { return __awaiter(void 0, void 0, void 0, function () {
            var respVisitTypes, selectedVisitTypeLocation_1, visitTypeFromConvo_1, visitTypeOptions;
            var _a;
            return __generator(this, function (_b) {
                respVisitTypes = (_a = visitTypesResponse === null || visitTypesResponse === void 0 ? void 0 : visitTypesResponse.data) === null || _a === void 0 ? void 0 : _a.visitTypes;
                if (Array.isArray(respVisitTypes)) {
                    selectedVisitTypeLocation_1 = '';
                    visitTypeFromConvo_1 = sourceOfCare.tags.find(function (tag) { return tag.name === 'default_visit_type_external_id'; });
                    visitTypeOptions = respVisitTypes.map(function (vt) {
                        var visitTypeOption = createOptionFromResponseVisitType(vt);
                        if (shouldSelectVisitType(vt, visitTypeFromConvo_1)) {
                            setSelectedVisitType(visitTypeOption);
                            setSelectedVisitLocation(getVisitLocationOrDefault(vt.type));
                            selectedVisitTypeLocation_1 = vt.type;
                        }
                        return visitTypeOption;
                    });
                    setVisitTypes(visitTypeOptions || []);
                    setVisibleVisitTypes(visitTypeOptions.filter(function (visitType) { return visitType.type === selectedVisitTypeLocation_1; }));
                }
                return [2 /*return*/];
            });
        }); };
        setupVisitTypeOptionsFilters();
        /*
          Run this every time care locations changes, so we reset the filters based on
          Convo default visit type (which is what search_care_locations endopint
          results are based on)
        */
    }, [careLocations, visitTypesResponse]);
    var searchContextValue = useMemo(function () { return ({
        visitTypes: visitTypes,
        setVisitTypes: setVisitTypes,
        visibleVisitTypes: visibleVisitTypes,
        setVisibleVisitTypes: setVisibleVisitTypes,
        selectedVisitType: selectedVisitType,
        setSelectedVisitType: setSelectedVisitType,
        selectedVisitLocation: selectedVisitLocation,
        setSelectedVisitLocation: setSelectedVisitLocation,
    }); }, [visitTypes, visibleVisitTypes, selectedVisitType, selectedVisitLocation]);
    return (React.createElement(VisitFilterContext.Provider, { value: searchContextValue }, children));
};
export var VisitFilterProviderWrapper = function (props) {
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var sourceOfCare = props.sourceOfCare, children = props.children;
    if (!partnerCustomizations.enableVisitTypeFilters(sourceOfCare)) {
        return (React.createElement(VisitFilterContext.Provider, { value: defaultContext }, children));
    }
    return React.createElement(VisitFilterProvider, __assign({}, props), props.children);
};
