/* GENERATED FROM proto/partners.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */
export var Partner;
(function (Partner) {
    Partner["UNKNOWN_PARTNER"] = "UNKNOWN_PARTNER";
    Partner["CLEARSTEP"] = "CLEARSTEP";
    Partner["CLEARSTEP_DEMO"] = "CLEARSTEP_DEMO";
    Partner["MOUNTAIN"] = "MOUNTAIN";
    Partner["CVS"] = "CVS";
    Partner["BAYCARE"] = "BAYCARE";
    Partner["HELLOPATIENTS"] = "HELLOPATIENTS";
    Partner["RIVERSIDEMEDWELLFORCE"] = "RIVERSIDEMEDWELLFORCE";
    Partner["MILLCITYMEDWELLFORCE"] = "MILLCITYMEDWELLFORCE";
    Partner["GILCHRISTMDWELLFORCE"] = "GILCHRISTMDWELLFORCE";
    Partner["MERRIMACKFAMILYMEDWELLFORCE"] = "MERRIMACKFAMILYMEDWELLFORCE";
    Partner["MERRIMACKVALLEYINTERNALMEDWELLFORCE"] = "MERRIMACKVALLEYINTERNALMEDWELLFORCE";
    Partner["CHELMSFORDFAMILYWELLFORCE"] = "CHELMSFORDFAMILYWELLFORCE";
    Partner["HCA_TRISTAR"] = "HCA_TRISTAR";
    Partner["HCA_HEALTHONE"] = "HCA_HEALTHONE";
    Partner["HCA_LASVEGAS"] = "HCA_LASVEGAS";
    Partner["NOVANT"] = "NOVANT";
    Partner["AERIAL"] = "AERIAL";
    Partner["PENN"] = "PENN";
    Partner["HCA_FLORIDA"] = "HCA_FLORIDA";
    Partner["HCA_SANANTONIO"] = "HCA_SANANTONIO";
    Partner["MAHEALTH"] = "MAHEALTH";
    Partner["NORTHWELL"] = "NORTHWELL";
    Partner["MARKETPLACE"] = "MARKETPLACE";
    Partner["EMORY"] = "EMORY";
    Partner["HCA_MEDICALCITY"] = "HCA_MEDICALCITY";
    Partner["ORTHOPEDIC_INSTITUTE"] = "ORTHOPEDIC_INSTITUTE";
    Partner["MOUNT_SINAI"] = "MOUNT_SINAI";
    Partner["SENTARA"] = "SENTARA";
    Partner["ARC"] = "ARC";
    Partner["TUFTS"] = "TUFTS";
    Partner["DEMO_PARTNER"] = "DEMO_PARTNER";
    Partner["BSWH"] = "BSWH";
    Partner["COREWELLHEALTH"] = "COREWELLHEALTH";
    Partner["OCHSNER"] = "OCHSNER";
    Partner["PAGER"] = "PAGER";
})(Partner || (Partner = {}));
