import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { onEnter } from '../../../util/KeyPressHandlers';
import { logEvent } from '../../../util/EventLog';
import { MessageType } from '../../clearstep/proto/logging_pb';
import '../../clearstep/proto/care_locations_pb';
import { THEME } from '../../clearstep/util/Theme';
import { NoAvailableAppointments } from './NoAvailableAppointments';
import { isToday, isTomorrow, } from '../../clearstep/BookAppointment/BookAppointmentForm/bookAppointmentUtil';
import Loading from '../../tailwind_common/Loading';
import Tab from '../../tailwind_common/Tab';
import Button from '../../tailwind_common/Button';
var newSlot = function (slot) { return ({ time: slot.time, date: new Date(slot.time), id: slot.id }); };
var formatDate = function (date) {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};
var getNextAppointmentSlots = function (slots) {
    var _a, _b;
    if (!slots.length) {
        return {
            firstDay: {
                dateString: '',
                appointments: [],
            },
            secondDay: {
                dateString: '',
                appointments: [],
            },
        };
    }
    var sortedAppointments = slots.sort(function (a, b) { return a.date.valueOf() - b.date.valueOf(); });
    var firstDay = slots[0];
    var firstDaySlots = slots.filter(function (slot) { return slot.date.getDate() === firstDay.date.getDate(); });
    var firstIsToday = isToday(firstDay.date);
    var firstDayResults = {
        dateString: firstIsToday ? Day.TODAY : formatDate(firstDay.date),
        appointments: firstDaySlots,
    };
    var secondDate = sortedAppointments.find(function (slot) { return slot.date.getDate() !== firstDay.date.getDate(); });
    if (!secondDate) {
        return { firstDay: firstDayResults };
    }
    var secondDaySlots = slots.filter(function (slot) { return slot.date.getDate() === (secondDate === null || secondDate === void 0 ? void 0 : secondDate.date.getDate()); });
    var secondIsTomorrow = ((_a = secondDaySlots[0]) === null || _a === void 0 ? void 0 : _a.date) ? isTomorrow(secondDaySlots[0].date) : false;
    var secondDayResults = {
        dateString: secondIsTomorrow ? Day.TOMORROW : formatDate((_b = secondDaySlots[0]) === null || _b === void 0 ? void 0 : _b.date),
        appointments: secondDaySlots,
    };
    return {
        firstDay: firstDayResults,
        secondDay: secondDayResults,
    };
};
export var Day;
(function (Day) {
    Day["TODAY"] = "Today";
    Day["TOMORROW"] = "Tomorrow";
})(Day || (Day = {}));
var PartnerLocationAppointmentScheduling = function (props) {
    var _a, _b;
    var t = useTranslation().t;
    var schedule = props.schedule, onSelectDay = props.onSelectDay, selectedAppointmentDay = props.selectedAppointmentDay;
    var slots = (_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.slots) === null || _a === void 0 ? void 0 : _a.map(function (s) { return newSlot(s); })) !== null && _b !== void 0 ? _b : [];
    var _c = getNextAppointmentSlots(slots), firstDay = _c.firstDay, _d = _c.secondDay, secondDay = _d === void 0 ? { dateString: null, appointments: [] } : _d;
    var firstDayString = firstDay.dateString, firstDaySlots = firstDay.appointments;
    var secondDayString = secondDay.dateString, secondDaySlots = secondDay.appointments;
    var currentSlots = selectedAppointmentDay === firstDayString ? firstDaySlots : secondDaySlots;
    var haveAppointments = !!(currentSlots === null || currentSlots === void 0 ? void 0 : currentSlots.length);
    var loading = schedule && !Object.keys(schedule).length;
    var showNoAppointments = (!loading && !haveAppointments) || (schedule && !!schedule.error);
    useEffect(function () {
        onSelectDay(firstDayString);
    }, [schedule]);
    var handleSelectAppointment = function (slot) {
        var onSelectAppointment = props.onSelectAppointment, locationId = props.locationId;
        onSelectAppointment(slot);
        logEvent(MessageType.BUTTON_CLICK, {
            name: 'Select appointment slot',
            appointment_time: slot.date,
            location_id: locationId,
        }, { partner: THEME.partner });
    };
    var handleBookAppointment = function () {
        var onBookAppointment = props.onBookAppointment, selectedAppointment = props.selectedAppointment;
        logEvent(MessageType.APPOINTMENT_INITIATED, {}, { partner: THEME.partner });
        logEvent(MessageType.BUTTON_CLICK, {
            name: selectedAppointment ? 'Book appointment' : 'Find more availability',
        }, { partner: THEME.partner });
        onBookAppointment();
    };
    var handleSelectAppointmentDay = function (day) {
        var onSelectDay = props.onSelectDay, onSelectAppointment = props.onSelectAppointment, locationId = props.locationId;
        onSelectDay(day);
        onSelectAppointment(null);
        logEvent(MessageType.BUTTON_CLICK, {
            name: 'Select appointment day',
            day: day,
            location_id: locationId,
        }, { partner: THEME.partner });
    };
    var getSlotTimeForRendering = function (slot) {
        return slot.date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    };
    var renderAvailableAppointments = function (slots) {
        var selectedAppointment = props.selectedAppointment;
        var truncatedSlots = slots.slice(0, 18);
        return truncatedSlots.map(function (appointmentSlot) { return (React.createElement("div", { className: twMerge('cursor-pointer rounded-md border border-solid border-primary bg-white px-5 py-2.5 text-center text-sm font-semibold text-black', 'hover:bg-primary hover:text-white', appointmentSlot.time === (selectedAppointment === null || selectedAppointment === void 0 ? void 0 : selectedAppointment.time) && 'bg-primary text-white'), key: appointmentSlot.time, onClick: function () { return handleSelectAppointment(appointmentSlot); }, onKeyPress: onEnter(function () { return handleSelectAppointment(appointmentSlot); }), role: "button", tabIndex: 0, "data-action-type": "book_app", "data-click-type": "select_appt_slot" }, getSlotTimeForRendering(appointmentSlot))); });
    };
    var renderBookAppointmentButton = function () {
        var selectedAppointment = props.selectedAppointment;
        var clickType = 'see_availability';
        if (!selectedAppointment) {
            clickType = 'start_book_appt_flow';
        }
        return (React.createElement(Button, { onClick: handleBookAppointment, actionType: "book_appt", clickType: clickType }, selectedAppointment
            ? t('careLocation.bookingAppointment.selectedAppointmentText', 'Book appointment')
            : t('careLocation.bookingAppointment.nonSelectedAppointmentText', 'See more availability')));
    };
    return (React.createElement("div", { className: "flex flex-col items-center" },
        React.createElement("div", { className: "flex flex-row mobile:self-start" },
            firstDayString && (React.createElement(Tab, { tabLabel: firstDayString, active: selectedAppointmentDay === firstDayString, onClick: function () { return handleSelectAppointmentDay(firstDayString); } })),
            secondDayString && (React.createElement(Tab, { tabLabel: secondDayString, active: selectedAppointmentDay === secondDayString, onClick: function () { return handleSelectAppointmentDay(secondDayString); } }))),
        currentSlots && haveAppointments && (React.createElement("div", { className: "flex w-full flex-col" },
            React.createElement("div", { className: "my-5 grid grid-cols-3 gap-3" }, renderAvailableAppointments(currentSlots)),
            renderBookAppointmentButton())),
        loading && (React.createElement(Loading, { text: t('careLocation.bookingAppointment.loadingText', 'Finding appointments...') ||
                'Finding appointments...' })),
        showNoAppointments && (React.createElement(NoAvailableAppointments, { schedule: schedule, handleBookAppointment: handleBookAppointment, availabilityLink: props.availabilityLink }))));
};
export default PartnerLocationAppointmentScheduling;
