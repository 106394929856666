var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { useActor } from '@xstate/react';
import { twMerge } from 'tailwind-merge';
import { InitialCard } from '../Components/InitialCard/InitialCard';
import { MetricMachineContext } from '../Context/MetricFormContext';
import { CollapsedCard } from '../Components/CollapsedCard/CollapsedCard';
import { MetricCard } from '../Components/MetricCard/MetricCard';
import { CSATCard } from '../Components/CSAT/CSATCard';
import { CESCard } from '../Components/CES/CESCard';
import { GenericFeedbackSplit } from '../Components/GenericFeedback/GenericFeedbackSplit';
import { SuccessCard } from '../../SuccessCard/SuccessCard';
import { AppFeedback } from '../../../clearstep/MetricForm/Components/AppFeedback/AppFeedback';
import { MetricMachineStates } from '../StateMachine/MetricFormMachineTypes';
import { isSmallScreen } from '../../../clearstep/util/AppHelpers';
import { useFeedbackConfig } from './FeedbackConfigHook';
import { POST_INTENT_FEEDBACK_CATEGORY, useGenericFeedbackConfig, } from './GenericFeedbackConfigHook';
import '../Components/MetricProps';
export var MetricController = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var feedbackConfig = useFeedbackConfig();
    var genericFeedbackType = useGenericFeedbackConfig();
    var questions = function () {
        var _a;
        var _b = current.context, question = _b.question, questions = _b.questions, intent = _b.intent;
        var data = current.context.questions[question];
        var metricCardProps = {
            step: 0,
            disableBack: (_a = data === null || data === void 0 ? void 0 : data.disableBack) !== null && _a !== void 0 ? _a : false,
            nextEvent: function () { return metricService.send({ type: 'NEXT', question: data.next }); },
            backEvent: function () { return metricService.send('BACK'); },
            collapseEvent: function () { return metricService.send('COLLAPSE'); },
        };
        var commonProps = {
            onSelect: function (score) {
                metricService.send({ type: 'SAVE', score: score });
            },
            dynamicConfig: feedbackConfig,
        };
        // Cannot proceed to third question until at least one score is selected
        var hasSelectedScore = questions.CSAT.score !== -1 || questions.CES.score !== -1;
        // Intent selection required when showing post-intent form in generic feedback
        var needsPostIntentOption = genericFeedbackType === POST_INTENT_FEEDBACK_CATEGORY && !intent;
        switch (question) {
            case 'Generic':
                return (React.createElement(MetricCard, __assign({}, metricCardProps, { step: 3, nextEvent: function () { return metricService.send({ type: 'SUBMIT' }); }, disableSubmit: needsPostIntentOption }),
                    React.createElement(GenericFeedbackSplit, __assign({}, commonProps, { feedbackType: genericFeedbackType }))));
            case 'CES':
                return (React.createElement(MetricCard, __assign({}, metricCardProps, { step: 2, disableSubmit: !hasSelectedScore }),
                    React.createElement(CESCard, __assign({}, commonProps, { score: questions.CES.score }))));
            case 'CSAT':
                return (React.createElement(MetricCard, __assign({}, metricCardProps, { step: 1 }),
                    React.createElement(CSATCard, __assign({}, commonProps, { score: questions.CSAT.score }))));
            default:
                throw new Error('invalid metric state reached');
        }
    };
    var collapseEvent = function () {
        metricService.send('COLLAPSE');
    };
    var saveEvent = function (message) {
        metricService.send({ type: 'SAVE', message: message });
    };
    var submitEvent = function () {
        metricService.send('SUBMIT');
    };
    var isOpen = current.matches(MetricMachineStates.APPFEEDBACK) ||
        current.matches(MetricMachineStates.QUESTION);
    var showCollapsedCard = current.matches(MetricMachineStates.COLLAPSED) &&
        (!isSmallScreen() || current.context.type !== 'app');
    return (React.createElement("div", { className: twMerge('fmt-2 flex justify-end', 'mobile:mt-0 mobile:w-full', isOpen && 'mobile:fixed mobile:bottom-0 mobile:h-full mobile:bg-overlay-backdrop') },
        current.matches(MetricMachineStates.APPFEEDBACK) && (React.createElement(AppFeedback, { collapseEvent: collapseEvent, submitEvent: submitEvent, saveEvent: saveEvent })),
        current.matches(MetricMachineStates.EXPANDED) && React.createElement(InitialCard, null),
        showCollapsedCard && React.createElement(CollapsedCard, null),
        current.matches(MetricMachineStates.QUESTION) && questions(),
        current.matches(MetricMachineStates.SUBMITTED) && React.createElement(SuccessCard, null)));
};
