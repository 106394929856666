import React, { useEffect, useState } from 'react';
export var ScoreIcon = function (props) {
    var defaultIcon = props.defaultIcon, hoverIcon = props.hoverIcon, selectIcon = props.selectIcon, selected = props.selected, score = props.score, onSelect = props.onSelect;
    var _a = useState(selected ? selectIcon : defaultIcon), curr = _a[0], setCurr = _a[1];
    useEffect(function () {
        setCurr(selected ? selectIcon : defaultIcon);
    }, [selected]);
    var handleMouseEnter = function () {
        if (!selected) {
            setCurr(hoverIcon);
        }
    };
    var handleMouseLeave = function () {
        if (!selected) {
            setCurr(defaultIcon);
        }
    };
    return (React.createElement("button", { onClick: function () { return onSelect(score); }, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave },
        React.createElement("img", { src: curr, alt: "" })));
};
