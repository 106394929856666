import 'react';
export function onEnter(enterHandler) {
    var onEnterHandler = function (event) {
        var key = event.which || event.keyCode;
        // Enter key was pressed
        if (key === 13) {
            event.preventDefault();
            enterHandler();
        }
    };
    return onEnterHandler;
}
