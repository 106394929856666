import React from 'react';
import { twMerge } from 'tailwind-merge';
export var StepSection = function (props) {
    var icon = props.icon, content = props.content, className = props.className;
    return (React.createElement("div", { className: twMerge('flex items-center justify-center gap-3', className) },
        React.createElement("div", { className: twMerge('flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-primary', 'mobile:h-6 mobile:w-6') }, icon),
        React.createElement("div", { className: "flex flex-col items-start justify-center gap-1" },
            React.createElement("p", { className: "flex items-center text-base font-normal text-gray-700" }, content))));
};
export default StepSection;
