import '../proto/theme_pb';
import { defaultLocale, enUS, esMX } from '../../../util/i18n/constants';
var THEME = window.theme;
export function geti18nFormattedLocale(locale) {
    if (locale === 'EN_US') {
        return enUS;
    }
    if (locale === 'ES_MX') {
        return esMX;
    }
    return defaultLocale;
}
// Helper to get formatted partner name. Accounts for clearstep partner, where
// partnerHostName is 'app'. Defaults to 'clearstep' if empty or if theme not
// attached.
export function getPartnerName() {
    var hostName = THEME === null || THEME === void 0 ? void 0 : THEME.partnerHostName;
    return !hostName || hostName === 'app' ? 'clearstep' : hostName;
}
export { THEME };
