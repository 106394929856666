import React from 'react';
import { GenericFeedbackCard } from './GenericFeedbackCard';
import { PostIntentFeedbackCard } from './PostIntentFeedbackCard';
import { GENERIC_FEEDBACK_CATEGORY, POST_INTENT_FEEDBACK_CATEGORY, } from '../../Controller/GenericFeedbackConfigHook';
export var GenericFeedbackSplit = function (_a) {
    var feedbackType = _a.feedbackType;
    switch (feedbackType) {
        case GENERIC_FEEDBACK_CATEGORY:
            return React.createElement(GenericFeedbackCard, null);
        case POST_INTENT_FEEDBACK_CATEGORY:
            return React.createElement(PostIntentFeedbackCard, null);
        default:
            return null;
    }
};
