import React from 'react';
import '../../../../api/ConversationResults';
import { DollarIcon, ErrorOutline } from '../../../SVG';
import ConversationLoading from '../../ConversationLoading/ConversationLoading';
import { THEME } from '../../util/Theme';
import ButtonWithIcon, { ButtonWithIconVariant } from '../../../tailwind_common/ButtonWithIcon';
export var CostBreakdownButtonWithIcon = function (props) {
    var costEstimate = props.costEstimate, handleOpenCostBreakdownModal = props.handleOpenCostBreakdownModal;
    return (React.createElement(React.Fragment, null, !costEstimate || costEstimate.error ? (React.createElement(ButtonWithIcon, { text: "Error loading cost breakdown", variant: ButtonWithIconVariant.Red, icon: React.createElement(ErrorOutline, null), disabled: true, onClick: handleOpenCostBreakdownModal })) : costEstimate.data && !costEstimate.loading ? (React.createElement(ButtonWithIcon, { text: "See cost breakdown", icon: React.createElement(DollarIcon, { fill: THEME.primaryColor }), disabled: false, onClick: handleOpenCostBreakdownModal })) : (React.createElement(ButtonWithIcon, { text: "See cost breakdown", icon: React.createElement(ConversationLoading, { small: true }), disabled: true, onClick: handleOpenCostBreakdownModal }))));
};
