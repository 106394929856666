import 'i18next';
import { SETTINGS_NAMESPACE } from './constants';
// Helper to dynamically update page title
// TODO: figure out way to pre-populate on backend (so HTML comes over the wire with translated content)
export var setPageTitle = function (t) {
    if (!document.title) {
        return;
    }
    // assume only run once
    var translatedTitle = t('pageTitle', { ns: SETTINGS_NAMESPACE, defaultValue: document.title });
    // TODO: if translation does not exist, this method will not re-run
    document.title = translatedTitle;
};
