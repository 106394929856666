import React, { useContext } from 'react';
import 'i18next';
import '../../../../api/ConversationResults';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/results_pb';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../clearstep/proto/care_locations_pb';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import { THEME } from '../../../clearstep/util/Theme';
export var SpecialistOption = function (props) {
    var careLocations = props.careLocations, sourceOfCare = props.sourceOfCare, zipCode = props.zipCode, isTherapistSupported = props.isTherapistSupported, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, renderValidCareLocation = props.renderValidCareLocation, setZipFocus = props.setZipFocus, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isLoading = props.isLoading, insuranceStatus = props.insuranceStatus, conversationId = props.conversationId;
    var careDetails = sourceOfCare.careDetails;
    var numberOfCareLocations = THEME.numberOfCareLocations;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerSpecialistCareOption = partnerCustomizations.partnerOptionWithCareDetail({
        sourceOfCare: sourceOfCare,
        careLocations: careLocations,
        numberOfCareLocations: numberOfCareLocations,
        setZipFocus: setZipFocus,
        isTherapistSupported: isTherapistSupported,
        careDetails: sourceOfCare.careDetails,
        conversationId: conversationId,
    });
    if (partnerSpecialistCareOption) {
        return partnerSpecialistCareOption;
    }
    var behavioralHealthSpecialistOption = partnerCustomizations.behavioralHealthSpecialistOption(careDetails);
    if (behavioralHealthSpecialistOption) {
        return behavioralHealthSpecialistOption;
    }
    if (mixSchedulingAndNonLocations()) {
        return mixSchedulingAndNonLocations();
    }
    var validCareLocation = renderValidCareLocation(careLocations, careDetails);
    if (validCareLocation) {
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: validCareLocation, zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
