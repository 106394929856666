import { THEME } from '../util/Theme';
import '../proto/care_locations_pb';
import '../Types';
var expiryTime = 24 * 60 * 60 * 1000; // 24 hours from now
var isExpired = function (expiry) {
    var now = new Date();
    return !expiry || now.getTime() > expiry;
};
var getExpiry = function () {
    var now = new Date();
    return now.getTime() + expiryTime;
};
export var setCacheRequestData = function (request) {
    var storage = {};
    storage.data = request;
    storage.expiry = getExpiry();
    localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('search_care_locations_request', JSON.stringify(storage));
};
var isValidCacheRequestData = function (cache, conversationId) {
    var _a, _b;
    if (!cache) {
        return false;
    }
    var reqData = JSON.parse(cache);
    return (reqData &&
        (reqData === null || reqData === void 0 ? void 0 : reqData.data) &&
        ((_a = reqData === null || reqData === void 0 ? void 0 : reqData.data) === null || _a === void 0 ? void 0 : _a.convoId) === conversationId &&
        ((_b = reqData === null || reqData === void 0 ? void 0 : reqData.data) === null || _b === void 0 ? void 0 : _b.organization) === THEME.partner &&
        !isExpired(reqData === null || reqData === void 0 ? void 0 : reqData.expiry));
};
export var getCacheRequestData = function (conversationId) {
    var cache = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('search_care_locations_request');
    if (!cache || !isValidCacheRequestData(cache, conversationId)) {
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem('search_care_locations_request');
        return null;
    }
    var cacheData = JSON.parse(cache);
    return cacheData.data;
};
