import React, { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ExclamationCircle } from '../SVG';
import DropDownMenuSection from './DropDownMenuSection';
import { DropdownIcon } from '../svg/DropdownIcon';
var Input = function (props, ref) {
    var _a = useState(false), openDropdown = _a[0], setOpenDropdown = _a[1];
    var Label = props.Label, _b = props.type, type = _b === void 0 ? 'text' : _b, _c = props.inputMode, inputMode = _c === void 0 ? 'none' : _c, min = props.min, placeholder = props.placeholder, trailingAddOn = props.trailingAddOn, leadingAddOn = props.leadingAddOn, className = props.className, description = props.description, onKeyPress = props.onKeyPress, error = props.error, value = props.value, onChange = props.onChange, onBlur = props.onBlur, disabled = props.disabled, ariaLabel = props.ariaLabel, autoComplete = props.autoComplete, id = props.id, dropDownOptions = props.dropDownOptions, selectedOption = props.selectedOption, showDropDownMenu = props.showDropDownMenu, selectedDropDownOption = props.selectedDropDownOption, onClickDropDown = props.onClickDropDown;
    return (React.createElement("div", { className: "text-base md:text-sm" },
        React.createElement("label", { htmlFor: id, className: "relative leading-0 text-gray-900" },
            React.createElement(Label, null)),
        React.createElement("div", { className: twMerge('relative mt-2 rounded-md', error && 'shadow-none', !error && 'shadow-sm') },
            leadingAddOn && (React.createElement("div", { className: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3" },
                React.createElement("span", { className: "text-gray-500 sm:text-sm" }, "$"))),
            React.createElement("input", { id: id, type: type, name: description, inputMode: inputMode, min: min, className: twMerge('box-border h-12 w-full rounded-md px-3 py-2.5 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6 md:h-11 md:text-sm', className, disabled && 'cursor-not-allowed', error &&
                    'border border-solid border-red-600 text-red-900 shadow-none ring-0 placeholder:text-red-300 focus:ring-red-600', !error && 'focus:border-focus-indigo-500'), placeholder: placeholder, "aria-describedby": description, ref: ref, onKeyPress: onKeyPress, onChange: onChange, onBlur: onBlur, value: value, disabled: disabled, "aria-label": ariaLabel, autoComplete: autoComplete }),
            trailingAddOn && (React.createElement("div", { className: twMerge('pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3', showDropDownMenu && 'right-10', error && showDropDownMenu && 'right-18', error && !showDropDownMenu && 'right-5') },
                React.createElement("span", { className: twMerge('text-gray-500', error && 'text-red-900') }, trailingAddOn))),
            error && (React.createElement("div", { className: twMerge('pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3', showDropDownMenu && 'right-10') },
                React.createElement(ExclamationCircle, { className: "h-4 w-4", "aria-hidden": "true" }))),
            showDropDownMenu && (React.createElement("button", { className: twMerge('absolute inset-y-0 right-0 my-1 flex cursor-pointer items-center rounded-br-md rounded-tr-md border-0 border-l border-solid border-gray-300 px-3', 'focus:my-0 focus:border-2 focus:border-focus-indigo-500 focus:px-2.5'), "aria-label": "dropDownMenuButton", "data-testId": "dropDownMenuButton", onClick: function (e) {
                    setOpenDropdown(!openDropdown);
                    onClickDropDown(e);
                } },
                React.createElement(DropdownIcon, { className: twMerge('transform transition-all duration-360 ease-in-out', openDropdown && 'rotate-180') }))),
            showDropDownMenu && openDropdown && (React.createElement(DropDownMenuSection, { options: dropDownOptions !== null && dropDownOptions !== void 0 ? dropDownOptions : [], onClickOptionSelected: function (optionSelected) {
                    setOpenDropdown(false);
                    selectedDropDownOption(optionSelected);
                }, selectedOption: selectedOption }))),
        React.createElement("div", { className: "h-8" }, error && (React.createElement("div", { role: "alert", className: "text-sm text-red-600" }, error)))));
};
export default forwardRef(Input);
