import { THEME } from '../components/clearstep/util/Theme';
import { isAndroid, isIOS } from '../components/clearstep/util/AppHelpers';
export var MYCHART_URI = (THEME === null || THEME === void 0 ? void 0 : THEME.myChartURI) || '';
var FALLBACK_TIMEOUT = 25;
// Opens URI into Epic MyChart app for given partner ID. Fallsback after timeout
// to loading device appropriate app store.
var launchMyChart = function () {
    if (!MYCHART_URI) {
        console.error('launchMyChart called without myChartURI');
        return;
    }
    window.location = MYCHART_URI;
    if (isIOS()) {
        handleTimeout(launchiTunes);
    }
    else if (isAndroid()) {
        handleTimeout(launchGooglePlay);
    }
    else {
        console.error("launchMyChart called from invalid user agent: ".concat(navigator.userAgent));
    }
};
var handleTimeout = function (cb) { return setTimeout(cb, FALLBACK_TIMEOUT); };
var launchiTunes = function () {
    window.location = 'https://itunes.apple.com/us/app/mychart/id382952264?mt=8';
};
var launchGooglePlay = function () {
    window.location = 'http://play.google.com/store/apps/details?id=epic.mychart.android';
};
// config + userAgent test for myChart
export var isMyChartSupported = function () { return !!MYCHART_URI && (isAndroid() || isIOS()); };
// regex test for myChart url
export var isMyChartUrl = function (url) { return /^(https:\/\/)?mychart\./.test(url); };
// Conditionally initiates MyChart redirect for appropriate URLs, partners.
// Returns true if redirect is initiated
export var handleMyChartRedir = function (url) {
    if (!isMyChartSupported() || !isMyChartUrl(url)) {
        return false;
    }
    launchMyChart();
    return true;
};
