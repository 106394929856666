import React from 'react';
/**
 * Helper component to wrap form elements in a fieldset for screen reader accessibility.
 * Includes optional 'alert' role to force reading of label. Includes ref forwarding to
 * inner fieldset for custom focus behavior.
 */
var ScreenReaderFormGroup = function (props, ref) {
    var label = props.label, className = props.className, alert = props.alert, tabIndex = props.tabIndex, children = props.children;
    return (React.createElement("fieldset", { className: className, ref: ref, tabIndex: tabIndex },
        React.createElement("legend", { className: "h-0.25 w-0.25 overflow-hidden opacity-0", role: alert ? 'alert' : undefined }, label),
        children));
};
export default React.forwardRef(ScreenReaderFormGroup);
