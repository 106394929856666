/* GENERATED FROM proto/conversation.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */
export var FlowItem;
(function (FlowItem) {
    var Type;
    (function (Type) {
        Type["UNKNOWN"] = "UNKNOWN";
        Type["QUESTION"] = "QUESTION";
        Type["REMARK"] = "REMARK";
        Type["LLMRESPONSE"] = "LLMRESPONSE";
    })(Type = FlowItem.Type || (FlowItem.Type = {}));
})(FlowItem || (FlowItem = {}));
export var Conversation;
(function (Conversation) {
    var Type;
    (function (Type) {
        Type["UNKNOWN"] = "UNKNOWN";
        Type["SYMPTOM_CHECK"] = "SYMPTOM_CHECK";
        Type["CORONA"] = "CORONA";
        Type["NATIVE_SYMPTOM_CHECK"] = "NATIVE_SYMPTOM_CHECK";
        Type["NATIVE_CORONA"] = "NATIVE_CORONA";
        Type["FLEXIBLE_SYMPTOM_CHECK"] = "FLEXIBLE_SYMPTOM_CHECK";
        Type["PATIENT_SERVICE"] = "PATIENT_SERVICE";
        Type["LLM_EXPERIENCE"] = "LLM_EXPERIENCE";
    })(Type = Conversation.Type || (Conversation.Type = {}));
})(Conversation || (Conversation = {}));
export var Gender;
(function (Gender) {
    Gender["UNKNOWN_GENDER"] = "UNKNOWN_GENDER";
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["OTHER"] = "OTHER";
})(Gender || (Gender = {}));
