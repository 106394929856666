import '../../../../util/EventLog';
import { EventCategory } from '../../../../util/GaEvents';
import { Partner } from '../../../clearstep/proto/partners_pb';
export var logToDataLayer = function (type, data) {
    var _a;
    var dataLayerLog = {
        event: type,
        event_label: Partner.BSWH,
        event_category: EventCategory[type],
        non_interaction: false,
        data: data,
    };
    if (type) {
        (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push(dataLayerLog);
    }
};
