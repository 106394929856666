var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import 'i18next';
import MultiSelectOption from '../MultiSelectOption/MultiSelectOption';
import '../Types';
import '../proto/conversation_pb';
import styles from '../SingleSelectQuestion/SingleSelectQuestion.css';
import ScreenReaderFormGroup from '../../tailwind_common/ScreenReaderFormGroup';
import Button from '../../tailwind_common/Button';
var MultiSelectQuestion = function (_a) {
    var questionId = _a.questionId, options = _a.options, onSubmit = _a.onSubmit, _b = _a.label, label = _b === void 0 ? '' : _b, hideSendButton = _a.hideSendButton, t = _a.t, _c = _a.autoFocus, autoFocus = _c === void 0 ? true : _c, addNoneOfTheAbove = _a.addNoneOfTheAbove;
    var _d = useState({}), optionsState = _d[0], setOptionsState = _d[1];
    var _e = useState(false), noneOfTheAboveChecked = _e[0], setNoneOfTheAboveChecked = _e[1];
    var _f = useState(addNoneOfTheAbove), enableSubmit = _f[0], setEnableSubmit = _f[1];
    var primary = useRef(null);
    useEffect(function () {
        var _a;
        if (autoFocus) {
            (_a = primary.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [autoFocus]);
    useEffect(function () {
        var nothingSelected = !Object.values(optionsState).some(function (checked) { return checked; });
        if (nothingSelected && !addNoneOfTheAbove && enableSubmit) {
            setEnableSubmit(false);
        }
        else if (!nothingSelected && !enableSubmit) {
            setEnableSubmit(true);
        }
    }, [optionsState, addNoneOfTheAbove, enableSubmit]);
    var handleMultiSelectOptionChange = function (name, newValue) {
        setOptionsState(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[name] = newValue, _a)));
        });
        setNoneOfTheAboveChecked(false);
    };
    var handleSelectNoneOfTheAbove = function (checked) {
        setOptionsState({});
        setNoneOfTheAboveChecked(!checked);
    };
    var handleSubmit = function () {
        var selectedOptions = options.filter(function (o) { return optionsState[o.value]; });
        onSubmit(selectedOptions);
    };
    var renderOptions = function () {
        return options.map(function (option) {
            var checked = optionsState[option.value];
            return (React.createElement(MultiSelectOption, { key: "".concat(questionId, "--").concat(option.value), checked: checked, label: option.value, onChange: function () { return handleMultiSelectOptionChange(option.value || '', !checked); } }));
        });
    };
    return (React.createElement("div", { className: styles.multiselect, "data-questionId": questionId },
        React.createElement(ScreenReaderFormGroup, { className: styles.selectQuestion, ref: primary, tabIndex: -1, label: label },
            renderOptions(),
            addNoneOfTheAbove && (React.createElement(MultiSelectOption, { checked: noneOfTheAboveChecked, onChange: function () { return handleSelectNoneOfTheAbove(noneOfTheAboveChecked); }, label: t('multiSelect.noneOption', 'None of the above') || '' }))),
        !hideSendButton && (React.createElement(Button, { disabled: !enableSubmit, onClick: handleSubmit }, t('common.sendCta', 'Send')))));
};
export default withTranslation()(MultiSelectQuestion);
