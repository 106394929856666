import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import AngryFace from './FaceIcons/AngryFace/AngryFace.svg';
import AngryFaceHover from './FaceIcons/AngryFace/AngryFaceHover.svg';
import AngryFaceSelected from './FaceIcons/AngryFace/AngryFaceSelected.svg';
import AngryFaceMobile from './FaceIcons/AngryFace/AngryFaceMobile.svg';
import AngryFaceSelectedMobile from './FaceIcons/AngryFace/AngryFaceSelectedMobile.svg';
import UnhappyFace from './FaceIcons/UnhappyFace/UnhappyFace.svg';
import UnhappyFaceHover from './FaceIcons/UnhappyFace/UnhappyFaceHover.svg';
import UnhappyFaceSelected from './FaceIcons/UnhappyFace/UnhappyFaceSelected.svg';
import UnhappyFaceMobile from './FaceIcons/UnhappyFace/UnhappyFaceMobile.svg';
import UnhappyFaceSelectedMobile from './FaceIcons/UnhappyFace/UnhappyFaceSelectedMobile.svg';
import ExcitedFace from './FaceIcons/ExcitedFace/ExcitedFace.svg';
import ExcitedFaceHover from './FaceIcons/ExcitedFace/ExcitedFaceHover.svg';
import ExcitedFaceSelected from './FaceIcons/ExcitedFace/ExcitedFaceSelected.svg';
import ExcitedFaceMobile from './FaceIcons/ExcitedFace/ExcitedFaceMobile.svg';
import ExcitedFaceSelectedMobile from './FaceIcons/ExcitedFace/ExcitedFaceSelectedMobile.svg';
import NeutralFace from './FaceIcons/NeutralFace/NeutralFace.svg';
import NeutralFaceHover from './FaceIcons/NeutralFace/NeutralFaceHover.svg';
import NeutralFaceSelected from './FaceIcons/NeutralFace/NeutralFaceSelected.svg';
import NeutralFaceMobile from './FaceIcons/NeutralFace/NeutralFaceMobile.svg';
import NeutralFaceSelectedMobile from './FaceIcons/NeutralFace/NeutralFaceSelectedMobile.svg';
import HappyFace from './FaceIcons/HappyFace/HappyFace.svg';
import HappyFaceHover from './FaceIcons/HappyFace/HappyFaceHover.svg';
import HappyFaceSelected from './FaceIcons/HappyFace/HappyFaceSelected.svg';
import HappyFaceMobile from './FaceIcons/HappyFace/HappyFaceMobile.svg';
import HappyFaceSelectedMobile from './FaceIcons/HappyFace/HappyFaceSelectedMobile.svg';
import { SelectScore } from '../SelectScore/SelectScore';
import { ScoreSelector } from '../SelectScore/ScoreSelector';
import { isSmallScreen } from '../../../../clearstep/util/AppHelpers';
import Textarea from '../../../../tailwind_common/Textarea';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import '../MetricProps';
import { MetricPrompt } from '../MetricPrompt/MetricPrompt';
import { GenericLabel } from '../GenericLabel/GenericLabel';
import { GenericFooter } from '../GenericFooter/GenericFooter';
var isLowCSATScore = function (score) { return score && score !== -1 && score < 4; };
export var CSATCard = function (props) {
    var score = props.score, onSelect = props.onSelect, dynamicConfig = props.dynamicConfig;
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var csatMessage = current.context.csatMessage;
    // Show feedback if low score was previously selected, or feedback message has
    // previously been added
    var _a = useState(!!(csatMessage || isLowCSATScore(score))), showFeedback = _a[0], setShowFeedback = _a[1];
    var t = useTranslation().t;
    var selectHandler = function (score) {
        onSelect(score);
        if (isLowCSATScore(score)) {
            // Show the feedback text area when a low score is selected, and stay open
            // even if a higher score is later selected
            setShowFeedback(true);
        }
    };
    var scores = isSmallScreen()
        ? [
            {
                score: 1,
                defaultIcon: AngryFaceMobile,
                hoverIcon: AngryFaceSelectedMobile,
                selectIcon: AngryFaceSelectedMobile,
            },
            {
                score: 2,
                defaultIcon: UnhappyFaceMobile,
                hoverIcon: UnhappyFaceSelectedMobile,
                selectIcon: UnhappyFaceSelectedMobile,
            },
            {
                score: 3,
                defaultIcon: NeutralFaceMobile,
                hoverIcon: NeutralFaceSelectedMobile,
                selectIcon: NeutralFaceSelectedMobile,
            },
            {
                score: 4,
                defaultIcon: HappyFaceMobile,
                hoverIcon: HappyFaceSelectedMobile,
                selectIcon: HappyFaceSelectedMobile,
            },
            {
                score: 5,
                defaultIcon: ExcitedFaceMobile,
                hoverIcon: ExcitedFaceSelectedMobile,
                selectIcon: ExcitedFaceSelectedMobile,
            },
        ]
        : [
            {
                score: 1,
                defaultIcon: AngryFace,
                hoverIcon: AngryFaceHover,
                selectIcon: AngryFaceSelected,
            },
            {
                score: 2,
                defaultIcon: UnhappyFace,
                hoverIcon: UnhappyFaceHover,
                selectIcon: UnhappyFaceSelected,
            },
            {
                score: 3,
                defaultIcon: NeutralFace,
                hoverIcon: NeutralFaceHover,
                selectIcon: NeutralFaceSelected,
            },
            {
                score: 4,
                defaultIcon: HappyFace,
                hoverIcon: HappyFaceHover,
                selectIcon: HappyFaceSelected,
            },
            {
                score: 5,
                defaultIcon: ExcitedFace,
                hoverIcon: ExcitedFaceHover,
                selectIcon: ExcitedFaceSelected,
            },
        ];
    return (React.createElement("div", { className: "my-4 flex flex-1 flex-col mobile:mb-6" },
        React.createElement(MetricPrompt, { promptText: (dynamicConfig === null || dynamicConfig === void 0 ? void 0 : dynamicConfig.csat_question) ||
                t('feedbackModal.csat.promptText', 'Please rate your overall satisfaction with this digital navigator.') }),
        React.createElement(SelectScore, { scoreSelectorProps: {
                scores: scores,
                score: score,
                onSelect: selectHandler,
            }, ScoreSelectorComponent: ScoreSelector, lowScoreText: t('feedbackModal.csat.lowScoreText', 'VERY UNSATISFIED'), highScoreText: t('feedbackModal.csat.highScoreText', 'VERY SATISFIED') }),
        showFeedback && (React.createElement(React.Fragment, null,
            React.createElement(Textarea, { containerClassName: "flex-1", Label: GenericLabel, placeholder: t('feedbackModal.generic.question.placeholder', 'Enter your response'), onChange: function (e) {
                    metricService.send({ type: 'SAVE', csatMessage: e.target.value });
                }, value: csatMessage !== null && csatMessage !== void 0 ? csatMessage : '', id: "csatTextarea" }),
            React.createElement(GenericFooter, null)))));
};
