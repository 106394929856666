/* eslint-disable react/jsx-no-literals */
/*
  disabling jsx-no-literals as this file is not currently used
  for any partners that require translation
*/
import * as React from 'react';
import { MessageType } from '../proto/logging_pb';
import { linkToAndLogConvo } from '../../../util/EventLog';
import { getAppPath } from '../../../util/ProxyPath';
import { isDemo } from '../util/AppHelpers';
import styles from './VirtualVisitOptions.css';
import Button from '../../tailwind_common/Button';
var VirtualVisitOptions = function () {
    var renderButton = function (url, provider) { return (React.createElement(Button, { onClick: function () {
            return linkToAndLogConvo(url, MessageType.BUTTON_CLICK, {
                name: "Start virtual visit for ".concat(provider),
            });
        }, secondary: true }, "Start virtual visit")); };
    return (React.createElement("div", { className: styles.virtualVisitOptions },
        React.createElement("div", { className: styles.virtualVisitOptions__option },
            React.createElement("img", { className: styles.virtualVisitOptions__optionLogo, src: getAppPath('/static/images/partners/doctorondemand/conversation_logo.png'), alt: "Doctor On Demand" }),
            React.createElement("div", { className: styles.virtualVisitOptions__optionDataItems }, isDemo() && (React.createElement("div", { className: styles.virtualVisitOptions__optionData },
                React.createElement("div", { className: styles.virtualVisitOptions__optionDataItem },
                    React.createElement("div", { className: styles.virtualVisitOptions__waitTimeLow },
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeValue }, "14"),
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeLabel }, "mins")),
                    React.createElement("div", { className: styles.virtualVisitOptions__optionDataItemLabel }, "average wait time"))))),
            renderButton('https://www.doctorondemand.com/', 'Doctor On Demand')),
        React.createElement("div", { className: styles.virtualVisitOptions__option },
            React.createElement("img", { className: styles.virtualVisitOptions__optionLogo, src: getAppPath('/static/images/app/teladoc.png'), alt: "Teladoc" }),
            React.createElement("div", { className: styles.virtualVisitOptions__optionDataItems }, isDemo() && (React.createElement("div", { className: styles.virtualVisitOptions__optionData },
                React.createElement("div", { className: styles.virtualVisitOptions__optionDataItem },
                    React.createElement("div", { className: styles.virtualVisitOptions__waitTimeModerate },
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeValue }, "23"),
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeLabel }, "mins")),
                    React.createElement("div", { className: styles.virtualVisitOptions__optionDataItemLabel }, "average wait time"))))),
            renderButton('https://member.teladoc.com/signin', 'Teladoc')),
        React.createElement("div", { className: styles.virtualVisitOptions__option },
            React.createElement("img", { className: styles.virtualVisitOptions__optionLogo, src: getAppPath('/static/images/app/mdlive.png'), alt: "MDLIVE" }),
            React.createElement("div", { className: styles.virtualVisitOptions__optionDataItems }, isDemo() && (React.createElement("div", { className: styles.virtualVisitOptions__optionData },
                React.createElement("div", { className: styles.virtualVisitOptions__optionDataItem },
                    React.createElement("div", { className: styles.virtualVisitOptions__waitTimeHigh },
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeValue }, "44"),
                        React.createElement("span", { className: styles.virtualVisitOptions__waitTimeLabel }, "mins")),
                    React.createElement("div", { className: styles.virtualVisitOptions__optionDataItemLabel }, "average wait time"))))),
            renderButton('https://patient.mdlive.com/register#/step-1', 'MDLIVE'))));
};
export default VirtualVisitOptions;
