var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import 'i18next';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy.Wrapped';
import { ButtonContainer } from './ButtonContainer/ButtonContainer';
var CareCard = function (props) {
    var title = props.title, icon = props.icon, description = props.description, buttons = props.buttons, useMarkdown = props.useMarkdown, useMarginTop = props.useMarginTop, heading = props.heading, noMargin = props.noMargin, _a = props.headerClasses, headerClasses = _a === void 0 ? ['text-2xl leading-7.5 font-normal mobile:text-[22px] mobile:leading-6.5'] : _a, buttonsRight = props.buttonsRight, borderBottom = props.borderBottom;
    var Heading = heading || 'h3';
    var descriptionTWStyling = 'pb-6 font-normal';
    return (React.createElement("div", { className: twMerge('mb-1', (!title || noMargin) && 'm-0') },
        React.createElement("section", null,
            (title || icon) && (React.createElement("header", { className: classNames(headerClasses) },
                icon && React.createElement("span", { className: "pr-6" }, icon),
                title && React.createElement(Heading, null, title))),
            (description || buttons) && (React.createElement("div", { className: "mt-4" },
                description && (React.createElement("p", { className: descriptionTWStyling }, useMarkdown ? (React.createElement(Markdown, { options: {
                        overrides: {
                            ul: function (props) { return React.createElement("ul", __assign({}, props, { className: "ml-6 mt-4" })); },
                        },
                    } }, description)) : (description))),
                buttons &&
                    buttons.map(function (button, index) {
                        var _a;
                        return (React.createElement("div", { className: twMerge('my-3 border-0 border-t border-solid border-ui-light-gray pb-3', (!((_a = button.header) === null || _a === void 0 ? void 0 : _a.text) || button.header.text.length <= 0) &&
                                'mt-neg-4.25 border-0', index === buttons.length - 1 && 'mb-0', buttons.length > 1 && index === buttons.length - 1 && 'border-0', borderBottom &&
                                index === buttons.length - 1 &&
                                'border-b border-solid border-ui-light-gray pb-8'), key: button.url },
                            React.createElement(ButtonContainer, { button: button, useMarginTop: useMarginTop, buttonRight: buttonsRight !== null && buttonsRight !== void 0 ? buttonsRight : false })));
                    }))))));
};
export default CareCard;
