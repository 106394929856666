import React from 'react';
import { useTranslation } from 'react-i18next';
import ConversationMessage from '../ConversationMessage/ConversationMessage';
import { retrievePartnerPathUrl } from '../../clearstep/util/AppHelpers';
import { getAppPath } from '../../../util/ProxyPath';
var getUrl = function () {
    var partnerInPathUrl = retrievePartnerPathUrl();
    var searchParams = window.location.search;
    return partnerInPathUrl.length > 0
        ? "".concat(partnerInPathUrl, "/workflows/care_or_service_now/").concat(searchParams)
        : "/workflows/care_or_service_now/".concat(searchParams);
};
var BookCareOrServiceNow = function () {
    var t = useTranslation().t;
    var bookCareNowUrl = getUrl();
    var markdownText = t('bookCareOrServices.introText', 'Hi there, answer a few questions in 2-4 minutes to: \n* Learn the best place to treat your symptoms\n* Find online scheduling\n\nIf you are ready to book care, go to [Book Care or Services Now]({{bookCareNowUrl}}).', { bookCareNowUrl: getAppPath(bookCareNowUrl) });
    return (React.createElement(ConversationMessage, { key: "care-or-service-now-key", message: {
            is_from_user: false,
            text: markdownText,
            isCurrentQuestion: false,
        }, isCurrentQuestion: false, isFirstMessage: true }));
};
export default BookCareOrServiceNow;
