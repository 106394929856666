import * as React from 'react';
import '../../proto/care_locations_pb';
import { formatPhoneNumber, formatStructuredAddress } from '../../util/CareLocationHelpers';
import { StarRatingIcon, PhoneIcon, LocationPinIcon } from '../../../SVG';
import styles from './Provider.css';
var Provider = function (props) {
    var doctorLocation = props.doctorLocation, hideCostEstimate = props.hideCostEstimate;
    var name = doctorLocation.name, specialty = doctorLocation.specialty, structuredAddress = doctorLocation.structuredAddress, phone = doctorLocation.phone, starRating = doctorLocation.starRating, practiceName = doctorLocation.practiceName, distance = doctorLocation.distance;
    return (React.createElement("div", { className: styles.provider },
        React.createElement("div", { className: styles.provider__rating },
            React.createElement("span", null, starRating),
            " ",
            React.createElement(StarRatingIcon, null)),
        React.createElement("div", { className: styles.provider__text },
            React.createElement("div", { className: styles.provider__name }, name),
            React.createElement("div", { className: styles.provider__specialty }, specialty),
            React.createElement("div", { className: styles.provider__practice }, practiceName),
            React.createElement("div", { className: styles.provider__address }, formatStructuredAddress(structuredAddress)),
            React.createElement("div", { className: styles.provider__phoneAndDistance },
                phone && (React.createElement("div", { className: styles.provider__phone },
                    React.createElement(PhoneIcon, null),
                    React.createElement("span", null, formatPhoneNumber(phone)))),
                typeof distance === 'number' && (React.createElement("div", { className: styles.provider__distance },
                    React.createElement(LocationPinIcon, null),
                    distance.toFixed(2),
                    ' miles away'))),
            !hideCostEstimate && (React.createElement("div", { className: styles.provider__estimatedCost },
                "Co-pay: ",
                React.createElement("span", null, "$20"))))));
};
export default Provider;
