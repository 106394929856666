import * as React from 'react';
import { useTranslation } from 'react-i18next';
import 'i18next';
import CareLocation from '../CareLocation/CareLocation';
import CareLocationError from '../CareLocation/CareLocationError';
import FindCarePlaceholder from '../../tailwind_common/FindCarePlaceholder';
import '../Types';
import ZipCodeSubmit from '../../tailwind_common/ZipCodeSubmit';
import { ScreenReaderOnly } from '../../tailwind_common/ScreenReaderOnly';
import { capitalizeAllFirstLetters } from '../../../util/Strings';
var FindCareLocation = function (props) {
    var zipCode = props.zipCode, onChange = props.onChange, onSubmit = props.onSubmit, careLocations = props.careLocations, error = props.error, componentData = props.componentData, insuranceStatus = props.insuranceStatus, placeholder = props.placeholder, loading = props.loading, hideZipCodeInput = props.hideZipCodeInput, setZipFocus = props.setZipFocus;
    var title = componentData.title, heading = componentData.heading, description = componentData.description, icon = componentData.icon, hideFindOtherLocations = componentData.hideFindOtherLocations, findOtherLocationsUrl = componentData.findOtherLocationsUrl;
    var careLocation = Array.isArray(careLocations) ? careLocations[0] : null;
    var Heading = heading || 'h3';
    var t = useTranslation().t;
    // renders new-lines as <br> elements
    var renderDescription = function () {
        return description === null || description === void 0 ? void 0 : description.split('\n\n').map(function (desc, i, arr) { return (React.createElement(React.Fragment, null,
            React.createElement("p", { key: desc }, desc),
            i !== arr.length - 1 && React.createElement("br", null))); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("section", null,
            title && (React.createElement("header", { className: "flex items-center gap-4 pb-4" },
                icon && icon,
                React.createElement(Heading, { className: "text-3xl font-bold leading-9.5 mobile:text-2.75xl mobile:leading-8", "data-cy": "careOptionTitle" }, capitalizeAllFirstLetters(title)))),
            description && React.createElement("div", { className: "py-6" }, renderDescription()),
            !hideZipCodeInput && !!onChange && !!onSubmit && (React.createElement(ZipCodeSubmit, { cta: t('common.search', 'Search'), placeholder: t('common.enterZipCode', 'Enter zip code'), inputAriaLabel: t('common.zipCode', 'Zip code'), label: t('common.zipCode', 'Zip code'), initialValue: zipCode, onChange: onChange, onSubmit: onSubmit, error: error, buttonAriaLabel: t('findCareLocation.searchButton.ariaLabel', "".concat(title, " search"), {
                    title: title,
                }) || '' }))),
        React.createElement("section", { className: "mt-4" },
            careLocation && (React.createElement(React.Fragment, null,
                React.createElement(ScreenReaderOnly, { role: "alert", message: t('findCareLocation.resultsFoundScreenReaderAnnouncement', "".concat(title, " locations found near you"), { title: title }) }),
                React.createElement(CareLocation, { location: careLocation, key: careLocation.name, insuranceStatus: insuranceStatus, zipCode: zipCode, hideFindOtherLocations: hideFindOtherLocations, findOtherLocationsUrl: findOtherLocationsUrl }))),
            error && React.createElement(CareLocationError, null),
            placeholder && (React.createElement(FindCarePlaceholder, { setZipFocus: setZipFocus, loadingCareLocation: loading })))));
};
export default FindCareLocation;
