var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from 'react';
import 'i18next';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';
import { linkToAndLogConvo, logEvent } from '../../../util/EventLog';
import { InfoIcon } from '../../svg/InfoIcon';
import { MapIcon } from '../../svg/Map';
import { MapPinIcon } from '../../svg/MapPin';
import { PartnerCustomizationContext } from '../../tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import '../proto/care_locations_pb';
import { MessageType } from '../proto/logging_pb';
import { formatAddress, formatPhoneNumber, mapsDirectionsURL, mapsSearchURL, mapURL, } from '../util/CareLocationHelpers';
import { THEME } from '../util/Theme';
import styles from './CareLocation.css';
import CareLocationError from './CareLocationError';
import { CostBreakdownButtonWithIcon } from '../CostEstimate/CostBreakdownButtonWithIcon/CostBreakdownButtonWithIcon';
import '../../../api/ConversationResults';
import CostEstimate from '../CostEstimate/CostEstimate';
import LinkWithIcon from '../../tailwind_common/LinkWithIcon';
import Modal from '../../tailwind_common/Modal';
import Button from '../../tailwind_common/Button';
var CareLocation = function (props) {
    var _a;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var _b = useState(false), showCostBreakdownModal = _b[0], setShowCostBreakdownModal = _b[1];
    var t = useTranslation().t;
    var handleOpenCostBreakdownModal = function () {
        var costEstimate = props.costEstimate;
        if (!costEstimate || costEstimate.loading || costEstimate.error) {
            return;
        }
        setShowCostBreakdownModal(true);
    };
    var location = props.location, hideFindOtherLocations = props.hideFindOtherLocations, findOtherLocationsUrl = props.findOtherLocationsUrl, findOtherLocationsText = props.findOtherLocationsText, hideMap = props.hideMap, insuranceStatus = props.insuranceStatus, zipCode = props.zipCode, marketplace = props.marketplace, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate;
    if (location && location.name) {
        var name = location.name, structuredAddress = location.structuredAddress, phone_1 = location.phone, hours = location.hours, link_1 = location.link, careType = location.careType, waitTime = location.waitTime, distance = location.distance, schedulingLink_1 = location.schedulingLink;
        var streetNumber = structuredAddress.streetNumber, route = structuredAddress.route, locality = structuredAddress.locality, administrativeAreaLevel1 = structuredAddress.administrativeAreaLevel1, postalCode = structuredAddress.postalCode;
        var seeLocationDetailsLinkText_1 = t('careLocation.seeLocationDetails', 'See location details');
        var getDistanceText_1 = t('careLocation.milesAway', '{{miles}} miles away', {
            miles: distance.toFixed(2),
        });
        var getDirectionsLinkText_1 = t('careLocation.getDirections', 'Get directions');
        var getDirectionsLink_1 = mapsDirectionsURL(structuredAddress).href;
        var findOtherLocationsLinkText_1 = findOtherLocationsText || t('careLocation.findOtherLocations', 'Find other locations');
        var findOtherLocationsLink_1 = findOtherLocationsUrl || mapsSearchURL(careType, (_a = zipCode !== null && zipCode !== void 0 ? zipCode : postalCode) !== null && _a !== void 0 ? _a : '', THEME.partner);
        return (React.createElement("div", { "data-cy": "care-location" },
            React.createElement("div", { className: styles.careLocation__contentContainer },
                React.createElement("div", { className: styles.careLocation__content },
                    React.createElement("div", null,
                        name && (React.createElement("p", null,
                            React.createElement("b", null, name))),
                        React.createElement("p", null, 
                        // eslint-disable-next-line react/jsx-no-literals
                        "".concat(streetNumber, " ").concat(formatAddress(route))),
                        React.createElement("p", null, 
                        // eslint-disable-next-line react/jsx-no-literals
                        "".concat(formatAddress(locality), ", ").concat(administrativeAreaLevel1, " ").concat(postalCode)),
                        hours && !isEmpty(hours) && hours !== 'unknown' && (React.createElement("p", null,
                            React.createElement("b", null, t('careLocation.openHoursLabel', 'Hours: ')),
                            React.createElement("span", null, hours))),
                        phone_1 && (React.createElement("p", null,
                            React.createElement("b", null, t('careLocation.phoneNumberLabel', 'Phone: ')),
                            React.createElement("span", null,
                                React.createElement("a", { href: "tel:".concat(phone_1), target: "_blank", onClick: function () {
                                        logEvent(MessageType.PHONE_NUMBER_CLICK, __assign({ name: phone_1 }, (insuranceStatus && { insuranceStatus: insuranceStatus })), {
                                            partner: THEME.partner,
                                            customData: {
                                                'external-window': {
                                                    title: MessageType.PHONE_NUMBER_CLICK,
                                                    phone: phone_1,
                                                },
                                            },
                                        });
                                    }, rel: "noopener noreferrer", "data-action-type": "book_appt", "data-click-type": "cta-phone" }, formatPhoneNumber(phone_1))))),
                        waitTime && (React.createElement("p", null,
                            React.createElement("b", null, t('careLocation.waitTimeLabel', 'Wait Time: ')),
                            React.createElement("span", null, waitTime)))),
                    React.createElement("div", { className: styles.careLocation__controls },
                        link_1 && (React.createElement(LinkWithIcon, { text: seeLocationDetailsLinkText_1, icon: React.createElement(InfoIcon, { fill: "var(--primary-color)" }), href: link_1, needsLinkReferrer: THEME.needLinkReferrer, onClick: function () {
                                logEvent(MessageType.LINK_CLICK, __assign({ name: seeLocationDetailsLinkText_1 }, (insuranceStatus && { insuranceStatus: insuranceStatus })), {
                                    partner: THEME.partner,
                                    customData: {
                                        'external-window': {
                                            title: MessageType.LINK_CLICK,
                                            text: seeLocationDetailsLinkText_1,
                                            url: link_1,
                                        },
                                    },
                                });
                            } })),
                        !!distance && distance > 0 && (React.createElement("div", { className: styles.careLocation__linkItem },
                            React.createElement(LinkWithIcon, { text: getDistanceText_1, icon: React.createElement(MapPinIcon, null), href: getDirectionsLink_1, openNewTab: true, onClick: function () {
                                    logEvent(MessageType.LINK_CLICK, __assign({ name: getDistanceText_1 }, (insuranceStatus && { insuranceStatus: insuranceStatus })), {
                                        partner: THEME.partner,
                                        customData: {
                                            'external-window': {
                                                title: MessageType.LINK_CLICK,
                                                text: getDistanceText_1,
                                                url: getDirectionsLink_1,
                                            },
                                        },
                                    });
                                } }))),
                        React.createElement("div", { className: styles.careLocation__linkItem },
                            React.createElement(LinkWithIcon, { text: getDirectionsLinkText_1, icon: React.createElement(MapIcon, null), href: getDirectionsLink_1, openNewTab: true, onClick: function () {
                                    logEvent(MessageType.LINK_CLICK, __assign({ name: getDirectionsLinkText_1 }, (insuranceStatus && { insuranceStatus: insuranceStatus })), {
                                        partner: THEME.partner,
                                        customData: {
                                            'external-window': {
                                                text: getDirectionsLinkText_1,
                                                url: getDirectionsLink_1,
                                            },
                                        },
                                    });
                                } })),
                        !hideFindOtherLocations && (React.createElement("div", { className: styles.careLocation__linkItem },
                            React.createElement(LinkWithIcon, { text: findOtherLocationsLinkText_1 || '', icon: React.createElement(MapPinIcon, null), href: findOtherLocationsLink_1, openNewTab: true, onClick: function () {
                                    logEvent(MessageType.FIND_OTHER_LOCATIONS, __assign({ name: findOtherLocationsLinkText_1 }, (insuranceStatus && { insuranceStatus: insuranceStatus })), {
                                        partner: THEME.partner,
                                        customData: {
                                            'external-window': {
                                                url: findOtherLocationsLink_1,
                                                text: findOtherLocationsLinkText_1 || '',
                                            },
                                        },
                                    });
                                } }))),
                        showCostEstimate && costEstimate && (React.createElement("div", { className: classNames(styles.careLocation__linkItem, styles.careLocation__costEstimateWrapper) },
                            React.createElement(CostBreakdownButtonWithIcon, { costEstimate: costEstimate, handleOpenCostBreakdownModal: handleOpenCostBreakdownModal }))))),
                React.createElement("div", null,
                    !hideMap && (React.createElement("a", { href: mapsDirectionsURL(structuredAddress).href, target: "_blank", onClick: function () {
                            return logEvent(MessageType.MAP_CLICK, {
                                name: 'Map for care location',
                            }, { partner: THEME.partner });
                        }, rel: "noopener noreferrer" },
                        React.createElement("img", { className: styles.careLocation__map, src: mapURL(location), alt: t('careLocation.mapAlt', 'Map for care location') || '' }))),
                    partnerCustomizations.showCareLocationButton(careType) && (React.createElement(Button, { className: styles.careLocation__button, onClick: function () {
                            linkToAndLogConvo(link_1, MessageType.CLICKED_PARTNER_CARE_OPTION, {
                                name: 'Check in Online Now',
                            }, {
                                partner: THEME.partner,
                                customData: {
                                    'external-window': {
                                        title: MessageType.BUTTON_CLICK,
                                        text: 'Check in Online Now',
                                        url: link_1,
                                    },
                                },
                            });
                        }, actionType: "check_in", clickType: "cta" }, t('careLocation.checkInCtaLabel', 'Check in Online Now'))),
                    marketplace && (phone_1 || schedulingLink_1) && (React.createElement("div", { className: styles.careLocation__marketplaceButtonWrapper },
                        schedulingLink_1 && (React.createElement(Button, { className: styles.careLocation__marketplaceButton, onClick: function () {
                                return linkToAndLogConvo(schedulingLink_1, MessageType.CLICKED_PARTNER_CARE_OPTION, { name: 'Schedule Appointment Online' }, { partner: THEME.partner });
                            }, actionType: "book_appt", clickType: "cta" }, t('careLocation.scheduleAppointmentCta', 'Schedule Appointment Online'))),
                        phone_1 && (React.createElement(Button, { className: styles.careLocation__marketplaceButton, secondary: true, onClick: function () {
                                return linkToAndLogConvo("tel:".concat(phone_1), MessageType.CLICKED_PARTNER_CARE_OPTION, { name: 'Call for Availability' }, { partner: THEME.partner });
                            }, actionType: "book_appt", clickType: "cta-phone" }, t('careLocation.callCta', 'Call for Availability'))))))),
            showCostBreakdownModal && (React.createElement(Modal, { isOpen: showCostBreakdownModal, onClose: function () { return setShowCostBreakdownModal(false); }, title: t('careLocation.openCostModal', 'Your projected cost of care') || '' },
                React.createElement(CostEstimate, { location: location, costEstimate: costEstimate })))));
    }
    return React.createElement(CareLocationError, null);
};
export default CareLocation;
