import React, { Suspense } from 'react';
import { twMerge } from 'tailwind-merge';
import './WarningMessageHook';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy';
import { renderLink } from '../../clearstep/util/MarkdownHelpers';
import { MessageType } from '../../../util/EventLog';
// Height spacer while Markdown loads
var Placeholder = function () { return React.createElement("div", { className: "h-6 w-full" }); };
var WarningMessage = function (props) {
    var timestamp = props.timestamp, message = props.message;
    return (React.createElement(Suspense, { fallback: React.createElement(Placeholder, null) },
        React.createElement("p", { className: twMerge('text-center font-primary text-base font-normal tracking-tighter text-gray-600', 'mobile:text-start') },
            timestamp && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "font-semibold" }, timestamp), ": ")),
            React.createElement(Markdown, { options: {
                    overrides: {
                        a: function (innerProps) {
                            return renderLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK);
                        },
                    },
                } }, message))));
};
export default WarningMessage;
