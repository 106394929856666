import '../../../../util/EventLog';
import '../../../clearstep/Types';
import '../../../clearstep/proto/care_locations_pb';
import '../../../clearstep/proto/results_pb';
import './EventLogPartnerCustomizations';
export var logEventToExternalWindow = function (options) {
    var customData = options.customData;
    var logData = customData && customData['external-window'];
    if (logData) {
        logData.clearstepId = window.conversationId.toString();
        var parentWindow = window.parent;
        parentWindow.postMessage(logData, '*');
    }
};
export var hcaCustomDataWrapper = 'external-window';
