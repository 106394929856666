/* COOKIE HELPERS */
export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=".concat(d.toUTCString());
    var cookie = "".concat(cname, "=").concat(cvalue, ";").concat(expires);
    if (window.location.origin.endsWith('clearstep.health')) {
        cookie += ';domain=.clearstep.health';
    }
    document.cookie = cookie;
}
export function haveCookie(key) {
    return !!window.document.cookie.split(';').filter(function (item) { return item.trim().indexOf("".concat(key, "=")) === 0; })
        .length;
}
/* COOKIE KEYS */
export var clearstepCoronavirusDisclaimerCookie = 'clearstep_coronavirus_disclaimer_accepted';
export var npsSubmitted = 'clearstep_nps_submitted';
