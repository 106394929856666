import { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import './proto/results_pb';
import './proto/care_locations_pb';
// For editor only (doesn't need to be translated)
// Editor using this in a number of locations outside of JSX
// useAgeUnits won't work outside of JSX
// Prefer useAgeUnits going forward so it is translatable
/**
 * @deprecated
 */
export var AgeUnits = {
    Years: 'years',
    Months: 'months',
    Weeks: 'weeks',
    Days: 'days',
};
export var useAgeUnits = function () {
    var t = useTranslation().t;
    var ageUnits = useState({
        Years: { value: 'years', label: t('ageUnits.years', 'years') },
        Months: { value: 'months', label: t('ageUnits.months', 'months') },
        Weeks: { value: 'weeks', label: t('ageUnits.weeks', 'weeks') },
        Days: { value: 'days', label: t('ageUnits.days', 'days') },
    })[0];
    return ageUnits;
};
// Not displaying this as text anywhere so not translating for now
// Simple translation won't work since this is declared outside of JSX
// Would need to do something like useAgeUnits()
export var ResultStatus = {
    Loading: 'loading',
    Error: 'error',
};
export var YesNoOptions = {
    Yes: i18next.t('yesNoOptions.yes', 'Yes'),
    No: i18next.t('yesNoOptions.no', 'No'),
};
export var YesValue = 'Yes';
export var NoValue = 'No';
export var useYesNoOptions = function () {
    var t = useTranslation().t;
    return {
        Yes: { value: YesValue, label: t('yesNoOptions.yes', 'Yes') },
        No: { value: NoValue, label: t('yesNoOptions.no', 'No') },
    };
};
