import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useRingStyle } from './util';
var Button = function (props) {
    var className = props.className, secondary = props.secondary, styledText = props.styledText, alternative = props.alternative, disabled = props.disabled, onClick = props.onClick, tabIndex = props.tabIndex, isText = props.isText, children = props.children, type = props.type, rightArrow = props.rightArrow, useMarginTop = props.useMarginTop, ariaLabel = props.ariaLabel, actionType = props.actionType, clickType = props.clickType, onMouseEnter = props.onMouseEnter, onMouseLeave = props.onMouseLeave, innerClassName = props.innerClassName;
    var ringStyle = useRingStyle();
    var baseTW = alternative
        ? 'rounded-md px-4 py-2 transition duration-150 hover:shadow-button-hover focus:ring focus:ring-offset-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-40 mobile:mx-6'
        : 'btn duration-400 ease flex cursor-pointer flex-row items-center justify-center rounded-md bg-cta-color text-white transition-all focus:shadow-focus focus:outline-none disabled:cursor-not-allowed disabled:bg-button-disabled-background disabled:text-common-text-disabled-primary';
    var noneSecondaryTW = alternative && !secondary ? 'bg-primary text-white' : '';
    var alternativeSecondaryTW = alternative
        ? 'border-2 border-solid border-primary bg-white text-primary disabled:border-text-light-gray'
        : 'border border-solid border-primary bg-transparent text-primary hover:bg-primary hover:text-white disabled:bg-white disabled:border-text-light-gray';
    var spanElement = function () {
        if (alternative) {
            return React.createElement("span", { className: innerClassName }, children);
        }
        return (React.createElement("span", { className: twMerge('flex w-full flex-col px-6 py-2.25 text-base font-semibold mobile:px-4 mobile:py-2.5', styledText && rightArrow && 'flex flex-row items-center p-3.5', styledText && !rightArrow && 'p-3.5', innerClassName) }, children));
    };
    return (React.createElement("button", { className: twMerge(baseTW, noneSecondaryTW, secondary && alternativeSecondaryTW, styledText && 'rounded-none bg-transparent text-primary', !!isText && 'border-none bg-white text-primary', useMarginTop && 'mt-4', className), "data-te-ripple-init": !!alternative, "data-te-ripple-color": alternative ? 'light' : '', 
        // Can't use string concatenation with tailwind utilities, so we need to use inline styles
        style: alternative ? ringStyle : undefined, onClick: onClick, disabled: disabled, tabIndex: tabIndex, type: type, "aria-label": ariaLabel, "data-action-type": actionType, "data-click-type": clickType, onMouseEnter: function () {
            if (onMouseEnter) {
                onMouseEnter();
            }
        }, onMouseLeave: function () {
            if (onMouseLeave) {
                onMouseLeave();
            }
        } }, spanElement()));
};
export default Button;
