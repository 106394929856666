import * as React from 'react';
import '../Types';
import styles from './HistoricalConversation.css';
function renderMessageBox(text, isUser) {
    return (React.createElement("div", { className: isUser
            ? styles['historical_conversation__user-message-card']
            : styles['historical_conversation__bot-message-card'] },
        React.createElement("p", null, text)));
}
var HistoricalConversation = function (props) {
    var messages = props.messages;
    return (React.createElement("div", { className: styles.historical_conversation }, messages.map(function (message) { return renderMessageBox(message.text, message.is_from_user); })));
};
export default HistoricalConversation;
