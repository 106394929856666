import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { onEnter } from '../../../util/KeyPressHandlers';
import { useAgeUnits } from '../../clearstep/Types';
import { prepareText } from '../../clearstep/TextUtil';
import { isMobile, isSafari } from '../../clearstep/util/AppHelpers';
import Input from '../../tailwind_common/Input';
import AgeLabelWithTooltip from './AgeLabelWithTooltip';
import ScreenReaderFormGroup from '../../tailwind_common/ScreenReaderFormGroup';
import Button from '../../tailwind_common/Button';
import '../../tailwind_common/DropDownMenuSection';
import { capitalizeFirstLetter } from '../../../util/Strings';
var isValidYearsAge = function (age, isProxyUser) {
    return !!age && age <= 105 && (age >= 16 || isProxyUser);
};
export var SymptomCheckAgeQuestion = function (props) {
    var t = useTranslation().t;
    var possibleAgeUnits = useAgeUnits();
    var _a = useState(''), age = _a[0], setAge = _a[1];
    var _b = useState(possibleAgeUnits.Years), ageUnits = _b[0], setAgeUnits = _b[1];
    var _c = useState(''), validationError = _c[0], setValidationError = _c[1];
    var _d = useState({
        value: '',
        displayName: '',
    }), selectedDropDownOption = _d[0], setSelectedDropDownOption = _d[1];
    var _e = useState([]), ageDropdownOption = _e[0], setAgeDropdownOption = _e[1];
    useEffect(function () {
        if (possibleAgeUnits) {
            setAgeUnits(possibleAgeUnits.Years);
        }
    }, [possibleAgeUnits]);
    var inputRef = useRef(null);
    var isMobileSafari = isMobile() && isSafari();
    var YEARS_NON_PROXY_AGE_VALIDATION_ERR = t('ageInput.YEARS_NON_PROXY_AGE_VALIDATION_ERR', 'Must be between 16-105 years old');
    var YEARS_PROXY_AGE_VALIDATION_ERR = t('ageInput.YEARS_PROXY_AGE_VALIDATION_ERR', 'Must be between 1-105 years old');
    var MONTHS_AGE_VALIDATION_ERR = t('ageInput.MONTHS_AGE_VALIDATION_ERR', 'Must be between 1-24 months old');
    var WEEKS_AGE_VALIDATION_ERR = t('ageInput.WEEKS_AGE_VALIDATION_ERR', 'Must be between 1-12 weeks old');
    var DAYS_AGE_VALIDATION_ERR = t('ageInput.DAYS_AGE_VALIDATION_ERR', 'Must be between 0-30 days old');
    useEffect(function () {
        // NOTE: See FreeTextInputQuestion.tsx - same issue
        if (isMobileSafari) {
            return;
        }
        inputRef.current.focus();
    }, []);
    useEffect(function () {
        var complaint = props.complaint, questionId = props.questionId;
        var heap = window.heap;
        if (heap) {
            heap.track('QUESTION_DISPLAYED', {
                questionId: questionId,
                complaint: complaint,
            });
        }
    }, []);
    var setNonProxyAgeRestriction = function (state) {
        if (!isProxyUser) {
            props.setShowAgeRestriction(state);
        }
    };
    var getAgeUnitValidationError = function () {
        switch (ageUnits.value) {
            case possibleAgeUnits.Years.value:
                return isProxyUser ? YEARS_PROXY_AGE_VALIDATION_ERR : YEARS_NON_PROXY_AGE_VALIDATION_ERR;
            case possibleAgeUnits.Months.value:
                return MONTHS_AGE_VALIDATION_ERR;
            case possibleAgeUnits.Weeks.value:
                return WEEKS_AGE_VALIDATION_ERR;
            case possibleAgeUnits.Days.value:
                return DAYS_AGE_VALIDATION_ERR;
            default:
                return YEARS_NON_PROXY_AGE_VALIDATION_ERR;
        }
    };
    var isAgeBetweenInclusively = function (parsedAge, lowerNumber, higherNumber) { return !parsedAge || parsedAge > higherNumber || parsedAge < lowerNumber; };
    var checkAndSetValidationErrors = function (age) {
        var parsedAge = Number(age);
        switch (ageUnits.value) {
            case possibleAgeUnits.Years.value:
                if (!isValidYearsAge(parsedAge, isProxyUser)) {
                    if (parsedAge && parsedAge < 16) {
                        setNonProxyAgeRestriction(true);
                    }
                    setValidationError(getAgeUnitValidationError());
                    return true;
                }
                break;
            case possibleAgeUnits.Months.value:
                if (isAgeBetweenInclusively(parsedAge, 1, 24)) {
                    setValidationError(getAgeUnitValidationError());
                    return true;
                }
                break;
            case possibleAgeUnits.Weeks.value:
                if (isAgeBetweenInclusively(parsedAge, 1, 12)) {
                    setValidationError(getAgeUnitValidationError());
                    return true;
                }
                break;
            case possibleAgeUnits.Days.value:
                if (isAgeBetweenInclusively(parsedAge, 0, 30)) {
                    setValidationError(getAgeUnitValidationError());
                    return true;
                }
                break;
            default:
                setValidationError(getAgeUnitValidationError());
                return true;
        }
        setValidationError('');
        setNonProxyAgeRestriction(false);
        return false;
    };
    var handleChange = function (event) {
        var value = event.target.value;
        setAge(value);
        // Clear errors when changes are valid
        if (validationError) {
            checkAndSetValidationErrors(value);
        }
    };
    var handleDropDownClick = function (_) {
        setValidationError('');
    };
    var handleSubmit = function () {
        // Abort if any errors
        if (validationError !== '' || checkAndSetValidationErrors(age)) {
            return;
        }
        props.onSubmit({ age: age, ageUnits: ageUnits.value });
    };
    var handleAgeUnitSelected = function (selectedOption) {
        var selectedAgeUnit = selectedOption.value;
        switch (selectedAgeUnit) {
            case possibleAgeUnits.Years.value:
                setAgeUnits(possibleAgeUnits.Years);
                break;
            case possibleAgeUnits.Months.value:
                setAgeUnits(possibleAgeUnits.Months);
                break;
            case possibleAgeUnits.Weeks.value:
                setAgeUnits(possibleAgeUnits.Weeks);
                break;
            case possibleAgeUnits.Days.value:
                setAgeUnits(possibleAgeUnits.Days);
                break;
            default:
                setAgeUnits(possibleAgeUnits.Years);
        }
        setSelectedDropDownOption(selectedOption);
        setValidationError('');
        setNonProxyAgeRestriction(false);
    };
    var isProxyUser = props.isProxyUser;
    var labelText = prepareText(t('ageInput.ariaLabel', "How old are {you|they}? Input number of {{ageUnits}}.", {
        ageUnits: isProxyUser && ageUnits.value === possibleAgeUnits.Months.value
            ? possibleAgeUnits.Months.label
            : possibleAgeUnits.Years.label,
    }), { isProxyUser: isProxyUser });
    useEffect(function () {
        if (!isEmpty(possibleAgeUnits)) {
            setAgeDropdownOption([
                {
                    value: possibleAgeUnits.Years.value,
                    displayName: capitalizeFirstLetter(possibleAgeUnits.Years.label),
                },
                {
                    value: possibleAgeUnits.Months.value,
                    displayName: capitalizeFirstLetter(possibleAgeUnits.Months.label),
                },
                {
                    value: possibleAgeUnits.Weeks.value,
                    displayName: capitalizeFirstLetter(possibleAgeUnits.Weeks.label),
                },
                {
                    value: possibleAgeUnits.Days.value,
                    displayName: capitalizeFirstLetter(possibleAgeUnits.Days.label),
                },
            ]);
        }
    }, [possibleAgeUnits]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenReaderFormGroup, { alert: isMobileSafari, label: labelText },
            React.createElement(Input, { id: "symptomCheckAgeQuestion-id", Label: AgeLabelWithTooltip, ariaLabel: labelText, type: "number", inputMode: "decimal", min: "0", ref: inputRef, placeholder: t('common.age', 'Age') || '', value: age, onChange: handleChange, autoComplete: "off", onKeyPress: onEnter(handleSubmit), trailingAddOn: ageUnits.label, description: t('common.age', 'Age') || '', error: validationError, dropDownOptions: ageDropdownOption, selectedOption: selectedDropDownOption, showDropDownMenu: isProxyUser, selectedDropDownOption: function (selectedOption) { return handleAgeUnitSelected(selectedOption); }, onClickDropDown: handleDropDownClick })),
        React.createElement(Button, { disabled: validationError !== '', onClick: handleSubmit, className: "w-full" }, t('common.sendCta', 'Send'))));
};
export default SymptomCheckAgeQuestion;
