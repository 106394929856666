import { Children, isValidElement } from 'react';
// Helpers to handle React element Children
// Largely just copied from react-children-utilities: https://github.com/fernandopasik/react-children-utilities/blob/main/src/lib/onlyText.ts
export var hasChildren = function (element) {
    return isValidElement(element) && Boolean(element.props.children);
};
export var childToString = function (child) {
    if (typeof child === 'undefined' || child === null || typeof child === 'boolean') {
        return '';
    }
    if (JSON.stringify(child) === '{}') {
        return '';
    }
    return child.toString();
};
// Recursively iterates through children, and gets combined text content
export var onlyText = function (children) {
    if (!(children instanceof Array) && !isValidElement(children)) {
        return childToString(children);
    }
    return Children.toArray(children).reduce(function (text, child) {
        var newText = '';
        if (hasChildren(child)) {
            newText = onlyText(child.props.children);
        }
        else if (isValidElement(child)) {
            newText = '';
        }
        else {
            newText = childToString(child);
        }
        return text.concat(newText);
    }, '');
};
