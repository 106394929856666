import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import DropDownMenuSection from './DropDownMenuSection';
import { ChevronDown } from '../SVG';
export var DropdownMenu = function (_a) {
    var options = _a.options, onClickOptionSelected = _a.onClickOptionSelected, selectedOption = _a.selectedOption, className = _a.className, label = _a.label, checkmarkFill = _a.checkmarkFill;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var handleClickSelectedOption = function (optionSelected) {
        setMenuOpen(false);
        onClickOptionSelected(optionSelected);
    };
    return (React.createElement(Menu, { as: "div", className: twMerge(className, 'relative inline-block text-left') },
        React.createElement("div", null,
            React.createElement(Menu.Button, { className: "inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50", onClick: function () { return setMenuOpen(true); } },
                label,
                " ",
                React.createElement(ChevronDown, null))),
        React.createElement(Transition, { as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" },
            React.createElement("div", null, menuOpen && (React.createElement(DropDownMenuSection, { options: options, onClickOptionSelected: handleClickSelectedOption, selectedOption: selectedOption, checkmarkFill: checkmarkFill }))))));
};
export default DropdownMenu;
