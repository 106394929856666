import React, { useContext } from 'react';
import 'i18next';
import '../../../../api/ConversationResults';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/results_pb';
import { THEME } from '../../../clearstep/util/Theme';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import { Partner } from '../../../clearstep/proto/partners_pb';
import PartnerCareLocation from '../../PartnerCareLocation/PartnerCareLocation';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import CVSCareLocation from '../../../clearstep/CVSCareLocation/CVSCareLocation';
import { showMap } from '../../../clearstep/util/AppHelpers';
export var RetailClinicOption = function (props) {
    var complaint = props.complaint, careLocations = props.careLocations, zipCode = props.zipCode, isNewPatient = props.isNewPatient, sourceOfCare = props.sourceOfCare, insuranceStatus = props.insuranceStatus, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, setZipFocus = props.setZipFocus, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate, isLoading = props.isLoading;
    var careDetails = sourceOfCare.careDetails;
    var numberOfCareLocations = THEME.numberOfCareLocations;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerRetailClinicOption = partnerCustomizations.retailClinicOption({
        insuranceStatus: insuranceStatus,
        zipCode: zipCode,
        soc: sourceOfCare,
        details: careDetails,
    });
    if (THEME.symptomCheckCustomizations.defaultStatic && partnerRetailClinicOption) {
        return partnerRetailClinicOption;
    }
    if (!careLocations.length &&
        partnerCustomizations.retailClinicFallbackOption({ insuranceStatus: insuranceStatus, zipCode: zipCode })) {
        return partnerCustomizations.retailClinicFallbackOption({
            insuranceStatus: insuranceStatus,
            zipCode: zipCode,
        });
    }
    if (Array.isArray(careLocations) && careLocations.length > 0) {
        if (mixSchedulingAndNonLocations()) {
            return mixSchedulingAndNonLocations();
        }
        if (!showMap(sourceOfCare.careType)) {
            if (THEME.partner === Partner.CVS) {
                return (React.createElement("div", null,
                    partnerRetailClinicOption,
                    React.createElement("div", { className: "pt-4 mobile:pt-0" }, careLocations.slice(0, numberOfCareLocations).map(function (careLocation, index) { return (React.createElement(CVSCareLocation, { complaint: complaint, location: careLocation, key: careLocation.externalId, zipCode: zipCode, isNewPatient: isNewPatient, insuranceStatus: insuranceStatus, lastLocation: index === numberOfCareLocations - 1, firstLocation: index === 0 })); }))));
            }
            return (React.createElement("div", null,
                partnerRetailClinicOption,
                careLocations.slice(0, numberOfCareLocations).map(function (careLocation) { return (React.createElement(PartnerCareLocation, { location: careLocation, key: careLocation.name, zipCode: zipCode })); })));
        }
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: careLocations[0], zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
