import React from 'react';
import styles from './TreatmentBreakdown.css';
var renderDiagnosticResource = function (diagnostic) { return (React.createElement("div", { className: styles.treatmentBreakdown__treatmentItem, key: diagnostic.name },
    React.createElement("div", { className: styles.treatmentBreakdown__treatmentItemMetadata },
        React.createElement("div", { className: styles.treatmentBreakdown__treatmentItemName }, diagnostic.name)))); };
export var TreatmentBreakdown = function (props) {
    var costEstimate = props.costEstimate;
    return (React.createElement("div", { className: styles.treatmentBreakdown },
        React.createElement("div", { className: styles.treatmentBreakdown__treatmentBreakdownHeader }, "Services you may need based on your symptoms (only a doctor knows what you will actually need)"),
        React.createElement("div", { className: styles.treatmentBreakdown__treatmentItems }, costEstimate.data &&
            costEstimate.data.diagnostics &&
            costEstimate.data.diagnostics.map(function (diagnostic) {
                return renderDiagnosticResource(diagnostic);
            })),
        React.createElement("div", { className: styles.treatmentBreakdown__totalCostSection },
            React.createElement("div", { className: styles.treatmentBreakdown__totalCostHeader }, "Total Estimated Cost"),
            React.createElement("div", { className: styles.treatmentBreakdown__totalCost },
                "$",
                costEstimate.data.totalCost ? costEstimate.data.totalCost.toFixed(2) : ''))));
};
export default TreatmentBreakdown;
