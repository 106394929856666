import React from 'react';
import { twMerge } from 'tailwind-merge';
import { LeftArrow, RightArrow } from '../svg/Arrows';
export var ButtonWithArrowDirectionVariant = {
    Default: 0,
    Left: 1,
};
export var ButtonWithArrowColorVariant = {
    Default: 0,
    Gray: 1,
};
var ButtonWithArrow = function (_a) {
    var text = _a.text, onClick = _a.onClick, className = _a.className, directionVariant = _a.directionVariant, colorVariant = _a.colorVariant;
    var color = colorVariant === ButtonWithArrowColorVariant.Gray ? '#747486' : 'var(--primary-color)';
    return (React.createElement("button", { className: twMerge('text-decoration-none flex cursor-pointer flex-row items-center', className), style: { color: color }, onClick: onClick, "aria-label": text },
        React.createElement("span", { className: "flex items-center", tabIndex: -1 },
            directionVariant === ButtonWithArrowDirectionVariant.Left && React.createElement(LeftArrow, { fill: color }),
            React.createElement("p", { className: twMerge('font-semibold tracking-tight', directionVariant === ButtonWithArrowDirectionVariant.Left && 'ml-2', directionVariant === ButtonWithArrowDirectionVariant.Default && 'mr-2') }, text),
            directionVariant === ButtonWithArrowDirectionVariant.Default && (React.createElement(RightArrow, { fill: color })))));
};
ButtonWithArrow.defaultProps = {
    colorVariant: ButtonWithArrowColorVariant.Default,
    directionVariant: ButtonWithArrowDirectionVariant.Default,
};
export default ButtonWithArrow;
