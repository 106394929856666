var _a;
import { getCareDetailSelectOptions } from './CareDetail.util';
export var TestingCareDetail;
(function (TestingCareDetail) {
    TestingCareDetail["SYMPTOMATIC_COVID_TESTING"] = "symptomatic-covid-testing";
    TestingCareDetail["ASYMPTOMATIC_COVID_TESTING"] = "asymptomatic-covid-testing";
    TestingCareDetail["TEST_TO_TREAT_COVID"] = "test-to-treat-covid";
})(TestingCareDetail || (TestingCareDetail = {}));
export var TestingCareDetailLabel = (_a = {},
    _a[TestingCareDetail.SYMPTOMATIC_COVID_TESTING] = 'Symptomatic COVID Testing',
    _a[TestingCareDetail.ASYMPTOMATIC_COVID_TESTING] = 'Asymptomatic COVID Testing',
    _a[TestingCareDetail.TEST_TO_TREAT_COVID] = 'Test to Treat COVID',
    _a);
export var TestingCareDetailSelectOptions = getCareDetailSelectOptions(TestingCareDetail, TestingCareDetailLabel);
