import React from 'react';
import { useTranslation } from 'react-i18next';
import { RestartIcon } from '../../../svg/RestartIcon';
import { MetricController } from '../../MetricForm/Controller/MetricController';
import { restartConversation, isDev, isMobile } from '../../../clearstep/util/AppHelpers';
export var ConversationControls = function (props) {
    var conversationInProgress = props.conversationInProgress, isClearstepEmployee = props.isClearstepEmployee, conversationId = props.conversationId, partner = props.partner;
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "box-border hidden display-mobile-feedback:absolute display-mobile-feedback:bottom-14 display-mobile-feedback:flex display-mobile-feedback:w-full display-mobile-feedback:flex-row-reverse display-mobile-feedback:items-center display-mobile-feedback:justify-between display-mobile-feedback:px-6 display-mobile-feedback:py-0" }, conversationInProgress && (React.createElement("button", { className: "flex flex-row items-center rounded-full border border-solid border-common-bot-medium-gray bg-white px-4 py-2 shadow-conversation-message focus-within:shadow-focus-outset", onClick: restartConversation },
            React.createElement(RestartIcon, { className: "h-4 w-4 pr-2" }),
            React.createElement("p", { className: "text-sm font-semibold text-common-charcoal" }, t('common.restart', 'Restart'))))),
        React.createElement("div", { className: "sticky bottom-0 right-0 z-10" },
            React.createElement("div", { className: "absolute bottom-0 right-0 flex flex-col items-end pr-5 display-mobile-feedback:w-full display-mobile-feedback:pr-0" },
                conversationInProgress && (React.createElement("button", { className: "mb-4 flex flex-row items-center rounded-full border border-solid border-common-bot-medium-gray bg-white px-6 py-3 shadow-conversation-message focus-within:shadow-focus-outset display-mobile-feedback:hidden", onClick: restartConversation },
                    React.createElement(RestartIcon, { className: "pr-2" }),
                    React.createElement("p", { className: "font-semibold text-common-charcoal" },
                        ' ',
                        t('common.restart', 'Restart')))),
                (isClearstepEmployee || isDev()) && !isMobile() && (React.createElement("a", { href: "/partner/".concat(partner, "/?conversation_id=").concat(conversationId) }, "Iterate")),
                React.createElement("div", { className: "display-mobile-feedback:mr-5 mobile:mr-0 mobile:w-full" },
                    React.createElement(MetricController, null))))));
};
