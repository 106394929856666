import * as React from 'react';
import '../../styles/constants.css';
export var WarningIcon = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b;
    return (React.createElement("svg", { width: "56", height: "56", viewBox: "0 0 56 56", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className },
        React.createElement("circle", { cx: "28", cy: "28", r: "28", fill: "#b50000" }),
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.8996 12.9043C26.5372 12.5314 27.2608 12.3333 28 12.3333C28.7392 12.3333 29.4628 12.5314 30.1004 12.9043C30.7375 13.277 31.2649 13.81 31.6367 14.4468L31.6407 14.4537L43.4324 34.9042L43.4437 34.9241C43.8075 35.5786 43.998 36.3166 44 37.0653C44.002 37.8139 43.8155 38.5529 43.4553 39.2094C43.095 39.8661 42.5716 40.4202 41.9318 40.8113C41.2916 41.2027 40.5593 41.4156 39.8083 41.4241L39.7917 41.4243L16.1917 41.4242C15.4407 41.4156 14.7084 41.2027 14.0682 40.8113C13.4284 40.4202 12.905 39.8661 12.5447 39.2094C12.1845 38.5529 11.998 37.8139 12 37.0653C12.002 36.3166 12.1925 35.5786 12.5563 34.9241L12.5676 34.9042L24.2092 14.7141L24.3633 14.4468C24.7351 13.81 25.2625 13.277 25.8996 12.9043ZM26.8772 15.9108L15.0944 36.3458C14.9749 36.5642 14.9098 36.8149 14.9091 37.0731C14.9084 37.3346 14.9738 37.5889 15.0951 37.81C15.2163 38.0309 15.3872 38.208 15.5855 38.3292C15.7813 38.4489 15.9992 38.5114 16.2182 38.5151H39.7818C40.0008 38.5114 40.2187 38.4489 40.4145 38.3292C40.6128 38.208 40.7837 38.0309 40.9049 37.81C41.0262 37.5889 41.0916 37.3346 41.0909 37.0731C41.0902 36.8148 41.0251 36.5641 40.9055 36.3457L29.1245 15.9137L29.1228 15.9108C28.9984 15.6988 28.8276 15.5301 28.6316 15.4154C28.4352 15.3006 28.2179 15.2424 28 15.2424C27.7821 15.2424 27.5648 15.3006 27.3684 15.4154C27.1724 15.5301 27.0016 15.6988 26.8772 15.9108ZM26.5455 23.9699C26.5455 23.1666 27.1967 22.5154 28.0001 22.5154C28.8034 22.5154 29.4546 23.1666 29.4546 23.9699V29.7881C29.4546 30.5914 28.8034 31.2427 28.0001 31.2427C27.1967 31.2427 26.5455 30.5914 26.5455 29.7881V23.9699ZM28.0001 35.606C28.8034 35.606 29.4546 34.9548 29.4546 34.1514C29.4546 33.3481 28.8034 32.6969 28.0001 32.6969C27.1967 32.6969 26.5455 33.3481 26.5455 34.1514C26.5455 34.9548 27.1967 35.606 28.0001 35.606Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "32", height: "32", fill: "white", transform: "translate(12 11)" })))));
};
