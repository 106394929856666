import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '../SVG';
import Button from './Button';
import { useRingStyle } from './util';
export var Slideover = function (props) {
    var isOpen = props.isOpen, setIsOpen = props.setIsOpen, Title = props.Title, Content = props.Content;
    var ringStyle = useRingStyle();
    var t = useTranslation().t;
    return (React.createElement(Transition.Root, { show: isOpen, as: Fragment },
        React.createElement(Dialog, { as: "div", className: "relative z-10", onClose: setIsOpen },
            React.createElement(Transition.Child, { as: Fragment, enter: "ease-in-out duration-500", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in-out duration-500", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                React.createElement("div", { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" })),
            React.createElement("div", { className: "fixed inset-0 overflow-hidden" },
                React.createElement("div", { className: "absolute inset-0 overflow-hidden" },
                    React.createElement("div", { className: "pointer-events-none fixed inset-y-0 right-0 flex max-w-[90%] pl-10 md:max-w-full" },
                        React.createElement(Transition.Child, { as: Fragment, enter: "transform transition ease-in-out duration-500 sm:duration-700", enterFrom: "translate-x-full", enterTo: "translate-x-0", leave: "transform transition ease-in-out duration-500 sm:duration-700", leaveFrom: "translate-x-0", leaveTo: "translate-x-full" },
                            React.createElement(Dialog.Panel, { className: "pointer-events-auto w-screen max-w-2xl" },
                                React.createElement("div", { className: "flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl" },
                                    React.createElement("div", { className: "flex min-h-0 flex-1 flex-col overflow-y-scroll" },
                                        React.createElement("div", null,
                                            React.createElement("div", null,
                                                React.createElement(Dialog.Title, { className: "text-base font-semibold leading-6 text-gray-900" }, Title),
                                                React.createElement("div", { className: "absolute right-4 top-4 md:right-6 md:top-6" },
                                                    React.createElement("button", { type: "button", className: "tw-focus rounded-md p-px leading-[0px]", style: ringStyle, onClick: function () { return setIsOpen(false); } },
                                                        React.createElement("span", { className: "sr-only" }, t('slideover.XButtonScreenReader', 'Close panel')),
                                                        React.createElement(XIcon, { className: "h-4 w-4", stroke: "#9CA3AF", "aria-hidden": "true" }))))),
                                        React.createElement("div", { className: "relative flex-1" }, Content)),
                                    React.createElement("div", { className: "flex flex-shrink-0 justify-end border-x-0 border-b-0 border-t border-solid border-gray-200 px-6 py-5" },
                                        React.createElement(Button, { onClick: function () { return setIsOpen(false); }, className: "rounded-md" }, t('common.closeCta', 'Close'))))))))))));
};
export default Slideover;
