var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy.Wrapped';
import { HomeCareOverviewIcon } from '../../svg/HomeCareOverviewIcon';
import { HomeCareBandaidIcon } from '../../svg/HomeCareBandaidIcon';
import { StethoscopeCircleIcon } from '../../svg/StethoscopeCircleIcon';
import { HomeCareDirectionsIcon } from '../../svg/HomeCareDirectionsIcon';
import EducationDropdown from './EducationDropdown';
import styles from './Education.css';
import { prepareText } from '../TextUtil';
import { renderLink } from '../util/MarkdownHelpers';
import { MessageType } from '../../../util/EventLog';
var EducationMarkdown = /** @class */ (function (_super) {
    __extends(EducationMarkdown, _super);
    function EducationMarkdown() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderSectionHeader = function (props) {
            var children = props.children;
            var isProxyUser = _this.props.isProxyUser;
            var icon;
            if (children) {
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    if (child === 'Overview' ||
                        child === 'General Information' ||
                        child === 'Descripción General') {
                        icon = React.createElement(HomeCareOverviewIcon, null);
                        break;
                    }
                    else if (_this.isTextEqualCheckingProxyUser('Warning signs that {your|their} symptoms are getting worse', child, isProxyUser) ||
                        child === 'Señales de advertencia de que sus síntomas están empeorando') {
                        icon = React.createElement(StethoscopeCircleIcon, null);
                        break;
                    }
                    else if (_this.isTextEqualCheckingProxyUser('What can {you|they} do to feel better?', child, isProxyUser) ||
                        _this.isTextEqualCheckingProxyUser('¿Qué puede hacer {usted|ellos} para sentirte mejor?', child, isProxyUser)) {
                        icon = React.createElement(HomeCareBandaidIcon, null);
                        break;
                    }
                    else if (child === 'Next steps' || child === 'Próximos Pasos') {
                        icon = React.createElement(HomeCareDirectionsIcon, null);
                        break;
                    }
                }
            }
            return (React.createElement("div", { className: "iconHeaderContainer" },
                icon && React.createElement("span", { className: "iconContainer" }, icon),
                React.createElement("h3", { className: "iconHeader" }, children),
                React.createElement("div", { className: "iconHeaderHr" })));
        };
        return _this;
    }
    EducationMarkdown.prototype.isTextEqualCheckingProxyUser = function (expected, actual, isProxyUser) {
        if (typeof isProxyUser === 'boolean') {
            return prepareText(expected, { isProxyUser: isProxyUser }) === prepareText(actual, { isProxyUser: isProxyUser });
        }
        return expected === actual;
    };
    EducationMarkdown.prototype.render = function () {
        var _this = this;
        var text = this.props.text;
        return (React.createElement("div", { className: styles.educationMarkdown },
            React.createElement(Markdown, { options: {
                    overrides: {
                        details: EducationDropdown,
                        a: function (innerProps) {
                            return renderLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK);
                        },
                        h1: function (innerProps) { return _this.renderSectionHeader(innerProps); },
                    },
                } }, text || '')));
    };
    return EducationMarkdown;
}(React.Component));
export default EducationMarkdown;
