import React from 'react';
import InsuranceProgressBar from '../InsuranceProgressBar/InsuranceProgressBar';
import styles from './InsuranceCalculationGraph.css';
export var InsuranceCalculationGraph = function (props) {
    var calculation = props.calculation, title = props.title;
    return (React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataItem },
        React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataItemHeader },
            React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataItemMetadata },
                React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataTitle },
                    title,
                    ":"),
                React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataDescription },
                    "Your ", title === null || title === void 0 ? void 0 :
                    title.toLowerCase(),
                    ":",
                    ' ',
                    React.createElement("span", null,
                        "$",
                        calculation.amount.toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                        })))),
            React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataTitleValue },
                "$",
                calculation.amountRemaining.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                }))),
        React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataValues },
            React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceDataRange },
                React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceMet },
                    "Met:",
                    ' ',
                    React.createElement("span", null,
                        "$",
                        calculation.amountMet.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }))),
                React.createElement("div", { className: styles.insuranceCalculationGraph__insuranceRemaining },
                    "Remaining:",
                    ' ',
                    React.createElement("span", null,
                        "$",
                        calculation.amountRemaining.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }))))),
        React.createElement(InsuranceProgressBar, { fillPercentage: (calculation.amountMet / calculation.amount) * 100 })));
};
export default InsuranceCalculationGraph;
