var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Suspense } from 'react';
import { useEffect, useState } from 'preact/hooks';
import ConversationResults from '../../clearstep/ConversationResults/ConversationResults';
import { askForLocationForPartner, isDemo } from '../../clearstep/util/AppHelpers';
import { Conversation } from '../../clearstep/proto/conversation_pb';
import { createPartnerCustomizations, PartnerCustomizationContext, } from '../Partners/symptom_check/PartnerCustomizations';
import '../Partners/symptom_check/BasePartner';
import { MetricContextProvider } from '../MetricForm/Context/MetricContextProvider';
import '../../clearstep/Types';
import { i18nInit } from '../../../util/i18n/config';
import { useSplit } from '../../clearstep/util/Split';
var ResultPage = function (props) {
    var _a = useState(null), partnerCustomizations = _a[0], setPartnerCustomizations = _a[1];
    var _b = useState(false), translationsInit = _b[0], setTranslationsInit = _b[1];
    useEffect(function () {
        // render ConversationResults once translations are loaded
        i18nInit(function () { return setTranslationsInit(true); });
        var getPartnerCustomizations = function () { return __awaiter(void 0, void 0, void 0, function () {
            var customizations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createPartnerCustomizations];
                    case 1:
                        customizations = _a.sent();
                        setPartnerCustomizations(customizations);
                        return [2 /*return*/];
                }
            });
        }); };
        getPartnerCustomizations().then(function (_) { });
    }, []);
    useEffect(function () { var _a, _b; return useSplit((_b = (_a = props.resultsData) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()); }, []);
    var resultsData = props.resultsData;
    var recommendedCare = resultsData.recommendedCare, id = resultsData.id, type = resultsData.type, age = resultsData.age, complaint = resultsData.complaint, isProxyUser = resultsData.isProxyUser, flexibleUsingNative = resultsData.flexibleUsingNative, isClearstepEmployee = resultsData.isClearstepEmployee, zipCode = resultsData.zipCode, externalUserId = resultsData.externalUserId, partnerGroup = resultsData.partnerGroup;
    var getConvoTypeEnumFromString = function (inputValue) {
        var valueInEnum = Object.values(Conversation.Type).find(function (value) { return value === inputValue; });
        if (valueInEnum) {
            return valueInEnum;
        }
        return Conversation.Type.UNKNOWN;
    };
    var convoTypeEnum = getConvoTypeEnumFromString(type);
    return (partnerCustomizations && (React.createElement(PartnerCustomizationContext.Provider, { value: partnerCustomizations },
        React.createElement(Suspense, { fallback: "" },
            React.createElement(MetricContextProvider, null,
                React.createElement("div", null, translationsInit && (React.createElement(ConversationResults, { conversationType: convoTypeEnum, conversationId: id, isProxyUser: isProxyUser, age: age, recommendedCare: recommendedCare, askForLocation: askForLocationForPartner(convoTypeEnum) && !isDemo(), complaint: complaint, flexibleUsingNative: flexibleUsingNative, isClearstepEmployee: isClearstepEmployee, zipCode: zipCode, externalUserId: externalUserId, partnerGroup: partnerGroup }))))))));
};
export default ResultPage;
