var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import 'i18next';
import '../../../../api/ConversationResults';
import { ResultStatus } from '../../../clearstep/Types';
import '../../../clearstep/proto/results_pb';
import { THEME } from '../../../clearstep/util/Theme';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import PartnerCareLocation from '../../PartnerCareLocation/PartnerCareLocation';
import { showMap } from '../../../clearstep/util/AppHelpers';
export var UrgentCareOption = function (props) {
    var careLocations = props.careLocations, insuranceStatus = props.insuranceStatus, zipCode = props.zipCode, setZipFocus = props.setZipFocus, isLoading = props.isLoading, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, sourceOfCare = props.sourceOfCare, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate;
    var numberOfCareLocations = THEME.numberOfCareLocations;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerUrgentCareOption = partnerCustomizations.urgentCareOption(__assign(__assign({}, sourceOfCare), { insuranceStatus: insuranceStatus, zipCode: zipCode, careLocations: careLocations, numberOfCareLocations: numberOfCareLocations }));
    if (partnerUrgentCareOption) {
        return partnerUrgentCareOption;
    }
    if (Array.isArray(careLocations) && !!careLocations.length) {
        if (mixSchedulingAndNonLocations()) {
            return React.createElement(React.Fragment, null, mixSchedulingAndNonLocations());
        }
        if (!showMap(sourceOfCare.careType)) {
            return (React.createElement(React.Fragment, null, careLocations.slice(0, numberOfCareLocations).map(function (careLocation) { return (React.createElement(PartnerCareLocation, { location: careLocation, key: careLocation.name })); })));
        }
        var urgentCareCustomizations = partnerCustomizations.getUrgentCareCardCustomizations();
        var _a = __assign({}, urgentCareCustomizations), hideFindOtherLocations = _a.hideFindOtherLocations, findOtherLocationsUrl = _a.findOtherLocationsUrl, findOtherLocationsText = _a.findOtherLocationsText;
        return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: careLocations[0], zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading, hideFindOtherLocations: hideFindOtherLocations, findOtherLocationsText: findOtherLocationsText, findOtherLocationsUrl: findOtherLocationsUrl }));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
