import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../tailwind_common/Tooltip';
import { CircleQuestionMark } from '../../SVG';
import { PartnerCustomizationContext } from '../Partners/symptom_check/PartnerCustomizations';
var AgeLabelWithTooltip = function () {
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var t = useTranslation().t;
    return (React.createElement("span", { className: "relative flex items-center" },
        React.createElement("span", { className: "mr-1" }, t('common.age', 'Age')),
        React.createElement(Tooltip, { id: "age-question-tooltip", header: t('ageInput.tooltip.header', 'Why do we ask?'), content: t('ageInput.tooltip.content', 'Many conditions affect certain age groups differently. This helps us give you more accurate care advice.'), Icon: function () { return React.createElement(CircleQuestionMark, { className: "h-5 w-5 md:h-4 md:w-4" }); }, arrowPosition: partnerCustomizations.ageQuestionTooltipArrowPosition() })));
};
export default AgeLabelWithTooltip;
