var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Partner } from '../components/clearstep/proto/partners_pb';
import { THEME } from '../components/clearstep/util/Theme';
export var UTMContext = React.createContext({
    source: '',
    medium: '',
    campaign: '',
    term: '',
    content: '',
});
export function addParams(url, utmParams, defaults, extra) {
    if (defaults === void 0) { defaults = {}; }
    var _a = __assign(__assign({}, defaults), utmParams), source = _a.source, medium = _a.medium, campaign = _a.campaign, term = _a.term, content = _a.content;
    var ret = new URL(url);
    var covidDefault = THEME.covidDefault;
    if (source) {
        var updatedSource = source;
        // TODO(annie): make this more robust if extending implementation
        if (covidDefault) {
            if (window.location.href.indexOf('checksymptoms') > -1) {
                updatedSource = "".concat(source, "_core");
            }
            else {
                updatedSource = "".concat(source, "_covid");
            }
        }
        ret.searchParams.append('utm_source', updatedSource);
    }
    if (medium) {
        ret.searchParams.append('utm_medium', medium);
    }
    if (campaign) {
        var key = 'utm_campaign';
        if (THEME.partner === Partner.CVS) {
            key = 'cid';
        }
        ret.searchParams.append(key, campaign);
    }
    if (term) {
        ret.searchParams.append('utm_term', term);
    }
    if (content) {
        ret.searchParams.append('utm_content', content);
    }
    if (extra) {
        Object.entries(extra).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            return ret.searchParams.append(key, value);
        });
    }
    return ret.toString();
}
