var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy.Wrapped';
import { triageIcon, triageColor } from '../util/RenderHelpers';
import '../proto/results_pb';
import Modal from '../../tailwind_common/Modal/Modal.Lazy.Wrapped';
import styles from './TriageRecommendation.css';
import { prepareText } from '../TextUtil';
import { MessageType } from '../../../util/EventLog';
import { renderLink } from '../util/MarkdownHelpers';
import Button from '../../tailwind_common/Button';
var alertDiv = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({}, props, { role: "alert" }), children));
};
var renderSummaryText = function (primaryCareSummary, isProxyUser) {
    var preparedText = prepareText(primaryCareSummary, { isProxyUser: isProxyUser });
    return (React.createElement(Markdown, { options: {
            forceBlock: true,
            overrides: {
                p: alertDiv,
                a: function (_a) {
                    var href = _a.href, children = _a.children;
                    return renderLink(href, children, MessageType.CLICKED_PARTNER_CARE_OPTION, '', {
                        extraData: { linkType: 'triage-summary' },
                    });
                },
            },
        }, className: classNames(styles.triageRecommendation__summary) }, preparedText));
};
var renderDescriptionText = function (primaryCareDescription, isProxyUser) {
    var preparedText = prepareText(primaryCareDescription, { isProxyUser: isProxyUser });
    return React.createElement(Markdown, null, preparedText);
};
export var TriageRecommendation = function (props) {
    var triageResponse = props.triageResponse, isProxyUser = props.isProxyUser;
    var bestCareType = triageResponse.bestCareType, primaryCareSummary = triageResponse.primaryCareSummary, primaryCareDescription = triageResponse.primaryCareDescription, hideIcon = triageResponse.hideIcon;
    var t = useTranslation().t;
    var _a = useState(false), showCareDescriptionModal = _a[0], setShowCareDescriptionModal = _a[1];
    var descriptionCTA = t('triageSummaryModal.openCta', 'View more information');
    return (React.createElement("div", { className: styles.triageRecommendation },
        React.createElement("div", { className: styles.triageRecommendation__border, style: { backgroundColor: triageColor(bestCareType) } }),
        React.createElement("div", { className: styles.triageRecommendation__content },
            !hideIcon && (React.createElement("div", { className: styles.triageRecommendation__icon }, triageIcon(bestCareType))),
            React.createElement("div", { className: styles.triageRecommendation__text },
                renderSummaryText(primaryCareSummary, isProxyUser),
                primaryCareDescription && (React.createElement(Button, { onClick: function () { return setShowCareDescriptionModal(true); } }, descriptionCTA)))),
        React.createElement(Modal, { isOpen: showCareDescriptionModal, onClose: function () { return setShowCareDescriptionModal(false); }, title: t('triageSummaryModal.title', 'More Information'), titleStyle: { fontSize: '26px' } },
            React.createElement("div", { className: styles.modal }, renderDescriptionText(primaryCareDescription, isProxyUser)))));
};
