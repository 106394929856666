import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
var LocationHours = function (props) {
    var t = useTranslation().t;
    var renderHours = function () {
        // Check if hoursList is non-empty and the first element is not an empty string
        if (props.hoursList.length > 0 && props.hoursList[0] !== '') {
            return props.hoursList.map(function (hours, _) {
                var delimiterIndex = hours.indexOf(':');
                var day = hours.substring(0, delimiterIndex);
                var hoursOfOperation = hours.substring(delimiterIndex + 1);
                return (React.createElement("div", { key: hours, className: "mt-1.5 flex flex-row" },
                    React.createElement("p", { className: "pr-1 text-base font-light" },
                        day,
                        // eslint-disable-next-line react/jsx-no-literals
                        ':'),
                    React.createElement("p", { className: "text-base font-semibold" }, hoursOfOperation)));
            });
        }
        if (props.showDefaultHours) {
            return (React.createElement("span", { className: "mt-1.5 flex flex-row" },
                React.createElement("p", { className: "mt-1.5 text-sm" }, t('careLocation.partnerCareLocation.defaultWeekdaySched', 'Weekdays | 8am - 8pm')),
                React.createElement("p", { className: "mt-1.5 text-sm" }, t('careLocation.partnerCareLocation.defaultWeekendSched', 'Weekends | 9am - 5pm'))));
        }
    };
    return React.createElement("div", { className: twMerge('flex flex-col') }, renderHours());
};
export default LocationHours;
