import React from 'react';
import 'i18next';
import '../../clearstep/proto/results_pb';
import './ButtonContainer/ButtonContainer';
import CareCard from './CareCard';
var buildCareCardButtonFromProto = function (button) {
    var cta = button.cta, url = button.url, heading = button.heading, text = button.text, useWhitespace = button.useWhitespace;
    var headingType = (heading || 'p');
    return {
        cta: cta,
        url: url,
        header: {
            text: text,
            type: headingType,
            useWhitespace: useWhitespace,
        },
    };
};
export var buildCareCardButtons = function (buttons) {
    if (!buttons) {
        return [];
    }
    return buttons.map(function (b) { return buildCareCardButtonFromProto(b); });
};
export var buildCareCardFromCareOption = function (cardData, icon, hasCareLocations) {
    var subtitle = cardData.subtitle, description = cardData.description, isMarkdown = cardData.isMarkdown, heading = cardData.heading, buttons = cardData.buttons;
    return (React.createElement(CareCard, { title: subtitle, description: description, useMarkdown: isMarkdown, heading: heading, buttons: buildCareCardButtons(buttons), icon: icon, buttonsRight: false, borderBottom: hasCareLocations }));
};
export var buildCareCardFromPropsObject = function (props) { return (React.createElement(CareCard, { title: props.title, description: props.description, descriptionClasses: props.descriptionClasses || [], buttons: props.buttons, useMarkdown: props.useMarkdown, useMarginTop: props.useMarginTop, heading: props.heading, noMargin: props.noMargin, headerClasses: props.headerClasses || [] })); };
