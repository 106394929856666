var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import 'i18next';
import { prepareText } from '../../../../clearstep/TextUtil';
import CareOptionContentByCareType from '../../CareOptionContentByCareType/CareOptionContentByCareType';
import '../../../../clearstep/proto/results_pb';
import '../../../../clearstep/Types';
import '../../../../../api/ConversationResults';
import { PartnerCustomizationContext } from '../../../Partners/symptom_check/PartnerCustomizations';
import { UserContext } from '../../../../../context/UserContext';
export var CareOptionContent = function (props) {
    var sourceOfCare = props.sourceOfCare, isLoading = props.isLoading, age = props.age, insuranceStatus = props.insuranceStatus, zipCode = props.zipCode, careLocations = props.careLocations;
    var careType = sourceOfCare.careType, description = sourceOfCare.description, careDetails = sourceOfCare.careDetails;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var userData = useContext(UserContext);
    var isProxyUser = userData.isProxyUser;
    var hasDescription = !!description ||
        !!partnerCustomizations.careOptionDescription({ careType: careType, careDetails: careDetails, isProxyUser: isProxyUser });
    var showDescription = hasDescription &&
        partnerCustomizations.showCareOptionDescription({
            careType: careType,
            careLocations: careLocations,
            age: age,
            zipCode: zipCode,
            insuranceStatus: insuranceStatus,
            careDetails: careDetails,
        }) &&
        !partnerCustomizations.behavioralHealthSpecialistOption(careDetails);
    return (React.createElement("div", { className: "rounded-bl-2xl rounded-br-2xl border-x border-b border-t-0 border-solid border-ui-light-gray p-8 mobile:px-4 mobile:py-3" },
        showDescription &&
            (partnerCustomizations.careOptionDescription({
                careType: careType,
                careDetails: careDetails,
                isProxyUser: isProxyUser,
                sourceOfCare: sourceOfCare,
            }) || (React.createElement("p", { className: "pb-8", "data-cy-description": "testms" },
                prepareText(description, { isProxyUser: isProxyUser }),
                ' '))),
        React.createElement("div", { className: "flex flex-col" },
            React.createElement(CareOptionContentByCareType, __assign({}, props, { isLoading: isLoading })))));
};
