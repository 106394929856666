import { useActor } from '@xstate/react';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { MetricMachineContext } from '../MetricForm/Context/MetricFormContext';
export var GiveFeedbackMobileLink = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var showFeedbackButton = current.context.type === 'app';
    var t = useTranslation().t;
    return (React.createElement(React.Fragment, null, showFeedbackButton && (React.createElement("div", { className: "mt-5 hidden flex-col self-end display-mobile-feedback:flex" },
        React.createElement("button", { onClick: function () { return metricService.send('EXPAND'); } },
            React.createElement("p", { className: "font-semibold tracking-tight text-common-medium-gray" }, t('common.giveFeedbackLowerF', 'Give feedback')))))));
};
export default GiveFeedbackMobileLink;
