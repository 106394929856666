var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import 'i18next';
import { PartnerCustomizationContext } from '../../Partners/symptom_check/PartnerCustomizations';
import '../../../clearstep/proto/results_pb';
import { ResultStatus } from '../../../clearstep/Types';
import { THEME } from '../../../clearstep/util/Theme';
import ZocdocScheduleButton from '../../../clearstep/ZocdocScheduleButton/ZocdocScheduleButton';
import { CareLocationOrPlaceholder } from './CareLocationOrPlaceholder';
import '../../../../api/ConversationResults';
import PartnerCareLocation from '../../PartnerCareLocation/PartnerCareLocation';
import FindCarePlaceholder from '../../../tailwind_common/FindCarePlaceholder';
import CareLocationError from '../../../clearstep/CareLocation/CareLocationError';
import { showMap } from '../../../clearstep/util/AppHelpers';
export var PrimaryCareOption = function (props) {
    var careLocations = props.careLocations, zocdocBaseLink = props.zocdocBaseLink, insuranceStatus = props.insuranceStatus, zipCode = props.zipCode, age = props.age, setZipFocus = props.setZipFocus, isLoading = props.isLoading, mixSchedulingAndNonLocations = props.mixSchedulingAndNonLocations, sourceOfCare = props.sourceOfCare, costEstimate = props.costEstimate, showCostEstimate = props.showCostEstimate;
    var numberOfCareLocations = THEME.numberOfCareLocations;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var partnerPrimaryCareOption = partnerCustomizations.primaryCareOption(__assign(__assign({}, sourceOfCare), { age: age, insuranceStatus: insuranceStatus, zipCode: zipCode, zocdocBaseLink: zocdocBaseLink, careLocations: careLocations, numberOfCareLocations: numberOfCareLocations, setZipFocus: setZipFocus, isLoading: isLoading }));
    var renderPrimaryCareLocations = function (numberOfCareLocations) {
        var supportMultipleCareLocations = partnerCustomizations.supportMultipleCareLocations();
        if (Array.isArray(careLocations)) {
            if (mixSchedulingAndNonLocations()) {
                return mixSchedulingAndNonLocations();
            }
            if (supportMultipleCareLocations && !showMap(sourceOfCare.careType)) {
                return careLocations
                    .slice(0, numberOfCareLocations)
                    .map(function (careLocation) { return (React.createElement(PartnerCareLocation, { location: careLocation, key: careLocation.name })); });
            }
            return (React.createElement(CareLocationOrPlaceholder, { setZipFocus: setZipFocus, careLocation: careLocations[0], zipCode: zipCode, insuranceStatus: insuranceStatus || '', sourceOfCare: sourceOfCare, costEstimate: costEstimate, showCostEstimate: showCostEstimate, isLoading: isLoading }));
        }
        return null;
    };
    if (partnerPrimaryCareOption) {
        return partnerPrimaryCareOption;
    }
    if (Array.isArray(careLocations) && !!careLocations.length) {
        return (React.createElement("div", null,
            renderPrimaryCareLocations(numberOfCareLocations),
            !THEME.symptomCheckCustomizations.hideZocdoc && (React.createElement(ZocdocScheduleButton, { href: zocdocBaseLink }))));
    }
    if (careLocations === ResultStatus.Error) {
        return React.createElement(CareLocationError, null);
    }
    return (React.createElement(FindCarePlaceholder, { text: partnerCustomizations.findCarePlaceholderText(sourceOfCare.careType), loadingCareLocation: isLoading(), setZipFocus: setZipFocus }));
};
