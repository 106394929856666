var evalTextExpr = function (expr, params) {
    // TODO(dave): may want to make this fancier in the future to take into account
    // other variables likes age, partner, etc.
    var isProxyUser = params.isProxyUser;
    var parts = expr.split('|');
    return isProxyUser ? parts[1] : parts[0];
};
// Makes message such as "What is {your|his or her} gender" fit for viewing
export var prepareText = function (message, params) {
    var ret = '';
    var openBraceIndex;
    // t() function uses {{}} to denote variables, which we want to ignore when subbing out proxy user text
    var isTranslationBrace = function (i, brace, message) {
        return (message[i - 1] && message[i - 1] === brace) || (message[i + 1] && message[i + 1] === brace);
    };
    for (var i = 0; i < message.length; i++) {
        if (message[i] === '}' && !isTranslationBrace(i, '}', message)) {
            if (openBraceIndex === undefined) {
                throw new Error('Mismatched braces!');
            }
            ret += evalTextExpr(message.substring(openBraceIndex + 1, i), params);
            openBraceIndex = undefined;
        }
        else if (message[i] === '{' && !isTranslationBrace(i, '{', message)) {
            if (openBraceIndex !== undefined) {
                throw new Error('Mismatched braces!');
            }
            openBraceIndex = i;
        }
        else if (openBraceIndex === undefined) {
            ret += message[i];
        }
    }
    if (openBraceIndex !== undefined) {
        return "FORMATTED IMPROPERLY: ".concat(message);
    }
    return ret;
};
