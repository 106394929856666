var containsEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
var containsPhone = /\+?([0-9]{3})?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/;
var containsSSN = /\d{3}[- .]?\d{2}[- .]?\d{4}/;
var containsDate = /(\d{1,2})[-/. ](\d{1,2})[-/. ](\d{2,4})/;
var containsFiveConsecutiveDigits = /\d{5}/;
var containsAbove9999CommaSeparated = /\d{2,3},\d{3}/;
var containsName = /my\s+name\s+is/i;
export var emailError = 'contains e-mail';
export var phoneError = 'contains phone number';
export var ssnError = 'contains ssn';
export var dateError = 'contains date';
export var fiveConsecutiveDigitsError = 'contains five consecutive digits';
export var numberAbove9999CommaSeparatedError = 'contains number over 10,000';
export var containsNameError = 'contains name';
var isMatch = function (regex, text) { return text.match(regex); };
var regexMatchesAny = function (regExes, text) {
    for (var i = 0; i < regExes.length; i++) {
        var regEx = regExes[i];
        if (isMatch(regEx.regEx, text)) {
            return regEx.errorMessage;
        }
    }
    return '';
};
export var stringContainsPersonalInformation = function (text) {
    return regexMatchesAny([
        { regEx: containsEmail, errorMessage: emailError },
        { regEx: containsPhone, errorMessage: phoneError },
        { regEx: containsSSN, errorMessage: ssnError },
        { regEx: containsDate, errorMessage: dateError },
        { regEx: containsFiveConsecutiveDigits, errorMessage: fiveConsecutiveDigitsError },
        { regEx: containsAbove9999CommaSeparated, errorMessage: numberAbove9999CommaSeparatedError },
        { regEx: containsName, errorMessage: containsNameError },
    ], text);
};
