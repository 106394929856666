import React from 'react';
import '../../../../../clearstep/proto/conversation_pb';
import SingleSelectNonSearchableQuestion from '../../../../SingleSelectQuestion/SingleSelectNonSearchableQuestion';
import { isMyTuftsUser } from './myTuftsLoginHelpers';
var MyTuftsLoginQuestion = function (props) {
    var options = props.options, handleSelect = props.handleSelect, _a = props.label, label = _a === void 0 ? '' : _a, questionId = props.questionId, _b = props.autoFocus, autoFocus = _b === void 0 ? true : _b, setShowMyTuftsLogin = props.setShowMyTuftsLogin;
    var onSelect = function (option) {
        if (isMyTuftsUser(questionId, option)) {
            setShowMyTuftsLogin(true);
            return;
        }
        handleSelect(option);
    };
    return (React.createElement(SingleSelectNonSearchableQuestion, { options: options, onSelect: onSelect, label: label, questionId: questionId, key: questionId, autoFocus: autoFocus }));
};
export default MyTuftsLoginQuestion;
