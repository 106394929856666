export var hexToRedGreenBlue = function (hex) {
    try {
        hex = hex.replace('#', '');
        if (hex.length === 3) {
            hex = hex
                .split('')
                .map(function (c) { return c + c; })
                .join('');
        }
        var num = parseInt(hex, 16);
        var red = Math.floor(num / 65536) % 256;
        var green = Math.floor(num / 256) % 256;
        var blue = num % 256;
        return { red: red, green: green, blue: blue };
    }
    catch (e) {
        console.log('error extracting rgb from hex');
        return {};
    }
};
