import React from 'react';
import Provider from './Provider/Provider';
import InsuranceBreakdown from './InsuranceBreakdown/InsuranceBreakdown';
import TreatmentBreakdown from './TreatmentBreakdown/TreatmentBreakdown';
import styles from './CostEstimate.css';
import '../proto/care_locations_pb';
export var CostEstimate = function (props) {
    var location = props.location, costEstimate = props.costEstimate;
    return (React.createElement("div", { className: styles.costBreakdownContent__costBreakdown },
        React.createElement("div", { className: styles.costBreakdownContent__costBreakdownDescription }, "We project the services your doctor may provide and let you know what they would cost based on your insurance."),
        React.createElement(Provider, { doctorLocation: location, hideCostEstimate: true }),
        React.createElement(TreatmentBreakdown, { costEstimate: costEstimate }),
        React.createElement(InsuranceBreakdown, { costEstimate: costEstimate })));
};
export default CostEstimate;
