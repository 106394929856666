import { careOptionRenderInfo, CareOptionRenderType, } from '../../../util/care_details/VaccineCareDetail';
import '../../../util/GaEvents';
import { SourceOfCare } from '../proto/results_pb';
import '../proto/theme_pb';
import { THEME } from './Theme';
import '../Types';
export var showVaccineCareOption = function (careOption) {
    return careOption.careDetails.every(function (careDetail) {
        return careOptionRenderInfo[careDetail] &&
            careOptionRenderInfo[careDetail].careOptionRenderType !== CareOptionRenderType.LINKBOX;
    });
};
export var removeNonStandardCareOptions = function (careOptions) {
    return careOptions.filter(function (careOption) {
        if (careOption.careType === SourceOfCare.Type.VACCINE) {
            return showVaccineCareOption(careOption);
        }
        return true;
    });
};
export var getCareOptionsForRendering = function (careOptions) {
    var supportedOptions = {};
    var careOptionsForRendering = [];
    // TODO: this is already happening on the backend – is this also needed here?
    THEME.supportedCareOptions.forEach(function (opt) {
        supportedOptions[opt.careType] = true;
    });
    careOptions.forEach(function (opt) {
        if (supportedOptions[opt.careType]) {
            careOptionsForRendering.push(opt);
        }
    });
    careOptionsForRendering = removeNonStandardCareOptions(careOptionsForRendering);
    return careOptionsForRendering;
};
export var filterUnsupportedCareDetails = function (careType, careDetails, supportedCareDetails) {
    var foundCareType = supportedCareDetails[careType];
    if (!foundCareType) {
        return [];
    }
    var detailsMap = {};
    careDetails.forEach(function (detail) {
        detailsMap[detail] = detail;
    });
    return foundCareType.details
        .filter(function (detail) { return detail.name in detailsMap; })
        .map(function (detail) { return detail.name; });
};
export var partnerSupportsCareDetail = function (careType, careDetail, supportedCareDetails) {
    var foundCareType = supportedCareDetails[careType];
    if (!foundCareType) {
        return false;
    }
    return !!foundCareType.details.find(function (detail) { return detail.name === careDetail; });
};
export var getCareDetailsForCareType = function (recommendedCare, careType) {
    var _a;
    var triageResponse = recommendedCare.triageResponse;
    var careOptions = triageResponse.careOptions;
    var careDetails = (_a = careOptions.find(function (careOption) { return careOption.careType === careType; })) === null || _a === void 0 ? void 0 : _a.careDetails;
    if (careDetails) {
        return careDetails;
    }
    return [];
};
