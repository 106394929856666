var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import DropdownBlock from '../../tailwind_common/DropdownBlock';
import { hasChildren } from '../../../util/ReactChildren';
// Formats dropdown from string content
// Note: this should only happen if content is misformatted, but would
// result in Markdown formatting content as a string.
// Optimistically pulls first 'line' of string (assuming multiline content)
// appends any additional elements as further content inside the Dropdown
// element (testing showed it would always be and empty arr)
var StringDropdown = function (content, details) {
    var _a;
    // Matches first 'line' of content, attempting to trim any surrounding whitespace
    var titleMatches = content.match(/^\s*(\w.*)\s+/);
    if (!titleMatches || !((_a = titleMatches[1]) === null || _a === void 0 ? void 0 : _a.length)) {
        // Pass in as title if unable to split out a title
        return React.createElement(DropdownBlock, { title: content }, details);
    }
    // from capture group
    var title = titleMatches[1];
    // everything after entire matched regex section (first non-whitespace char)
    var remainingContent = content.slice(titleMatches[0].length);
    var parsedContent = __spreadArray([remainingContent], details, true);
    return React.createElement(DropdownBlock, { title: title }, parsedContent);
};
// Parses children from a markdown <detail> element into title and content for
// a DropdownBlock element
var EducationDropdown = function (props) {
    var children = props.children;
    if (typeof children === 'undefined' || children === null || typeof children === 'boolean') {
        return React.createElement(DropdownBlock, { title: "" });
    }
    var childrenArr = React.Children.toArray(children);
    var firstItem = childrenArr[0];
    var details = childrenArr.slice(1);
    if (hasChildren(firstItem)) {
        return React.createElement(DropdownBlock, { title: firstItem.props.children }, details);
    }
    // If markup is not correctly formatted, will be passed as a single element
    // array with a formatted string.
    if (typeof firstItem === 'string') {
        return StringDropdown(firstItem, details);
    }
    return React.createElement(DropdownBlock, { title: firstItem }, details);
};
export default EducationDropdown;
