/* GENERATED FROM proto/logging.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */
export var MessageType;
(function (MessageType) {
    MessageType["UNKNOWN_MESSAGE_TYPE"] = "UNKNOWN_MESSAGE_TYPE";
    MessageType["BUTTON_CLICK"] = "BUTTON_CLICK";
    MessageType["USER_POS"] = "USER_POS";
    MessageType["CLICKED_CONVO_HEADER"] = "CLICKED_CONVO_HEADER";
    MessageType["TOGGLED_DROPDOWN_BLOCK"] = "TOGGLED_DROPDOWN_BLOCK";
    MessageType["CLICKED_PARTNER_RESOURCE_CENTER"] = "CLICKED_PARTNER_RESOURCE_CENTER";
    MessageType["CLICKED_PARTNER_CARE_OPTION"] = "CLICKED_PARTNER_CARE_OPTION";
    MessageType["CLICKED_PARTNER_NURSE_LINE"] = "CLICKED_PARTNER_NURSE_LINE";
    MessageType["CLICKED_DONATE_PLASMA"] = "CLICKED_DONATE_PLASMA";
    MessageType["CLICKED_CONTINUE_TO_CHECK_SYMPTOMS"] = "CLICKED_CONTINUE_TO_CHECK_SYMPTOMS";
    MessageType["SET_INITIAL_COMPLAINT"] = "SET_INITIAL_COMPLAINT";
    MessageType["VIEW_RESULTS"] = "VIEW_RESULTS";
    MessageType["APPOINTMENT_INITIATED"] = "APPOINTMENT_INITIATED";
    MessageType["APPOINTMENT_BOOKED"] = "APPOINTMENT_BOOKED";
    MessageType["APPOINTMENT_FAILED"] = "APPOINTMENT_FAILED";
    MessageType["CONVERSATION_START"] = "CONVERSATION_START";
    MessageType["CONVERSATION_CLOSE"] = "CONVERSATION_CLOSE";
    MessageType["ANSWER_QUESTION"] = "ANSWER_QUESTION";
    MessageType["LINK_CLICK"] = "LINK_CLICK";
    MessageType["PHONE_NUMBER_CLICK"] = "PHONE_NUMBER_CLICK";
    MessageType["LOCATION_SEARCH"] = "LOCATION_SEARCH";
    MessageType["LOCATION_SEARCH_RESULT"] = "LOCATION_SEARCH_RESULT";
    MessageType["MAP_CLICK"] = "MAP_CLICK";
    MessageType["FIND_OTHER_LOCATIONS"] = "FIND_OTHER_LOCATIONS";
    MessageType["CLICKED_PARTNER_EXTERNAL_LINK"] = "CLICKED_PARTNER_EXTERNAL_LINK";
    MessageType["REDIRECT_INITIATED"] = "REDIRECT_INITIATED";
    MessageType["ANSWER_IS_PROXY_USER"] = "ANSWER_IS_PROXY_USER";
    MessageType["WIDGET_EXPANSION"] = "WIDGET_EXPANSION";
    MessageType["WIDGET_WORKFLOW_CLICKED"] = "WIDGET_WORKFLOW_CLICKED";
    MessageType["SUBMITTED_BOOKING_INTENT"] = "SUBMITTED_BOOKING_INTENT";
    MessageType["GEOLOCATION_REQUESTED"] = "GEOLOCATION_REQUESTED";
})(MessageType || (MessageType = {}));
