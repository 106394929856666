var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'i18next';
import { useEffect } from 'preact/hooks';
import scrollIntoView from 'scroll-into-view';
import '../clearstep/proto/results_pb';
import { YesValue } from '../clearstep/Types';
import { getInsurancePlans, } from '../../api/ConversationResults';
import { zipValidator } from './ZipCodeSubmit';
import { PartnerCustomizationContext } from '../tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import Select from './Select';
import Button from './Button';
import TextField from './TextField';
var matchPlanByName = function (name, plans) {
    var matches = plans.filter(function (plan) { return plan.planName === name; });
    return !matches.length ? null : matches[0];
};
export var SearchCareLocationsForm = function (props) {
    var careLocationsError = props.careLocationsError, handleUpdateZipcode = props.handleUpdateZipcode, initialZipcode = props.initialZipcode, handleUpdateInsuranceStatus = props.handleUpdateInsuranceStatus, careOptions = props.careOptions, zipCode = props.zipCode, showCostEstimate = props.showCostEstimate, getCostEstimate = props.getCostEstimate, onSearchCareLocations = props.onSearchCareLocations, zipFocus = props.zipFocus, setZipFocus = props.setZipFocus, requestData = props.requestData, setRequestData = props.setRequestData;
    var t = useTranslation().t;
    var _a = useState(''), memberId = _a[0], setMemberId = _a[1];
    var _b = useState(''), dateOfBirth = _b[0], setDateOfBirth = _b[1];
    var _c = useState(''), zipCodeError = _c[0], setZipCodeError = _c[1];
    var _d = useState(''), insurancePlanError = _d[0], setInsurancePlanError = _d[1];
    var _e = useState(''), dateOfBirthError = _e[0], setDateOfBirthError = _e[1];
    var _f = useState([]), insurancePlans = _f[0], setInsurancePlans = _f[1];
    var _g = useState(null), selectedPlan = _g[0], setSelectedPlan = _g[1];
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var searchBtnText = useState(t('common.search', 'Search'))[0];
    var zipRef = useRef(null);
    useEffect(function () {
        var fetchInsurancePlans = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getInsurancePlans()];
                    case 1:
                        response = _a.sent();
                        if (response && !response.error && response.data) {
                            setInsurancePlans(response.data);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        fetchInsurancePlans();
    }, []);
    useEffect(function () {
        if (zipFocus === true) {
            if (zipRef.current) {
                zipRef.current.focus();
                scrollIntoView(zipRef.current, {
                    time: 0,
                    align: {
                        top: 0,
                        topOffset: 200,
                    },
                });
            }
            setZipFocus(false);
        }
    }, [zipFocus]);
    var partnerSearchFilter = partnerCustomizations.locationSearchFilters({
        careOptions: careOptions,
        requestData: requestData,
        setRequestData: setRequestData,
    });
    var handleSelectInsurancePlan = function (selected) {
        setInsurancePlanError('');
        setSelectedPlan(matchPlanByName(selected.label, insurancePlans));
        handleUpdateInsuranceStatus(YesValue);
    };
    var validDateOfBirth = function () {
        if (!showCostEstimate) {
            return true;
        }
        if (!dateOfBirth) {
            return true;
        }
        var dateEntered = new Date(dateOfBirth);
        var isDate = dateEntered instanceof Date && !isNaN(dateEntered.valueOf());
        var minDob = new Date('1900-01-01');
        var today = new Date();
        return isDate && dateEntered > minDob && dateEntered < today;
    };
    var validInsuranceUid = function () {
        if (!showCostEstimate) {
            return true;
        }
        return !!selectedPlan && !!selectedPlan.planName;
    };
    var handleSearch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var setIsTherapistSupported, zipToSearch, isTherapistSupported;
        return __generator(this, function (_a) {
            setIsTherapistSupported = props.setIsTherapistSupported;
            zipToSearch = zipCode || initialZipcode;
            if (!zipToSearch ||
                !zipValidator(zipToSearch) ||
                (showCostEstimate && !validInsuranceUid()) ||
                !validDateOfBirth()) {
                if (!zipToSearch) {
                    setZipCodeError(t('searchCareLocationForm.errors.noZipCode', 'Please enter a zip code'));
                    setIsTherapistSupported(false);
                }
                if (!zipValidator(zipToSearch)) {
                    setZipCodeError(t('searchCareLocationForm.errors.invalidZipCode', 'Please enter a valid zip code'));
                    setIsTherapistSupported(false);
                }
                if (showCostEstimate && !validInsuranceUid()) {
                    setInsurancePlanError(t('searchCareLocationForm.errors.noInsurancePlan', 'Please select an insurance plan'));
                }
                if (!validDateOfBirth()) {
                    setDateOfBirthError(t('searchCareLocationForm.errors.birthdayInvalid', 'Please enter a valid date'));
                }
                return [2 /*return*/];
            }
            setZipCodeError('');
            setDateOfBirthError('');
            setInsurancePlanError('');
            // Hardcode while using sandbox (sandbox endpoint requires specific inputs)
            if (showCostEstimate) {
                getCostEstimate({
                    conversationId: window.conversationId,
                    zipcode: zipCode,
                    payerId: '000045',
                    memberId: '0000000000',
                    dateOfBirth: dateOfBirth || '1980-01-02', // Required DOB for sandbox success
                });
            }
            isTherapistSupported = partnerCustomizations.isTherapistSupported({
                zipCode: zipCode,
            });
            isTherapistSupported.then(function (value) {
                setIsTherapistSupported(value);
            });
            onSearchCareLocations();
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("div", { className: "flex flex-wrap items-end mobile:flex-col mobile:items-start" },
        React.createElement("div", { role: "group", "aria-label": t('searchCareLocationForm.formAriaLabel', 'Find care locations near you') || '', className: "mobile:flex-column flex flex-wrap" },
            React.createElement("div", { className: "mb-2.5 mr-3.75 mobile:mb-0 mobile:w-[90vw] mobile:max-w-[90vw]" },
                React.createElement(TextField, { placeholder: t('common.enterZipCode', 'Enter Zip Code'), ariaLabel: t('searchCareLocationForm.errors.zipInputAriaLabel', 'Enter your zip code in this input field. This field is required.'), label: t('common.zipCode', 'Zip code'), id: "zip-input", boldLabel: true, onChange: handleUpdateZipcode, initialValue: initialZipcode, onEnter: handleSearch, error: careLocationsError || zipCodeError, required: false, inputType: "number", inputMode: "decimal", ref: zipRef, className: "text-semi-base font-bold mobile:w-[90vw] mobile:max-w-[90vw]", innerClassName: "mobile:w-full" })),
            partnerSearchFilter && React.createElement("div", null, partnerSearchFilter),
            showCostEstimate && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "mr-3.75" },
                    React.createElement("div", { className: "w-75 min-w-fit max-w-75 mobile:w-full mobile:max-w-full" },
                        React.createElement(Select, { label: "Insurance Carrier", required: true, selected: {
                                value: (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.planName) || '',
                                label: (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.planName) || '',
                            }, options: (insurancePlans || []).map(function (plan) { return ({
                                value: plan.planName,
                                label: plan.planName,
                            }); }), onSelect: handleSelectInsurancePlan, error: insurancePlanError, placeholder: "Select" }))),
                React.createElement("div", { className: "mb-2.5 mr-3.75" },
                    React.createElement(TextField, { placeholder: "Member ID (optional)", ariaLabel: "Input member ID (optional)", label: "Member ID (optional)", onChange: setMemberId, initialValue: memberId || '', className: "mobile:width-[90vw] mobile:max-width-[90vw] text-semi-base font-bold" })),
                React.createElement("div", { className: "mb-2.5 mr-3.75" },
                    React.createElement(TextField, { placeholder: "Date of Birth (optional)", ariaLabel: "Date of Birth (optional)", onChange: setDateOfBirth, inputType: "date", label: "Date of Birth (optional)", error: dateOfBirthError, value: dateOfBirth, usePropsForValue: true, className: "mobile:width-[90vw] mobile:max-width-[90vw] text-semi-base font-bold" }))))),
        React.createElement("div", { className: "mb-6.5 mobile:mb-0 mobile:w-full" },
            React.createElement(Button, { disabled: false, onClick: handleSearch, className: "w-45 mobile:w-full" }, searchBtnText))));
};
export default SearchCareLocationsForm;
