import { logEvent, MessageType } from '../../../util/EventLog';
import { Conversation } from '../proto/conversation_pb';
import { Partner } from '../proto/partners_pb';
import '../proto/results_pb';
import { THEME } from './Theme';
/**
 * This function removes the query params representing initial conditions and returns the remaining query string.
 *
 * If the given query param is an empty string, then the returned value is ALWAYS also an empty string.
 *
 * If the given query param contains only initial query params and no other params, then the returned value MAY
 * be simply "?". This is acceptable because setting the window.location.search to a "?" is allowed.
 *
 * Space characters represented as "%20" are removed. However, other space characters such as "+" or " " WILL
 * not be removed. However, this SHOULD NOT break the resulting URL. Whitespace encoding in URLs is rather messy:
 * it's encoded differently in different parts of the URL and in different versions of the HTTP standard.
 * See https://stackoverflow.com/questions/1634271/url-encoding-the-space-character-or-20
 *
 * It may be possible to reimplement this function to use URLSearchParams.delete(). However, that function is
 * not supported on Internet Explorer as of March 2021.
 * See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/delete
 *
 * The function preserves all other query params, including any "&" characters necessary to join multiple such
 * params.
 *
 * @param query -- the query string, normally window.location.search
 * @returns -- modified query string without the params for initial conditions
 */
export function clearInitialQueryParams(query) {
    var symptoms = /symptoms=([a-zA-Z0-9]|%20)*&?/;
    var conversationId = /conversation_id=([a-zA-Z0-9-]|%20)*&?/;
    var amphersand = /&$/;
    return query.replace(symptoms, '').replace(conversationId, '').replace(amphersand, '');
}
export function clearWorkflowFromHref(href) {
    var workflow = /\/workflows\/(.*?)\//;
    return href.replace(workflow, '');
}
export function clearConversationFromHref(href) {
    var conversation = /\/conversation\/.*$/;
    return href.replace(conversation, '');
}
export function retrievePartnerWorkflowPathUrl() {
    var partnerWorkflowInPathRegExp = new RegExp('(/partner/[a-zA-Z-]+)?/workflows/[a-zA-Z0-9_-]+');
    var partnerWorkflowInPath = window.location.pathname.match(partnerWorkflowInPathRegExp);
    return partnerWorkflowInPath && partnerWorkflowInPath.length > 0
        ? partnerWorkflowInPath[0]
        : retrievePartnerPathUrl();
}
export function retrievePartnerPathUrl() {
    var partnerInPathRegExp = new RegExp('/partner/[a-zA-Z-]+');
    var partnersInPath = window.location.pathname.match(partnerInPathRegExp);
    return partnersInPath && partnersInPath.length > 0 ? partnersInPath[0] : '';
}
export function restartConversation() {
    logEvent(MessageType.BUTTON_CLICK, {
        name: 'Restart',
    }, {
        partner: THEME.partner,
        customData: {
            'external-window': {
                title: MessageType.BUTTON_CLICK,
                text: 'Restart',
            },
        },
    });
    // clear query params, which causes a reload
    // Redirect to main page when restarting on care or service now flow
    if (window.location.href.includes('workflows/care_or_service_now')) {
        window.location.href = clearWorkflowFromHref(window.location.href);
        return;
    }
    if (window.location.href.includes('conversation/new/immediate-care-visit')) {
        window.location.href = clearConversationFromHref(window.location.href);
        return;
    }
    window.location.search = clearInitialQueryParams(window.location.search);
}
/* CLEARSTEP DEMO */
export function isDemo() {
    return window.location.pathname.indexOf('demo') > -1;
}
export function getSearchCareLocationsPartner() {
    return isDemo() ? Partner.CLEARSTEP_DEMO : THEME.partner;
}
export function getURLParamValue(key) {
    var params = new URLSearchParams(window.location.search);
    return params.get(key);
}
export function hasEmbedUrlParam() {
    return getURLParamValue('embed') === '1';
}
export var isMinuteClinicOrigin = function () {
    return getURLParamValue('utmCampaign') === 'minuteclinic' ||
        getURLParamValue('utm_campaign') === 'minuteclinic' ||
        getURLParamValue('utmMedium') === 'mc_homepage_link' ||
        getURLParamValue('utm_medium') === 'mc_homepage_link';
};
// Hide Educational Information header (for marketplace)
export var hasHideHeaderParam = function () { return getURLParamValue('hideHeader') === '1'; };
export function hasMarketplaceUrlParam() {
    return getURLParamValue('marketplace') === '1';
}
export function hasWidgetUrlPath() {
    return window.location.pathname.indexOf('widget') > -1;
}
export function useWidgetStyle() {
    return getURLParamValue('style') === 'widget';
}
export function paramExistsAndIsFalse(param) {
    var paramValue = getURLParamValue(param);
    return paramValue === '0' || paramValue === 'false';
}
/* CONVERSATION TYPES */
export function isSymptomCheck(type) {
    return (type === Conversation.Type.SYMPTOM_CHECK ||
        type === Conversation.Type.NATIVE_SYMPTOM_CHECK ||
        type === Conversation.Type.FLEXIBLE_SYMPTOM_CHECK);
}
export function isPatientService(type) {
    return type === Conversation.Type.PATIENT_SERVICE;
}
export function isCorona(complaint) {
    return (complaint && complaint === 'covid-19') || window.location.href.includes('covid-19');
}
export function isConversation(type) {
    return (type === Conversation.Type.CORONA ||
        type === Conversation.Type.NATIVE_CORONA ||
        type === Conversation.Type.UNKNOWN ||
        type === Conversation.Type.SYMPTOM_CHECK ||
        type === Conversation.Type.NATIVE_SYMPTOM_CHECK ||
        type === Conversation.Type.FLEXIBLE_SYMPTOM_CHECK ||
        type === Conversation.Type.PATIENT_SERVICE);
}
/* PARTNERS */
export function isBayCare() {
    return THEME.partner === Partner.BAYCARE;
}
export function isCVS() {
    return THEME.partner === Partner.CVS;
}
export function useCoronaDisclaimer() {
    return isBayCare();
}
export function askForLocationForPartner(type) {
    return ((isSymptomCheck(type) && !THEME.symptomCheckCustomizations.disableAskForLocation) ||
        (isCorona(type) && !THEME.covidCustomizations.disableAskForLocation));
}
/* PLATFORMS / BROWSERS */
export function isIOS() {
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    var detectIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !window.MSStream;
    return detectIOS;
}
export function isAndroid() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
}
export function isMobileInWebView() {
    var normalizedUserAgent = navigator.userAgent;
    return isMobile() && isWebView(normalizedUserAgent);
}
export function isWebView(normalizedUserAgent) {
    return normalizedUserAgent.includes('WebView');
}
/* PHONE NUMBER HELPER FUNCTIONS */
export var CountryCodes;
(function (CountryCodes) {
    CountryCodes["US"] = "U.S. +1";
    CountryCodes["NON_US"] = "Non-U.S.";
})(CountryCodes || (CountryCodes = {}));
export function getDigits(value) {
    if (!value)
        return '';
    return value.replace(/[^\d]/g, '');
}
export function formatUSPhoneOnInput(value, prevValue) {
    if (!value)
        return '';
    var digitsOnly = getDigits(value);
    var digitsOnlyLength = digitsOnly.length;
    if (!prevValue || value.length >= prevValue.length) {
        // returns: "x", "xx", "xxx"
        if (digitsOnlyLength < 3) {
            return digitsOnly;
        }
        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (digitsOnlyLength < 6) {
            return "(".concat(digitsOnly.slice(0, 3), ") ").concat(digitsOnly.slice(3));
        }
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return "(".concat(digitsOnly.slice(0, 3), ") ").concat(digitsOnly.slice(3, 6), "-").concat(digitsOnly.slice(6, 10));
    }
    // Backspace cases
    if (digitsOnlyLength === 3) {
        // go from (xxx) x -> xx
        return digitsOnly.slice(0, 3);
    }
    if (digitsOnlyLength === 6) {
        // go from (xxx) xxx-x to (xxx) xxx
        return "(".concat(digitsOnly.slice(0, 3), ") ").concat(digitsOnly.slice(3, 6));
    }
    // backspace where we don't need to remove any additional characters
    return value;
}
export var phoneNumberMatch = new RegExp(/^(\(\d{3})\) (\d{3})-(\d{4})$/); // Format: (111) 111-1111
export var digitMatch = new RegExp(/^\d+$/);
export function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }
    return false;
}
// Mimics CSS is mobile setting
export var isSmallScreen = function () { return window.innerWidth < 767; };
export function isSafari() {
    /*
        Checks if the navigator.userAgent string does not contain chrome or android
        and does contain safari (on iOS, chrome & android will also include safari)
    */
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return isSafari;
}
export function isInternetExplorer() {
    var ua = window.navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to detect newer ones */
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
}
export function isGoogleChrome() {
    var ua = window.navigator.userAgent;
    return ua.indexOf('Chrome') > -1;
}
export function isFirefox() {
    var ua = window.navigator.userAgent;
    return ua.indexOf('Firefox') > -1;
}
export function isMicrosoftEdge() {
    var ua = window.navigator.userAgent;
    return ua.indexOf('Edg') > -1;
}
export function isOpera() {
    var ua = window.navigator.userAgent;
    return ua.indexOf('Opera') > -1 || ua.indexOf('OPR') > -1;
}
var getHost = function () { return window.location.host; };
export var isDev = function () {
    var host = getHost();
    var isEphemeral = /^clearstep-pr-(\d+)\.qa01-eks-01\.clearstep\.health$/.test(host);
    return (host.includes('localhost') ||
        host.includes('-dev-demo.clearstep.health') ||
        host.includes('-int.clearstep.health') ||
        isEphemeral);
};
export var isStaging = function () {
    var host = getHost();
    return host.includes('-staging.clearstep.health');
};
export var getEnv = function () {
    if (isDev()) {
        return 'dev';
    }
    if (isStaging()) {
        return 'staging';
    }
    return 'prod';
};
// Typically the tab key will tab the user to the next input in a form
// For certain textareas (such as education markdown editing) users would like the tab key to insert a tab
export var insertTabInTextarea = function (e, ref) {
    if (e.key === 'Tab' && !e.shiftKey && ref.current) {
        e.preventDefault();
        var _a = ref.current, selectionStart = _a.selectionStart, selectionEnd = _a.selectionEnd, value = _a.value;
        ref.current.value = "".concat(value.substring(0, selectionStart), "\t").concat(value.substring(selectionEnd));
        ref.current.selectionStart = selectionEnd + 1 - (selectionEnd - selectionStart);
        ref.current.selectionEnd = selectionEnd + 1 - (selectionEnd - selectionStart);
    }
};
export var addQueryParamsAndReload = function (param, value) {
    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    params.set(param, value);
    url.search = params.toString();
    window.location.href = url.href;
};
export var addQueryParamToPath = function (path, param, value) {
    // Note this will safely add a query param to a path (not a full URL)
    var _a = path.split('?'), basePath = _a[0], queryString = _a[1];
    if (typeof URLSearchParams !== 'undefined') {
        var searchParams = new URLSearchParams(queryString || '');
        searchParams.set(param, value);
        var newQueryString_1 = searchParams.toString();
        return newQueryString_1 ? "".concat(basePath, "?").concat(newQueryString_1) : basePath;
    }
    // Fallback logic if URLSearchParams is unavailable
    var encodedParam = encodeURIComponent(param);
    var encodedValue = encodeURIComponent(value);
    var hasTrailingQuestionMark = path.endsWith('?');
    var separator = queryString || hasTrailingQuestionMark ? '&' : '?';
    var newQueryString = "".concat(queryString || '').concat(separator).concat(encodedParam, "=").concat(encodedValue);
    return "".concat(basePath, "?").concat(newQueryString);
};
export var showMap = function (careType) {
    // Typescript thinks that showMap is a list of key-value entries, when it is actually a map from key to value.
    // Must use typecasts to make that explicit to Typescript.
    var entry = THEME.showMap[careType];
    var showMap = !!entry && entry;
    return showMap;
};
