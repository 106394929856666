import React from 'react';
import 'i18next';
import { twMerge } from 'tailwind-merge';
import { linkToAndLogConvo } from '../../../../util/EventLog';
import { MessageType } from '../../../clearstep/proto/logging_pb';
import { THEME } from '../../../clearstep/util/Theme';
import '../CareCard';
import Button from '../../../tailwind_common/Button';
export var ButtonContainer = function (props) {
    var button = props.button, useMarginTop = props.useMarginTop, buttonRight = props.buttonRight;
    var cta = button.cta, url = button.url, header = button.header, lookerTags = button.lookerTags;
    var Header = (header === null || header === void 0 ? void 0 : header.type) || 'h3';
    var lookerData = lookerTags || {};
    lookerData.name = url;
    var headerExists = button.header && (header === null || header === void 0 ? void 0 : header.text);
    return (React.createElement("div", { key: url, className: twMerge('pt-6 mobile:w-full', buttonRight && 'flex flex-row items-center mobile:flex-col mobile:items-center', buttonRight && !headerExists && 'justify-end p-0') },
        headerExists && (React.createElement(Header, { className: twMerge(Header === 'h3' && 'my-4', (header === null || header === void 0 ? void 0 : header.useWhitespace) && 'whitespace-pre-wrap', buttonRight && 'mr-4 basis-4/5 font-normal mobile:mr-0') }, header === null || header === void 0 ? void 0 : header.text)),
        React.createElement(Button, { onClick: function () {
                return linkToAndLogConvo(button.url, MessageType.CLICKED_PARTNER_CARE_OPTION, lookerData, {
                    partner: THEME.partner,
                });
            }, useMarginTop: useMarginTop, className: twMerge('h-fit min-w-65 max-w-65 mobile:w-full mobile:min-w-0 mobile:max-w-none', buttonRight && 'mobile:mt-4', buttonRight && headerExists && 'basis-2/5'), innerClassName: "font-normal" }, cta)));
};
