var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import classNames from 'classnames';
import 'i18next';
import { twMerge } from 'tailwind-merge';
import Modal from '../../tailwind_common/Modal/Modal.Lazy.Wrapped';
import '../proto/care_locations_pb';
import { formatAddress, formatStructuredAddress, mapsDirectionsURL, mapURL, } from '../util/CareLocationHelpers';
import PartnerLocationAppointmentScheduling, { Day, } from '../../tailwind_clearstep/PartnerLocationAppointmentScheduling/PartnerLocationAppointmentScheduling';
import { CompassIcon } from '../../svg/Compass';
import { MapIcon } from '../../svg/Map';
import { MapPinIcon } from '../../svg/MapPin';
import { CoronavirusIcon } from '../../svg/CoronavirusIcon';
import { logEvent, MessageType } from '../../../util/EventLog';
import { addParams, UTMContext } from '../../../util/UTM';
import { SuccessIcon } from '../../svg/SuccessIcon';
import styles from './CVSCareLocation.css';
import { InfoIcon } from '../../svg/InfoIcon';
import { bookAppointment } from '../../../api/ConversationResults';
import ButtonWithIcon from '../../tailwind_common/ButtonWithIcon';
import Checkbox from '../../tailwind_common/Checkbox';
import LinkWithIcon from '../../tailwind_common/LinkWithIcon';
import Loading from '../../tailwind_common/Loading';
import Button from '../../tailwind_common/Button';
import TextField from '../../tailwind_common/TextField';
import { THEME } from '../util/Theme';
/* eslint-disable react/jsx-no-literals */
/*
  disabling jsx-no-literals as CVS does not currently require translation
*/
var GenderOptions;
(function (GenderOptions) {
    GenderOptions["FEMALE"] = "Female";
    GenderOptions["MALE"] = "Male";
})(GenderOptions || (GenderOptions = {}));
var YesNoOptions;
(function (YesNoOptions) {
    YesNoOptions["YES"] = "Yes";
    YesNoOptions["NO"] = "No";
})(YesNoOptions || (YesNoOptions = {}));
var CovidRiskType;
(function (CovidRiskType) {
    CovidRiskType["VACCINE"] = "Vaccine";
    CovidRiskType["SYMPTOMS"] = "Symptoms";
})(CovidRiskType || (CovidRiskType = {}));
var CVSCareLocation = /** @class */ (function (_super) {
    __extends(CVSCareLocation, _super);
    function CVSCareLocation(props) {
        var _this = _super.call(this, props) || this;
        _this.getBookingFormErrorText = function () {
            var _a = _this.state, showBookingValidationError = _a.showBookingValidationError, showBookingServerError = _a.showBookingServerError;
            if (showBookingValidationError) {
                return 'Please complete all fields to book your appointment.';
            }
            if (showBookingServerError) {
                return 'Sorry, we encountered an error. Please try again.';
            }
            return '';
        };
        _this.getBookingModalTitle = function () {
            var _a = _this.state, showCovidPrescreen = _a.showCovidPrescreen, showCovidFallback = _a.showCovidFallback, showBookingForm = _a.showBookingForm, showAppointmentBooked = _a.showAppointmentBooked, showLoading = _a.showLoading;
            if (showCovidPrescreen) {
                return 'COVID-19 safety precautions';
            }
            if (showCovidFallback) {
                return 'COVID-19 pre-screen';
            }
            if (showBookingForm) {
                return 'Book appointment';
            }
            if (showLoading) {
                return 'Booking your MinuteClinic appointment...';
            }
            if (showAppointmentBooked) {
                return 'Your MinuteClinic confirmation';
            }
            return '';
        };
        _this.handleClickCVSLink = function (name) {
            var insuranceStatus = _this.props.insuranceStatus;
            logEvent(MessageType.CLICKED_PARTNER_CARE_OPTION, {
                name: name,
                insuranceStatus: insuranceStatus,
            }, { partner: THEME.partner });
        };
        _this.handleSelectAppointmentDay = function (day) {
            _this.setState({ selectedAppointmentDay: day });
        };
        _this.handleSelectAppointment = function (selectedAppointment) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            if (selectedAppointment) {
                _this.setState({
                    selectedAppointment: selectedAppointment,
                    bookAppointmentData: __assign(__assign({}, bookAppointmentData), { timeZone: new Date(selectedAppointment.time)
                            .toLocaleTimeString('en-us', { timeZoneName: 'short' })
                            .split(' ')[2] }),
                });
            }
        };
        _this.handleStartBookAppointment = function () {
            _this.setState({ showBookingModal: true, showCovidPrescreen: true });
        };
        _this.handleBookAppointment = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, complaint, location, zipCode, isNewPatient, _b, selectedAppointment, bookAppointmentData, needBookingData, requestBody, response;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props, complaint = _a.complaint, location = _a.location, zipCode = _a.zipCode, isNewPatient = _a.isNewPatient;
                        _b = this.state, selectedAppointment = _b.selectedAppointment, bookAppointmentData = _b.bookAppointmentData;
                        needBookingData = false;
                        Object.values(bookAppointmentData).forEach(function (value) {
                            if (!value) {
                                needBookingData = true;
                            }
                        });
                        if (needBookingData) {
                            this.setState({ showBookingValidationError: true });
                            return [2 /*return*/];
                        }
                        this.setState({ showLoading: true, showBookingForm: false, showBookingServerError: false });
                        logEvent(MessageType.BUTTON_CLICK, {
                            name: 'Book appointment',
                            zipCode: zipCode,
                        }, { partner: THEME.partner });
                        requestBody = {
                            complaint: complaint,
                            convoId: window.conversationId,
                            careLocation: location,
                            time: (selectedAppointment === null || selectedAppointment === void 0 ? void 0 : selectedAppointment.time) || '',
                            patientInfo: bookAppointmentData,
                            isNewPatient: isNewPatient,
                            appointmentId: '',
                        };
                        return [4 /*yield*/, bookAppointment(requestBody)];
                    case 1:
                        response = _d.sent();
                        if (!response || response.error || ((_c = response.data) === null || _c === void 0 ? void 0 : _c.error) || !response.data.confirmationCode) {
                            this.bookAppointmentErrorHandler();
                        }
                        else {
                            this.bookAppointmentSuccessHandler(response.data);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.bookAppointmentErrorHandler = function () {
            _this.setState({ showBookingServerError: true, showLoading: false, showBookingForm: true });
            logEvent(MessageType.APPOINTMENT_FAILED, {}, { partner: THEME.partner });
        };
        _this.bookAppointmentSuccessHandler = function (bookAppointmentResponse) {
            if (bookAppointmentResponse.confirmationCode) {
                _this.setState({
                    minuteClinicConfirmationCode: bookAppointmentResponse.confirmationCode,
                    showAppointmentBooked: true,
                    showLoading: false,
                    bookAppointmentData: {
                        firstName: '',
                        lastName: '',
                        dateOfBirth: '',
                        gender: '',
                        emailAddress: '',
                        phoneNumber: '',
                        canText: '',
                        timeZone: '',
                        zipCode: '',
                        address: '',
                    },
                });
                logEvent(MessageType.APPOINTMENT_BOOKED, {}, { partner: THEME.partner });
            }
        };
        _this.handleCloseBookingModal = function () {
            var showAppointmentBooked = _this.state.showAppointmentBooked;
            var zipCode = _this.props.zipCode;
            logEvent(MessageType.BUTTON_CLICK, {
                name: 'Close book appointment modal',
                booked_appointment: showAppointmentBooked,
            }, { partner: THEME.partner });
            _this.setState({
                showBookingModal: false,
                showCovidPrescreen: false,
                showCovidFallback: false,
                showBookingForm: false,
                showLoading: false,
                showAppointmentBooked: false,
                showBookingValidationError: false,
                covidRiskData: {
                    vaccine: '',
                    symptoms: '',
                },
                covidPrescreenData: {
                    fever: false,
                    cough: false,
                    shortnessOfBreath: false,
                    testedPositive: '',
                    contactWithDiagnosed: '',
                },
                bookAppointmentData: {
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    gender: '',
                    emailAddress: '',
                    phoneNumber: '',
                    canText: '',
                    timeZone: '',
                    zipCode: zipCode,
                    address: '',
                },
                showBookingServerError: false,
            });
        };
        _this.handleShowMapModal = function () {
            _this.setState({ showMapModal: true });
        };
        _this.handleCloseMapModal = function () {
            _this.setState({ showMapModal: false });
        };
        _this.handleResetBookingValidation = function () {
            _this.setState({ showBookingValidationError: false });
        };
        _this.handleUpdateFever = function () {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var fever = covidPrescreenData.fever;
            _this.setState({ covidPrescreenData: __assign(__assign({}, covidPrescreenData), { fever: !fever }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateCough = function () {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var cough = covidPrescreenData.cough;
            _this.setState({ covidPrescreenData: __assign(__assign({}, covidPrescreenData), { cough: !cough }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateShortnessOfBreath = function () {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var shortnessOfBreath = covidPrescreenData.shortnessOfBreath;
            _this.setState({
                covidPrescreenData: __assign(__assign({}, covidPrescreenData), { shortnessOfBreath: !shortnessOfBreath }),
            }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateTestedPositive = function (option) {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var testedPositive = covidPrescreenData.testedPositive;
            var YES = YesNoOptions.YES, NO = YesNoOptions.NO;
            var parsedTestedPositive = '';
            if (option === YES && testedPositive !== YES) {
                parsedTestedPositive = YES;
            }
            if (option === NO && testedPositive !== NO) {
                parsedTestedPositive = NO;
            }
            _this.setState({
                covidPrescreenData: __assign(__assign({}, covidPrescreenData), { testedPositive: parsedTestedPositive }),
            }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateCovidRisk = function (type, option) {
            var covidRiskData = _this.state.covidRiskData;
            var YES = YesNoOptions.YES, NO = YesNoOptions.NO;
            var VACCINE = CovidRiskType.VACCINE, SYMPTOMS = CovidRiskType.SYMPTOMS;
            if (option === YES || NO) {
                if (type === VACCINE) {
                    _this.setState({
                        covidRiskData: __assign(__assign({}, covidRiskData), { vaccine: option }),
                        covidPrescreenData: {
                            fever: false,
                            cough: false,
                            shortnessOfBreath: false,
                            testedPositive: '',
                            contactWithDiagnosed: '',
                        },
                    });
                }
                if (type === SYMPTOMS) {
                    _this.setState({
                        covidRiskData: __assign(__assign({}, covidRiskData), { symptoms: option }),
                    });
                    if (option === YES) {
                        _this.setState({
                            covidPrescreenData: {
                                fever: false,
                                cough: false,
                                shortnessOfBreath: false,
                                testedPositive: '',
                                contactWithDiagnosed: '',
                            },
                        });
                    }
                    if (option === NO) {
                        _this.setState({
                            covidPrescreenData: {
                                fever: false,
                                cough: false,
                                shortnessOfBreath: false,
                                testedPositive: NO,
                                contactWithDiagnosed: NO,
                            },
                        });
                    }
                }
            }
        };
        _this.handleUpdateContactWithDiagnosed = function (option) {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var contactWithDiagnosed = covidPrescreenData.contactWithDiagnosed;
            var YES = YesNoOptions.YES, NO = YesNoOptions.NO;
            var parsedContactWithDiagnosed = '';
            if (option === YES) {
                if (contactWithDiagnosed === YES) {
                    parsedContactWithDiagnosed = '';
                }
                else {
                    parsedContactWithDiagnosed = YES;
                }
            }
            if (option === NO) {
                if (contactWithDiagnosed === NO) {
                    parsedContactWithDiagnosed = '';
                }
                else {
                    parsedContactWithDiagnosed = NO;
                }
            }
            _this.setState({
                covidPrescreenData: __assign(__assign({}, covidPrescreenData), { contactWithDiagnosed: parsedContactWithDiagnosed }),
            }, _this.handleResetBookingValidation);
        };
        _this.handleFinishCovidPrescreen = function () {
            var covidPrescreenData = _this.state.covidPrescreenData;
            var fever = covidPrescreenData.fever, cough = covidPrescreenData.cough, shortnessOfBreath = covidPrescreenData.shortnessOfBreath, contactWithDiagnosed = covidPrescreenData.contactWithDiagnosed, testedPositive = covidPrescreenData.testedPositive;
            var YES = YesNoOptions.YES;
            if (!testedPositive || !contactWithDiagnosed) {
                _this.setState({ showBookingValidationError: true });
                return;
            }
            logEvent(MessageType.BUTTON_CLICK, {
                name: 'Submit COVID pre-screen',
                data: covidPrescreenData,
            }, { partner: THEME.partner });
            var hasCovidSymptom = fever || cough || shortnessOfBreath;
            if ((fever && cough) ||
                (fever && shortnessOfBreath) ||
                testedPositive === YES ||
                (contactWithDiagnosed === YES && hasCovidSymptom)) {
                // TODO(annie): add logging here
                _this.setState({
                    showCovidPrescreen: false,
                    showCovidFallback: true,
                    showBookingValidationError: false,
                });
                return;
            }
            _this.setState({ showCovidPrescreen: false, showBookingForm: true });
        };
        _this.handleUpdateFirstName = function (firstName) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { firstName: firstName }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateLastName = function (lastName) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { lastName: lastName }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateDateOfBirth = function (dateOfBirth) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { dateOfBirth: dateOfBirth }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateGender = function (option) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            var gender = bookAppointmentData.gender;
            var parsedGender = '';
            if (option === GenderOptions.FEMALE && gender !== 'F') {
                parsedGender = 'F';
            }
            if (option === GenderOptions.MALE && gender !== 'M') {
                parsedGender = 'M';
            }
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { gender: parsedGender }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateAddress = function (address) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { address: address }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateEmailAddress = function (emailAddress) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { emailAddress: emailAddress }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdatePhoneNumber = function (phoneNumber) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { phoneNumber: phoneNumber }) }, _this.handleResetBookingValidation);
        };
        _this.handleUpdateCanText = function (option) {
            var bookAppointmentData = _this.state.bookAppointmentData;
            var canText = bookAppointmentData.canText;
            var parsedCanText = '';
            if (option === YesNoOptions.YES && canText !== 'Y') {
                parsedCanText = 'Y';
            }
            if (option === YesNoOptions.NO && canText !== 'N') {
                parsedCanText = 'N';
            }
            _this.setState({ bookAppointmentData: __assign(__assign({}, bookAppointmentData), { canText: parsedCanText }) }, _this.handleResetBookingValidation);
        };
        _this.renderClinic = function () {
            var location = _this.props.location;
            var structuredAddress = location.structuredAddress, description = location.description, distance = location.distance;
            var route = structuredAddress.route, locality = structuredAddress.locality, administrativeAreaLevel1 = structuredAddress.administrativeAreaLevel1, postalCode = structuredAddress.postalCode;
            var showDistance = !!distance;
            var showMap = !!location.latitude && !!location.longitude;
            return (React.createElement("div", { className: styles.cvsCareLocation__clinic },
                React.createElement("div", { className: styles.cvsCareLocation__clinicIcon },
                    React.createElement("img", { src: "/static/images/partners/cvs/minute_clinic_icon.png", alt: "MinuteClinic" })),
                React.createElement("div", { className: styles.cvsCareLocation__clinicData },
                    description && React.createElement("div", { className: styles.cvsCareLocation__description }, description),
                    React.createElement("div", { className: styles.cvsCareLocation__text },
                        React.createElement("p", null, formatAddress(route)),
                        React.createElement("p", null, "".concat(formatAddress(locality), ", ").concat(administrativeAreaLevel1, " ").concat(postalCode))),
                    showDistance && (React.createElement("div", { className: styles.cvsCareLocation__distance },
                        React.createElement(MapPinIcon, null),
                        React.createElement("p", null, "".concat(distance, " miles away")))),
                    React.createElement(LinkWithIcon, { text: "Get directions", icon: React.createElement(CompassIcon, null), href: mapsDirectionsURL(structuredAddress).href }),
                    showMap && (React.createElement(ButtonWithIcon, { text: "View map", icon: React.createElement(MapIcon, null), onClick: _this.handleShowMapModal })))));
        };
        _this.renderBookingFormClinicDetails = function () {
            var _a = _this.state, selectedAppointment = _a.selectedAppointment, selectedAppointmentDay = _a.selectedAppointmentDay;
            return (React.createElement("div", { className: styles.cvsCareLocation__bookingFormClinicDetails },
                React.createElement("div", { className: styles.cvsCareLocation__bookingFormClinicDetail },
                    React.createElement("h2", null, "Location"),
                    _this.renderClinic()),
                React.createElement("div", { className: styles.cvsCareLocation__bookingFormClinicDetail },
                    React.createElement("h2", null, "Appointment time"),
                    React.createElement("p", null, "".concat(selectedAppointmentDay).concat((selectedAppointment === null || selectedAppointment === void 0 ? void 0 : selectedAppointment.time) &&
                        " at ".concat(new Date(selectedAppointment === null || selectedAppointment === void 0 ? void 0 : selectedAppointment.time).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: '2-digit',
                        })), " ")))));
        };
        _this.renderBackToResultsButton = function () { return (React.createElement("div", { className: styles.cvsCareLocation__bookingModalBackToResults },
            React.createElement(Button, { styledText: true, onClick: _this.handleCloseBookingModal }, "Back to symptom check results"))); };
        _this.state = {
            showMapModal: false,
            showBookingModal: false,
            showCovidPrescreen: false,
            showCovidFallback: false,
            showBookingForm: false,
            selectedAppointmentDay: Day.TODAY,
            selectedAppointment: null,
            covidRiskData: {
                vaccine: '',
                symptoms: '',
            },
            covidPrescreenData: {
                fever: false,
                cough: false,
                shortnessOfBreath: false,
                testedPositive: '',
                contactWithDiagnosed: '',
            },
            bookAppointmentData: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                gender: '',
                phoneNumber: '',
                emailAddress: '',
                canText: '',
                timeZone: '',
                zipCode: props.zipCode,
                address: '',
            },
            showBookingValidationError: false,
            showLoading: false,
            showAppointmentBooked: false,
            minuteClinicConfirmationCode: '',
            showBookingServerError: false,
        };
        return _this;
    }
    CVSCareLocation.prototype.render = function () {
        var _this = this;
        var _a = this.props, location = _a.location, lastLocation = _a.lastLocation, firstLocation = _a.firstLocation;
        var _b = this.state, showMapModal = _b.showMapModal, showBookingModal = _b.showBookingModal, selectedAppointment = _b.selectedAppointment, selectedAppointmentDay = _b.selectedAppointmentDay, covidPrescreenData = _b.covidPrescreenData, showBookingForm = _b.showBookingForm, showCovidFallback = _b.showCovidFallback, bookAppointmentData = _b.bookAppointmentData, showBookingValidationError = _b.showBookingValidationError, showLoading = _b.showLoading, showAppointmentBooked = _b.showAppointmentBooked, covidRiskData = _b.covidRiskData, showCovidPrescreen = _b.showCovidPrescreen, showBookingServerError = _b.showBookingServerError, minuteClinicConfirmationCode = _b.minuteClinicConfirmationCode;
        var vaccine = covidRiskData.vaccine, symptoms = covidRiskData.symptoms;
        var fever = covidPrescreenData.fever, cough = covidPrescreenData.cough, shortnessOfBreath = covidPrescreenData.shortnessOfBreath, testedPositive = covidPrescreenData.testedPositive, contactWithDiagnosed = covidPrescreenData.contactWithDiagnosed;
        var firstName = bookAppointmentData.firstName, lastName = bookAppointmentData.lastName, phoneNumber = bookAppointmentData.phoneNumber, emailAddress = bookAppointmentData.emailAddress, dateOfBirth = bookAppointmentData.dateOfBirth, canText = bookAppointmentData.canText, gender = bookAppointmentData.gender, address = bookAppointmentData.address;
        var YES = YesNoOptions.YES, NO = YesNoOptions.NO;
        var VACCINE = CovidRiskType.VACCINE, SYMPTOMS = CovidRiskType.SYMPTOMS;
        return (React.createElement(UTMContext.Consumer, null, function (utmParams) {
            var _a, _b;
            return (React.createElement("div", { className: twMerge('cvsCareLocation', 'w-full border-x-0 border-b-0 border-t border-solid border-ui-light-gray pt-6', firstLocation && 'border-t-0', !lastLocation && 'pb-6') },
                React.createElement("div", { className: styles.cvsCareLocation__content },
                    _this.renderClinic(),
                    React.createElement("div", { className: styles.cvsCareLocation__scheduling },
                        React.createElement(PartnerLocationAppointmentScheduling, { locationId: location.externalId, schedule: location.schedule, selectedAppointment: selectedAppointment, selectedAppointmentDay: selectedAppointmentDay, onSelectAppointment: _this.handleSelectAppointment, onSelectDay: _this.handleSelectAppointmentDay, onBookAppointment: _this.handleStartBookAppointment }))),
                React.createElement(Modal, { isOpen: showMapModal, onClose: _this.handleCloseMapModal, title: "Your nearby MinuteClinic location" },
                    React.createElement("div", { className: styles.cvsCareLocation__map },
                        React.createElement("p", null, formatStructuredAddress(location.structuredAddress)),
                        React.createElement("a", { href: mapsDirectionsURL(location.structuredAddress).href, target: "_blank", onClick: function () { }, rel: "noopener noreferrer" },
                            React.createElement("img", { src: mapURL(location), alt: "Map for care location" })))),
                React.createElement(Modal, { isOpen: showBookingModal, title: _this.getBookingModalTitle() || '', onClose: _this.handleCloseBookingModal, disableCloseOnClickOutside: true },
                    showCovidPrescreen && (React.createElement("div", { className: styles.cvsCareLocation__covidScreener },
                        React.createElement("p", null, "To best protect our patients and communities, please answer the following questions before booking an appointment."),
                        vaccine === YES && symptoms === YES && (React.createElement("div", { className: styles.cvsCareLocation__blocked },
                            React.createElement(InfoIcon, { fill: "black" }),
                            React.createElement("p", null, "Unfortunately, you\u2019re not eligible for an in-person MinuteClinic visit at this time. Please refer to our virtual care options instead."))),
                        React.createElement("div", { className: styles.cvsCareLocation__covidScreenerQuestions },
                            React.createElement("div", { className: styles.cvsCareLocation__covidScreenerQuestion },
                                React.createElement("p", null,
                                    "Have you had a COVID-19 vaccine in the past ",
                                    React.createElement("b", null, "7 days?"), " (More questions may appear based on your answer.)"),
                                React.createElement("div", { role: "radiogroup", "aria-label": "Have you had a COVID-19 vaccine in the past 7 days?", className: styles.cvsCareLocation__covidScreenerOptions },
                                    React.createElement("input", { id: "vaccinePositive", type: "radio", checked: vaccine === YES, onChange: function () { return _this.handleUpdateCovidRisk(VACCINE, YES); } }),
                                    React.createElement("label", { htmlFor: "vaccinePositive" }, "Yes"),
                                    React.createElement("input", { id: "vaccineNegative", type: "radio", checked: vaccine === NO, onChange: function () { return _this.handleUpdateCovidRisk(VACCINE, NO); } }),
                                    React.createElement("label", { htmlFor: "vaccineNegative" }, "No"))),
                            vaccine === YES && (React.createElement("div", { className: styles.cvsCareLocation__covidScreenerQuestion },
                                React.createElement("p", null,
                                    "Have you had any new onset of loss of ",
                                    React.createElement("b", null, "taste or smell?"), " (More questions may appear based on your answer.)"),
                                React.createElement("div", { role: "radiogroup", "aria-label": "Have you had any new onset of loss of taste or smell?", className: styles.cvsCareLocation__covidScreenerOptions },
                                    React.createElement("input", { id: "symptomPositive", type: "radio", checked: symptoms === YES, onChange: function () { return _this.handleUpdateCovidRisk(SYMPTOMS, YES); } }),
                                    React.createElement("label", { htmlFor: "symptomPositive" }, "Yes"),
                                    React.createElement("input", { id: "symptomNegative", type: "radio", checked: symptoms === NO, onChange: function () { return _this.handleUpdateCovidRisk(SYMPTOMS, NO); } }),
                                    React.createElement("label", { htmlFor: "symptomNegative" }, "No")))),
                            vaccine === NO && (React.createElement("div", null,
                                React.createElement("div", { className: styles.cvsCareLocation__symptomCheckbox },
                                    React.createElement("p", { id: "symptom-prompt" },
                                        "In the past ",
                                        React.createElement("b", null, "7 days"),
                                        ", have you experienced any of these symptoms? Select any that apply."),
                                    React.createElement("div", { role: "group", "aria-labelledby": "symptom-prompt", className: styles.cvsCareLocation__covidScreenerOptions },
                                        React.createElement("input", { id: "fever", type: "checkbox", checked: fever, onChange: _this.handleUpdateFever }),
                                        React.createElement("label", { htmlFor: "fever" }, "Fever"),
                                        React.createElement("input", { id: "cough", type: "checkbox", checked: cough, onChange: _this.handleUpdateCough }),
                                        React.createElement("label", { htmlFor: "cough" }, "Cough"),
                                        React.createElement("input", { id: "shortnessOfBreath", type: "checkbox", checked: shortnessOfBreath, onChange: _this.handleUpdateShortnessOfBreath }),
                                        React.createElement("label", { htmlFor: "shortnessOfBreath" }, "Shortness of Breath"))),
                                React.createElement("div", { className: styles.cvsCareLocation__covidScreenerQuestion },
                                    React.createElement("p", { id: "test-result-prompt" },
                                        "In the past ",
                                        React.createElement("b", null, "14 days"),
                                        ", have you been tested for COVID-19 with a positive or unknown result? ",
                                        React.createElement("b", null, "(required)")),
                                    React.createElement("div", { role: "radiogroup", "aria-labelledby": "test-result-prompt", className: styles.cvsCareLocation__covidScreenerOptions },
                                        React.createElement("input", { id: "testPositive", type: "radio", checked: testedPositive === YES, onChange: function () { return _this.handleUpdateTestedPositive(YES); } }),
                                        React.createElement("label", { htmlFor: "testPositive" }, "Yes"),
                                        React.createElement("input", { id: "testNegative", type: "radio", checked: testedPositive === NO, onChange: function () { return _this.handleUpdateTestedPositive(NO); } }),
                                        React.createElement("label", { htmlFor: "testNegative" }, "No"))),
                                React.createElement("div", { className: styles.cvsCareLocation__covidScreenerQuestion },
                                    React.createElement("p", { id: "exposure-prompt" },
                                        "In the past ",
                                        React.createElement("b", null, "14 days"),
                                        ", have you had known exposure to a COVID-19 patient? ",
                                        React.createElement("b", null, "(required)")),
                                    React.createElement("div", { role: "radiogroup", "aria-labelledby": "exposure-prompt", className: styles.cvsCareLocation__covidScreenerOptions },
                                        React.createElement("input", { id: "contactPositive", type: "radio", checked: contactWithDiagnosed === YES, onChange: function () { return _this.handleUpdateContactWithDiagnosed(YES); } }),
                                        React.createElement("label", { htmlFor: "contactPositive" }, "Yes"),
                                        React.createElement("input", { id: "contactNegative", type: "radio", checked: contactWithDiagnosed === NO, onChange: function () { return _this.handleUpdateContactWithDiagnosed(NO); } }),
                                        React.createElement("label", { htmlFor: "contactNegative" }, "No")))))),
                        vaccine === null ||
                            (symptoms === null && (React.createElement("div", { className: styles.cvsCareLocation__bookingFormSubmit },
                                React.createElement("p", { className: classNames(styles.cvsCareLocation__bookingValidationError, (_a = {},
                                        _a[styles['cvsCareLocation__bookingValidationError--showing']] = showBookingValidationError,
                                        _a)) }, "Please complete all required questions before continuing.")))),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingFormSubmit },
                            React.createElement(Button, { disabled: vaccine === YES && symptoms === YES, onClick: function () { return _this.handleFinishCovidPrescreen(); } }, "Continue")))),
                    showCovidFallback && (React.createElement("div", { className: styles.cvsCareLocation__covidScreener },
                        React.createElement("div", { className: styles.cvsCareLocation__covidScreenerFallbackAdviceHeader },
                            React.createElement("div", null,
                                React.createElement(CoronavirusIcon, null),
                                React.createElement("h2", null, "You may be at risk for COVID-19")),
                            React.createElement("p", null, "Since you may have been exposed to COVID-19, we ask that you do not book a MinuteClinic visit at this time."),
                            React.createElement("p", null,
                                React.createElement("b", null, "If you believe you are experiencing a medical emergency, "),
                                "call 911 immediately."),
                            React.createElement("p", null,
                                React.createElement("b", null, "If you believe you are sick with COVID-19, "),
                                " we recommend following the CDC's guidance.")),
                        React.createElement("div", { className: styles.cvsCareLocation__covidScreenerFallbackAdvice },
                            React.createElement("p", null,
                                React.createElement("b", null, "Telemedicine services "),
                                " can be an effective option for screening from the comfort of your home. See if an",
                                ' ',
                                React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/virtual-care/e-clinic', utmParams, {
                                        content: window.conversationId,
                                    }), onClick: function () { return _this.handleClickCVSLink('E-Clinic Visit'); }, target: "_blank", rel: "noreferrer" }, "E-Clinic Visit"),
                                ' ',
                                "or",
                                ' ',
                                React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/virtual-care/video-visit', utmParams, {
                                        content: window.conversationId,
                                    }), onClick: function () { return _this.handleClickCVSLink('Video Visit'); }, target: "_blank", rel: "noreferrer" }, "Video Visit"),
                                ' ',
                                "is right for you."),
                            React.createElement("p", null,
                                React.createElement("b", null, "Not sure which telemedicine option is right for you?"),
                                " Check out our",
                                ' ',
                                React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/virtual-care/telehealth-options', utmParams, {
                                        content: window.conversationId,
                                    }), onClick: function () { return _this.handleClickCVSLink('comparison page'); }, target: "_blank", rel: "noreferrer" },
                                    "comparison page",
                                    ' '),
                                ' ',
                                "to learn more."),
                            React.createElement("p", null,
                                React.createElement("b", null, "We offer testing "),
                                " at select locations.",
                                ' ',
                                React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/covid-19-testing', utmParams, {
                                        content: window.conversationId,
                                    }), onClick: function () {
                                        return _this.handleClickCVSLink('Find out how to get tested for COVID-19.');
                                    }, target: "_blank", rel: "noreferrer" }, "Find out how to get tested for COVID-19."))),
                        _this.renderBackToResultsButton())),
                    showBookingForm && (React.createElement("div", { className: styles.cvsCareLocation__bookingForm },
                        _this.renderBookingFormClinicDetails(),
                        React.createElement("div", { role: "group", "aria-label": "Appointment information", className: styles.cvsCareLocation__bookingFormFields },
                            React.createElement(TextField, { label: "First name (legal name)", ariaLabel: "First name", onChange: _this.handleUpdateFirstName, initialValue: firstName }),
                            React.createElement(TextField, { label: "Last name", ariaLabel: "Last name", onChange: _this.handleUpdateLastName, initialValue: lastName }),
                            React.createElement(TextField, { inputType: "date", label: "Date of birth", ariaLabel: "Date of birth", onChange: _this.handleUpdateDateOfBirth, initialValue: dateOfBirth }),
                            React.createElement("div", { className: styles.cvsCareLocation__bookingFormHelperText },
                                React.createElement("p", null,
                                    "Patients must be 18 months or older. Minors must be accompanied by an adult.",
                                    React.createElement("br", null),
                                    React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/services', utmParams, {
                                            content: window.conversationId,
                                        }), target: "_blank", rel: "noreferrer", onClick: function () { return _this.handleClickCVSLink('Other restrictions may apply.'); } }, "Other restrictions may apply."))),
                            React.createElement("div", { className: styles.cvsCareLocation__bookingFormFieldsCheckboxQuestion },
                                React.createElement("p", { id: "gender-prompt" }, "Gender"),
                                React.createElement("div", { role: "radiogroup", "aria-labelledby": "gender-prompt", className: styles.cvsCareLocation__bookingFormFieldsCheckboxOptions },
                                    React.createElement(Checkbox, { checked: gender === 'F', label: GenderOptions.FEMALE, onChange: function () { return _this.handleUpdateGender(GenderOptions.FEMALE); } }),
                                    React.createElement(Checkbox, { checked: gender === 'M', label: GenderOptions.MALE, onChange: function () { return _this.handleUpdateGender(GenderOptions.MALE); } }))),
                            React.createElement(TextField, { label: "Address", ariaLabel: "Address", onChange: _this.handleUpdateAddress, initialValue: address }),
                            React.createElement(TextField, { label: "Email address", ariaLabel: "Email address", onChange: _this.handleUpdateEmailAddress, initialValue: emailAddress }),
                            React.createElement(TextField, { label: "Mobile phone number (e.g. 5555555555)", ariaLabel: "Mobile phone number (e.g. 5555555555)", onChange: _this.handleUpdatePhoneNumber, inputType: "tel", initialValue: phoneNumber }),
                            React.createElement("div", { className: styles.cvsCareLocation__bookingFormFieldsCheckboxQuestion },
                                React.createElement("p", { id: "text-reminders-prompt" }, "Can CVS text you appointment reminders?*"),
                                React.createElement("div", { role: "radiogroup", "aria-labelledby": "text-reminders-prompt", className: styles.cvsCareLocation__bookingFormFieldsCheckboxOptions },
                                    React.createElement(Checkbox, { checked: canText === 'Y', label: YesNoOptions.YES, onChange: function () { return _this.handleUpdateCanText(YesNoOptions.YES); } }),
                                    React.createElement(Checkbox, { checked: canText === 'N', label: YesNoOptions.NO, onChange: function () { return _this.handleUpdateCanText(YesNoOptions.NO); } })))),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingFormSubmit },
                            React.createElement("p", { className: classNames(styles.cvsCareLocation__bookingValidationError, (_b = {},
                                    _b[styles['cvsCareLocation__bookingValidationError--showing']] = showBookingValidationError || showBookingServerError,
                                    _b)) }, _this.getBookingFormErrorText()),
                            React.createElement(Button, { onClick: _this.handleBookAppointment, actionType: "book_appt", clickType: "direct" }, "Book appointment")),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingFormHelperText },
                            React.createElement("p", null,
                                "By continuing, you are agreeing to the MinuteClinic",
                                ' ',
                                React.createElement("a", { href: "https://www.cvs.com/minuteclinic/info/terms-use", target: "_blank", rel: "noreferrer" }, "Terms of Use"),
                                ' ',
                                "and",
                                ' ',
                                React.createElement("a", { href: "https://www.cvs.com/minuteclinic/info/privacy-policy", target: "_blank", rel: "noreferrer" }, "Privacy Policy"),
                                ".")),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingFormHelperText },
                            React.createElement("p", null, "*Your carrier's message and data rates apply. Participating carriers include T-Mobile, Verizon Wireless, AT&T, Sprint, Boost, U.S. Cellular, MetroPCS, Virgin Mobile, and Cricket. You can opt out of the notifications and alerts at any time by sending STOP to 35437. For help with text alerts, send HELP to 35437 or call us at 1-888-389-2727.")))),
                    showLoading && (React.createElement("div", { className: styles.cvsCareLocation__bookingFormLoading },
                        React.createElement(Loading, null))),
                    showAppointmentBooked && (React.createElement("div", { className: styles.cvsCareLocation__bookingForm },
                        React.createElement("div", { className: styles.cvsCareLocation__bookingConfirmationMessage },
                            React.createElement(SuccessIcon, null),
                            React.createElement("p", null, "You've successfully reserved your visit, and we sent an email confirmation to you. We're looking forward to helping you get better and stay healthy.")),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingConfirmationBlock },
                            React.createElement("h2", null, "Confirmation code"),
                            React.createElement("p", null, minuteClinicConfirmationCode)),
                        _this.renderBookingFormClinicDetails(),
                        React.createElement("div", { className: styles.cvsCareLocation__bookingConfirmationBlock },
                            React.createElement("h2", null, "Need to reschedule or cancel?"),
                            React.createElement("p", null,
                                "Visit the",
                                ' ',
                                React.createElement("a", { href: addParams('https://www.cvs.com/minuteclinic/?show_reschedule=true', utmParams, {
                                        content: window.conversationId,
                                    }), onClick: function () { return _this.handleClickCVSLink('MinuteClinic cancel page'); }, target: "_blank", rel: "noreferrer" }, "MinuteClinic page"),
                                ' ',
                                "to update your appointment.")),
                        _this.renderBackToResultsButton())))));
        }));
    };
    return CVSCareLocation;
}(React.Component));
export default CVSCareLocation;
