import React from 'react';
function extractCSVContent(csvData) {
    return csvData.map(function (row) { return row.map(function (val) { return val; }).join(','); }).join('\n');
}
function handleClientsDownload(symptom, csvData) {
    var url = window.URL.createObjectURL(new Blob([extractCSVContent(csvData)], { type: 'text/csv;charset=utf-8;' }));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "".concat(symptom, "-symptom-to-diagnostic-resources-validation.csv"));
    document.body.appendChild(link);
    link.click();
}
var DownloadSymptomDiagnostic = function (props) {
    var csvData = props.csvData, symptom = props.symptom;
    handleClientsDownload(symptom, csvData);
    return React.createElement(React.Fragment, null);
};
export default DownloadSymptomDiagnostic;
