var _a, _b;
import { getCareDetailSelectOptions } from './CareDetail.util';
export var VaccineCareDetailDescriptor;
(function (VaccineCareDetailDescriptor) {
    VaccineCareDetailDescriptor["INFLUENZA"] = "influenza";
    VaccineCareDetailDescriptor["SHINGLES"] = "shingles";
    VaccineCareDetailDescriptor["PNEUMONIA"] = "pneumonia";
    VaccineCareDetailDescriptor["TETANUS"] = "tetanus";
    VaccineCareDetailDescriptor["TUBERCULOSIS"] = "tuberculosis";
    VaccineCareDetailDescriptor["COVID_19_PEDIATRIC_NO_PREFERENCE"] = "covid-19-pediatric-no-preference";
    VaccineCareDetailDescriptor["COVID_19_THIRD_DOSE_MODERNA"] = "covid-19-third-dose-moderna";
    VaccineCareDetailDescriptor["COVID_19_BOOSTER_MODERNA"] = "covid-19-booster-moderna";
    VaccineCareDetailDescriptor["COVID_19_BOOSTER_PFIZER"] = "covid-19-booster-pfizer";
    VaccineCareDetailDescriptor["COVID_19_PFIZER"] = "covid-19-pfizer";
    VaccineCareDetailDescriptor["COVID_19_NO_PREFERENCE"] = "covid-19-no-preference";
})(VaccineCareDetailDescriptor || (VaccineCareDetailDescriptor = {}));
export var VaccineCareDetailLabel = (_a = {},
    _a[VaccineCareDetailDescriptor.INFLUENZA] = 'Influenza',
    _a[VaccineCareDetailDescriptor.SHINGLES] = 'Shingles',
    _a[VaccineCareDetailDescriptor.PNEUMONIA] = 'Pneumonia',
    _a[VaccineCareDetailDescriptor.TETANUS] = 'Tetanus',
    _a[VaccineCareDetailDescriptor.TUBERCULOSIS] = 'Tuberculosis',
    _a[VaccineCareDetailDescriptor.COVID_19_PEDIATRIC_NO_PREFERENCE] = 'Covid 19 Pediatric No Preference',
    _a[VaccineCareDetailDescriptor.COVID_19_THIRD_DOSE_MODERNA] = 'Covid 19 Pfizer',
    _a[VaccineCareDetailDescriptor.COVID_19_BOOSTER_MODERNA] = 'Covid 19 Booster Moderna',
    _a[VaccineCareDetailDescriptor.COVID_19_BOOSTER_PFIZER] = 'Covid 19 Third Dose Moderna',
    _a[VaccineCareDetailDescriptor.COVID_19_PFIZER] = 'Covid 19 Booster Pfizer',
    _a[VaccineCareDetailDescriptor.COVID_19_NO_PREFERENCE] = 'Covid 19 No Preference',
    _a);
export var CareOptionRenderType;
(function (CareOptionRenderType) {
    CareOptionRenderType["STANDARD"] = "STANDARD";
    CareOptionRenderType["LINKBOX"] = "LINKBOX";
})(CareOptionRenderType || (CareOptionRenderType = {}));
export var careOptionRenderInfo = (_b = {},
    _b[VaccineCareDetailDescriptor.INFLUENZA.toString()] = {
        name: VaccineCareDetailDescriptor.INFLUENZA,
        careOptionRenderType: CareOptionRenderType.LINKBOX,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_PEDIATRIC_NO_PREFERENCE.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_PEDIATRIC_NO_PREFERENCE,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_THIRD_DOSE_MODERNA.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_THIRD_DOSE_MODERNA,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_BOOSTER_MODERNA.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_BOOSTER_MODERNA,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_BOOSTER_PFIZER.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_BOOSTER_PFIZER,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_PFIZER.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_PFIZER,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b[VaccineCareDetailDescriptor.COVID_19_NO_PREFERENCE.toString()] = {
        name: VaccineCareDetailDescriptor.COVID_19_NO_PREFERENCE,
        careOptionRenderType: CareOptionRenderType.STANDARD,
    },
    _b);
export var VaccineCareDetailSelectOptions = getCareDetailSelectOptions(VaccineCareDetailDescriptor, VaccineCareDetailLabel);
