import React from 'react';
import '../SelectScore/ScoreSelector';
export var CESMobileScoreSelect = function (props) {
    var scores = props.scores, scoreNumber = props.score, onSelect = props.onSelect;
    var selectIcon = function (index) {
        onSelect(index + 1);
    };
    return (React.createElement(React.Fragment, null, scores.map(function (scoreObj, index) { return (React.createElement("button", { key: scoreObj.score, onClick: function () { return selectIcon(index); } },
        React.createElement("img", { src: scoreObj.score <= (scoreNumber !== null && scoreNumber !== void 0 ? scoreNumber : -1) ? scoreObj.selectIcon : scoreObj.defaultIcon, alt: "" }))); })));
};
