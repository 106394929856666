import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import CheckSymptomsIcon from '../../svg/CheckSymptomsIcon';
import GetCareRecommendationIcon from '../../svg/GetCareRecommendationIcon';
import ChooseCareAndBookIcon from '../../svg/ChooseCareAndBookIcon';
import StepSection from './StepSection';
import { InfoCircleIcon } from '../../SVG';
import Slideover from '../../tailwind_common/Slideover';
import CareRecSlideoverTitle from '../CareRecommendationSlideoverContent/CareRecSlideoverTitle';
import CareRecSlideoverContent from '../CareRecommendationSlideoverContent/CareRecSlideoverContent';
import { useRingStyle } from '../../tailwind_common/util';
import WarningBanner from './WarningBanner';
import { DividerHr } from './DividerHr';
import { Header } from './Header';
var ConversationBanner = function () {
    var MOBILE_PIXEL = 767;
    var _a = useState(window.innerWidth < MOBILE_PIXEL), isMobile = _a[0], setIsMobile = _a[1];
    var _b = useState(false), isSlideoverOpen = _b[0], setIsSlideoverOpen = _b[1];
    var ringStyle = useRingStyle();
    var t = useTranslation().t;
    var handleResize = function () {
        setIsMobile(window.innerWidth < MOBILE_PIXEL);
    };
    useEffect(function () {
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    var renderBanner = function () { return (React.createElement("div", { className: "flex flex-col items-center font-primary" },
        React.createElement(WarningBanner, null),
        React.createElement("div", { className: twMerge('flex items-start px-4', 'mobile:mb-4 mobile:justify-center') },
            React.createElement("div", { className: twMerge('flex w-full flex-col items-center gap-6 rounded-[0.375rem] border border-solid border-gray-200 bg-white pb-0 shadow-sm', 'mobile:w-full mobile:items-start mobile:gap-4 mobile:pb-0') },
                React.createElement(Header, { isMobile: isMobile }),
                React.createElement("div", { className: twMerge('mx-4 mb-4 flex items-center justify-center', 'mobile:mx-0 mobile:flex-col mobile:items-start mobile:items-baseline mobile:gap-3 mobile:px-4 mobile:py-0') },
                    React.createElement(StepSection, { icon: React.createElement(CheckSymptomsIcon, { width: isMobile ? '16' : '21', height: isMobile ? '16' : '21' }), content: React.createElement(React.Fragment, null, t('conversationBanner.steps.1', 'Check Symptoms')), className: "mobile:pb-0" }),
                    React.createElement(DividerHr, null),
                    React.createElement(StepSection, { icon: React.createElement(GetCareRecommendationIcon, { width: isMobile ? '16' : '21', height: isMobile ? '16' : '21' }), content: React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                React.createElement("p", { className: "font-normal" },
                                    t('conversationBanner.steps.2', 'Get care recommendations'),
                                    ' ')),
                            React.createElement("button", { className: twMerge('tw-focus ml-1 flex h-5 w-5 cursor-pointer items-center rounded-full', 'focus:ring-offset-0'), style: ringStyle, onClick: function () { return setIsSlideoverOpen(!isSlideoverOpen); } },
                                React.createElement(InfoCircleIcon, { height: "20", width: "20" }))) }),
                    React.createElement(DividerHr, null),
                    React.createElement(StepSection, { icon: React.createElement(ChooseCareAndBookIcon, { width: isMobile ? '16' : '21', height: isMobile ? '16' : '21' }), content: React.createElement(React.Fragment, null, t('conversationBanner.steps.3', 'Choose care and book')), className: "mobile:pt-px" })))),
        React.createElement(Slideover, { isOpen: isSlideoverOpen, setIsOpen: setIsSlideoverOpen, Title: React.createElement(CareRecSlideoverTitle, null), Content: React.createElement(CareRecSlideoverContent, null) }))); };
    return React.createElement(Suspense, { fallback: "" }, renderBanner());
};
export default ConversationBanner;
