import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { logEvent, MessageType } from '../../util/EventLog';
import { THEME } from '../clearstep/util/Theme';
var LinkWithIcon = function (props) {
    var text = props.text, icon = props.icon, href = props.href, color = props.color, className = props.className, pTagClassName = props.pTagClassName, iconClassName = props.iconClassName, onClick = props.onClick, needsLinkReferrer = props.needsLinkReferrer, openNewTab = props.openNewTab, messageType = props.messageType;
    var tailwindATagStyling = 'flex flex-row items-center cursor-pointer text-decoration-none no-underline focus:outline-none mb-4';
    var handleClick = function () {
        if (onClick) {
            onClick();
            return;
        }
        logEvent(messageType || MessageType.BUTTON_CLICK, {
            name: text,
        }, { partner: THEME.partner });
    };
    return (React.createElement(React.Fragment, null, href ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    React.createElement("a", { className: twMerge(tailwindATagStyling, className), href: href, target: openNewTab ? '_blank' : '', onClick: handleClick, style: { color: color }, rel: "noopener".concat(needsLinkReferrer && !openNewTab ? '' : ' noreferrer') },
        React.createElement("div", { className: twMerge('mr-0.75 mt-0.75 min-w-8.75', iconClassName) }, icon),
        React.createElement("p", { className: twMerge('font-semibold tracking-[-.32px]', pTagClassName) }, text))) : (React.createElement("div", { className: twMerge(tailwindATagStyling, 'cursor-auto', className) },
        React.createElement("div", { className: twMerge('min-w-35 mr-0.75 mt-0.75', iconClassName) }, icon),
        React.createElement("p", { className: twMerge('font-medium', pTagClassName) }, text)))));
};
LinkWithIcon.defaultProps = {
    color: 'var(--primary-color)',
};
export default LinkWithIcon;
