export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
// titlezeWords capitalizes the first letter of each word
// and lower cases the rest
export function titlecaseWords(str) {
    return transformWords(str, function (word) { return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); });
}
export function capitalizeAllFirstLetters(str) {
    return transformWords(str, function (word) { return word.charAt(0).toUpperCase() + word.slice(1); });
}
export function transformWords(str, callbackFn) {
    var nonCapWords = new Set([
        'a',
        'an',
        'the',
        'and',
        'but',
        'or',
        'nor',
        'for',
        'so',
        'yet',
        'in',
        'on',
        'under',
        'over',
        'with',
        'at',
        'by',
        'of',
        'to',
        'etc.',
        'np',
        'md',
        'pa',
        'do',
        'phd',
        'dpm',
        'msc',
        'mbbs',
        'dmd',
    ]);
    var words = str.split(' ');
    var capitalizedWords = words.map(function (word, index) {
        if (word.length === 0 || (index !== 0 && nonCapWords.has(word.toLowerCase()))) {
            return word;
        }
        return callbackFn(word, index);
    });
    return capitalizedWords.join(' ');
}
