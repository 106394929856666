import '../proto/care_locations_pb';
import { SourceOfCare } from '../proto/results_pb';
import '../proto/address_pb';
import { Partner } from '../proto/partners_pb';
import { THEME } from './Theme';
export var baseCareLocation = {
    careType: SourceOfCare.Type.PRIMARY_CARE,
    careDetails: [],
    name: '',
    structuredAddress: {
        streetNumber: '',
        route: '',
        locality: '',
        administrativeAreaLevel1: '',
        administrativeAreaLevel2: '',
        postalCode: '',
        country: '',
        floor: '',
        room: '',
    },
    distance: 0,
    duration: '',
    link: '',
    phone: '',
    hours: '',
    waitTime: '',
    fax: '',
    latitude: 42.63894,
    longitude: -71.38402,
    specialty: '',
    practiceName: '',
    starRating: '',
    partner: THEME.partner,
    externalId: '',
    externalServiceId: '',
    externalDeptId: '',
    bookingType: '',
    description: '',
    schedule: {
        partner: Partner.CLEARSTEP,
        externalId: '',
        careLocationInternalId: 0,
        slots: [],
        error: '',
    },
    organizationName: '',
    schedulingLink: '',
    providerName: '',
    secondarySpecialties: [],
    providerImageLink: '',
    internalId: 0,
    tags: [],
    availabilityLink: '',
    numRatings: 0,
};
export function mapURL(location) {
    // https://developers.google.com/maps/documentation/maps-static/dev-guide
    var url = new URL('https://maps.googleapis.com/maps/api/staticmap');
    url.searchParams.append('key', window.MAPS_API_KEY);
    url.searchParams.append('zoom', '13');
    url.searchParams.append('markers', "".concat(location.latitude, ",").concat(location.longitude));
    url.searchParams.append('size', '415x130');
    return url.href;
}
export function formatStructuredAddress(a) {
    return "".concat(a.streetNumber, " ").concat(a.route, " ").concat(a.locality, " ").concat(a.administrativeAreaLevel1, " ").concat(a.postalCode);
}
export function mapsDirectionsURL(structuredAddress) {
    var url = new URL('https://www.google.com/maps/dir/');
    url.searchParams.append('api', '1');
    url.searchParams.append('destination', formatStructuredAddress(structuredAddress));
    url.searchParams.append('travelmode', 'driving');
    return url;
}
export function mapsSearchURL(careType, zipCode, partner) {
    var _a;
    var addCVSToQuery = partner !== undefined && partner.valueOf() === Partner.CVS.valueOf();
    var query = (_a = {},
        _a[SourceOfCare.Type.ER] = 'hospital',
        _a[SourceOfCare.Type.URGENT_CARE] = 'urgent care',
        _a[SourceOfCare.Type.PRIMARY_CARE] = 'primaryCare',
        _a[SourceOfCare.Type.RETAIL_CLINIC] = 'minute clinic',
        _a[SourceOfCare.Type.DENTIST] = 'dentist',
        _a[SourceOfCare.Type.LAB] = 'quest diagnostics or labcorp',
        _a[SourceOfCare.Type.HOME] = addCVSToQuery ? 'cvs pharmacy' : 'pharmacy',
        _a)[careType];
    var url = new URL('https://www.google.com/maps/search/');
    url.searchParams.append('api', '1');
    url.searchParams.append('type', 'health');
    url.searchParams.append('query', "".concat(zipCode, " ").concat(query));
    return url.toString();
}
/* eslint-disable prefer-template */
export function formatPhoneNumber(phoneNumber) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}
export function formatAddress(address) {
    return address
        ? address
            .split(' ')
            .filter(function (w) { return w.length > 0; })
            .map(function (w) { return w[0].toUpperCase() + w.substr(1).toLowerCase(); })
            .join(' ')
        : '';
}
