var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep } from 'lodash';
import { assign, createMachine } from 'xstate';
import { postMetricFeedback, submitMessageFeedback, } from '../../../../api/Feedback';
import { logEvent } from '../../../../util/EventLog';
import { MessageType } from '../../../clearstep/proto/logging_pb';
import { isMobile } from '../../../clearstep/util/AppHelpers';
import { THEME } from '../../../clearstep/util/Theme';
import { MetricMachineStates, } from './MetricFormMachineTypes';
import { getTreatment } from '../../../clearstep/util/Split';
// Send API call
var submitScores = function (context) { return __awaiter(void 0, void 0, void 0, function () {
    var feedbackTreatment, genericTreatment, category, conversationId, params;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getTreatment('feedback_category', {})];
            case 1:
                feedbackTreatment = _a.sent();
                return [4 /*yield*/, getTreatment('generic_feedback_category', {})];
            case 2:
                genericTreatment = _a.sent();
                category = feedbackTreatment || 'symptom_check';
                conversationId = window.conversationId;
                // log intent as CTA engagement when user intends to follow up with care
                if (context.intent && context.intent !== 'no_booking') {
                    logEvent(MessageType.SUBMITTED_BOOKING_INTENT, { value: context.intent }, { partner: THEME.partner });
                }
                params = {
                    conversation_id: conversationId,
                    scores: [
                        {
                            type: 'csat',
                            score: context.questions.CSAT.score,
                            message: context.csatMessage,
                            category: category,
                        },
                        {
                            type: 'ces',
                            score: context.questions.CES.score,
                            category: category,
                        },
                        {
                            type: 'generic',
                            score: 0,
                            message: context.message,
                            category: genericTreatment || 'generic',
                            intent: context.intent,
                        },
                    ],
                };
                postMetricFeedback(params);
                return [2 /*return*/];
        }
    });
}); };
var submitFeedback = function (context) {
    var conversationId = window.conversationId;
    // Handle empty message gracefully without submitting anything
    if (!context.message && !context.csatMessage) {
        return;
    }
    var params = {
        message: context.message,
        csat_message: context.csatMessage,
        feedback_type: 'app_feedback',
        conversation_id: conversationId,
        message_len: 0,
    };
    submitMessageFeedback(params);
};
var stopScroll = function () {
    if (isMobile()) {
        document.body.style.overflow = 'hidden';
    }
};
var resumeScroll = function () {
    document.body.style.overflow = 'unset';
};
var questions = {
    CSAT: {
        next: 'CES',
        disableBack: true,
        score: -1,
    },
    CES: {
        next: 'Generic',
        disableBack: false,
        score: -1,
    },
    Generic: {
        next: '',
        disableBack: false,
        score: -1,
    },
};
export var metricMachine = createMachine({
    id: 'metrics',
    schema: {
        context: {},
        events: {},
    },
    initial: MetricMachineStates.COLLAPSED,
    context: {
        questions: questions,
        question: 'CSAT',
        stack: [],
        message: '',
        csatMessage: '',
        intent: '',
        type: 'app',
    },
    states: {
        appfeedback: {
            on: {
                COLLAPSE: MetricMachineStates.COLLAPSED,
                SAVE: {
                    actions: assign({
                        message: function (context, event) { var _a; return (_a = event.message) !== null && _a !== void 0 ? _a : context.message; },
                    }),
                },
                SUBMIT: MetricMachineStates.SUBMITTED,
            },
            entry: function () { return stopScroll(); },
        },
        expanded: {
            on: {
                GIVE_FEEDBACK: {
                    target: MetricMachineStates.QUESTION,
                    actions: function (context, _) {
                        return assign({
                            question: function () { return 'csat'; },
                            stack: function () { return context.stack.concat('csat'); },
                        });
                    },
                },
                COLLAPSE: { target: MetricMachineStates.COLLAPSED },
            },
        },
        collapsed: {
            on: {
                EXPAND: [
                    {
                        target: MetricMachineStates.QUESTION,
                        cond: function (context, _) { return context.type === 'metric'; },
                    },
                    {
                        target: MetricMachineStates.APPFEEDBACK,
                        cond: function (context, _) { return context.type === 'app'; },
                    },
                ],
                INIT: {
                    target: MetricMachineStates.EXPANDED,
                    actions: assign({ type: 'metric' }),
                },
            },
            entry: function () { return resumeScroll(); },
        },
        question: {
            on: {
                NEXT: {
                    actions: assign({
                        question: function (_, event) { return event.question; },
                        stack: function (context, _) { return context.stack.concat(context.question); },
                    }),
                },
                SAVE: {
                    actions: assign({
                        questions: function (context, event) {
                            var _a;
                            var questions = cloneDeep(context.questions);
                            var currentQuestion = questions[context.question];
                            questions[context.question] = __assign(__assign({}, currentQuestion), { score: (_a = event.score) !== null && _a !== void 0 ? _a : currentQuestion.score });
                            return questions;
                        },
                        message: function (context, event) { var _a; return (_a = event.message) !== null && _a !== void 0 ? _a : context.message; },
                        csatMessage: function (context, event) { var _a; return (_a = event.csatMessage) !== null && _a !== void 0 ? _a : context.csatMessage; },
                        intent: function (context, event) { var _a; return (_a = event.intent) !== null && _a !== void 0 ? _a : context.intent; },
                    }),
                },
                BACK: {
                    // Pop last question from stack, assign to current question
                    actions: assign(function (context, _) {
                        var stack = context.stack;
                        var newStack = stack.slice(0, stack.length - 1);
                        var prev = stack[stack.length - 1];
                        return {
                            question: prev,
                            stack: newStack,
                        };
                    }),
                    cond: function (context) { return context.stack.length > 0; }, // Only execute when stack is not empty
                },
                COLLAPSE: {
                    target: MetricMachineStates.COLLAPSED,
                },
                SUBMIT: {
                    target: MetricMachineStates.SUBMITTED,
                    cond: function (ctx) { return !ctx.questions[ctx.question].next.length; },
                },
            },
            entry: function () { return stopScroll(); },
        },
        submitted: {
            after: {
                3000: {
                    target: MetricMachineStates.COLLAPSED,
                    // Reset form state
                    actions: assign({
                        questions: questions,
                        question: 'CSAT',
                        stack: [],
                        message: '',
                        type: 'app',
                    }),
                },
            },
            entry: [
                function (context, _) {
                    if (context.type === 'app') {
                        return;
                    }
                    submitScores(context);
                },
                function () { return resumeScroll(); },
            ],
        },
    },
}, {
    actions: {
        submitScores: submitScores,
        submitFeedback: submitFeedback,
    },
});
