var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default {
    input: function (provided, _state) { return (__assign(__assign({}, provided), { width: '250px', display: 'flex', flexDirection: 'column' })); },
    control: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: '16px', cursor: 'pointer', paddingRight: '16px', border: '1px solid #dbdbe0', boxShadow: '0 0 12px rgb(0 0 0 / 10%)', '&:hover': {
            boxShadow: '0 0 12px rgb(0 0 0 / 10%)',
        } })); },
    container: function (provided, _state) { return (__assign(__assign({}, provided), { marginBottom: '10px' })); },
    option: function (provided, _state) { return (__assign(__assign({}, provided), { border: 'none', width: '100%', textAlign: 'left', cursor: 'pointer', display: 'flex', padding: '10px 15px' })); },
    menu: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: '16px' })); },
    menuList: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: '16px' })); },
    valueContainer: function (provided, _state) { return (__assign(__assign({}, provided), { padding: '16px 8px 16px 20px' })); },
    dropdownIndicator: function (provided, state) { return (__assign(__assign({}, provided), { transition: 'all 0.36s ease-in-out', transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)' })); },
};
