import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { SETTINGS_NAMESPACE, STATIC_NAMESPACE } from '../../../util/i18n/constants';
import { useIsNamespaceEnabled } from '../../../util/i18n/split';
export var Header = function (_a) {
    var isMobile = _a.isMobile;
    var settingsNsEnabled = useIsNamespaceEnabled(SETTINGS_NAMESPACE);
    var headerNs = settingsNsEnabled ? SETTINGS_NAMESPACE : STATIC_NAMESPACE;
    var t = useTranslation(headerNs).t;
    return (React.createElement("div", { className: twMerge('gap-1/4 flex flex-col items-center justify-center self-stretch rounded-t-[0.375rem] bg-gray-50 py-4', 'mobile:gap-1 mobile:px-6 mobile:py-3') },
        React.createElement("div", { className: "flex flex-col items-center justify-center self-stretch" },
            React.createElement("p", { className: twMerge('font-primary text-2xl font-medium leading-8 tracking-tight text-gray-900', 'mobile:text-xl') }, isMobile
                ? t('conversationBanner.mobileTitle', 'Check Symptoms & Find Care')
                : t('conversationBanner.desktopTitle', 'Symptom Checker')))));
};
