var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Suspense } from 'react';
import LLMarkdown from './Markdown.Lazy';
/**
 * Wraps lazy-loaded MarkdownToJsx component in a Suspense handler.
 * Markdown.Lazy can be imported directly if used inside higher level Suspense wrapper.
 */
var WrappedLLMarkdown = function (props) { return (React.createElement(Suspense, { fallback: "" },
    React.createElement(LLMarkdown, __assign({}, props)))); };
export default WrappedLLMarkdown;
