import React from 'react';
import { twMerge } from 'tailwind-merge';
import { CheckMark } from '../SVG';
var DropDownMenuSection = function (_a) {
    var options = _a.options, onClickOptionSelected = _a.onClickOptionSelected, selectedOption = _a.selectedOption, _b = _a.checkmarkFill, checkmarkFill = _b === void 0 ? '' : _b;
    return (React.createElement("div", { className: "absolute right-0 mt-2 w-31 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" },
        React.createElement("div", { className: "py-1", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "options-menu" }, options.map(function (option) { return (React.createElement("button", { key: "".concat(option.value), onClick: function () { return onClickOptionSelected(option); }, className: "block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900", role: "menuitem" },
            React.createElement("div", null,
                React.createElement("span", { className: twMerge(selectedOption && selectedOption.value === option.value && 'font-bold') }, option.displayName),
                selectedOption && selectedOption.value === option.value && (React.createElement(CheckMark, { className: "float-right pt-1", fill: checkmarkFill }))))); }))));
};
export default DropDownMenuSection;
