import * as React from 'react';
/* CORONAVIRUS */
export var HospitalTriageIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "75", height: "75", viewBox: "0 0 75 75", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M36.9433 4.01221H70.7893V72.2911H4.21117V4.01221H13.3796", fill: "#ECEDF2" }),
        React.createElement("path", { d: "M36.9433 4.01221H70.7893V11.0322H4.21117V4.01221H13.3796", fill: "#C6CAD5" }),
        React.createElement("path", { d: "M27.8692 6.66978H70.7893H73.779C74.4533 6.66978 74.9999 6.12324 74.9999 5.44883V2.57495C74.9999 1.90068 74.4534 1.354 73.779 1.354H31.0166H19.3044H1.22134C0.547073 1.354 0.000392914 1.90054 0.000392914 2.57495V5.44897C0.000392914 6.12324 0.546926 6.66992 1.22134 6.66992H4.21109H20.3578", style: { fill: 'var(--primary-color)' } }),
        React.createElement("path", { d: "M25.1617 29.1687C32.7798 29.1687 38.9555 22.993 38.9555 15.3749C38.9555 7.75677 32.7798 1.58105 25.1617 1.58105C17.5435 1.58105 11.3678 7.75677 11.3678 15.3749C11.3678 22.993 17.5435 29.1687 25.1617 29.1687Z", fill: "#C6CAD5" }),
        React.createElement("path", { d: "M25.1617 27.6385C32.7798 27.6385 38.9555 21.4628 38.9555 13.8446C38.9555 6.2265 32.7798 0.0507812 25.1617 0.0507812C17.5435 0.0507812 11.3678 6.2265 11.3678 13.8446C11.3678 21.4628 17.5435 27.6385 25.1617 27.6385Z", fill: "white" }),
        React.createElement("path", { d: "M33.2165 11.1367H28.4799C28.1427 11.1367 27.8692 10.8633 27.8692 10.526V5.7894C27.8692 5.4522 27.5959 5.17871 27.2585 5.17871H23.0637C22.7265 5.17871 22.453 5.45205 22.453 5.7894V10.5261C22.453 10.8633 22.1796 11.1368 21.8423 11.1368H17.1057C16.7685 11.1368 16.495 11.4102 16.495 11.7475V15.9424C16.495 16.2796 16.7684 16.5531 17.1057 16.5531H21.8423C22.1795 16.5531 22.453 16.8264 22.453 17.1638V21.9003C22.453 22.2375 22.7263 22.511 23.0637 22.511H27.2585C27.5957 22.511 27.8692 22.2377 27.8692 21.9003V17.1638C27.8692 16.8266 28.1426 16.5531 28.4799 16.5531H33.2165C33.5537 16.5531 33.8272 16.2797 33.8272 15.9424V11.7475C33.8272 11.4102 33.5538 11.1367 33.2165 11.1367Z", fill: "#D81616" }),
        React.createElement("path", { d: "M53.5322 32.2681H46.0726V45.8896H53.5322V32.2681Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M28.9277 32.2681H21.4681V45.8896H28.9277V32.2681Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M65.8337 32.2681H58.3741V45.8896H65.8337V32.2681Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M41.2292 32.2681H33.7696V45.8896H41.2292V32.2681Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M16.6266 32.2681H9.16705V45.8896H16.6266V32.2681Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M28.9277 50.5186H21.4681V64.1401H28.9277V50.5186Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M41.2292 50.5186H33.7696V64.1401H41.2292V50.5186Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M16.6266 50.5186H9.16705V64.1401H16.6266V50.5186Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M53.5322 14.0156H46.0726V27.6372H53.5322V14.0156Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M65.8337 14.0156H58.3741V27.6372H65.8337V14.0156Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M53.532 46.8078H46.0724C45.5653 46.8078 45.1541 46.3965 45.1541 45.8895V32.2679C45.1541 31.7608 45.5654 31.3496 46.0724 31.3496H53.532C54.0391 31.3496 54.4503 31.7609 54.4503 32.2679V45.8895C54.4503 46.3966 54.039 46.8078 53.532 46.8078ZM46.9907 44.9711H52.6135V33.1862H46.9907V44.9711Z", fill: "white" }),
        React.createElement("path", { d: "M28.9284 46.8078H21.4689C20.9617 46.8078 20.5506 46.3965 20.5506 45.8895V32.2679C20.5506 31.7608 20.9619 31.3496 21.4689 31.3496H28.9284C29.4356 31.3496 29.8467 31.7609 29.8467 32.2679V45.8895C29.8467 46.3966 29.4354 46.8078 28.9284 46.8078ZM22.3872 44.9711H28.01V33.1862H22.3872V44.9711Z", fill: "white" }),
        React.createElement("path", { d: "M65.8335 46.8078H58.374C57.8668 46.8078 57.4557 46.3965 57.4557 45.8895V32.2679C57.4557 31.7608 57.867 31.3496 58.374 31.3496H65.8335C66.3407 31.3496 66.7518 31.7609 66.7518 32.2679V45.8895C66.7518 46.3966 66.3407 46.8078 65.8335 46.8078ZM59.2923 44.9711H64.9151V33.1862H59.2923V44.9711Z", fill: "white" }),
        React.createElement("path", { d: "M41.2302 46.8078H33.7706C33.2635 46.8078 32.8523 46.3965 32.8523 45.8895V32.2679C32.8523 31.7608 33.2637 31.3496 33.7706 31.3496H41.2302C41.7373 31.3496 42.1485 31.7609 42.1485 32.2679V45.8895C42.1485 46.3966 41.7372 46.8078 41.2302 46.8078ZM34.689 44.9711H40.3118V33.1862H34.689V44.9711Z", fill: "white" }),
        React.createElement("path", { d: "M16.6265 46.8078H9.16704C8.65991 46.8078 8.24873 46.3965 8.24873 45.8895V32.2679C8.24873 31.7608 8.66005 31.3496 9.16704 31.3496H16.6266C17.1337 31.3496 17.5449 31.7609 17.5449 32.2679V45.8895C17.5449 46.3966 17.1337 46.8078 16.6265 46.8078ZM10.0853 44.971H15.7081V33.1862H10.0853V44.971Z", fill: "white" }),
        React.createElement("path", { d: "M65.8334 50.5195H46.0722V69.6328L65.8334 71.1636V50.5195Z", fill: "#A0D9F2" }),
        React.createElement("path", { d: "M66.752 70.3985H64.9152V51.4382H46.9908V70.3985H45.154V50.5199C45.154 50.0127 45.5654 49.6016 46.0724 49.6016H65.8335C66.3407 49.6016 66.7519 50.0129 66.7519 50.5199L66.752 70.3985Z", fill: "white" }),
        React.createElement("path", { d: "M28.9286 65.0598H21.469C20.9619 65.0598 20.5507 64.6484 20.5507 64.1414V50.5199C20.5507 50.0127 20.9621 49.6016 21.469 49.6016H28.9286C29.4357 49.6016 29.8469 50.0129 29.8469 50.5199V64.1414C29.8469 64.6484 29.4356 65.0598 28.9286 65.0598ZM22.3873 63.223H28.0102V51.4382H22.3873V63.223Z", fill: "white" }),
        React.createElement("path", { d: "M41.2301 65.0598H33.7706C33.2634 65.0598 32.8523 64.6484 32.8523 64.1414V50.5199C32.8523 50.0127 33.2636 49.6016 33.7706 49.6016H41.2301C41.7373 49.6016 42.1485 50.0129 42.1485 50.5199V64.1414C42.1485 64.6484 41.7371 65.0598 41.2301 65.0598ZM34.6889 63.223H40.3117V51.4382H34.6889V63.223Z", fill: "white" }),
        React.createElement("path", { d: "M16.6267 65.0598H9.16726C8.66013 65.0598 8.24895 64.6484 8.24895 64.1414V50.5199C8.24895 50.0127 8.66028 49.6016 9.16726 49.6016H16.6268C17.134 49.6016 17.5451 50.0129 17.5451 50.5199V64.1414C17.5451 64.6484 17.134 65.0598 16.6267 65.0598ZM10.0856 63.223H15.7084V51.4382H10.0856V63.223Z", fill: "white" }),
        React.createElement("path", { d: "M53.5319 28.5571H46.0724C45.5652 28.5571 45.154 28.1458 45.154 27.6388V14.0169C45.154 13.5098 45.5654 13.0986 46.0724 13.0986H53.5319C54.0391 13.0986 54.4502 13.51 54.4502 14.0169V27.6385C54.4502 28.1458 54.0389 28.5571 53.5319 28.5571ZM46.9907 26.7204H52.6135V14.9354H46.9907V26.7204Z", fill: "white" }),
        React.createElement("path", { d: "M65.8335 28.5571H58.3739C57.8668 28.5571 57.4556 28.1458 57.4556 27.6388V14.0169C57.4556 13.5098 57.8669 13.0986 58.3739 13.0986H65.8335C66.3406 13.0986 66.7518 13.51 66.7518 14.0169V27.6385C66.7518 28.1458 66.3406 28.5571 65.8335 28.5571ZM59.2922 26.7204H64.915V14.9354H59.2922V26.7204Z", fill: "white" }),
        React.createElement("path", { d: "M65.8344 54.2695H46.0727V56.1063H65.8344V54.2695Z", fill: "white" }),
        React.createElement("path", { d: "M56.8712 55.188H55.0345V71.1628H56.8712V55.188Z", fill: "white" }),
        React.createElement("path", { d: "M28.9284 53.9634H21.4682V55.8001H28.9284V53.9634Z", fill: "white" }),
        React.createElement("path", { d: "M41.2306 55.7997H33.7705V53.9629H40.3123V50.5205H42.1491V54.8812C42.1489 55.3885 41.7377 55.7997 41.2306 55.7997Z", fill: "white" }),
        React.createElement("path", { d: "M16.6261 53.9634H9.16737V55.8001H16.6261V53.9634Z", fill: "white" }),
        React.createElement("path", { d: "M65.8332 37.5492H58.3745V35.7124H64.9147V32.2686H66.7515V36.6307C66.7516 37.1378 66.3405 37.5492 65.8332 37.5492Z", fill: "white" }),
        React.createElement("path", { d: "M41.2299 35.7124H33.7697V37.5492H41.2299V35.7124Z", fill: "white" }),
        React.createElement("path", { d: "M16.6261 35.7124H9.16737V37.5492H16.6261V35.7124Z", fill: "white" }),
        React.createElement("path", { d: "M28.9284 35.7124H21.4682V37.5492H28.9284V35.7124Z", fill: "white" }),
        React.createElement("path", { d: "M53.5314 35.7124H46.0713V37.5492H53.5314V35.7124Z", fill: "white" }),
        React.createElement("path", { d: "M65.8332 19.2977H58.3745V17.4609H64.9147V14.0171H66.7515V18.3792C66.7516 18.8865 66.3405 19.2977 65.8332 19.2977Z", fill: "white" }),
        React.createElement("path", { d: "M53.5314 17.4619H46.0713V19.2987H53.5314V17.4619Z", fill: "white" }),
        React.createElement("path", { d: "M72.7542 69.6333H2.24531C1.00532 69.6333 0 70.6386 0 71.8785V74.9491H74.9996V71.8785C74.9996 70.6386 73.9942 69.6333 72.7542 69.6333Z", style: { fill: 'var(--primary-color)' } })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "75", height: "75", fill: "white" }))))); };
export var CoronavirusTriageTelemedicineIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "70", height: "67", viewBox: "0 0 70 67", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M45.943 54.656L35.7761 51.2983L25.6098 54.656L24.0894 60.6156L35.7761 63.808L47.4629 60.6156L45.943 54.656Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M70 47.9404L36.1242 45.1274L0 47.9404V50.8771C0 52.955 1.66412 54.6562 3.69621 54.6562H66.3038C68.3359 54.6562 70 52.955 70 50.8771V47.9404Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.69621 0.01454C1.66412 0.01454 0 1.71572 0 3.79366V47.9398H70V3.79366C70 1.71572 68.3359 0.047255 66.3038 0.01454C64.3037 -0.018175 13.2046 0.01454 3.69621 0.01454V0.01454Z", fill: "#546E7A" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M64.6117 6.33026V42.1521C64.6117 42.5943 64.2598 42.9579 63.824 42.9579H6.14418C5.7132 42.9579 5.35645 42.5943 5.35645 42.1521V6.33026C5.35645 5.88425 5.71266 5.52002 6.14418 5.52002H63.824C64.2598 5.52002 64.6117 5.88425 64.6117 6.33026Z", fill: "#8EBFE8" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M47.4633 60.6152H20.746C19.0295 60.6152 17.6255 62.0509 17.6255 63.8055V65.5563C17.6255 66.3496 18.2578 67.0001 19.0338 67.0001H52.5235C53.2957 67.0001 53.9318 66.3496 53.9318 65.5563V63.8055C53.9318 62.0509 52.5235 60.6152 50.8076 60.6152H47.4633Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M45.3662 60.6152C47.0864 60.6152 48.4904 62.0509 48.4904 63.8055V65.5563C48.4904 66.3496 47.8544 67.0001 47.0821 67.0001H52.5226C53.2948 67.0001 53.9309 66.3496 53.9309 65.5563V63.8055C53.9309 62.0509 52.5226 60.6152 50.8067 60.6152H45.3662Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M40.5029 54.6567H25.6102L24.7861 57.9004H38.2188C39.8829 57.9004 41.4749 57.8306 41.775 58.9882L42.0228 60.6163H47.4633L45.9434 54.6567H40.5029Z", fill: "#90A4AE" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M44.3674 30.1467L46.1191 28.4575C47.7111 26.8049 47.8873 27.0502 50.1875 27.0502H55.0918C56.0157 27.0502 56.7676 26.2776 56.7676 25.3365V14.8001C56.7676 13.8552 56.0157 13.082 55.0918 13.082H38.5787C37.6585 13.082 36.9028 13.8552 36.9028 14.8001V25.3365C36.9028 26.2776 37.6585 27.0502 38.5787 27.0502H40.535C41.547 27.0502 41.547 27.0502 41.547 28.2612V29.4188C41.547 30.4777 42.911 31.5535 44.3674 30.1467V30.1467Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M53.8307 14.8001V25.3365C53.8307 26.2776 53.0787 27.0502 52.1548 27.0502H55.091C56.015 27.0502 56.7669 26.2776 56.7669 25.3365V14.8001C56.7669 13.8552 56.015 13.082 55.091 13.082H52.1548C53.0787 13.082 53.8307 13.8552 53.8307 14.8001Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.9257 42.9578H38.4787C38.0664 35.6073 32.2062 31.9465 26.6937 31.2148C25.3179 30.609 22.8976 30.597 21.8097 31.2066C16.5412 31.9018 9.9248 35.8363 9.9248 42.9578H25.9257Z", fill: "white" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.1105 19.9588H27.6062C24.5423 19.9588 23.2622 19.1818 22.1941 18.5193C20.726 17.611 20.39 16.9239 19.1222 18.7892C18.5582 19.6197 18.1102 19.9588 17.3422 19.9588H16.3579V24.3153C16.3579 27.4849 18.8178 30.2051 21.8101 31.2073C22.6544 31.4608 23.3503 31.6162 24.2342 31.6206C25.0705 31.6288 25.9026 31.4815 26.6946 31.2155C27.4225 30.9701 28.1184 30.6184 28.7545 30.1849C30.7144 28.8475 32.1105 26.7079 32.1105 24.3153V19.9588Z", fill: "#FFDDA6" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.1105 21.2876C32.1468 20.8869 32.1148 20.3307 32.1105 19.9583C32.0507 8.89464 16.3579 10.375 16.3579 19.4474V21.2876H17.3422C18.1102 21.2876 18.5582 20.9485 19.1222 20.1181C20.39 18.2528 20.726 18.9398 22.1941 19.8482C23.2622 20.5107 24.5423 21.2876 27.6062 21.2876H32.1105Z", fill: "#546E7A" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.1581 42.9584H38.4783C38.066 35.6079 32.2058 31.9471 26.6933 31.2153C25.9013 31.4809 25.0692 31.6286 24.2334 31.6199C24.0214 31.6199 23.8211 31.6117 23.6294 31.5916C28.8776 33.3009 32.8221 37.0195 33.1581 42.9584V42.9584Z", fill: "#F4F4F4" }),
    React.createElement("path", { d: "M24.3012 40.4622H24.2734C23.6854 40.4622 23.2085 39.9753 23.2085 39.3744C23.2085 38.7735 23.6854 38.2866 24.2734 38.2866H24.3012C24.8892 38.2866 25.3661 38.7735 25.3661 39.3744C25.3661 39.9753 24.8892 40.4622 24.3012 40.4622Z", fill: "#546E7A" }),
    React.createElement("path", { d: "M16.706 40.0702V42.9584H14.5762V40.0702C14.5762 39.4704 15.0536 38.9824 15.6411 38.9824C16.2296 38.9824 16.706 39.4704 16.706 40.0702V40.0702Z", fill: "#7B7B7B" }),
    React.createElement("path", { d: "M24.2016 36.6751H24.1738C23.5858 36.6751 23.1089 36.1881 23.1089 35.5873C23.1089 34.9864 23.5858 34.4995 24.1738 34.4995H24.2016C24.7896 34.4995 25.2665 34.9864 25.2665 35.5873C25.2665 36.1881 24.7896 36.6751 24.2016 36.6751Z", fill: "#546E7A" }),
    React.createElement("path", { d: "M33.831 40.0702V42.9584H31.7012V40.0702C31.7012 39.4704 32.1776 38.9824 32.7661 38.9824C33.3535 38.9824 33.831 39.4704 33.831 40.0702V40.0702Z", fill: "#7B7B7B" }),
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M45.3599 18.3691L43.5678 20.1988C42.717 21.0674 42.717 22.4887 43.5678 23.3572C44.4186 24.2258 45.8107 24.2258 46.6613 23.3572L48.4534 21.5275L45.3599 18.3691Z", fill: "#DD352E" }),
        React.createElement("path", { d: "M48.456 21.5245L50.2207 19.7229C51.0714 18.8543 51.0714 17.433 50.2207 16.5646C49.3699 15.6959 47.9779 15.6959 47.1272 16.5646L45.3623 18.366L48.456 21.5245Z", fill: "#EBBA16" }),
        React.createElement("path", { d: "M45.9443 18.96L43.605 21.3482C43.5249 21.4299 43.5249 21.5626 43.605 21.6443C43.6451 21.6852 43.6976 21.7056 43.7501 21.7056C43.8026 21.7056 43.8551 21.6852 43.8951 21.6443L46.2344 19.256L45.9443 18.96Z", fill: "#F46767" }),
        React.createElement("path", { d: "M46.2333 19.2562L48.4061 17.0381C48.4862 16.9564 48.4862 16.8237 48.4061 16.742C48.326 16.6602 48.1962 16.6602 48.1161 16.742L45.9434 18.9601L46.2333 19.2562Z", fill: "#FCD770" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "7.92913", height: "8.0953", fill: "white", transform: "translate(42.9297 15.9131)" }))))); };
export var CoronavirusTriageHomeIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "71", height: "71", viewBox: "0 0 71 71", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M61.238 10.2065H53.1025V24.4065H61.238V10.2065Z", fill: "#747486" }),
        React.createElement("path", { d: "M64.3441 2.9585H49.9961V10.6502H64.3441V2.9585Z", fill: "#747486" }),
        React.createElement("path", { d: "M6.21191 38.3106V71.0001H64.1952V38.3106C52.8057 26.921 46.4452 20.5606 35.2035 9.31885C23.9619 20.7084 17.6015 27.0688 6.21191 38.3106Z", fill: "#F0F0F5" }),
        React.createElement("path", { d: "M64.3432 38.3106C52.9536 26.921 46.5932 20.5606 35.3515 9.31885C29.5827 14.9396 25.1452 19.3771 20.7077 23.8146C16.2702 28.2521 11.8327 32.6896 6.21191 38.3104V44.9667C17.6015 33.5771 23.9619 27.2167 35.2036 15.975C46.5932 27.3645 52.9536 33.725 64.1953 44.9667V45.8542V38.3104H64.3432V38.3106Z", fill: "#747486" }),
        React.createElement("path", { d: "M30.0273 71.0001H12.5732V53.8418C12.5732 48.9605 16.567 45.1147 21.3003 45.1147C26.1815 45.1147 30.0273 49.1085 30.0273 53.8418V71.0001Z", fill: "#415A6B" }),
        React.createElement("path", { d: "M57.9833 45.1147H35.2041V65.0835H57.9833V45.1147Z", fill: "#344A5E" }),
        React.createElement("path", { d: "M45.4108 47.4814H37.5713V62.7169H45.4108V47.4814Z", fill: "#8AD7F8" }),
        React.createElement("path", { d: "M55.6169 47.4814H47.7773V62.7169H55.6169V47.4814Z", fill: "#8AD7F8" }),
        React.createElement("path", { d: "M69.6687 32.2458L38.7542 1.33125C36.9792 -0.44375 33.873 -0.44375 32.098 1.33125L1.33125 32.2458C-0.44375 34.0208 -0.44375 37.127 1.33125 38.902C3.10625 40.677 6.2125 40.677 7.9875 38.902L35.5 11.3895L63.0125 38.902C64.7875 40.677 67.8937 40.677 69.6687 38.902C71.4437 36.9792 71.4437 34.0208 69.6687 32.2458Z", fill: "#747486" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "71", height: "71", fill: "white" }))))); };
/* SYMPTOM CHECK */
export var SymptomCheckTriageUrgentCareIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "90", height: "80", viewBox: "0 0 90 80", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M82.5387 37.166V68.8517C82.5387 74.9986 77.3901 79.9999 71.0617 79.9999H18.9384C12.6102 79.9999 7.46143 74.9986 7.46143 68.8517V37.166C7.46143 36.4779 7.74619 35.8205 8.25068 35.339L39.0499 5.93445C40.6899 4.36869 42.845 3.58496 45.0001 3.58496C47.1551 3.58496 49.3102 4.36869 50.9503 5.93445L81.7493 35.339C82.2539 35.8205 82.5387 36.4779 82.5387 37.166Z", fill: "#EFEFEF" }),
    React.createElement("path", { d: "M89.2447 42.5009C88.7279 43.0097 88.0459 43.2659 87.3638 43.2659C86.6959 43.2659 86.0297 43.0217 85.5164 42.5316L66.3849 24.2676L47.2535 6.0035C46.6313 5.4093 45.8156 5.11391 45 5.11391C44.1844 5.11391 43.3687 5.4093 42.7465 6.0035L4.48363 42.5316C3.44652 43.5237 1.77659 43.5083 0.755303 42.5009C-0.264229 41.4918 -0.250166 39.8697 0.788702 38.8776L39.0498 2.34949C40.6898 0.783733 42.8449 0 45 0C47.1551 0 49.3102 0.783733 50.9502 2.34949L89.2113 38.8776C90.2502 39.8697 90.2642 41.4918 89.2447 42.5009Z", style: { fill: 'var(--primary-color)' } }),
    React.createElement("path", { d: "M63.1415 42.624V52.8467C63.1415 53.7892 62.3557 54.5542 61.3837 54.5542H52.0214V63.6499C52.0214 64.5924 51.2339 65.3574 50.2636 65.3574H39.7395C38.7692 65.3574 37.9817 64.5924 37.9817 63.6499V54.5542H28.6177C27.6456 54.5542 26.8599 53.7892 26.8599 52.8467V42.624C26.8599 41.6815 27.6456 40.9165 28.6177 40.9165H37.9817V31.8208C37.9817 30.8782 38.7692 30.1133 39.7395 30.1133H50.2637C51.2341 30.1133 52.0216 30.8782 52.0216 31.8208V40.9165H61.3838C62.3557 40.9165 63.1415 41.6815 63.1415 42.624Z", style: { fill: 'var(--primary-color)' } }),
    React.createElement("path", { d: "M63.1408 42.624V52.8467C63.1408 53.7892 62.3551 54.5542 61.383 54.5542H52.0207V63.6499C52.0207 64.5924 51.2332 65.3574 50.2629 65.3574H45V30.1133H50.2629C51.2332 30.1133 52.0207 30.8782 52.0207 31.8208V40.9165H61.383C62.3551 40.9165 63.1408 41.6815 63.1408 42.624Z", style: { fill: 'var(--primary-color)' } }),
    React.createElement("path", { d: "M89.2447 42.5009C88.7279 43.0097 88.0459 43.2659 87.3638 43.2659C86.6959 43.2659 86.0297 43.0217 85.5164 42.5316L47.2535 6.0035C46.6313 5.4093 45.8156 5.1139 45 5.1139V0C47.1551 0 49.3102 0.783733 50.9502 2.34949L89.2113 38.8776C90.2502 39.8697 90.2642 41.4918 89.2447 42.5009Z", style: { fill: 'var(--primary-color)' } }))); };
export var SymptomCheckTriageDoctorIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "90", height: "90", viewBox: "0 0 90 90", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M36.0879 55.6052L26.5253 56.2219C26.5253 56.2219 9.55889 35.0049 22.4469 12.5158C31.947 -4.0566 49.1163 -0.443641 57.4915 2.60195C57.4915 2.60195 66.8324 1.72391 68.4787 16.1155C70.125 30.5071 65.3936 48.3044 55.0079 57.6607L36.0879 55.6052Z", fill: "#414451" }),
        React.createElement("path", { d: "M57.9504 67.5695L52.2665 78.3953L41.2913 79.7035L31.7648 75.7219L30.5312 66.2625L32.9336 59.3482L33.6163 57.3079C34.1328 56.7423 34.4832 56.045 34.6282 55.2929C34.7498 54.8282 34.8377 54.3554 34.8917 53.8782C34.9411 53.4918 34.9817 53.0967 35.0015 52.6852C35.1117 50.4005 35.0235 48.1107 34.738 45.8412C34.738 45.8412 56.0298 36.0361 55.0651 45.8412C54.1004 55.6464 56.0024 56.7165 56.0024 56.7165L57.9504 67.5695Z", fill: "#F4BB87" }),
        React.createElement("path", { d: "M31.687 67.2266C31.687 67.2266 42.4941 72.5791 58.8603 67.2266V89.9999H35.4953L31.687 67.2266Z", fill: "#69B0EE" }),
        React.createElement("path", { d: "M52.1976 14.9209C52.1976 14.9209 51.5808 17.5253 47.4673 18.2805C45.5552 18.6778 43.6798 19.2354 41.8613 19.9477C37.1362 21.6705 33.2983 25.2146 31.2055 29.7879C29.6016 33.5007 28.9922 37.5674 29.4375 41.5872C29.4375 41.5872 36.4999 53.5149 45.0001 53.4459C53.5003 53.3766 60.6945 41.9978 61.9326 38.1597C61.9326 38.1597 64.1945 38.3308 65.3261 34.9375C65.8814 33.4091 66.1546 31.7926 66.1327 30.1667C66.1215 29.7488 65.9695 29.347 65.7014 29.0264C64.8533 28.1399 63.5616 27.8386 62.4089 28.2582C62.4089 28.2578 54.7338 25.2034 52.1976 14.9209V14.9209Z", fill: "#FED19A" }),
        React.createElement("path", { d: "M56.0121 56.7119L48.3296 90.0004H77.9383C79.8973 90.0004 81.4855 88.4123 81.4855 86.4532V76.7041C81.4853 68.0676 74.7431 60.9334 66.1204 60.4455L62.1924 60.2261C59.9754 60.1005 57.9138 59.0482 56.5116 57.3265L56.0121 56.7119Z", fill: "#EEEFEE" }),
        React.createElement("path", { d: "M34.0127 56.8232L41.6756 89.9999H12.0614C10.1024 89.9999 8.51416 88.4119 8.51416 86.4527V76.7036C8.51434 68.0672 15.2566 60.9329 23.8793 60.445L27.8073 60.2256C30.0243 60.1 32.0859 59.0477 33.4879 57.326L34.0127 56.8232Z", fill: "#EEEFEE" }),
        React.createElement("path", { d: "M65.9622 63.793C61.536 63.793 58.0601 69.6668 58.0601 77.1683C57.9907 80.41 58.7435 83.6164 60.2485 86.4883C60.6453 87.1972 61.1242 87.8569 61.6754 88.4539C62.2859 88.4368 62.8241 88.0486 63.033 87.4748C62.4659 86.8819 61.9855 86.2116 61.6062 85.4839C60.3033 82.9087 59.6511 80.0536 59.7063 77.1681C59.7063 70.8092 62.5687 65.438 65.9622 65.438C69.3558 65.438 72.2181 70.8092 72.2181 77.1681C72.2677 80.0569 71.6125 82.9141 70.3094 85.4927C69.9529 86.1758 69.5045 86.8067 68.9771 87.3683C69.136 87.9599 69.6495 88.3878 70.2601 88.4373C70.8364 87.803 71.3362 87.103 71.7493 86.352C73.2025 83.5127 73.9292 80.3571 73.8642 77.1681C73.8644 69.6669 70.3906 63.793 65.9622 63.793V63.793Z", fill: "#BBBBCD" }),
        React.createElement("path", { d: "M71.8803 86.9665C71.8779 87.788 71.2125 88.4534 70.391 88.4558C70.3464 88.4585 70.3017 88.4528 70.2593 88.4392C69.6488 88.3897 69.1352 87.9618 68.9764 87.3702C68.9294 87.2413 68.907 87.1047 68.9105 86.9674C68.9067 86.1812 69.5234 85.5315 70.3087 85.4944C70.3357 85.4878 70.3634 85.4853 70.391 85.4867C71.2099 85.4831 71.8766 86.1441 71.8803 86.9628V86.9672V86.9665Z", fill: "#575B6D" }),
        React.createElement("path", { d: "M63.135 86.9668C63.1364 87.1418 63.1028 87.3153 63.0362 87.4771C62.8272 88.0509 62.289 88.439 61.6785 88.4561H61.6457C60.826 88.4524 60.1639 87.7865 60.1652 86.9668C60.1628 86.8039 60.1907 86.642 60.2474 86.4894C60.4437 85.8993 60.9909 85.4973 61.6128 85.4863H61.6457C62.4653 85.485 63.1315 86.1472 63.135 86.9668V86.9668Z", fill: "#575B6D" }),
        React.createElement("path", { d: "M65.9622 65.4414C65.5948 65.4406 65.2724 65.1966 65.172 64.8431C64.5649 63.0243 63.4161 61.4345 61.8794 60.2873C61.5072 60.027 61.4165 59.5143 61.6769 59.1421C61.9373 58.7699 62.4499 58.6792 62.8221 58.9396C64.6727 60.2996 66.048 62.2083 66.7523 64.3942C66.8753 64.8325 66.6198 65.2875 66.1817 65.4105C66.1095 65.4307 66.0349 65.4412 65.96 65.4412H65.9622V65.4414Z", fill: "#BBBBCD" }),
        React.createElement("path", { d: "M35.0125 52.6846C34.9873 53.096 34.9465 53.4912 34.9027 53.8775C34.8487 54.3547 34.7607 54.8275 34.6392 55.2922C33.2125 56.4358 24.2984 64.2008 24.7847 79.6746C24.8001 80.1309 24.443 80.5135 23.9867 80.5295H23.9615C23.517 80.5286 23.1531 80.1759 23.1384 79.7317C22.7194 69.3682 27.0998 59.3901 35.0125 52.6846V52.6846Z", fill: "#BBBBCD" }),
        React.createElement("path", { d: "M23.7996 87.068C26.0721 87.068 27.9142 85.2259 27.9142 82.9535C27.9142 80.681 26.0721 78.8389 23.7996 78.8389C21.5272 78.8389 19.6851 80.681 19.6851 82.9535C19.6851 85.2259 21.5272 87.068 23.7996 87.068Z", fill: "#575B6D" }),
        React.createElement("path", { d: "M23.8001 85.194C25.0373 85.194 26.0402 84.1911 26.0402 82.9539C26.0402 81.7168 25.0373 80.7139 23.8001 80.7139C22.563 80.7139 21.5601 81.7168 21.5601 82.9539C21.5601 84.1911 22.563 85.194 23.8001 85.194Z", fill: "#BBBBCD" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "90", height: "90", fill: "white" }))))); };
export var SymptomCheckVirtualVisitIcon = function () { return (React.createElement("svg", { width: "54", height: "50", viewBox: "0 0 54 50", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M35.0044 40.7879L27.2582 38.2822L19.5125 40.7879L18.354 45.2353L27.2582 47.6178L36.1624 45.2353L35.0044 40.7879Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M53.3333 35.776L27.5232 33.6768L0 35.776V37.9675C0 39.5182 1.2679 40.7878 2.81616 40.7878H50.5171C52.0654 40.7878 53.3333 39.5182 53.3333 37.9675V35.776Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.81616 0.0108507C1.2679 0.0108507 0 1.28038 0 2.83108V35.7758H53.3333V2.83108C53.3333 1.28038 52.0654 0.0352647 50.5171 0.0108507C48.9933 -0.0135634 10.0606 0.0108507 2.81616 0.0108507Z", style: { fill: 'var(--primary-color)' } }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M49.2279 4.7238V31.4564C49.2279 31.7864 48.9598 32.0578 48.6277 32.0578H4.68123C4.35286 32.0578 4.08105 31.7864 4.08105 31.4564V4.7238C4.08105 4.39095 4.35246 4.11914 4.68123 4.11914H48.6277C48.9598 4.11914 49.2279 4.39095 49.2279 4.7238Z", fill: "#E8E8EB" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.1623 45.2354H15.8062C14.4985 45.2354 13.4287 46.3067 13.4287 47.6161V48.9227C13.4287 49.5147 13.9105 50.0002 14.5017 50.0002H40.0176C40.606 50.0002 41.0906 49.5147 41.0906 48.9227V47.6161C41.0906 46.3067 40.0176 45.2354 38.7103 45.2354H36.1623Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.5649 45.2354C35.8756 45.2354 36.9453 46.3067 36.9453 47.6161V48.9227C36.9453 49.5147 36.4607 50.0002 35.8723 50.0002H40.0174C40.6058 50.0002 41.0904 49.5147 41.0904 48.9227V47.6161C41.0904 46.3067 40.0174 45.2354 38.71 45.2354H34.5649Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.8594 40.7871H19.5126L18.8848 43.2078H29.1191C30.387 43.2078 31.6 43.1557 31.8287 44.0195L32.0175 45.2345H36.1626L35.0045 40.7871H30.8594Z", fill: "#545763" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.8035 22.4975L35.1381 21.2369C36.3511 20.0036 36.4853 20.1867 38.2379 20.1867H41.9744C42.6784 20.1867 43.2513 19.6101 43.2513 18.9078V11.0448C43.2513 10.3397 42.6784 9.7627 41.9744 9.7627H29.3931C28.692 9.7627 28.1162 10.3397 28.1162 11.0448V18.9078C28.1162 19.6101 28.692 20.1867 29.3931 20.1867H30.8835C31.6546 20.1867 31.6546 20.1867 31.6546 21.0904V21.9543C31.6546 22.7445 32.6938 23.5473 33.8035 22.4975Z", fill: "#ECEFF1" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M41.0142 11.0448V18.9078C41.0142 19.6101 40.4412 20.1867 39.7373 20.1867H41.9744C42.6784 20.1867 43.2513 19.6101 43.2513 18.9078V11.0448C43.2513 10.3397 42.6784 9.7627 41.9744 9.7627H39.7373C40.4412 9.7627 41.0142 10.3397 41.0142 11.0448Z", fill: "#C4CCCF" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.7527 32.0578H29.3169C29.0027 26.5724 24.5378 23.8404 20.3378 23.2944C19.2896 22.8423 17.4455 22.8333 16.6167 23.2883C12.6026 23.8071 7.56152 26.7433 7.56152 32.0578H19.7527Z", fill: "white" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.4648 14.8937H21.033C18.6986 14.8937 17.7233 14.3138 16.9095 13.8195C15.7909 13.1416 15.535 12.6289 14.569 14.0209C14.1393 14.6406 13.7979 14.8937 13.2128 14.8937H12.4629V18.1448C12.4629 20.5101 14.3371 22.5401 16.6169 23.288C17.2602 23.4772 17.7904 23.5932 18.4639 23.5965C19.1011 23.6026 19.735 23.4927 20.3385 23.2941C20.8931 23.111 21.4233 22.8486 21.9079 22.5251C23.4012 21.527 24.4648 19.9303 24.4648 18.1448V14.8937Z", fill: "#FFDDA6" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.4648 15.8864C24.4925 15.5873 24.4681 15.1723 24.4648 14.8944C24.4193 6.63796 12.4629 7.7427 12.4629 14.5131V15.8864H13.2128C13.7979 15.8864 14.1393 15.6333 14.569 15.0136C15.535 13.6216 15.7909 14.1343 16.9095 14.8122C17.7233 15.3066 18.6986 15.8864 21.033 15.8864H24.4648Z", fill: "black" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.2633 32.0583H29.3169C29.0028 26.5729 24.5378 23.841 20.3378 23.2949C19.7344 23.4931 19.1004 23.6034 18.4636 23.5968C18.3021 23.5968 18.1495 23.5907 18.0034 23.5757C22.002 24.8513 25.0074 27.6264 25.2633 32.0583Z", fill: "#F4F4F4" }),
    React.createElement("path", { d: "M18.5156 30.1948H18.4945C18.0465 30.1948 17.6831 29.8315 17.6831 29.3831C17.6831 28.9347 18.0465 28.5713 18.4945 28.5713H18.5156C18.9636 28.5713 19.327 28.9347 19.327 29.3831C19.327 29.8315 18.9636 30.1948 18.5156 30.1948Z", fill: "#545763" }),
    React.createElement("path", { d: "M12.7282 29.9026V32.0579H11.1055V29.9026C11.1055 29.455 11.4692 29.0908 11.9168 29.0908C12.3652 29.0908 12.7282 29.455 12.7282 29.9026Z", fill: "#7B7B7B" }),
    React.createElement("path", { d: "M18.439 27.3696H18.4178C17.9698 27.3696 17.6064 27.0063 17.6064 26.5579C17.6064 26.1095 17.9698 25.7461 18.4178 25.7461H18.439C18.887 25.7461 19.2503 26.1095 19.2503 26.5579C19.2503 27.0063 18.887 27.3696 18.439 27.3696Z", fill: "#545763" }),
    React.createElement("path", { d: "M25.7756 29.9026V32.0579H24.1528V29.9026C24.1528 29.455 24.5158 29.0908 24.9642 29.0908C25.4118 29.0908 25.7756 29.455 25.7756 29.9026Z", fill: "#7B7B7B" }),
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { d: "M34.5601 13.708L33.1947 15.0734C32.5464 15.7216 32.5464 16.7823 33.1947 17.4304C33.8429 18.0786 34.9035 18.0786 35.5516 17.4304L36.9171 16.065L34.5601 13.708Z", fill: "#747486" }),
        React.createElement("path", { d: "M36.9191 16.0626L38.2637 14.7181C38.9118 14.0699 38.9118 13.0093 38.2637 12.3612C37.6154 11.7129 36.5548 11.7129 35.9067 12.3612L34.562 13.7055L36.9191 16.0626Z", fill: "#545763" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { x: "32.7085", y: "11.875", width: "6.04124", height: "6.04124", fill: "white" }))))); };
