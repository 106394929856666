import * as React from 'react';
import { logEvent } from '../../../util/EventLog';
import { getAppPath } from '../../../util/ProxyPath';
import { MessageType } from '../proto/logging_pb';
import styles from './VirtualVisitOptionsForCoronavirus.css';
import { THEME } from '../util/Theme';
import LinkWithArrow from '../../tailwind_common/LinkWithArrow';
var startVirtualVisitText = 'Start virtual visit';
var VirtualVisitProvider;
(function (VirtualVisitProvider) {
    VirtualVisitProvider["DOD"] = "Doctor on Demand";
    VirtualVisitProvider["MDLIVE"] = "MDLIVE";
    VirtualVisitProvider["TELADOC"] = "Teladoc";
})(VirtualVisitProvider || (VirtualVisitProvider = {}));
var VirtualVisitOptionsForCoronavirus = function () {
    var logStartVirtualVisit = function (provider) {
        logEvent(MessageType.CLICKED_PARTNER_CARE_OPTION, {
            name: "Start virtual visit with ".concat(provider),
        }, { partner: THEME.partner });
    };
    return (React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus },
        React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__option },
            React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__image },
                React.createElement("img", { src: getAppPath('/static/images/partners/doctorondemand/conversation_logo.png'), alt: VirtualVisitProvider.DOD })),
            React.createElement(LinkWithArrow, { text: startVirtualVisitText, href: "https://www.doctorondemand.com/", openNewTab: true, onClick: function () { return logStartVirtualVisit(VirtualVisitProvider.DOD); } })),
        React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__option },
            React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__image },
                React.createElement("img", { src: getAppPath('/static/images/app/mdlive.png'), alt: VirtualVisitProvider.MDLIVE })),
            React.createElement(LinkWithArrow, { text: startVirtualVisitText, href: "https://patient.mdlive.com/register#/step-1", openNewTab: true, onClick: function () { return logStartVirtualVisit(VirtualVisitProvider.MDLIVE); } })),
        React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__option },
            React.createElement("div", { className: styles.virtualVisitOptionsForCoronavirus__image },
                React.createElement("img", { src: getAppPath('/static/images/app/teladoc.png'), alt: VirtualVisitProvider.TELADOC })),
            React.createElement(LinkWithArrow, { text: startVirtualVisitText, href: "https://member.teladoc.com/signin", openNewTab: true, onClick: function () { return logStartVirtualVisit(VirtualVisitProvider.TELADOC); } }))));
};
export default VirtualVisitOptionsForCoronavirus;
