var _a;
import { getCareDetailSelectOptions } from './CareDetail.util';
export var SpecialistCareDetail;
(function (SpecialistCareDetail) {
    SpecialistCareDetail["SPORTS_MEDICINE"] = "sports-medicine";
    SpecialistCareDetail["FINGER_SPECIALIST"] = "finger-specialist";
    SpecialistCareDetail["HIP_SPECIALIST"] = "hip-specialist";
    SpecialistCareDetail["SHOULDER_SPECIALIST"] = "shoulder-specialist";
    SpecialistCareDetail["KNEE_SPECIALIST"] = "knee-specialist";
    SpecialistCareDetail["FOOT_SPECIALIST"] = "foot-specialist";
    SpecialistCareDetail["ANKLE_SPECIALIST"] = "ankle-specialist";
    SpecialistCareDetail["HAND_SPECIALIST"] = "hand-specialist";
    SpecialistCareDetail["WRIST_SPECIALIST"] = "wrist-specialist";
    SpecialistCareDetail["FOREARM_SPECIALIST"] = "forearm-specialist";
    SpecialistCareDetail["UPPER_ARM_SPECIALIST"] = "upper-arm-specialist";
    SpecialistCareDetail["ELBOW_SPECIALIST"] = "elbow-specialist";
    SpecialistCareDetail["ALLERGIST"] = "allergist";
    SpecialistCareDetail["CLINICAL_PSYCHOLOGIST"] = "clinical-psychologist";
    SpecialistCareDetail["THERAPIST"] = "therapist";
    SpecialistCareDetail["SUICIDE_LIFELINE"] = "suicide-lifeline";
    SpecialistCareDetail["PHYSICAL_THERAPIST"] = "physical-therapist";
    SpecialistCareDetail["PAIN_AND_SPINE"] = "pain-and-spine";
    SpecialistCareDetail["CHIROPRACTOR"] = "chiropractor";
    SpecialistCareDetail["UROLOGIST"] = "urology";
    SpecialistCareDetail["DERMATOLOGY"] = "dermatology";
    SpecialistCareDetail["ENT"] = "otolaryngology-ent";
    SpecialistCareDetail["NEUROLOGY"] = "neurology";
    SpecialistCareDetail["CARDIOLOGY"] = "cardiology";
    SpecialistCareDetail["SPINE_SPECIALIST"] = "spine-specialist";
    SpecialistCareDetail["GENERAL_ORTHOPEDIC"] = "general-orthopedic";
    SpecialistCareDetail["OPHTHALMOLOGIST"] = "ophthalmologist";
    SpecialistCareDetail["OBGYN"] = "obstetrics-gynecology";
    SpecialistCareDetail["PULMONOLOGIST"] = "pulmonologist";
    SpecialistCareDetail["IMAGING_TEST"] = "imaging-test";
    SpecialistCareDetail["ALLERGY"] = "allergy";
    SpecialistCareDetail["ANESTHESIOLOGY"] = "anesthesiology";
    SpecialistCareDetail["AUDIOLOGY"] = "audiology";
    SpecialistCareDetail["BARIATRIC_SURGERY"] = "bariatric-surgery";
    SpecialistCareDetail["CANCER_CENTER"] = "cancer-center";
    SpecialistCareDetail["CARDIAC_SURGERY"] = "cardiac-surgery";
    SpecialistCareDetail["CARDIOVASCULAR_CENTER"] = "cardiovascular-center";
    SpecialistCareDetail["CLINICAL_DECISION_MAKING"] = "clinical-decision-making";
    SpecialistCareDetail["COLORECTAL_SURGERY"] = "colorectal-surgery";
    SpecialistCareDetail["CONCIERGE_MEDICINE"] = "concierge-medicine";
    SpecialistCareDetail["CLINICAL_NUTRITION"] = "clinical-nutrition";
    SpecialistCareDetail["DENTISTRY"] = "dentistry";
    SpecialistCareDetail["DEPARTMENT_OF_MEDICINE"] = "department-of-medicine";
    SpecialistCareDetail["DEPARTMENT_OF_PEDIATRICS"] = "department-of-pediatrics";
    SpecialistCareDetail["DEPARTMENT_OF_SURGERY"] = "department-of-surgery";
    SpecialistCareDetail["INTERNAL_MEDICINE"] = "internal-medicine";
    SpecialistCareDetail["PEDIATRICS"] = "pediatrics";
    SpecialistCareDetail["GENERAL_SURGERY"] = "general-surgery";
    SpecialistCareDetail["EMERGENCY_SERVICES"] = "emergency-services";
    SpecialistCareDetail["OTOLARYNGOLOGY"] = "otolaryngology";
    SpecialistCareDetail["ENDOCRINOLOGY"] = "endocrinology";
    SpecialistCareDetail["GAMMA_KNIFE"] = "radiation-oncologist";
    SpecialistCareDetail["GASTROENTEROLOGY"] = "gastroenterology";
    SpecialistCareDetail["GENETICS"] = "genetics";
    SpecialistCareDetail["INFECTIOUS_DISEASE"] = "infectious-disease";
    SpecialistCareDetail["GERIATRICS"] = "geriatrics";
    SpecialistCareDetail["HEMATOLOGY_ONCOLOGY"] = "hematology-oncology";
    SpecialistCareDetail["HYPERBARIC_MEDICINE"] = "hyperbaric-medicine";
    SpecialistCareDetail["NEPHROLOGY"] = "nephrology";
    SpecialistCareDetail["NEUROSURGERY"] = "neurosurgery";
    SpecialistCareDetail["OCCUPATIONAL_THERAPY"] = "occupational-therapy";
    SpecialistCareDetail["OPHTHALMOLOGY"] = "ophthalmology";
    SpecialistCareDetail["ORTHOTICS_PROSTHETICS"] = "orthotics-prosthetics";
    SpecialistCareDetail["PAIN_MANAGEMENT"] = "pain-management";
    SpecialistCareDetail["PALLIATIVE_CARE"] = "palliative-care";
    SpecialistCareDetail["PATHOLOGY"] = "pathology";
    SpecialistCareDetail["PELVIC_FLOOR"] = "pelvic-floor";
    SpecialistCareDetail["PHARMACY"] = "pharmacy";
    SpecialistCareDetail["PHYSICAL_MEDICINE"] = "physical-medicine";
    SpecialistCareDetail["PHYSICAL_THERAPY"] = "physical-therapy";
    SpecialistCareDetail["PLASTIC_SURGERY"] = "plastic-surgery";
    SpecialistCareDetail["RADIOLOGY"] = "radiology";
    SpecialistCareDetail["PSYCHIATRY"] = "psychiatry";
    SpecialistCareDetail["PULMONOLOGY"] = "pulmonology";
    SpecialistCareDetail["RADIATION_ONCOLOGY"] = "radiation-oncology";
    SpecialistCareDetail["RHEUMATOLOGY"] = "rheumatology";
    SpecialistCareDetail["SPEECH_THERAPY"] = "speech-therapy";
    SpecialistCareDetail["SPINE_CENTER"] = "spine-center";
    SpecialistCareDetail["ACUTE_SURGERY"] = "acute-surgery";
    SpecialistCareDetail["SURGICAL_ONCOLOGY"] = "surgical-oncology";
    SpecialistCareDetail["THORACIC_SURGERY"] = "thoracic-surgery";
    SpecialistCareDetail["TRANSPLANT_SURGERY"] = "transplant-surgery";
    SpecialistCareDetail["TRAUMA_SURGERY"] = "trauma-surgery";
    SpecialistCareDetail["UROLOGY"] = "urology";
    SpecialistCareDetail["VASCULAR_SURGERY"] = "vascular-surgery";
    SpecialistCareDetail["VOLUNTEER_SERVICES"] = "volunteer-services";
    SpecialistCareDetail["WEIGHT_WELLNESS"] = "weight-wellness";
    SpecialistCareDetail["WOUND_CARE"] = "wound-care";
    SpecialistCareDetail["PODIATRY"] = "podiatry";
})(SpecialistCareDetail || (SpecialistCareDetail = {}));
export var SpecialistCareDetailLabel = (_a = {},
    _a[SpecialistCareDetail.SPORTS_MEDICINE] = 'Sports Medicine',
    _a[SpecialistCareDetail.FINGER_SPECIALIST] = 'Finger Specialist',
    _a[SpecialistCareDetail.HIP_SPECIALIST] = 'Hip Specialist',
    _a[SpecialistCareDetail.SHOULDER_SPECIALIST] = 'Shoulder Specialist',
    _a[SpecialistCareDetail.KNEE_SPECIALIST] = 'Orthopedic Knee Surgeon',
    _a[SpecialistCareDetail.FOOT_SPECIALIST] = 'Foot Specialist',
    _a[SpecialistCareDetail.ANKLE_SPECIALIST] = 'Ankle Specialist',
    _a[SpecialistCareDetail.HAND_SPECIALIST] = 'Hand Specialist',
    _a[SpecialistCareDetail.WRIST_SPECIALIST] = 'Wrist Specialist',
    _a[SpecialistCareDetail.FOREARM_SPECIALIST] = 'Forearm Specialist',
    _a[SpecialistCareDetail.UPPER_ARM_SPECIALIST] = 'Upper Arm Specialist',
    _a[SpecialistCareDetail.ELBOW_SPECIALIST] = 'Elbow Specialist',
    _a[SpecialistCareDetail.ALLERGIST] = 'Allergist',
    _a[SpecialistCareDetail.CLINICAL_PSYCHOLOGIST] = 'Clinical Psychologist',
    _a[SpecialistCareDetail.THERAPIST] = 'Therapist',
    _a[SpecialistCareDetail.SUICIDE_LIFELINE] = 'Suicide Lifeline',
    _a[SpecialistCareDetail.PHYSICAL_THERAPIST] = 'Physical Therapist',
    _a[SpecialistCareDetail.PAIN_AND_SPINE] = 'Pain and Spine',
    _a[SpecialistCareDetail.CHIROPRACTOR] = 'Chiropractor',
    _a[SpecialistCareDetail.UROLOGIST] = 'Urologist',
    _a[SpecialistCareDetail.DERMATOLOGY] = 'Dermatologist',
    _a[SpecialistCareDetail.ENT] = 'ENT (Ear, Nose, and Throat) Specialist',
    _a[SpecialistCareDetail.NEUROLOGY] = 'Neurologist',
    _a[SpecialistCareDetail.CARDIOLOGY] = 'Cardiologist',
    _a[SpecialistCareDetail.SPINE_SPECIALIST] = 'Spine Specialist',
    _a[SpecialistCareDetail.GENERAL_ORTHOPEDIC] = 'Orthopedic Surgeon',
    _a[SpecialistCareDetail.OPHTHALMOLOGIST] = 'Eye Doctor (Ophthalmologist)',
    _a[SpecialistCareDetail.OBGYN] = 'OB/GYN (Women’s Health provider)',
    _a[SpecialistCareDetail.PULMONOLOGIST] = 'Pulmonologist (lung specialist)',
    _a[SpecialistCareDetail.IMAGING_TEST] = 'Imaging Center',
    _a[SpecialistCareDetail.ALLERGY] = 'Allergist',
    _a[SpecialistCareDetail.ANESTHESIOLOGY] = 'Anesthesiologist',
    _a[SpecialistCareDetail.AUDIOLOGY] = 'Audiologist',
    _a[SpecialistCareDetail.BARIATRIC_SURGERY] = 'Bariatric Surgeon',
    _a[SpecialistCareDetail.CANCER_CENTER] = 'Cancer Center',
    _a[SpecialistCareDetail.CARDIAC_SURGERY] = 'Cardiac Surgeon',
    _a[SpecialistCareDetail.CARDIOVASCULAR_CENTER] = 'Cardiovascular Center',
    _a[SpecialistCareDetail.CLINICAL_DECISION_MAKING] = 'Clinical Decision Making',
    _a[SpecialistCareDetail.COLORECTAL_SURGERY] = 'Colorectal Surgeon',
    _a[SpecialistCareDetail.CONCIERGE_MEDICINE] = 'Diagnostician or Radiologist',
    _a[SpecialistCareDetail.CLINICAL_NUTRITION] = 'Clinical Nutritionist',
    _a[SpecialistCareDetail.DENTISTRY] = 'Dentist',
    _a[SpecialistCareDetail.DEPARTMENT_OF_MEDICINE] = 'Department of Medicine',
    _a[SpecialistCareDetail.DEPARTMENT_OF_PEDIATRICS] = 'Department of Pediatrics',
    _a[SpecialistCareDetail.DEPARTMENT_OF_SURGERY] = 'Department of Surgery',
    _a[SpecialistCareDetail.INTERNAL_MEDICINE] = 'Internist or Primary Care Physician',
    _a[SpecialistCareDetail.PEDIATRICS] = 'Pediatrician',
    _a[SpecialistCareDetail.GENERAL_SURGERY] = 'General Surgeon',
    _a[SpecialistCareDetail.EMERGENCY_SERVICES] = 'Emergency Services',
    _a[SpecialistCareDetail.OTOLARYNGOLOGY] = 'Otolaryngologist',
    _a[SpecialistCareDetail.ENDOCRINOLOGY] = 'Endocrinologist',
    _a[SpecialistCareDetail.GASTROENTEROLOGY] = 'Gastroenterologist',
    _a[SpecialistCareDetail.GAMMA_KNIFE] = 'Radiation Oncologist',
    _a[SpecialistCareDetail.GENETICS] = 'Geneticist',
    _a[SpecialistCareDetail.INFECTIOUS_DISEASE] = 'Infectious Disease Specialist',
    _a[SpecialistCareDetail.GERIATRICS] = 'Geriatrician',
    _a[SpecialistCareDetail.HEMATOLOGY_ONCOLOGY] = 'Hematology Oncologist',
    _a[SpecialistCareDetail.HYPERBARIC_MEDICINE] = 'Hyperbaric Medicine Specialist',
    _a[SpecialistCareDetail.NEPHROLOGY] = 'Nephrologist',
    _a[SpecialistCareDetail.NEUROSURGERY] = 'Neurosurgeon',
    _a[SpecialistCareDetail.OCCUPATIONAL_THERAPY] = 'Occupational Therapist',
    _a[SpecialistCareDetail.OPHTHALMOLOGY] = 'Ophthalmologist',
    _a[SpecialistCareDetail.ORTHOTICS_PROSTHETICS] = 'Orthotics Prosthetics Specialist',
    _a[SpecialistCareDetail.PAIN_MANAGEMENT] = 'Pain Management Specialist',
    _a[SpecialistCareDetail.PALLIATIVE_CARE] = 'Palliative Care Specialist',
    _a[SpecialistCareDetail.PATHOLOGY] = 'Pathologist',
    _a[SpecialistCareDetail.PELVIC_FLOOR] = 'Center for Pelvic Health',
    _a[SpecialistCareDetail.PHARMACY] = 'Pharmacist',
    _a[SpecialistCareDetail.PHYSICAL_MEDICINE] = 'Physical Medicine and Rehabilitation Physician',
    _a[SpecialistCareDetail.PHYSICAL_THERAPY] = 'Physical Therapist',
    _a[SpecialistCareDetail.PLASTIC_SURGERY] = 'Plastic Surgeon',
    _a[SpecialistCareDetail.RADIOLOGY] = 'Radiologist',
    _a[SpecialistCareDetail.PSYCHIATRY] = 'Psychiatrist',
    _a[SpecialistCareDetail.PULMONOLOGY] = 'Pulmonologist',
    _a[SpecialistCareDetail.RADIATION_ONCOLOGY] = 'Radiation Oncologist',
    _a[SpecialistCareDetail.RHEUMATOLOGY] = 'Rheumatologist',
    _a[SpecialistCareDetail.SPEECH_THERAPY] = 'Speech Therapy',
    _a[SpecialistCareDetail.SPINE_CENTER] = 'Spine Center',
    _a[SpecialistCareDetail.ACUTE_SURGERY] = 'Acute Surgery',
    _a[SpecialistCareDetail.SURGICAL_ONCOLOGY] = 'Surgical Oncologist',
    _a[SpecialistCareDetail.THORACIC_SURGERY] = 'Thoracic Surgeon',
    _a[SpecialistCareDetail.TRANSPLANT_SURGERY] = 'Transplant Surgeon',
    _a[SpecialistCareDetail.TRAUMA_SURGERY] = 'Trauma Surgeon',
    _a[SpecialistCareDetail.UROLOGY] = 'Urologist',
    _a[SpecialistCareDetail.VASCULAR_SURGERY] = 'Vascular Surgeon',
    _a[SpecialistCareDetail.VOLUNTEER_SERVICES] = 'Volunteer Services',
    _a[SpecialistCareDetail.WEIGHT_WELLNESS] = 'Weight Wellness',
    _a[SpecialistCareDetail.WOUND_CARE] = 'Wound Care',
    _a[SpecialistCareDetail.PODIATRY] = 'Podiatry',
    _a);
export var SpecialistCareDetailSelectOptions = getCareDetailSelectOptions(SpecialistCareDetail, SpecialistCareDetailLabel);
