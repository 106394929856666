import * as React from 'react';
import classNames from 'classnames';
import styles from './ConversationLoading.css';
var ConversationLoading = function (props) {
    var _a;
    var small = props.small;
    return (React.createElement("div", { className: classNames(styles.conversationLoading, (_a = {},
            _a[styles['conversationLoading--small']] = small,
            _a)) },
        React.createElement("div", { className: classNames(styles.conversationLoading__bubble, styles.conversationLoading__bubble_1) }),
        React.createElement("div", { className: classNames(styles.conversationLoading__bubble, styles.conversationLoading__bubble_2) }),
        React.createElement("div", { className: classNames(styles.conversationLoading__bubble, styles.conversationLoading__bubble_3) })));
};
export default ConversationLoading;
