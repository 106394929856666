var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createContext } from 'react';
import { Partner } from '../../../clearstep/proto/partners_pb';
import { THEME } from '../../../clearstep/util/Theme';
import './BasePartner';
function getClearstepPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./ClearstepPartner/ClearstepPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.ClearstepPartner()];
            }
        });
    });
}
function getMountainPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Mountain/MountainPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MountainPartner()];
            }
        });
    });
}
function getCVSPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./CVSPartner/CVSPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.CVSPartner()];
            }
        });
    });
}
function getBaycarePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Baycare/BayCarePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.BayCarePartner()];
            }
        });
    });
}
function getCorewellPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./CorewellHealth/CorewellHealthPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.CorewellHealthPartner()];
            }
        });
    });
}
function getRiversideWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/RiversideMedWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.RiversideMedWellforcePartner()];
            }
        });
    });
}
function getMillCityMedWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/MillCityMedWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MillCityMedWellforcePartner()];
            }
        });
    });
}
function getGilchristMDWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/GilchristMDWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.GilchristMDWellforcePartner()];
            }
        });
    });
}
function getMerrimackFamilyMedWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/MerrimackFamilyMedWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MerrimackFamilyMedWellforcePartner()];
            }
        });
    });
}
function getMerrimackValleyInternalMedWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/MerrimackValleyInternalMedWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MerrimackValleyInternalMedWellforcePartner()];
            }
        });
    });
}
function getChelmsfordFamilyWellforcePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./WellforcePartner/ChelmsfordFamilyWellforcePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.ChelmsfordFamilyWellforcePartner()];
            }
        });
    });
}
function getHcaTristarPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCATristar')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCATristar()];
            }
        });
    });
}
function getHcaMedicalCityPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCAMedicalCity')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCAMedicalCity()];
            }
        });
    });
}
function getHcaHealthOnePartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCAHealthOne')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCAHealthOne()];
            }
        });
    });
}
function getHcaLasVegasPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCALasVegas')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCALasVegas()];
            }
        });
    });
}
function getHcaFloridaPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCAFlorida')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCAFlorida()];
            }
        });
    });
}
function getHcaSanAntonioPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./HCA/HCASanAntonio')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.HCASanAntonio()];
            }
        });
    });
}
function getUnimplementedPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./UnimplementedPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.UnimplementedPartner()];
            }
        });
    });
}
function getNovantPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Novant/Novant')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.NovantPartner()];
            }
        });
    });
}
function getPennPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Penn/Penn')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.PennPartner()];
            }
        });
    });
}
function getMaHealthPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./MaHealth/MaHealth')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MaHealthPartner()];
            }
        });
    });
}
function getNorwellPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Northwell/Northwell')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.NorthwellPartner()];
            }
        });
    });
}
function getOIPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./OrthopedicInstitutePartner/OrthopedicInstitutePartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.OrthpedicInstitutePartner()];
            }
        });
    });
}
function getMountSinaiPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./MountSinaiPartner/MountSinaiPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.MountSinaiPartner()];
            }
        });
    });
}
function getArcPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Arc/Arc')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.ArcPartner()];
            }
        });
    });
}
function getTuftsPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Tufts/TuftsPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.TuftsPartner()];
            }
        });
    });
}
function getBSWHPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./BSWHPartner/BSWHPartner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.BSWHPartner()];
            }
        });
    });
}
function getOchsnerPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Ochsner/Ochsner')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.OchsnerPartner()];
            }
        });
    });
}
function getDemoPartner() {
    return __awaiter(this, void 0, void 0, function () {
        var partner;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, import('./Demo/Demo')];
                case 1:
                    partner = _a.sent();
                    return [2 /*return*/, new partner.DemoPartner()];
            }
        });
    });
}
function getPartnerCustomizations() {
    return __awaiter(this, void 0, Promise, function () {
        var partner;
        return __generator(this, function (_a) {
            partner = THEME.partner;
            switch (partner) {
                case Partner.CLEARSTEP:
                    return [2 /*return*/, getClearstepPartner()];
                case Partner.MOUNTAIN:
                    return [2 /*return*/, getMountainPartner()];
                case Partner.CVS:
                    return [2 /*return*/, getCVSPartner()];
                case Partner.BAYCARE:
                    return [2 /*return*/, getBaycarePartner()];
                case Partner.RIVERSIDEMEDWELLFORCE:
                    return [2 /*return*/, getRiversideWellforcePartner()];
                case Partner.MILLCITYMEDWELLFORCE:
                    return [2 /*return*/, getMillCityMedWellforcePartner()];
                case Partner.GILCHRISTMDWELLFORCE:
                    return [2 /*return*/, getGilchristMDWellforcePartner()];
                case Partner.MERRIMACKFAMILYMEDWELLFORCE:
                    return [2 /*return*/, getMerrimackFamilyMedWellforcePartner()];
                case Partner.MERRIMACKVALLEYINTERNALMEDWELLFORCE:
                    return [2 /*return*/, getMerrimackValleyInternalMedWellforcePartner()];
                case Partner.CHELMSFORDFAMILYWELLFORCE:
                    return [2 /*return*/, getChelmsfordFamilyWellforcePartner()];
                case Partner.HCA_TRISTAR:
                    return [2 /*return*/, getHcaTristarPartner()];
                case Partner.HCA_LASVEGAS:
                    return [2 /*return*/, getHcaLasVegasPartner()];
                case Partner.HCA_HEALTHONE:
                    return [2 /*return*/, getHcaHealthOnePartner()];
                case Partner.HCA_FLORIDA:
                    return [2 /*return*/, getHcaFloridaPartner()];
                case Partner.HCA_SANANTONIO:
                    return [2 /*return*/, getHcaSanAntonioPartner()];
                case Partner.HCA_MEDICALCITY:
                    return [2 /*return*/, getHcaMedicalCityPartner()];
                case Partner.HELLOPATIENTS:
                    return [2 /*return*/, getUnimplementedPartner()];
                case Partner.NOVANT:
                    return [2 /*return*/, getNovantPartner()];
                case Partner.AERIAL:
                    return [2 /*return*/, getClearstepPartner()];
                case Partner.PENN:
                    return [2 /*return*/, getPennPartner()];
                case Partner.MAHEALTH:
                    return [2 /*return*/, getMaHealthPartner()];
                case Partner.NORTHWELL:
                    return [2 /*return*/, getNorwellPartner()];
                case Partner.EMORY:
                    return [2 /*return*/, getClearstepPartner()];
                case Partner.ORTHOPEDIC_INSTITUTE:
                    return [2 /*return*/, getOIPartner()];
                case Partner.MOUNT_SINAI:
                    return [2 /*return*/, getMountSinaiPartner()];
                case Partner.SENTARA:
                    return [2 /*return*/, getClearstepPartner()];
                case Partner.ARC:
                    return [2 /*return*/, getArcPartner()];
                case Partner.TUFTS:
                    return [2 /*return*/, getTuftsPartner()];
                case Partner.DEMO_PARTNER:
                    return [2 /*return*/, getDemoPartner()];
                case Partner.COREWELLHEALTH:
                    return [2 /*return*/, getCorewellPartner()];
                case Partner.BSWH:
                    return [2 /*return*/, getBSWHPartner()];
                case Partner.OCHSNER:
                    return [2 /*return*/, getOchsnerPartner()];
                default:
                    throw new Error("Unknown partner: ".concat(partner));
            }
            return [2 /*return*/];
        });
    });
}
export var createPartnerCustomizations = getPartnerCustomizations();
export var PartnerCustomizationContext = createContext({});
