/* GENERATED FROM proto/theme.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */
import "../proto/partners_pb";
import "../proto/results_pb";
export var Theme;
(function (Theme) {
    var Locale;
    (function (Locale) {
        Locale["EN_US"] = "EN_US";
        Locale["ES_MX"] = "ES_MX";
    })(Locale = Theme.Locale || (Theme.Locale = {}));
})(Theme || (Theme = {}));
