var _a;
import '../../../../util/EventLog';
import { Partner } from '../../../clearstep/proto/partners_pb';
import { logToDataLayer } from './BSWH';
import { hcaCustomDataWrapper, logEventToExternalWindow } from './HCAPartner';
var partnerEventLogFunctions = (_a = {},
    _a[Partner.BSWH] = function (type, data) {
        logToDataLayer(type, data);
    },
    _a[Partner.HCA_FLORIDA] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a[Partner.HCA_TRISTAR] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a[Partner.HCA_HEALTHONE] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a[Partner.HCA_LASVEGAS] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a[Partner.HCA_SANANTONIO] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a[Partner.HCA_MEDICALCITY] = function (type, data, options) {
        logEventToExternalWindow(options);
    },
    _a);
export var getCustomDataWrapper = function () {
    var _a;
    return (_a = {},
        _a[Partner.HCA_FLORIDA] = hcaCustomDataWrapper,
        _a[Partner.HCA_TRISTAR] = hcaCustomDataWrapper,
        _a[Partner.HCA_HEALTHONE] = hcaCustomDataWrapper,
        _a[Partner.HCA_LASVEGAS] = hcaCustomDataWrapper,
        _a[Partner.HCA_SANANTONIO] = hcaCustomDataWrapper,
        _a[Partner.HCA_MEDICALCITY] = hcaCustomDataWrapper,
        _a);
};
export var callPartnerEventLog = function (type, partner, data, options) {
    var noop = function () { };
    var fn = partnerEventLogFunctions[partner] || noop;
    fn(type, data, options);
};
