import * as React from 'react';
import '../../clearstep/proto/conversation_pb';
import SingleSelectOption from './SingleSelectOption';
import ScreenReaderFormGroup from '../../tailwind_common/ScreenReaderFormGroup';
import { MessageType, submitLogEvent } from '../../../util/EventLog';
import { isMobileInWebView } from '../../clearstep/util/AppHelpers';
// requires changing "key" prop to force react to regonize change of options prop as a new component
// https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
var SingleSelectNonSearchableQuestion = function (props) {
    var fieldsetRef = React.useRef(null);
    var _a = React.useState(null), selectedIndex = _a[0], setSelectedIndex = _a[1];
    var options = props.options, label = props.label, questionId = props.questionId, autoFocus = props.autoFocus;
    var clickHandler = function (index) { return function (option) {
        var _a;
        // Ignore if any option has already been selected
        if (selectedIndex !== null) {
            return;
        }
        setSelectedIndex(index);
        if (((_a = option.link) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var urlHref = isMobileInWebView() && option.mobileLink ? option.mobileLink : option.link;
            submitLogEvent(MessageType.REDIRECT_INITIATED, {
                url: urlHref,
            });
            window.location.replace(urlHref);
        }
        props.onSelect(option);
    }; };
    React.useEffect(function () {
        var _a;
        if (autoFocus) {
            (_a = fieldsetRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (React.createElement(ScreenReaderFormGroup, { className: "relative flex min-w-26.5 max-w-104 flex-col self-end overflow-y-auto rounded-xl border border-solid border-common-bot-medium-gray shadow-conversation-message focus:outline-none", ref: fieldsetRef, tabIndex: -1, label: label },
        React.createElement("ul", { className: "[&>*:not(:last-child)]:border-b" }, options.map(function (option, index) { return (React.createElement(SingleSelectOption, { key: option.value, option: option, isSelected: selectedIndex === index, onSelect: clickHandler(index), questionId: questionId })); }))));
};
export default SingleSelectNonSearchableQuestion;
