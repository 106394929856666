import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { twMerge } from 'tailwind-merge';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import Textarea from '../../../../tailwind_common/Textarea';
import RadioGroup from '../../../../tailwind_common/RadioGroup';
import { MetricPrompt } from '../MetricPrompt/MetricPrompt';
import { GenericLabel } from '../GenericLabel/GenericLabel';
import { GenericFooter } from '../GenericFooter/GenericFooter';
var emptyOption = {
    value: '',
    label: '',
};
export var PostIntentFeedbackCard = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var _a = current.context, message = _a.message, intent = _a.intent;
    var t = useTranslation().t;
    var seekCareOptions = [
        {
            value: 'book_care',
            label: t('feedbackModal.postIntent.careOptions.bookCare', 'Book care through this site'),
        },
        {
            value: 'call_doctor',
            label: t('feedbackModal.postIntent.careOptions.callDoctor', 'Call the doctor’s office'),
        },
        {
            value: 'go_to_er_or_urgent_care',
            label: t('feedbackModal.postIntent.careOptions.EROrUrgentCare', 'Go to the ER or Urgent Care'),
        },
        {
            value: 'no_booking',
            label: t('feedbackModal.postIntent.careOptions.notBooking', 'I don’t plan to book care'),
        },
    ];
    var selectedSeekCareOption = seekCareOptions.find(function (opt) { return opt.value === intent; }) || emptyOption;
    return (React.createElement("div", { className: "my-6 flex flex-1 flex-col mobile:mt-4" },
        React.createElement(MetricPrompt, { promptText: t('feedbackModal.postIntent.question.promptText', 'How do you plan to'), promptSubtext: t('feedbackModal.postIntent.question.promptSubtext', 'seek care?') }),
        React.createElement(RadioGroup, { options: seekCareOptions, selectedOption: selectedSeekCareOption, onChange: function (opt) { return metricService.send({ type: 'SAVE', intent: opt.value }); }, className: twMerge('mb-6 grid grid-cols-2 gap-4', 'mobile:grid-cols-1'), labelClassName: "text-sm mobile:text-base" }),
        React.createElement(Textarea, { containerClassName: "flex-1", placeholder: t('feedbackModal.generic.question.placeholder', 'Enter your response'), Label: GenericLabel, onChange: function (e) { return metricService.send({ type: 'SAVE', message: e.target.value }); }, value: message !== null && message !== void 0 ? message : '', id: "generic feedback" }),
        React.createElement(GenericFooter, null)));
};
