import React from 'react';
import { useTranslation } from 'react-i18next';
import 'i18next';
import { ExternalLinkIcon } from '../svg/ExternalLink';
export var ExternalLink = function (props) {
    var href = props.href, linkText = props.linkText, linkAltText = props.linkAltText, onClick = props.onClick, className = props.className, _a = props.showIcon, showIcon = _a === void 0 ? true : _a;
    var t = useTranslation().t;
    return (React.createElement("a", { href: href, target: "_blank", rel: "noopener noreferrer", onClick: onClick, className: className },
        linkText,
        showIcon && (React.createElement(ExternalLinkIcon, { title: linkAltText !== null && linkAltText !== void 0 ? linkAltText : t('icons.externalLink.altText', 'Opens in a new window') }))));
};
