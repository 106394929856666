import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningExclamationTriangle } from '../../SVG';
export var PIError = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: "mt-3.25 bg-common-light-red p-3.25" },
        React.createElement("div", { className: "flex items-center pb-3.25" },
            React.createElement(WarningExclamationTriangle, { className: "mr-1.25" }),
            React.createElement("b", null, t('piError.header', 'Personally Identifiable Information'))),
        React.createElement("p", { className: "leading-22" }, t('piError.description', 'It looks like you may be entering personally identifiable information. Please remove to continue.'))));
};
export default PIError;
