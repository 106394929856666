import * as React from 'react';
export var HomeCareBandaidIcon = function () { return (React.createElement("svg", { "aria-hidden": "true", width: "56", height: "56", viewBox: "0 0 56 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "28", cy: "28", r: "28", fill: "var(--primary-color)" }),
    React.createElement("path", { d: "M32.4813 13.803C32.7334 13.5509 33.1007 13.4521 33.4453 13.5436L34.5429 13.8354C38.3564 14.8489 41.3349 17.8274 42.3484 21.6408L42.6401 22.7385C42.7317 23.0831 42.6329 23.4504 42.3808 23.7025L23.4533 42.63C23.1914 42.8919 22.8062 42.9876 22.4522 42.8787L21.024 42.4393C17.5416 41.3681 14.8157 38.6421 13.7444 35.1598L13.3051 33.7316C13.1962 33.3776 13.2919 32.9924 13.5538 32.7305L32.4813 13.803Z", stroke: "white", strokeWidth: "2" }),
    React.createElement("rect", { x: "27.3848", y: "18.8995", width: "14", height: "12", transform: "rotate(45 27.3848 18.8995)", stroke: "white", strokeWidth: "2" }),
    React.createElement("circle", { cx: "27.0312", cy: "27.0312", r: "0.5", transform: "rotate(45 27.0312 27.0312)", fill: "white" }),
    React.createElement("circle", { cx: "24.5564", cy: "27.3848", r: "0.5", transform: "rotate(45 24.5564 27.3848)", fill: "white" }),
    React.createElement("circle", { cx: "27.3848", cy: "24.5563", r: "0.5", transform: "rotate(45 27.3848 24.5563)", fill: "white" }),
    React.createElement("circle", { cx: "29.1525", cy: "29.1525", r: "0.5", transform: "rotate(45 29.1525 29.1525)", fill: "white" }),
    React.createElement("circle", { cx: "26.6777", cy: "29.5061", r: "0.5", transform: "rotate(45 26.6777 29.5061)", fill: "white" }),
    React.createElement("circle", { cx: "29.5061", cy: "26.6777", r: "0.5", transform: "rotate(45 29.5061 26.6777)", fill: "white" }),
    React.createElement("circle", { cx: "28.799", cy: "31.6274", r: "0.5", transform: "rotate(45 28.799 31.6274)", fill: "white" }),
    React.createElement("circle", { cx: "31.6274", cy: "28.799", r: "0.5", transform: "rotate(45 31.6274 28.799)", fill: "white" }))); };
