var _a, _b, _c, _d;
import * as React from 'react';
import { getURLParamValue, useWidgetStyle } from '../clearstep/util/AppHelpers';
import { THEME } from '../clearstep/util/Theme';
var ProgressBar = function (props) {
    var _a = React.useState(true), showProgressBar = _a[0], setShowProgressBar = _a[1];
    var showProgressBarParam = getURLParamValue('showProgressBar');
    if (showProgressBarParam === '0' || showProgressBarParam === 'false') {
        setShowProgressBar(false);
    }
    return (React.createElement(React.Fragment, null, showProgressBar && (React.createElement("div", { className: "h-1.5 w-full rounded-sm bg-ui-light-gray" },
        React.createElement("div", { className: "transition-width h-full rounded-inherit delay-0 duration-200 ease-in", style: { backgroundColor: props.fillColor, width: "".concat(props.fillPercentage, "%") } })))));
};
ProgressBar.defaultProps = {
    fillColor: ((_b = (_a = THEME.widgetCustomizations) === null || _a === void 0 ? void 0 : _a.widgetHeaderTheme) === null || _b === void 0 ? void 0 : _b.progressBarColor) && useWidgetStyle()
        ? (_d = (_c = THEME.widgetCustomizations) === null || _c === void 0 ? void 0 : _c.widgetHeaderTheme) === null || _d === void 0 ? void 0 : _d.progressBarColor
        : 'var(--primary-color)',
};
export default ProgressBar;
