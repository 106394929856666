import * as React from 'react';
import { SourceOfCare } from '../proto/results_pb';
import { CoronavirusTriageTelemedicineIcon, HospitalTriageIcon, CoronavirusTriageHomeIcon, SymptomCheckTriageDoctorIcon, SymptomCheckTriageUrgentCareIcon, } from '../../svg/CareTypes';
import { isCorona, isPatientService, isSymptomCheck } from './AppHelpers';
/* CORONAVIRUS */
export function triageColor(careTypeCode) {
    switch (careTypeCode) {
        case SourceOfCare.Type.AMBULANCE:
        case SourceOfCare.Type.ER:
            return '#ff695b';
        case SourceOfCare.Type.URGENT_CARE:
        case SourceOfCare.Type.TELEMEDICINE:
        case SourceOfCare.Type.NURSE_REACHOUT:
        case SourceOfCare.Type.PRIMARY_CARE:
        case SourceOfCare.Type.DENTIST:
            return '#ffa100';
        case SourceOfCare.Type.HOME:
        case SourceOfCare.Type.UNKNOWN:
            return '#30a661';
        default:
            return '#ffa100';
    }
}
export function triageIcon(bestCareType) {
    var conversationType = window.conversationType;
    switch (bestCareType) {
        case SourceOfCare.Type.AMBULANCE:
        case SourceOfCare.Type.ER:
        case SourceOfCare.Type.NURSE_REACHOUT:
            return React.createElement(HospitalTriageIcon, null);
        case SourceOfCare.Type.URGENT_CARE:
            return React.createElement(SymptomCheckTriageUrgentCareIcon, null);
        case SourceOfCare.Type.TELEMEDICINE:
        case SourceOfCare.Type.ASYNCH_CARE:
        case SourceOfCare.Type.PRIMARY_CARE:
        case SourceOfCare.Type.SPECIALIST:
        case SourceOfCare.Type.DENTIST:
            if (isCorona(conversationType))
                return React.createElement(CoronavirusTriageTelemedicineIcon, null);
            if (isSymptomCheck(conversationType) || isPatientService(conversationType)) {
                return React.createElement(SymptomCheckTriageDoctorIcon, null);
            }
            return null;
        case SourceOfCare.Type.HOME:
        case SourceOfCare.Type.UNKNOWN:
            return React.createElement(CoronavirusTriageHomeIcon, null);
        default:
            return React.createElement(SymptomCheckTriageDoctorIcon, null);
    }
}
/* GENERAL */
export function getSentenceCase(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
export function removeDashes(value) {
    if (!value) {
        return '';
    }
    return value.split('-').join(' ');
}
export function isEmergencyTriageRec(careType) {
    return (!!careType && (careType === SourceOfCare.Type.ER || careType === SourceOfCare.Type.AMBULANCE));
}
export function isDoctorTriageRec(careType) {
    return careType === SourceOfCare.Type.TELEMEDICINE || careType === SourceOfCare.Type.PRIMARY_CARE;
}
